export type ResultLike = Result | boolean | void;

export type ResultLocation = {
    line: number;
    column: number;
};

export class Result<T = void> {
    private errorFlag: boolean;
    readonly errorMessage: string | null;
    readonly data: T | undefined;

    private constructor(errorFlag: boolean, data: T | undefined, message: string | null) {
        this.errorFlag = errorFlag;
        this.data = data;
        this.errorMessage = message;
    }

    static ok(): Result<void>;
    static ok<T>(data: T): Result<T>;
    static ok<T, L>(data: T): Result<T>;
    static ok<T>(data?: T): Result<T> {
        return new Result(false, data, null);
    }

    static error<T = void>(message: string | null = null): Result<T> {
        return new Result<T>(true, undefined, message);
    }

    static from(value: ResultLike): Result;
    static from<T>(value: Result<T>): Result<T>;
    static from(value: Result | boolean | void) {
        if (value && value instanceof Result) {
            // @ts-ignore
            return new Result(value.errorFlag, value.data, value.code, value.errorMessage);
        } else if (value === false) {
            return Result.error();
        } else {
            return Result.ok();
        }
    }

    isOk(this: Result<T>): boolean {
        return !this.errorFlag;
    }

    isError(): boolean {
        return this.errorFlag;
    }

    removeData(): Result<void> {
        return new Result(this.errorFlag, undefined, this.errorMessage);
    }

    unwrap(): T {
        return this.data!;
    }

    map<U>(callback: (value: T) => U): Result<U> {
        if (this.isOk()) {
            return Result.ok(callback(this.data!));
        } else {
            return Result.error<U>(this.errorMessage);
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Result);