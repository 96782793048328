import { WEBGL_ATTRIBUTE_TYPES, WebglAttributesLayout, WebglAttributesLayoutDetails } from './webgl-attributes.ts';
import { WebglBufferWriter } from './webgl-buffer-writer.ts';
import { WebglBuffer } from './webgl-buffer.ts';
import { WebglIndexBuffer } from './webgl-index-buffer.ts';
import { WebglUniformsLayout, WebglUniformsLayoutDetails, WebglUniformValues } from './webgl-uniforms.ts';

export type WebglRenderPassParams<
    Uniforms extends WebglUniformsLayout,
    PerInstanceAttributes extends WebglAttributesLayout,
    PerVertexAttributes extends WebglAttributesLayout,
> = {
    gl: WebGL2RenderingContext;
    program: WebGLProgram;
    uniforms: WebglUniformsLayoutDetails<Uniforms>;
    perInstance: WebglAttributesLayoutDetails<PerInstanceAttributes>;
    perVertex: WebglAttributesLayoutDetails<PerVertexAttributes>;
    instanceInitialCapacity: number;
    vertexCountPerInstance: number;
};

export class WebglRenderPass<
    Uniforms extends WebglUniformsLayout,
    PerInstanceAttributes extends WebglAttributesLayout,
    PerVertexAttributes extends WebglAttributesLayout,
> {
    private gl: WebGL2RenderingContext;
    private program: WebGLProgram;
    private uniforms: WebglUniformsLayoutDetails<Uniforms>;
    private instance: WebglAttributesLayoutDetails<PerInstanceAttributes>;
    private vertex: WebglAttributesLayoutDetails<PerVertexAttributes>;
    private instanceBuffer: WebglBuffer<PerInstanceAttributes>;
    private vertexBuffer: WebglBuffer<PerVertexAttributes>;
    private vertexArrayObject: WebGLVertexArrayObject;
    private elementIndexBuffer: WebglIndexBuffer;

    constructor(params: WebglRenderPassParams<Uniforms, PerInstanceAttributes, PerVertexAttributes>) {
        this.gl = params.gl;
        this.program = params.program;
        this.uniforms = params.uniforms;
        this.vertex = params.perVertex;
        this.instance = params.perInstance;
        this.instanceBuffer = new WebglBuffer({
            gl: this.gl,
            attributes: params.perInstance,
            itemByteSize: params.perInstance.byteSize,
            initialItemCapacity: params.instanceInitialCapacity,
            usage: 'dynamic-draw',
        });
        this.vertexBuffer = new WebglBuffer({
            gl: this.gl,
            attributes: params.perVertex,
            itemByteSize: params.perVertex.byteSize,
            initialItemCapacity: params.vertexCountPerInstance,
            usage: 'static-draw',
        });
        this.vertexArrayObject = this.createVertexArray();
        this.elementIndexBuffer = new WebglIndexBuffer(this.gl);
    }

    private createVertexArray(): WebGLVertexArrayObject {
        let gl = this.gl;
        let vertexArrayObject = gl.createVertexArray()!;

        gl.bindVertexArray(vertexArrayObject);

        this.configureBufferAttributes(gl, this.vertexBuffer.getGlBuffer(), this.vertex.attributes, null);
        this.configureBufferAttributes(gl, this.instanceBuffer.getGlBuffer(), this.instance.attributes, 1);

        gl.bindBuffer(gl.ARRAY_BUFFER, null);
        gl.bindVertexArray(null);

        return vertexArrayObject;
    }

    private configureBufferAttributes<T extends WebglAttributesLayout>(
        gl: WebGL2RenderingContext,
        buffer: WebGLBuffer,
        attributes: WebglAttributesLayoutDetails<T>['attributes'],
        attribDivisor: number | null
    ) {
        gl.bindBuffer(gl.ARRAY_BUFFER, buffer);

        for (let attribute of Object.values(attributes)) {
            let { attributeType, location, size, type, normalized, stride, offset } = attribute;
            let { shaderType } = WEBGL_ATTRIBUTE_TYPES[attributeType];

            gl.enableVertexAttribArray(location);

            if (shaderType === 'float') {
                gl.vertexAttribPointer(location, size, type, normalized, stride, offset);
            } else if (shaderType === 'int') {
                gl.vertexAttribIPointer(location, size, type, stride, offset);
            }

            if (attribDivisor) {
                gl.vertexAttribDivisor(location, attribDivisor);
            }
        }
    }

    draw(uniforms: WebglUniformValues<Uniforms>) {
        let gl = this.gl;
        let vertexCount = this.vertexBuffer.getItemCount();
        let instanceCount = this.instanceBuffer.getItemCount();

        if (instanceCount === 0) {
            return;
        }

        gl.useProgram(this.program);
        gl.bindVertexArray(this.vertexArrayObject);

        for (let name in this.uniforms) {
            let { location, setFunction } = this.uniforms[name];
            let value = uniforms[name];
            setFunction(gl, location, value as any);
        }

        this.vertexBuffer.syncWithGpu();
        this.instanceBuffer.syncWithGpu();

        gl.drawArraysInstanced(gl.TRIANGLES, 0, vertexCount, instanceCount)
    }

    allocateInstance(): WebglBufferWriter<PerInstanceAttributes> {
        return this.instanceBuffer.allocate();
    }

    updateInstance(instanceIndex: number): WebglBufferWriter<PerInstanceAttributes> {
        return this.instanceBuffer.update(instanceIndex);
    }

    deallocateInstance(instanceIndex: number): null {
        return this.instanceBuffer.deallocate(instanceIndex);
    }

    allocateVertex(): WebglBufferWriter<PerVertexAttributes> {
        return this.vertexBuffer.allocate();
    }

    updateVertex(vertexIndex: number): WebglBufferWriter<PerVertexAttributes> {
        return this.vertexBuffer.update(vertexIndex);
    }

    deallocateVertex(vertexIndex: number): null {
        return this.vertexBuffer.deallocate(vertexIndex);
    }

    clearInstances() {
        this.instanceBuffer.clear();
    }
}
globalThis.ALL_FUNCTIONS.push(WebglRenderPass);