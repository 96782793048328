import { blue, bold, cyan, green, red, yellow } from 'colorette';

export function logVariable(name: string, value: any) {
    console.log(fmt(`[${name}] ${value}`, [bold, cyan]));
}

export function logInfo(message: string) {
    console.log(`${fmt('[INFO]', [bold])} ${fmt(message)}`);
}

export function logError(message: string) {
    console.error(`${fmt('[ERROR]', [bold, red])} ${fmt(message)}`);
}

export function logWarning(message: string) {
    console.error(`${fmt('[WARN]', [bold, yellow])} ${fmt(message)}`);
}

export function logStep(message: string) {
    console.log(fmt(`• ${message}...`, [bold, blue]));
}

export function logSuccess(message: string): true {
    console.log(fmt(message, [bold, green]));

    return true;
}

export function exitWithError(message: string): never {
    logError(message);
    process.exit(1);
}

function fmt(message: string, formatters: ((str: string) => string)[] = []) {
    let string = message;

    string = string.replace(/\*[^*]*\*/g, (str) => bold(str.substring(1, str.length - 1)))

    for (let callback of formatters) {
        string = callback(string);
    }

    return string;
}