import { TypeSchemaLike, formatTypeSchema } from './type-schema-like.ts';
import { TypeSchema } from './type-schema.ts';

export function optionalSchema<T>(itemTypeLike: TypeSchemaLike<T>): TypeSchema<T | undefined> {
    let itemType = formatTypeSchema(itemTypeLike);

    return {
        kind: itemType.kind,
        optional: true,
        check(ctx, value) {
            if (value === undefined) {
                return true;
            } else {
                return itemType.check(ctx, value);
            }
        },
        serialize(buffer, value, options, key) {
            let shouldExclude = options?.includedProperties && key && !options.includedProperties.includes(key);

            if (value === undefined || shouldExclude) {
                buffer.writeBoolean(false);
            } else {
                buffer.writeBoolean(true);
                itemType.serialize(buffer, value, options, key);
            }
        },
        deserialize(buffer, options) {
            if (!buffer.readBoolean()) {
                return undefined;
            } else {
                return itemType.deserialize(buffer, options);
            }
        },
    }
}