export type CallbackLoopParams = {
    callback: (elapsedMs: number) => void;
    intervalMs?: number;
    scheduleFunction?: CallbackLoopScheduleFunction;
};

export type CallbackLoopScheduleFunction = 'setTimeout' | 'requestAnimationFrame';

export class CallbackLoop {
    private callback: (elapsedMs: number) => void;
    private intervalMs: number;
    private timer: number | null = null;
    private started: boolean = false;
    private lastUpdateTime: number = 0;
    private scheduleFunction: (callback: () => void, delayMs: number) => number;
    private cancelFunction: (timeout: number) => void;

    constructor(params: CallbackLoopParams) {
        this.callback = params.callback;
        this.intervalMs = params.intervalMs ?? 0;
        
        if (params.scheduleFunction === 'requestAnimationFrame') {
            this.scheduleFunction = (callback) => requestAnimationFrame(callback);
            this.cancelFunction = (timer) => cancelAnimationFrame(timer);
        } else {
            this.scheduleFunction = (callback, delayMs) => setTimeout(callback, delayMs) as unknown as number;
            this.cancelFunction = (timer) => clearTimeout(timer);
        }
    }

    start() {
        if (this.started) {
            return;
        }

        this.lastUpdateTime = performance.now();

        let update = () => {
            let now = performance.now();
            let elapsedMs = now - this.lastUpdateTime;

            this.lastUpdateTime = now;
            this.callback(elapsedMs);
            
            if (this.started) {
                this.timer = this.scheduleFunction(update, this.intervalMs);  
            }
        };

        this.timer = this.scheduleFunction(update, this.intervalMs);
    }

    stop() {
        if (!this.started) {
            return;
        }

        this.cancelFunction(this.timer!);
        this.timer = null;
        this.started = false;
    }

    setInterval(duration: number) {
        this.intervalMs = duration;
    }
}
globalThis.ALL_FUNCTIONS.push(CallbackLoop);