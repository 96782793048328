import { App } from './components/app';

export function onShowPlaySetup() {
    App.instance.setState({
        setupPlayOpen: true
    });
}

export function onHidePlaySetup() {
    App.instance.setState({
        setupPlayOpen: false
    });
}

export function onResumePlay() {
    App.instance.setState({
        playPanelOpen: true,
    });
}