export class SortedMap<K, V> {
    private sortFunction: (a: V, b: V) => number;
    private items: Map<K, V> = new Map();
    private sortedItems: V[] = [];
    private shouldSort: boolean = false;

    constructor(sortFunction: (a: V, b: V) => number) {
        this.sortFunction = sortFunction;
    }

    get size() {
        return this.items.size;
    }

    private sortIfNecessary() {
        if (this.shouldSort) {
            this.sortedItems.sort(this.sortFunction);
            this.shouldSort = false;
        }
    }

    set(key: K, value: V): boolean {
        if (!this.items.has(key)) {
            this.shouldSort = true;
            this.items.set(key, value);
            this.sortedItems.push(value);

            return true;
        }
        
        return false;
    }

    get(key: K): V | undefined {
        return this.items.get(key);
    }

    delete(key: K) {
        let value = this.items.get(key);

        if (value !== undefined) {
            this.items.delete(key);
            this.sortedItems.remove(value);

            return true;
        }

        return false;
    }

    has(key: K): boolean {
        return this.items.has(key);
    }

    keys(): IterableIterator<K> {
        this.sortIfNecessary();

        return this.items.keys();
    }

    values(): IterableIterator<V> {
        this.sortIfNecessary();

        return this.items.values();
    }

    clear() {
        this.items.clear();
        this.sortedItems.length = 0;
    }

    unwrap(): V[] {
        this.sortIfNecessary();

        return this.sortedItems;
    }

    indexOf(item: V): number {
        this.sortIfNecessary();

        return this.sortedItems.indexOf(item);
    }
}
globalThis.ALL_FUNCTIONS.push(SortedMap);