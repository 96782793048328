import { Methods } from '../../utils/language/types.ts';
import { RoomApi } from '../room/room-api.ts';
import { GlobalContext } from './global-context.ts';

export const EXPOSED_ASYNC_METHOD_NAMES: string[] = [];

function expose<K extends keyof Methods<RoomApi>>(key: K, isAsync: boolean = false): Methods<RoomApi>[K] {
    if (isAsync) {
        EXPOSED_ASYNC_METHOD_NAMES.push(key);
    }

    return (...args: any[]) => (GlobalContext.api as any)[key](...args);
}

/**
 * Global object providing access to the framework's methods.
 */
export const Room = {
    /**
     * Wait for the user to perform the specified action.
     * This method heavily relies on type inference to return the correct type based on what is specified.
     * You should not specify the type parameters yourself.
     * @param {object} params
     */
    waitForUserInput: expose('waitForUserInput', true),
    waitForShortcut: expose('waitForShortcut', true),
    waitForButton: expose('waitForButton', true),
    waitForItemSelection: expose('waitForItemSelection', true),
    waitForScroll: expose('waitForScroll', true),
    waitForClientMessage: expose('waitForClientMessage', true),
    waitForServerResponse: expose('waitForServerResponse', true),
    waitForLocalServerResponse: expose('waitForLocalServerResponse', true),
    cancelAllUserInputs: expose('cancelAllUserInputs'),
    prompt: expose('prompt', true),
    getClientData: expose('getClientData', true),

    sendClientMessage: expose('sendClientMessage'),
    setInteractionConcurrency: expose('setConcurrency'),
    setInteractionPriority: expose('setPriority'),

    configureServer: expose('configureServer'),
    isClientConnected: expose('isClientConnected'),

    createRoom: expose('createRoom'),
    deleteRoom: expose('deleteRoom'),
    addPlayerToRoom: expose('addPlayerToRoom'),
    removePlayerFromRoom: expose('removePlayerFromRoom'),
    authenticatePlayer: expose('authenticatePlayer'),
    deauthenticatePlayer: expose('deauthenticatePlayer'),
    emitRoomEvent: expose('emitRoomEvent'),

    configureRenderer: expose('configureRenderer'),
    initLayers: expose('initLayers'),
    updateLayers: expose('updateLayers'),
    shakeLayers: expose('shakeLayers'),
    getPointerPosition: expose('getPointerPosition'),
    focusNext: expose('focusNext'),
    focusPrev: expose('focusPrev'),
    clearFocus: expose('clearFocus'),
    render: expose('render'),
    unmount: expose('unmount'),
    update: expose('update'),
    waitForAnimation: expose('waitForAnimation'),
    waitForDuration: expose('waitForDuration'),
    setAnimationsEnabled: expose('setAnimationsEnabled'),
    getHoveredComponent: expose('getHoveredComponent'),
    getClockProperties: expose('getClockProperties'),
    getLayerProperties: expose('getLayerProperties'),
    getFrameDuration: expose('getFrameDuration'),
    playAudio: expose('playAudio'),
    loadImage: expose('loadImage'),
    addModifier: expose('addModifier'),
    flash: expose('flash'),
    refresh: expose('refresh'),
    getViewRect: expose('getViewRect'),
    getView: expose('getView'),

    getId: expose('getId'),
    getLocalPlayer: expose('getLocalPlayer'),
    getLocalPlayerId: expose('getLocalPlayerId'),
    isLocalPlayer: expose('isLocalPlayer'),
    getSourcePlayer: expose('getSourcePlayer'),
    getSourcePlayerId: expose('getSourcePlayerId'),
    withSourcePlayer: expose('withSourcePlayer'),
    getDeltaMs: expose('getDeltaMs'),
    getDeltaSeconds: expose('getDeltaSeconds'),
    getServerCurrentTime: expose('getServerCurrentTime'),
    getRandomNumber: expose('getRandomNumber'),
    getInteractionSignature: expose('getSourceId'),

    get: expose('get'),
    getOptional: expose('getOptional'),
    getAll: expose('getAll'),
    spawn: expose('spawn'),
    despawn: expose('despawn'),
    findParent: expose('findParent'),
    getParent: expose('getParent'),
    getAncestor: expose('getAncestor'),
    findAncestor: expose('findAncestor'),
    emitEvent: expose('emitEvent'),

    fetchGoogleSpreadsheet: expose('fetchGoogleSpreadsheet'),

    assert: expose('assert'),
    getRoomInfo: expose('getRoomInfo'),
};

export const Debug = {
    getApi: () => GlobalContext.api,
    getAllRoomInfo: expose('getAllRoomInfo'),
};