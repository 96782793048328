import { Component } from '../component/component.ts';
import { View } from '../view/view.ts';

export class DefaultPlayer implements Component {
    id: string;

    constructor(id: string) {
        this.id = id;
    }

    render(view: View): void {
        
    }
}
globalThis.ALL_FUNCTIONS.push(DefaultPlayer);