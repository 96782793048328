import { CSSProperties } from 'preact/compat';
import { BoardLocalData } from './board-local-data';
import { Row } from '../base-components/container';
import { Button } from '../base-components/button';
import { App } from '../components/app';
import { BoardLinkComponent } from './board-link-component';

export type BoardHeaderProps = {
    localData: BoardLocalData;
    isGuest: boolean;
    style?: CSSProperties;
};

export function BoardHeader(props: BoardHeaderProps) {
    let { localData, isGuest } = props;
    let style: CSSProperties = {
        // backgroundColor: 'pink',
        // padding: '5px',
        maxHeight: '25px',
        ...props.style
    };

    let hasOngoingAction = localData.currentInteraction !== null;
    let buttonStyle: CSSProperties = {
        pointerEvents: hasOngoingAction ? 'none' : undefined
    };

    let onClickFlip = () => {
        localData.setInteraction(card => {
            localData.board.flipCard(card);
            localData.notifyBoardUpdated();
            setTimeout(() => localData.scheduleShowTooltip(card));
        });
    };

    let onClickRotate = () => {
        localData.setInteraction(card => {
            localData.board.rotateCard(card);
            localData.notifyBoardUpdated();
        });
    };

    let onClickReveal = () => {
        localData.setInteraction(card => {
            localData.board.revealCard(card);
            localData.notifyBoardUpdated();
            setTimeout(() => localData.scheduleShowTooltip(card));
        });
    };

    let onClickMark = () => {
        localData.setInteraction(card => {
            localData.board.markCard(card, localData.playerId);
            localData.notifyBoardUpdated();
        });
    };

    let onClickClear = () => {
        [...localData.board.cards()].forEach(card => card.marks = []);
        localData.notifyBoardUpdated();
    };

    let onClickDraw = () => {
        localData.setInteraction(card => {
            localData.board.moveCardToZone(card, localData.board.players[card.ownerId].zones.hand);
            localData.notifyBoardUpdated();
        });
    };

    let onClickKill = () => {
        localData.setInteraction(card => {
            localData.board.moveCardToZone(card, localData.board.players[card.ownerId].zones.graveyard);
            localData.notifyBoardUpdated();
        });
    };

    let onClickInspect = () => {
        localData.setInteraction(card => {
            localData.setInspectedZone(card.getZone());
        });
    };

    let onClickStopInspect = () => {
        localData.setInspectedZone(null);
    };

    let onClickShuffle = () => {
        localData.setInteraction(card => {
            localData.board.shuffleZone(card.getZone());
            localData.notifyBoardUpdated();
        });
    };

    let onClickFlipZone = () => {
        localData.setInteraction(targetCard => {
            for (let card of targetCard.getZone()?.cards ?? []) {
                localData.board.flipCard(card);

                for (let attachedCard of card.attachedCards) {
                    localData.board.flipCard(attachedCard);
                }
            }

            localData.notifyBoardUpdated();
        });
    };

    let onClickToggleGlobalVision = () => {
        localData.globalVision = !localData.globalVision;
        localData.forceRefresh();
    };

    let onClickReduce = () => {
        App.instance.setState({
            playPanelOpen: false,
        });
    };

    let onClickClose = () => {
        App.instance.setState({
            initBoardCallback: null,
            playPanelOpen: false,
        });
    };

    return (
        <Row style={style}>
            <Row justifyContent='start' gap={5} grow>
                <Button
                    style={buttonStyle}
                    label='Toggle global vision'
                    onClick={onClickToggleGlobalVision}
                    shortcut='KeyT'
                    tooltip={`Toggle global vision (currently ${localData.globalVision ? 'on' : 'off'}) (T)`}
                    disabled={hasOngoingAction}
                />
                <Button
                    style={buttonStyle}
                    label='Inspect zone'
                    onClick={onClickInspect}
                    shortcut='KeyI'
                    tooltip='Inspect zone (I)'
                    disabled={hasOngoingAction}
                />
                <Button
                    style={buttonStyle}
                    label='Stop inspect zone'
                    onClick={onClickStopInspect}
                    shortcut='Escape'
                    disabled={hasOngoingAction}
                    hidden
                />
                <Button
                    style={buttonStyle}
                    label='Shuffle zone'
                    onClick={onClickShuffle}
                    shortcut='KeyS'
                    tooltip='Shuffle zone (S)'
                    disabled={hasOngoingAction}
                />
                <Button
                    style={buttonStyle}
                    label='Flip zone'
                    onClick={onClickFlipZone}
                    shortcut='Shift_KeyF'
                    tooltip='Flip all cards in zone (Shift F)'
                    disabled={hasOngoingAction}
                />
                <Button
                    style={buttonStyle}
                    label='Flip card'
                    onClick={onClickFlip}
                    shortcut='KeyF'
                    tooltip='Flip card (F)'
                    disabled={hasOngoingAction}
                />
                <Button
                    style={buttonStyle}
                    label='Exhaust card'
                    onClick={onClickRotate}
                    shortcut='KeyE'
                    tooltip='Exhaust card (E)'
                    disabled={hasOngoingAction}
                />
                <Button
                    style={buttonStyle}
                    label='Reveal card'
                    onClick={onClickReveal}
                    shortcut='KeyR'
                    tooltip='Reveal card (R)'
                    disabled={hasOngoingAction}
                />
                <Button
                    style={buttonStyle}
                    label='Mark card'
                    onClick={onClickMark}
                    shortcut={['KeyM', 'Semicolon']}
                    tooltip='Mark card (M)'
                    disabled={hasOngoingAction}
                />
                <Button
                    style={buttonStyle}
                    label='Clear marks'
                    onClick={onClickClear}
                    shortcut='KeyC'
                    tooltip='Clear marks (C)'
                    disabled={hasOngoingAction}
                />
                <Button
                    style={buttonStyle}
                    label='Draw'
                    onClick={onClickDraw}
                    shortcut='KeyD'
                    tooltip='Move card to hand (D)'
                    disabled={hasOngoingAction}
                />
                <Button
                    style={buttonStyle}
                    label='Kill'
                    onClick={onClickKill}
                    shortcut='KeyK'
                    tooltip='Move card to graveyard (K)'
                    disabled={hasOngoingAction}
                />
            </Row>
            <Row justifyContent='end' gap={5} grow>
                <BoardLinkComponent
                    isGuest={isGuest}
                    localData={localData}
                />
                <Button
                    label='Reduce'
                    onClick={onClickReduce}
                    disabled={hasOngoingAction}
                />
                <Button
                    label='End game'
                    onClick={onClickClose}
                    disabled={hasOngoingAction}
                />
            </Row>
        </Row>
    );
};