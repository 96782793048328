import { ColorLike } from '../../../utils/color/color.ts';
import { DEFAULT_VIRTUAL_VIEWPORT } from '../../graphics-engine/graphics-engine-constants.ts';
import { Component } from '../../component/component.ts';
import { View } from '../../view/view.ts';
import { LayerId } from '../../graphics-engine/layer-types.ts';
import { getRandomNumber } from '../../../utils/language/math.ts';

/**
 * Particle emitted via {@link AnimationQueue.emitParticles}.
 * It can be modified via the `editParticle` function specified in {@link EmitParticlesParams}.
 */
export class Particle implements Component {
    startTime: number = 0;

    /**
     * Duration in milliseconds during which the particle stays alive.
     */
    duration: number = 1000;
    /**
     * Starting X coordinate of the particle.
     * 
     * Default: half of the default virtual viewport's width.
     */
    x: number = DEFAULT_VIRTUAL_VIEWPORT[0] / 2;
    /**
     * Starting Y coordinate of the particle.
     * 
     * Default: half of the default virtual viewport's height.
     */
    y: number = DEFAULT_VIRTUAL_VIEWPORT[1] / 2;
    /**
     * Direction in which the particle moves.
     * 
     * Default: a random value between 0 and 2 * PI.
     */
    angle: number = getRandomNumber() * 2 * Math.PI;
    /**
     * Starting speed (in virtual unit/second) at which the particle moves when it is emitted.
     */
    speed: number = 300;
    /**
     * Final speed at which the particle moves. **Currently ignored.**
     */
    speedEnd: number = 300;
    /**
     * Starting speed (in radian/second) at which the particle's angle changes.
     * Specify a negative value to rotate in the other direction.
     */
    angularSpeed: number = 0;
    /**
     * Final speed at which the particle's angle changes. **Currently ignored.**
     */
    angularSpeedEnd: number = 0;
    /**
     * Starting size of the particle.
     */
    size: number = 10;
    /**
     * Final size of the particle.
     */
    sizeEnd: number = 10;
    /**
     * Starting color of the particle.
     */
    color: ColorLike = 'black';
    /**
     * Final color of the particle.
     */
    colorEnd: ColorLike = 'black';
    /**
     * Starting alpha of the particle.
     */
    alpha: number = 1;
    /**
     * Final alpha of the particle.
     */
    alphaEnd: number = 1;
    imageUrl: string | null = null;
    layerId: LayerId = null;
 
    setLayerId(layerId: string | null): this {
        this.layerId = layerId;
        return this;
    }

    setImageUrl(imageUrl: string | null): this {
        this.imageUrl = imageUrl;
        return this;
    }

    /**
     * Set the `duration` of the particle.
     * @param duration 
     * @returns 
     */
    setDuration(duration: number): this {
        this.duration = duration;
        return this;
    }

    /**
     * Set both the `duration` and `speed` of the particle by specifying how far
     * it must go and at which speed.
     * @param range 
     * @param speed 
     * @returns 
     */
    setDurationFromRangeAndSpeed(range: number, speed: number): this {
        this.duration = range / speed * 1000;
        this.speed = speed;
        this.speedEnd = speed;
        return this;
    }

    /**
     * Set the starting position of the particle.
     * @param x 
     * @param y 
     * @returns 
     */
    setStartingPosition(x: number, y: number): this;
    setStartingPosition(point: { x: number, y: number }): this;
    setStartingPosition(x: number | { x: number, y: number }, y?: number): this {
        if (typeof x === 'number') {
            this.x = x;
            this.y = y!;
        } else {
            this.x = x.x;
            this.y = x.y;
        }
        return this;
    }

    /**
     * Set the starting direction of the particle.
     * @param angle 
     * @returns 
     */
    setAngle(angle: number): this {
        this.angle = angle;
        return this;
    }

    /**
     * Set the direction of the particle by specifying the position it must reach.
     * `setPosition` must have been called before.
     * @param xEnd 
     * @param yEnd 
     * @returns 
     */
    setAngleFromEndPosition(xEnd: number, yEnd: number): this {
        this.angle = Math.atan2(yEnd - this.y, xEnd - this.x);
        return this;
    }

    /**
     * Set the starting and final speed of the particle.
     * @param speed 
     * @param speedEnd 
     * @returns 
     */
    setSpeed(speed: number, speedEnd: number = speed): this {
        this.speed = speed;
        this.speedEnd = speedEnd
        return this;
    }

    /**
     * Set the starting and final angular speed of the particle.
     * @param angularSpeed 
     * @param angularSpeedEnd 
     * @returns 
     */
    setAngularSpeed(angularSpeed: number, angularSpeedEnd: number = angularSpeed): this {
        this.angularSpeed = angularSpeed;
        this.angularSpeedEnd = angularSpeedEnd
        return this;
    }

    /**
     * Set the starting and final size of the particle.
     * @param size 
     * @param sizeEnd 
     * @returns 
     */
    setSize(size: number, sizeEnd: number = size): this {
        this.size = size;
        this.sizeEnd = sizeEnd;
        return this;
    }

    /**
     * Set the starting and final color of the particle.
     * @param color 
     * @param colorEnd 
     * @returns 
     */
    setColor(color: ColorLike, colorEnd: ColorLike = color): this {
        this.color = color;
        this.colorEnd = colorEnd;
        return this;
    }

    /**
     * Set the starting and final alpha of the particle.
     * @param alpha 
     * @param alphaEnd 
     * @returns 
     */
    setAlpha(alpha: number, alphaEnd: number = alpha): this {
        this.alpha = alpha;
        this.alphaEnd = alphaEnd;
        return this;
    }

    render(view: View): void {
        let duration = this.duration;
        let dx = Math.cos(this.angle);
        let dy = Math.sin(this.angle);
        let m = this.speed * (this.duration / 1000);

        view.paint({
            layerId: this.layerId,
            unsafeOpti: true,
            duration: this.duration,
            detectable: false,
            x: { start: this.x, end: this.x + dx * m, duration },
            y: { start: this.y, end: this.y + dy * m, duration },
            width: { start: this.size, end: this.sizeEnd, duration },
            height: { start: this.size, end: this.sizeEnd, duration },
            color: { start: this.color, end: this.colorEnd, duration },
            alpha: { start: this.alpha, end: this.alphaEnd, duration },
            image: this.imageUrl,
            borderRadius: '50%'
        });
    }
}
globalThis.ALL_FUNCTIONS.push(Particle);