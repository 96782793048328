import { KeysFor } from '../../utils/language/types.ts';
import { RoomApi } from '../room/room-api.ts';
import { View } from '../view/view.ts';
import { Component } from './component.ts';

export type ComponentShape = 'rectangle' | 'circle';
export type ComponentInteractionCallback = (api: RoomApi) => Promise<void>;
export type ComponentHookMethodName = KeysFor<Component, () => void> & `on${string}`;
export type ComponentHighlightMethodName = Extract<KeysFor<Component, (view: View) => void>, `highlight${string}`>;
export type ComponentRenderMethods = Exclude<KeysFor<Component, (view: View) => void>, KeysFor<Component, () => void>>;

export type PromptableComponent = Component & Pick<Required<Component>, 'waitForData'>;
export type GetComponentResolveType<T extends PromptableComponent> = ReturnType<T['waitForData']>;

export type RoomHookMethodName = Exclude<KeysFor<Component, (roomId: string) => void> & `on${string}`, ComponentHookMethodName>;

export function isComponent(value: unknown): value is Component {
    return typeof value === 'object' && value !== null && 'render' in value && typeof value.render === 'function';
}