import { Component } from '../../framework/component/component.ts';
import { View } from '../../framework/view/view.ts';
import { LobbyPreview } from '../lobby-room/lobby-preview.ts';

export class HubPlayer implements Component {
    id: string;
    lobby: LobbyPreview | null = null;
    gameId: string | null = null;
    isInMatchmaking: boolean = false;
    gear: Map<any, number> = new Map();
    hasValidGear: boolean = false;

    constructor(id: string) {
        this.id = id;
    }

    isIdle(): boolean {
        return this.lobby === null && this.gameId === null;
    }

    render(view: View) {

    }
}
globalThis.ALL_FUNCTIONS.push(HubPlayer);