export class LocalStorage {
    private storage = typeof window !== 'undefined' ? window.localStorage : ({} as Storage);
    private keyPrefix: string = '';

    setKeyPrefix(prefix: string) {
        this.keyPrefix = prefix;
    }

    getItem(key: string) {
        let json = this.storage.getItem(this.keyPrefix + key);

        if (json) {
            try {
                return JSON.parse(json);
            } catch {}
        }

        return null;
    }

    setItem(key: string, data: any) {
        let json = JSON.stringify(data);

        this.storage.setItem(this.keyPrefix + key, json);
    }

    removeItem(key: string) {
        this.storage.removeItem(this.keyPrefix + key);
    }

    clear() {
        this.storage.clear();
    }
}
globalThis.ALL_FUNCTIONS.push(LocalStorage);