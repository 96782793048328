import crypto from 'crypto';
import { stringToArrayBuffer } from './array-buffer.ts';
import { DECRYPTED_CHUNK_SIZE, ENCRYPTED_CHUNK_SIZE, RSA_ALGORITHM_NAME } from './rsa-constants.ts';
import { importRsaKey } from './rsa-key.ts';

// https://stackoverflow.com/questions/65332716/how-to-encrypt-with-cryptojs-and-decrypt-with-rust-magic-crypt

export async function encryptRsaMessage(message: string | ArrayBuffer, keyPem: string): Promise<ArrayBuffer> {
    let arrayBuffer = typeof message === "string" ? stringToArrayBuffer(message) : message;
    let key = await importRsaKey(keyPem, "encrypt");
    let chunkSize = DECRYPTED_CHUNK_SIZE;
    let chunkCount = Math.floor((arrayBuffer.byteLength - 1) / chunkSize) + 1;
    let chunks: ArrayBuffer[] = [];

    for (let i = 0; i < chunkCount; ++i) {
        let start = i * chunkSize;
        let end = Math.min(start + chunkSize, arrayBuffer.byteLength);
        let input = arrayBuffer.slice(start, end);
        let encryptedChunk = await crypto.subtle.encrypt({ name: RSA_ALGORITHM_NAME }, key, input);

        chunks.push(encryptedChunk);
    }

    let totalLength = chunks.reduce((acc, chunk) => acc + chunk.byteLength, 0);
    let result = new Uint8Array(totalLength);
    let currentLength = 0;

    for (let i = 0; i < chunks.length; ++i) {
        result.set(new Uint8Array(chunks[i]), currentLength);

        currentLength += chunks[i].byteLength;
    }

    return result.buffer;
}

export async function decryptRsaMessage(ciphertext: ArrayBuffer, keyPem: string): Promise<ArrayBuffer | null> {
    let arrayBuffer = ciphertext;
    let key = await importRsaKey(keyPem, "decrypt");
    let length = arrayBuffer.byteLength;
    let chunkSize = ENCRYPTED_CHUNK_SIZE;
    let chunkCount = Math.ceil(length / chunkSize);
    let chunks: ArrayBuffer[] = [];

    if (arrayBuffer.byteLength % ENCRYPTED_CHUNK_SIZE !== 0) {
        return null;
    }

    for (let i = 0; i < chunkCount; ++i) {
        let start = i * chunkSize;
        let slice = new Uint8Array(arrayBuffer, start, chunkSize);

        try {
            let decryptedChunk = await crypto.subtle.decrypt({ name: RSA_ALGORITHM_NAME }, key, slice);

            chunks.push(decryptedChunk);
        } catch {
            return null;
        }
    }

    let resultLength = chunks.reduce((acc, arrayBuffer) => acc + arrayBuffer.byteLength, 0);
    let result = new Uint8Array(resultLength);
    let currentResultLength = 0;

    for (let i = 0; i < chunks.length; ++i) {
        let chunk = new Uint8Array(chunks[i]);
        let chunkLength = chunk.length;

        result.set(chunk, currentResultLength);
        currentResultLength += chunkLength;
    }

    return result.buffer;
}
