import { CSSProperties } from 'preact/compat';
import { BoardLocalData } from './board-local-data';
import { BoardCard } from './board-card';

export type BoardCardBackComponentProps = {
    card: BoardCard;
    localData: BoardLocalData;
};

export function BoardCardBackComponent(props: BoardCardBackComponentProps) {
    let { card, localData } = props;
    let color = localData.getPlayerColor(card.ownerId);
    let content = card.isFlipped ? '🎴' : '?';
    let style: CSSProperties = {
        boxSizing: 'border-box',
        border: `1px solid ${color}`,
        // objectFit: 'content',
        aspectRatio: '2.5 / 3.5',
        height: '100%',
        backgroundColor: 'black',
        font: '100px Arial',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '7px',
        pointerEvents: 'none',
    };

    return <div style={style}>{content}</div>;
};