import { DomEvent, DomEventName, DomEventNameToType, MouseButton } from './dom-events-types.ts';

export type DragData = {
    started: boolean;
    button: MouseButton;
    startX: number;
    startY: number;
    metaKey: boolean;
    ctrlKey: boolean;
    shiftKey: boolean;
    altKey: boolean;
};

export type EventMap<T> =
    T extends Window ? WindowEventMap
    : T extends Document ? DocumentEventMap
    : T extends HTMLElement ? HTMLElementEventMap
    : never;

export function getDomEventName(evt: DomEvent): DomEventName {
    if (evt.kind === 'keyboard') {
        if (evt.action === 'down') {
            return 'key-down';
        } else if (evt.action === 'up') {
            return 'key-up';
        }
    } else if (evt.kind === 'mouse') {
        if (evt.action === 'down') {
            return 'mouse-down';
        } else if (evt.action === 'up') {
            return 'mouse-up';
        } else if (evt.action === 'move') {
            return 'mouse-move';
        } else if (evt.action === 'click') {
            return 'click';
        } else if (evt.action === 'drag-start') {
            return 'drag-start';
        } else if (evt.action === 'drag-progress') {
            return 'drag';
        } else if (evt.action === 'drag-end') {
            return 'drag-end';
        }
    } else if (evt.kind === 'wheel') {
        if (evt.action === 'down') {
            return 'scroll-down';
        } else if (evt.action === 'up') {
            return 'scroll-up';
        }
    }

    console.error(evt);
    throw new Error(`cannot get name for event`);
}