export class DualMap<K, V> {
    private keyToValue: Map<K, V> = new Map();
    private valueToKey: Map<V, K> = new Map();

    constructor(iterator?: Iterable<[K, V]>) {
        if (iterator) {
            for (let [key, value] of iterator) {
                this.keyToValue.set(key, value);
                this.valueToKey.set(value, key);
            }
        }
    }

    get size() {
        return this.keyToValue.size;
    }

    getByKey(key: K): V | undefined {
        return this.keyToValue.get(key);
    }

    getByValue(value: V): K | undefined {
        return this.valueToKey.get(value);
    }

    set(key: K, value: V): V | undefined {
        let previousValue = this.getByKey(key);

        if (previousValue !== undefined) {
            this.valueToKey.delete(previousValue);
        }

        this.keyToValue.set(key, value);
        this.valueToKey.set(value, key);

        return previousValue;
    }

    hasKey(key: K): boolean {
        return this.keyToValue.has(key);
    }

    hasValue(value: V): boolean {
        return this.valueToKey.has(value);
    }

    deleteByKey(key: K): V | undefined {
        let value = this.keyToValue.get(key);

        if (value !== undefined) {
            this.keyToValue.delete(key);
            this.valueToKey.delete(value);
        }

        return value;
    }

    deleteByValue(value: V): K | undefined {
        let key = this.valueToKey.get(value);

        if (key !== undefined) {
            this.keyToValue.delete(key);
            this.valueToKey.delete(value);
        }

        return key;
    }

    clear() {
        this.keyToValue.clear();
        this.valueToKey.clear();
    }

    keys(): IterableIterator<K> {
        return this.keyToValue.keys();
    }

    values(): IterableIterator<V> {
        return this.keyToValue.values();
    }
    
    entries(): IterableIterator<[K, V]> {
        return this.keyToValue.entries();
    }
}
globalThis.ALL_FUNCTIONS.push(DualMap);