import { BoardLocalData } from './board-local-data';
import { toCssPixels } from 'outpost';
import { CSSProperties, useEffect, useRef } from 'preact/compat';
import { validateCallback } from '../utils';
import { BoardCardFrontComponent } from './board-card-front-component';
import { BoardCardBackComponent } from './board-card-back-component';
import { HIGHLIGHT_CLASS_NAME, OVERLAY_CLASS_NAME } from '../components/ui-constants';
import { BoardCard } from './board-card';
import { BoardZone } from './board-types';
import { BoardCardMarksComponent } from './board-card-marks-component';
import { Row } from '../base-components/container';

export type BoardCardComponentProps = {
    localData: BoardLocalData;
    sourceZone: BoardZone;
    card: BoardCard;
    stackIndex: number;
    disableHover?: boolean;
};

export function BoardCardComponent(props: BoardCardComponentProps) {
    let { localData, card, sourceZone, disableHover, stackIndex } = props;
    let isDraggable = !localData.getInteractedCard('dragged');
    let overlayRef = useRef<HTMLDivElement>(null);

    let isVisible = card.isVisibleBy(localData.playerId, localData.globalVision);

    let isValidDropTarget =
        localData.getInteractedCard('dragged') !== card &&
        card.parentCard === null;

    let getOverlay = () => overlayRef.current!;

    let onDragStart = (evt: DragEvent) => {
        getOverlay().classList.remove(HIGHLIGHT_CLASS_NAME);

        localData.setInteractedCard('dragged', card);
        localData.setHoveredCard(null);
        localData.forceRefresh();
    };

    let onDragEnd = (evt: MouseEvent) => {
        localData.grabbed = false;
        localData.setInteractedCard('dragged', null);
        localData.forceRefresh();
    };

    let onDragOver = (evt: DragEvent) => {
        evt.preventDefault();
    };

    let onDragEnter = (evt: DragEvent) => {
        getOverlay().classList.add(HIGHLIGHT_CLASS_NAME);
    };

    let onDragLeave = (evt: DragEvent) => {
        getOverlay().classList.remove(HIGHLIGHT_CLASS_NAME);
    };

    let onDrop = (evt: DragEvent) => {
        getOverlay().classList.remove(HIGHLIGHT_CLASS_NAME);

        let droppedCard = localData.getInteractedCard('dragged');

        if (droppedCard) {
            let targetZone = card.getZone();

            if (targetZone?.metadata.kind === 'stack') {
                localData.board.moveCardToZone(droppedCard, targetZone);
            } else {
                localData.board.attachCard(droppedCard, card);
            }

            localData.notifyBoardUpdated();
        }
    };

    let onMouseDown = (evt: MouseEvent) => {
        if (evt.button === 0) {
            if (localData.currentInteraction) {
                localData.currentInteraction(card);
            }
        } else if (evt.button === 2) {
            localData.board.markCard(card, localData.playerId);
            localData.notifyBoardUpdated();
        }
    };

    let cursor = undefined;

    if (localData.currentInteraction) {
        cursor = 'cell';
    } else if (isDraggable) {
        cursor = 'grab';
    }

    let isHovered = localData.getInteractedCard('hovered') === card;
    let style: CSSProperties = {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: toCssPixels(-stackIndex * 15),
        left: toCssPixels(stackIndex * 15),
        zIndex: isDraggable && localData.getInteractedCard('hovered') === card ? 1 : 0,
        cursor
    };

    let classList = [OVERLAY_CLASS_NAME];

    if (isHovered && !disableHover) {
        classList.push(HIGHLIGHT_CLASS_NAME);
    }

    classList.push(card.cardId);

    let overlay = <div
        ref={overlayRef}
        className={classList.join(' ')}
        onDragOver={validateCallback(onDragOver, isValidDropTarget)}
        onDragEnter={validateCallback(onDragEnter, isValidDropTarget)}
        onDragLeave={validateCallback(onDragLeave, isValidDropTarget)}
        onDrop={validateCallback(onDrop, isValidDropTarget)}
        onMouseDown={onMouseDown}
    ></div>;

    let content = isVisible
        ? <BoardCardFrontComponent card={card} localData={localData} />
        : <BoardCardBackComponent card={card} localData={localData} />;

    let eyeIcon = undefined;

    if (card.isRevealed) {
        eyeIcon = <div style={{
            position: 'absolute',
            top: -10,
            right: 0,
            fontSize: 'xx-large',
            // backgroundColor: 'black',
            color: 'gold',
            pointerEvents: 'none',
        }}>👁</div>;
    }

    if (card.isRotated) {
        style.transform = 'rotate(90deg)';
        style.transformOrigin = 'center';
    }

    let markElt = <BoardCardMarksComponent card={card} localData={localData} />;

    return <Row
        style={style}
        draggable
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        justifyContent='center'
        alignItems='center'
    >
        {content}
        {eyeIcon}
        {markElt}
        {overlay}
    </Row>;
};