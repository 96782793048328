import { Point, PointLike, PointProperties } from './point.ts';
import { Vector } from './vector.ts';

/**
 * Union type used by {@link Line.from} to create a new line, depending on what is specified:
 * - `Line`: creates a clone of the line
 * - `{ x1: number, y1: number, x2: number, y2: number }`: creates a line that goes from (x1, y1) to (x2, y2)
 * - `[x1: number, y1: number, x2: number, y2: number]`: creates a line that goes from (x1, y1) to (x2, y2)
 * - `[PointLike, PointLike]`: creates a line that goes from the first point to the second
 */
export type LineLike =
    | Line
    | { x1: number; y1: number; x2: number; y2: number; }
    | [number, number, number, number]
    | [PointLike, PointLike];

export type LineProperties = { x1: number; y1: number; x2: number; y2: number; };

/**
 * Represents a 2D line segment that goes from (x1, y1) to (x2, y2)
 */
export class Line {
    /**
     * X coordinate of the first extremity of the line.
     */
    x1: number;
    /**
     * Y coordinate of the first extremity of the line.
     */
    y1: number;
    /**
     * X coordinate of the second extremity of the line.
     */
    x2: number;
    /**
     * Y coordinate of the second extremity of the line.
     */
    y2: number;

    /**
     * 
     * @param x1 
     * @param y1 
     * @param x2 
     * @param y2 
     */
    constructor(x1: number, y1: number, x2: number, y2: number) {
        this.x1 = x1;
        this.y1 = y1;
        this.x2 = x2;
        this.y2 = y2;
    }

    /**
     * Creates a new line from the specified {@link LineLike}.
     * @param lineLike 
     * @returns 
     */
    static from(lineLike: LineLike): Line {
        if (lineLike instanceof Line) {
            return lineLike.clone();
        } else if (Array.isArray(lineLike)) {
            if (lineLike.length === 4) {
                return new Line(lineLike[0], lineLike[1], lineLike[2], lineLike[3]);
            } else {
                let p1 = Point.from(lineLike[0]);
                let p2 = Point.from(lineLike[1]);

                return new Line(p1.x, p1.y, p2.x, p2.y);
            }
        } else {
            return new Line(lineLike.x1, lineLike.y1, lineLike.x2, lineLike.y2);
        }
    }

    /**
     * Creates a copy of the line.
     * @returns 
     */
    clone(): Line {
        return new Line(this.x1, this.y1, this.x2, this.y2);
    }

    /**
     * Returns a new vector that goes from the first extremity of the line to the other.
     * @returns 
     */
    getOrientationVector(): Vector {
        return new Vector(this.x2 - this.x1, this.y2 - this.y1);
    }

    /**
     * Returns a new vector orthogonal to the line.
     * The `towards` parameter is used to select which one of the two candidates vector to choose: the method
     * selects the vector that makes so that the first extremity of the line added to the vector is closest to the
     * specified point. The length of the vector is equal to the length of the line.
     * @param towards 
     * @returns 
     */
    getOrthogonalVector(towards: PointLike): Vector {
        // TODO: optimize this
        let candidates = this.getOrientationVector().getOrthogonals();
        let d1 = this.getStart().add(candidates[0]).getDistanceTo(towards);
        let d2 = this.getStart().add(candidates[1]).getDistanceTo(towards);

        return d1 <= d2 ? candidates[0] : candidates[1];
    }

    /**
     * Returns a copy of the first extremity of the line.
     * @returns 
     */
    getStart(): Point {
        return new Point(this.x1, this.y1);
    }

    /**
     * Returns a copy of the second extremity of the line.
     * @returns 
     */
    getEnd(): Point {
        return new Point(this.x2, this.y2);
    }

    rotate(angle: number, center: PointLike): Line {
        let { x: cx, y: cy } = Point.resolve(center);
        let cos = Math.cos(angle);
        let sin = Math.sin(angle);
        let x1 = cos * (this.x1 - cx) - sin * (this.y1 - cy) + cx;
        let y1 = sin * (this.x1 - cx) + cos * (this.y1 - cy) + cy;
        let x2 = cos * (this.x2 - cx) - sin * (this.y2 - cy) + cx;
        let y2 = sin * (this.x2 - cx) + cos * (this.y2 - cy) + cy;

        return new Line(x1, y1, x2, y2);
    }

    equals(other: Line): boolean {
        return (this.x1 === other.x1 && this.y1 === other.y1 && this.x2 === other.x2 && this.y2 === other.y2)
            || (this.x1 === other.x2 && this.y1 === other.y2 && this.x2 === other.x1 && this.y2 === other.y1);
    }

    getFollowupPoint(other: Line): PointProperties | null {
        if (this.x1 === other.x1 && this.y1 === other.y1) {
            return { x: other.x2, y: other.y2 };
        } else if (this.x1 === other.x2 && this.y1 === other.y2) {
            return { x: other.x1, y: other.y1 };
        } else {
            return null;
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Line);