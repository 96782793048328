import { RenderParams } from './render-animation/render-params.ts';
import { Client } from '../client/client.ts';
import { AnimationCallback, AnimationId } from './animation-types.ts';
import { EmitParticlesParams } from './emit-particles-animation/emit-particles-params.ts';
import { PlayAudioParams } from './play-audio-animation/play-audio-params.ts';
import { playAudioAnimation } from './play-audio-animation/play-audio-animation.ts';
import { emitParticlesAnimation } from './emit-particles-animation/emit-particles-animation.ts';
import { UpdateClockParams } from './update-clock-animation/update-clock-params.ts';
import { UpdateLayerParams } from './update-layer-animation/update-layer-params.ts';
import { Component } from '../component/component.ts';
import { AnimationGroup } from './animation-group.ts';
import { renderAnimation } from './render-animation/render-animation.ts';
import { updateClockAnimation } from './update-clock-animation/update-clock-animation.ts';
import { updateLayerAnimation } from './update-layer-animation/update-layer-animation.ts';
import { waitAnimation } from './wait-animation/wait-animation.ts';
import { List } from '../../utils/memory/list.ts';
import { AnimationApi } from './animation-api.ts';
import { stopAnimation } from './stop-animation/stop-animation.ts';
import { AnimationQueueDummy } from './animation-queue-dummy.ts';
import { ClockProperties } from '../../utils/time/clock-types.ts';
import { Clock } from '../../utils/time/clock.ts';

export type AnimationQueueParams = {
    nonBlocking?: boolean;
    instant?: boolean;
};

export class AnimationQueue {
    private client: Client;
    private clock: Clock = new Clock();
    private groups: List<AnimationGroup> = new List();
    private nonBlocking: boolean;
    private instant: boolean;

    constructor(client: Client, params: AnimationQueueParams = {}) {
        this.client = client;
        this.nonBlocking = params.nonBlocking ?? false;
        this.instant = params.instant ?? false;
    }

    schedule(animation: AnimationCallback<void>): AnimationQueue;
    schedule<T>(animation: AnimationCallback<T>, params: T): AnimationQueue;
    schedule<T>(animation: AnimationCallback<T>, params?: T): AnimationQueue {
        let api = new AnimationApi(this.client, {
            clock: this.clock,
            animation,
            params
        });

        this.requireLastGroup().add(api);

        if (this.instant) {
            api.stop();
        }

        return this;
    }

    scheduleFromApi(animationApi: AnimationApi): AnimationQueue {
        this.requireLastGroup().add(animationApi);

        return this;
    }

    async awaitAll() {
        await Promise.all([...this.animations()].map(api => api.getOnCompletePromise()));
    }

    async awaitLast() {
        await this.groups.getLast()?.getLast()?.getOnCompletePromise();
    }

    /**
     * Make so all subsequent scheduled animations will start after the currently scheduled animations.
     * 
     * Note: some animations provide a `blocking` parameter (e.g {@link PlayAudioParams}).
     * If this field is set to `false`, it does not block subsequent animations. It is `true` by default for most animations.
     * @returns 
     */
    queue() {
        this.groups.push(new AnimationGroup(this.clock));

        return this;
    }

    update() {
        this.clock.tick();

        for (let group of this.groups) {
            group.update();

            if (!this.nonBlocking && group.isBlocking()) {
                break;
            }
        }

        for (let group of this.groups) {
            if (group.isCompleted()) {
                this.groups.remove(group);
            }
        }
    }

    forceStopNow(predicate: AnimationId | ((id: AnimationId) => boolean)): AnimationQueue {
        let callback = typeof predicate === 'function' ? predicate : (() => predicate);

        for (let animation of this.animations()) {
            if (callback(animation.getId())) {
                animation.stop();
            }
        }

        return this;
    }

    clear(): AnimationQueue {
        return this.forceStopNow(() => true);
    }

    getClockProperties(): ClockProperties {
        return this.clock.getProperties();
    }

    private requireLastGroup(): AnimationGroup {
        if (this.groups.size === 0) {
            this.groups.push(new AnimationGroup(this.clock));
        }

        return this.groups.getLast()!;
    }

    private *animations() {
        for (let group of this.groups) {
            yield* group.animations();
        }
    }

    /**
     * Wait for the specified duration (in milliseconds).
     * @param duration 
     * @returns 
     */
    wait(duration: number): AnimationQueue {
        return this.schedule(waitAnimation, duration);
    }

    stop(predicate: AnimationId | ((id: AnimationId) => boolean) = () => true): AnimationQueue {
        return this.schedule(stopAnimation, predicate);
    }

    render<T extends Component>(params: RenderParams<T> | T = {}): AnimationQueue {
        let formattedParams: RenderParams<T> =
            'render' in params ? { component: params } :
            params;

        return this.schedule(renderAnimation, formattedParams);
    }

    /**
     * Update the clock properties. Each animation queue has its own clock.
     * @param params 
     * @returns 
     */
    updateClock(params: UpdateClockParams): AnimationQueue {
        return this.schedule(updateClockAnimation, params);
    }

    /**
     * Update the specified layer. If it does not exist, it is created.
     * @param params 
     * @returns
     */
    updateLayer(params: UpdateLayerParams): AnimationQueue {
        return this.schedule(updateLayerAnimation, params);
    }

    /**
     * Start emitting particles.
     * @param params 
     * @returns 
     * @example
     * const PARTICLES_SCENE_ID = 0;
     * const PARTICLES_ANIMATION_ID = 'sound';
     * 
     * export class RootComponent implements Component {
     *     onMountClient(api: ComponentApi): void {
     *         // Set the virtual viewport to a square a side of 1000 virtual pixel.
     *         // This will cause the canvas to be a square. The default is 1600x900.
     *         api.configureRenderer({
     *             virtualViewport: [1000, 1000]
     *         });
     * 
     *         api.now().updateScene({
     *             layerId: PARTICLES_SCENE_ID,
     *             properties: {
     *                 cameraX: 0,
     *                 cameraY: 0
     *             }
     *         });
     *     }
     * 
     *     async runInteraction(api: ComponentApi): Promise<void> {
     *         await api.waitForKeyPress('Enter');
     * 
     *         api.queue()
     *             .stop(PARTICLES_ANIMATION_ID)
     *             .emitParticles({
     *                 animationId: PARTICLES_ANIMATION_ID,
     *                 layerId: PARTICLES_SCENE_ID,
     *                 countPerSecond: 500,
     *                 globalCompositeOperation: 'lighter',
     *                 editParticle: particle => {
     *                     particle.setColor('red', 'yellow');
     *                     particle.setAlpha(1, 0);
     *                     particle.setSize(30, 15)
     *                     particle.setPosition(0, 0);
     *                     particle.setAngle(Math.random() * Math.PI * 2);
     *                     particle.setDurationFromRangeAndSpeed(400, 250)
     *                 },
     *             })
     *     }
     * 
     *     render(view: View): void {
     *         view.paint()
     *             .backgroundColor('#222222')
     *     }
     * }
     */
    emitParticles(params: EmitParticlesParams): AnimationQueue {
        return this.schedule(emitParticlesAnimation, params);
    }

    /**
     * Plays the specified audio file.
     * 
     * See the {@link AnimationQueue.playSpriteAnimation} documentation for an example.
     * @param params 
     * @returns 
     */
    playAudio(params: PlayAudioParams | string): AnimationQueue {
        let formattedParams =
            typeof params === 'string' ? { audioUrl: params } :
            params;

        return this.schedule(playAudioAnimation, formattedParams);
    }
}

for (let key of Object.getOwnPropertyNames(AnimationQueue.prototype)) {
    if (key !== 'constructor') {
        (AnimationQueueDummy.prototype as any)[key] = function () {
            return this;
        };
    }
}
globalThis.ALL_FUNCTIONS.push(AnimationQueue);