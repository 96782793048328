import { OptionalProperties } from '../../../utils/language/types.ts';
import { Easing } from '../../../utils/time/easing.ts';
import { AssetUrl } from '../../asset-url.ts';
import { Component } from '../../component/component.ts';
import { AnimationId } from '../animation-types.ts';

/**
 * Parameters for {@link AnimationQueue.playAudio}.
 */
export type PlayAudioParams = {
    /**
     * URL of the audio to play.
     */
    audioUrl: AssetUrl;
    /**
     * Animation id, allowing the audio to be stopped via {@link AnimationQueue.stop}.
     * @default null
     */
    animationId?: AnimationId;
    /**
     * Delay after which to play the audio.
     * @default 0
     */
    delay?: number;
    /**
     * Whether or not to block animations queued after this one (via {@link AnimationQueue.queue}) from starting until the audio has finished playing.
     * @default false
     */
    blocking?: boolean;
    /**
     * Location of the audio, only used if `muteOutsideCamera` is `true`. If a function is passed, it is evaluated each frame.
     * @default null
     */
    location?: null | AudioLocation | (() => AudioLocation);
    /**
     * Volume of the audio, between 0 (muted) and 1 (full volume).
     * @default 1
     */
    volume?: number;
    /**
     * Whether to loop the audio.
     * If set to `true`, it is recommended to set `blockQueue` to `false` do it does not block the animation queue indefinitely.
     * @default false
     */
    loop?: boolean;
    /**
     * Whether to mute the audio when its `location` is outside the camera.
     * @default false
     */
    muteOutsideCamera?: boolean;
    /**
     * Is `muteOutsideCamera` is `true`, indicates from which distance to the camera edge the volume will start to lower.
     * If set to 0, the volume will abruptely be muted when the camera moves outside the audio location.
     * @default 0
     */
    fadeOutDistance?: number;
    /**
     * If `muteOutsideCamera` is `true`, indicates how fast the volume lowers when the audio location is between the camera
     * edges and `fadeOutDistance`.
     * @default 'cubic-in'
     */
    fadeOutEasing?: Easing;
}

export type AudioLocation = {
    layerId?: string | null;
    x: number;
    y: number;
};

export const DEFAULT_PLAY_AUDIO_PARAMS: OptionalProperties<PlayAudioParams> = {
    animationId: null,
    delay: 0,
    blocking: false,
    location: null,
    volume: 1,
    loop: false,
    muteOutsideCamera: false,
    fadeOutDistance: 0,
    fadeOutEasing: 'cubic-in',
};