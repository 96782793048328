import { FlattenUnaryArray, ParametersExceptFirstAndSecond } from '../language/types.ts';
import { WEBGL_TYPES, WebglType } from './webgl-types.ts';

export type WebglAttributeTypeDetails = {
    componentType: WebglType;
    componentCount: number;
    normalize: boolean;
    shaderType: 'float' | 'int';
    setFunction: (dataView: DataView, byteOffset: number, ...args: any[]) => void;
};

export const WEBGL_ATTRIBUTE_TYPES = {
    'float': {
        componentType: 'float',
        componentCount: 1,
        normalize: false,
        shaderType: 'float',
        setFunction: (dataView, byteOffset, value: number) => {
            dataView.setFloat32(byteOffset, value, true);
        }
    },
    'vec2': {
        componentType: 'float',
        componentCount: 2,
        normalize: false,
        shaderType: 'float',
        setFunction: (dataView, byteOffset, value1: number, value2: number) => {
            dataView.setFloat32(byteOffset, value1, true);
            dataView.setFloat32(byteOffset + 4, value2, true);
        }
    },
    'vec3': {
        componentType: 'float',
        componentCount: 3,
        normalize: false,
        shaderType: 'float',
        setFunction: (dataView, byteOffset, value1: number, value2: number, value3: number) => {
            dataView.setFloat32(byteOffset, value1, true);
            dataView.setFloat32(byteOffset + 4, value2, true);
            dataView.setFloat32(byteOffset + 8, value3, true);
        }
    },
    'vec4': {
        componentType: 'float',
        componentCount: 4,
        normalize: false,
        shaderType: 'float',
        setFunction: (dataView, byteOffset, value1: number, value2: number, value3: number, value4: number) => {
            dataView.setFloat32(byteOffset, value1, true);
            dataView.setFloat32(byteOffset + 4, value2, true);
            dataView.setFloat32(byteOffset + 8, value3, true);
            dataView.setFloat32(byteOffset + 12, value4, true);
        }
    },
    'color': {
        componentType: 'unsigned-byte',
        componentCount: 4,
        normalize: true,
        shaderType: 'float',
        setFunction: (dataView, byteOffset, r: number, g: number, b: number, a: number) => {
            dataView.setUint8(byteOffset, r);
            dataView.setUint8(byteOffset + 1, g);
            dataView.setUint8(byteOffset + 2, b);
            dataView.setUint8(byteOffset + 3, a);
        }
    },
    'uint': {
        componentType: 'unsigned-int',
        componentCount: 1,
        normalize: false,
        shaderType: 'int',
        setFunction: (dataView, byteOffset, value: number) => {
            dataView.setUint32(byteOffset, value, true);
        }
    },
    'uvec2': {
        componentType: 'unsigned-int',
        componentCount: 2,
        normalize: false,
        shaderType: 'int',
        setFunction: (dataView, byteOffset, value1: number, value2: number) => {
            dataView.setUint32(byteOffset, value1, true);
            dataView.setUint32(byteOffset + 4, value2, true);
        }
    },
    'uvec3': {
        componentType: 'unsigned-int',
        componentCount: 3,
        normalize: false,
        shaderType: 'int',
        setFunction: (dataView, byteOffset, value1: number, value2: number, value3: number) => {
            dataView.setUint32(byteOffset, value1, true);
            dataView.setUint32(byteOffset + 4, value2, true);
            dataView.setUint32(byteOffset + 8, value3, true);
        }
    },
    'uvec4': {
        componentType: 'unsigned-int',
        componentCount: 4,
        normalize: false,
        shaderType: 'int',
        setFunction: (dataView, byteOffset, value1: number, value2: number, value3: number, value4: number) => {
            dataView.setUint32(byteOffset, value1, true);
            dataView.setUint32(byteOffset + 4, value2, true);
            dataView.setUint32(byteOffset + 8, value3, true);
            dataView.setUint32(byteOffset + 12, value4, true);
        }
    },
    'int': {
        componentType: 'int',
        componentCount: 1,
        normalize: false,
        shaderType: 'int',
        setFunction: (dataView, byteOffset, value: number) => {
            dataView.setInt32(byteOffset, value, true);
        }
    },
    'ivec2': {
        componentType: 'int',
        componentCount: 2,
        normalize: false,
        shaderType: 'int',
        setFunction: (dataView, byteOffset, value1: number, value2: number) => {
            dataView.setInt32(byteOffset, value1, true);
            dataView.setInt32(byteOffset + 4, value2, true);
        }
    },
    'ivec3': {
        componentType: 'int',
        componentCount: 3,
        normalize: false,
        shaderType: 'int',
        setFunction: (dataView, byteOffset, value1: number, value2: number, value3: number) => {
            dataView.setInt32(byteOffset, value1, true);
            dataView.setInt32(byteOffset + 4, value2, true);
            dataView.setInt32(byteOffset + 8, value3, true);
        }
    },
    'ivec4': {
        componentType: 'int',
        componentCount: 4,
        normalize: false,
        shaderType: 'int',
        setFunction: (dataView, byteOffset, value1: number, value2: number, value3: number, value4: number) => {
            dataView.setInt32(byteOffset, value1, true);
            dataView.setInt32(byteOffset + 4, value2, true);
            dataView.setInt32(byteOffset + 8, value3, true);
            dataView.setInt32(byteOffset + 12, value4, true);
        }
    },
    'ushort': {
        componentType: 'unsigned-short',
        componentCount: 1,
        normalize: false,
        shaderType: 'int',
        setFunction: (dataView, byteOffset, value: number) => {
            dataView.setUint32(byteOffset, value, true);
        }
    },
} satisfies { [name: string]: WebglAttributeTypeDetails };

export type WebglAttributeType = keyof typeof WEBGL_ATTRIBUTE_TYPES;
export type WebglAttributesLayout = { [name: string]: WebglAttributeType };

export type WebglAttributesValues<T extends WebglAttributesLayout> = {
    [Name in keyof T]: FlattenUnaryArray<ParametersExceptFirstAndSecond<typeof WEBGL_ATTRIBUTE_TYPES[T[Name]]['setFunction']>>
};

export type WebglAttributeDetails = {
    attributeType: WebglAttributeType;
    location: number;
    size: number;
    type: number;
    normalized: boolean;
    stride: number;
    offset: number;
};

export type WebglAttributesLayoutDetails<T extends WebglAttributesLayout> = {
    byteSize: number;
    attributes: { [Key in keyof T]: WebglAttributeDetails };
};

export function computeAttributesDetails<T extends WebglAttributesLayout>(
    gl: WebGL2RenderingContext,
    program: WebGLProgram,
    attributesLayout: T
): WebglAttributesLayoutDetails<T> {
    let byteSize = 0;
    let attributes: WebglAttributesLayoutDetails<T>['attributes'] = {} as any;

    for (let [name, attributeType] of Object.entries(attributesLayout)) {
        let attributeName = name as keyof T & string;
        let { componentType, componentCount, normalize } = WEBGL_ATTRIBUTE_TYPES[attributeType];
        let { byteSize: componentByteSize, glConstant } = WEBGL_TYPES[componentType];
        let attributeByteSize = componentByteSize * componentCount;
        let location = gl.getAttribLocation(program, attributeName);

        attributes[attributeName] = {
            attributeType,
            location,
            size: componentCount,
            type: glConstant,
            normalized: normalize,
            stride: 0,
            offset: byteSize,
        };
        byteSize += attributeByteSize;
    }

    for (let value of Object.values(attributes)) {
        value.stride = byteSize;
    }

    return { byteSize, attributes };
}

export function makePerVertexAttributesValues<T extends WebglAttributesLayout>(
    pointCount: number,
    makeVertices: (index: number, pointCount: number) => [WebglAttributesValues<T>, WebglAttributesValues<T>, WebglAttributesValues<T>]
): WebglAttributesValues<T>[] {
    let result: WebglAttributesValues<T>[] = [];

    for (let i = 0; i < pointCount; ++i) {
        let [v1, v2, v3] = makeVertices(i, pointCount);

        result.push(v1, v2, v3)
    };

    return result;
}