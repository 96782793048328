import { Component } from '../component/component.ts';

export class DrawContext {
    private componentsByIndex: Component[] = [];
    
    currentTime: number;

    constructor(currentTime: number) {
        this.currentTime = currentTime;
    }

    register(component: Component | null): number {
        let index = 0;
        
        if (component) {
            this.componentsByIndex.push(component);
            index = this.componentsByIndex.length;
        }

        return index;
    }

    getComponentAt(index: number): Component | null {
        return this.componentsByIndex[index - 1] ?? null;
    }
};
globalThis.ALL_FUNCTIONS.push(DrawContext);