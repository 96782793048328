import { mix } from '../language/math.ts';
import { Writeable } from '../language/object.ts';

export type TransformLike = {
    tx?: number;
    ty?: number;
    sx?: number;
    sy?: number;
};

export class Transform {
    readonly tx: number = 0;
    readonly ty: number = 0;
    readonly sx: number = 1;
    readonly sy: number = 1;

    static readonly IDENTITY: Transform = new Transform();

    static from(value: TransformLike): Transform {
        let result = new Transform() as Writeable<Transform>;

        result.tx = value.tx ?? 0;
        result.ty = value.ty ?? 0;
        result.sx = value.sx ?? 1;
        result.sy = value.sy ?? 1;

        return result;
    }

    static mix(start: Transform, end: Transform, t: number, target?: Transform) {
        let result = (target ?? new Transform()) as Writeable<Transform>;

        result.tx = mix(start.tx, end.tx, t);
        result.ty = mix(start.ty, end.ty, t);
        result.sx = mix(start.sx, end.sx, t);
        result.sy = mix(start.sy, end.sy, t);

        return result;
    }

    clone(): Transform {
        return Transform.from(this);
    }

    set(value: TransformLike) {
        let self = this as Writeable<Transform>;

        if (value.tx !== undefined) {
            self.tx = value.tx;
        }

        if (value.ty !== undefined) {
            self.ty = value.ty;
        }

        if (value.sx !== undefined) {
            self.sx = value.sx;
        }

        if (value.sy !== undefined) {
            self.sy = value.sy;
        }
    }

    mult(transform: Transform) {
        let tx = this.tx + transform.tx * this.sx;
        let ty = this.ty + transform.ty * this.sy;
        let sx = this.sx * transform.sx;
        let sy = this.sy * transform.sy;

        return Transform.from({ tx, ty, sx, sy });
    }

    div(transform: Transform) {
        let sx = this.sx / transform.sx;
        let sy = this.sy / transform.sy;
        let tx = this.tx - transform.tx * sx;
        let ty = this.ty - transform.ty * sy;

        return Transform.from({ tx, ty, sx, sy });
    }
}
globalThis.ALL_FUNCTIONS.push(Transform);