import { FlexBuffer } from '../serialization/flex-buffer.ts';
import { TypeKind, TypeSchema } from './type-schema.ts';

// TODO: accept other buffer types
export function bufferSchema(): TypeSchema<Uint8Array> {
    return {
        kind: TypeKind.Other,
        optional: false,
        check(ctx, value) {
            return !!value && value instanceof FlexBuffer;
        },
        serialize(buffer, value) {
            buffer.writeBuffer(value);
        },
        deserialize(buffer) {
            return buffer.readBuffer();
        },
    }
};