import { MaybeAsync } from 'outpost';

export class AssetLoader {
    private images: { [key: string]: MaybeAsync<HTMLImageElement | null> } = {};
    private fonts: { [key: string]: MaybeAsync<null> } = {};

    async loadImage(url: string): Promise<HTMLImageElement | null> {
        let image = this.images[url];

        if (image === undefined) {
            image = loadImage(url);
            this.images[url] = image;

            image.then(result => this.images[url] = result);
        }

        return image;
    }

    getImage(url: string): HTMLImageElement | null {
        let image = this.images[url];

        if (!image || image instanceof Promise) {
            return null;
        } else {
            return image;
        }
    }

    async loadFont(name: string, url: string): Promise<null> {
        let promise = this.fonts[name];

        if (promise === undefined) {
            let fontFile = new FontFace(name, `url(${url})`);

            document.fonts.add(fontFile);

            promise = fontFile.load().then(() => null);
            this.fonts[name] = promise;

            promise.then(() => this.fonts[name] = null);
        }

        return promise;
    }

    isFontLoaded(name: string): boolean {
        return this.fonts[name] === null;
    }
}

export async function loadImage(url: string): Promise<HTMLImageElement | null> {
    let response!: Response;

    try {
        response = await fetch(url);
    } catch (e) {
        console.log(`[INFO] Using proxy to work around CORS restrictions (${url})`);

        response = await fetch(formatProxyUrl(url));
    }

    if (!response.ok) {
        let reason = response.statusText ? `: ${response.statusText}` : '';

        console.log(`[ERROR] Code ${response.status}${reason} (${url})`)

        return null;
    }

    let blob = await response.blob();
    let imageUrl = URL.createObjectURL(blob);
    let image = new Image();

    image.src = imageUrl;

    return new Promise(resolve => {
        image.onload = () => resolve(image);
    });
}

function formatProxyUrl(url: string): string {
    return `${window.location.origin}/proxy/${encodeURI(url)}`;
}
globalThis.ALL_FUNCTIONS.push(AssetLoader);