import { Column } from '../base-components/container';
import { OVERLAY_CLASS_NAME } from '../components/ui-constants';
import { BoardCard } from './board-card';
import { BoardLocalData } from './board-local-data';

export type BoardCardMarksComponentProps = {
    localData: BoardLocalData;
    card: BoardCard;
};

export function BoardCardMarksComponent(props: BoardCardMarksComponentProps) {
    let { localData, card } = props;

    if (!card.marks.length) {
        return <></>;
    }

    let marks = card.marks.map(playerId => <div style={{
        pointerEvents: 'none',
        color: localData.getPlayerColor(playerId),
        fontSize: '80px',
    }}>✗</div>);

    let isAttached = !!card.parentCard;

    return (
        <Column
            className={OVERLAY_CLASS_NAME}
            justifyContent='space-evenly'
            alignItems={isAttached ? 'end' : 'center'}
        >
            {marks}
        </Column>
    );
};