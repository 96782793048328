import { resolve } from 'path';

export type OutpostEnv = {
    APP_NAME: string;
    APP_PORT: number;
    CLIENT_DIR: string;
    SERVER_DIR: string;
    SERVER_DATA_DIR: string;
    SSL_CERT: string | null;
    SSL_KEY: string | null;
    IS_LOCAL: boolean;
};

const DEFAULT_ENV: OutpostEnv = {
    APP_NAME: '',
    APP_PORT: 0,
    CLIENT_DIR: '',
    SERVER_DIR: '',
    SERVER_DATA_DIR: '',
    SSL_CERT: null,
    SSL_KEY: null,
    IS_LOCAL: true,
};

const OUTPOST_ENV_PREFIX = 'OUTPOST_ENV=';

function parseOutpostEnv(): OutpostEnv {
    let argv = typeof process !== 'undefined' ? process.argv : [];
    let arg = argv.find(str => str.startsWith(OUTPOST_ENV_PREFIX));
    let result = DEFAULT_ENV;
    
    if (arg) {
        result = JSON.parse(arg.substring(OUTPOST_ENV_PREFIX.length));
    }

    result.CLIENT_DIR = resolvePath(result.CLIENT_DIR);
    result.SERVER_DIR = resolvePath(result.SERVER_DIR);
    result.SERVER_DATA_DIR = resolvePath(result.SERVER_DATA_DIR);

    return result;
}

function resolvePath(value: string) {
    if (value) {
        return resolve(value);
    } else {
        return '';
    }
}

export const OUTPOST_ENV = parseOutpostEnv();