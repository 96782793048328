import { CamelCase } from './types.ts';

export function toCamelCase<T extends string>(string: T): CamelCase<T> {
    return string.replace(/-[a-z]/g, str => str.substring(1).toUpperCase()) as CamelCase<T>;
}

export function toKebabCase(string: string): string {
    return string.replace(/[a-z][A-Z]/g, str => str[0] + '-' + str[1].toLowerCase());
}

export function parsePositiveNumber(string: string, defaultValue: number): number {
    let n = parseFloat(string);

    if (n >= 0) {
        return n;
    } else {
        return defaultValue;
    }
}

export function parseString(string: string, defaultValue: string): string {
    return string || defaultValue;
}

export function parseBoolean(string: string, defaultValue: boolean): boolean {
    let str = (string || '').toLowerCase();

    if (str === 'true' || str === 'yes') {
        return true;
    } else if (str === 'false' || str === 'no') {
        return false;
    } else {
        return defaultValue;
    }
}

export function deepClone(value: any): any {
    let result = value;

    if (Array.isArray(value)) {
        result = new Array(value.length);

        for (let i = 0; i < value.length; ++i) {
            result[i] = deepClone(value[i]);
        }
    } else if (value && value.constructor === Object) {
        result = {};

        for (let [key, val] of Object.entries(value)) {
            result[key] = deepClone(val);
        }
    }

    return result;
}

export function parseAttributes<T extends object>(input: { [key: string]: string; }, defaultValues: T): T {
    let result: any = {};

    for (let [key, defaultValue] of Object.entries(defaultValues)) {
        let inputValue = (input as any)[key];
        let parsedValue = undefined;

        if (typeof defaultValue === 'boolean') {
            parsedValue = parseBoolean(inputValue, defaultValue);
        } else if (typeof defaultValue === 'number') {
            parsedValue = parsePositiveNumber(inputValue, defaultValue);
        } else {
            parsedValue = parseString(inputValue, defaultValue);
        }

        result[key] = parsedValue;
    }

    return result;
}

export async function wait(durationMs = 0) {
    return new Promise(resolve => setTimeout(resolve, durationMs));
}

export function isImageUrl(string: string): boolean {
    return string.startsWith('http://') || string.startsWith('https://');
    return string.endsWith('.png')
        || string.endsWith('.jpeg')
        || string.endsWith('.jpg');
}

export function normalizeString(string: string | null | undefined): string {
    if (!string) {
        return '';
    }

    return string
        .toLowerCase()
        .replace(/[ ']+/g, '-')
        .normalize('NFKD')
        .replace(/[\u0300-\u036f]/g, "");
}

export function mergeObjects(target: any, source: any): any {
    if (!isObject(source)) {
        return source;
    } else if (!isObject(target)) {
        return mergeObjects({}, source);
    } else {
        for (let key in source) {
            target[key] = mergeObjects(target[key], source[key]);
        }

        return target;
    }
}

export function isObject(value: any): boolean {
    return value?.constructor === Object;
}

export function createCanvas(params: { width?: number, height?: number; } = {}): HTMLCanvasElement {
    let { width, height } = params;
    let canvas = document.createElement('canvas');

    canvas.width = width ?? canvas.width;
    canvas.height = height ?? canvas.height;

    return canvas;
}

export function copyCanvas(src: HTMLCanvasElement): HTMLCanvasElement {
    let canvas = createCanvas({ width: src.width, height: src.height });
    let ctx = canvas.getContext('2d')!;

    ctx.drawImage(src, 0, 0);

    return canvas;
}

export function validateCallback<T extends (...args: any[]) => any>(callback: T, value: boolean): T | undefined {
    if (value) {
        return callback;
    } else {
        return undefined;
    }
}