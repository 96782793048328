import { Collection, collectionToArray } from '../../utils/language/collection.ts';
import { Component } from './component.ts';
import { Graphics } from '../graphics-engine/graphics/graphics.ts';
import { RectProperties } from '../../utils/geometry/rect.ts';
import { View } from '../view/view.ts';

export type ComponentModifier<T = Component> = Collection<Graphics> | ((view: View, component: T) => void) | RectProperties | Component;
export type ComponentModifierCallback<T = Component> = (view: View, component: T) => void;

export function componentModifierToCallback(modifier: ComponentModifier): ComponentModifierCallback {
    if (typeof modifier === 'function') {
        return modifier;
    } else if (modifier && 'render' in modifier) {
        return view => modifier.render(view);
    } else if (modifier) {
        let array = collectionToArray(modifier);

        return (view) => {
            for (let item of array) {
                view.paint(item);
            }
        };
    } else {
        return () => {};
    }
}

export function mergeComponentModifiers(first: ComponentModifier, ...others: (ComponentModifier | null)[]): ComponentModifier {
    if (others.length < 2) {
        return first;
    } else {
        let callbacks: ComponentModifierCallback[] = [componentModifierToCallback(first)];

        for (let item of others) {
            callbacks.push(componentModifierToCallback(item))
        }

        return (view: View, component: Component) => {
            for (let callback of callbacks) {
                callback(view, component);
            }
        };
    }
}