import { exitWithError } from '../logging/logging-utils.ts';

export async function postJson<T, U = any>(url: string, body: U): Promise<T> {
    let fetch = await getFetch();
    let response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json'
        },
        
    });

    let raw = await response.text();

    try {
        return JSON.parse(raw) as T;
    } catch (err) {
        console.error(raw);
        exitWithError(`Did not receive JSON; exiting.`);
    }
}

export async function getJson<T, U extends object = {}>(url: string, params: U): Promise<T> {
    let fetch = await getFetch();
    let fullUrl = url;
    let entries = Object.entries(params ?? {});

    if (entries.length > 0) {
        fullUrl += '?';
    }

    fullUrl += entries.map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join('&');

    let response = await fetch(fullUrl);

    let raw = await response.text();

    try {
        return JSON.parse(raw) as T;
    } catch (err) {
        console.error(raw);
        throw new Error(`did not receive JSON`);
    }
}

export async function getFetch(): Promise<typeof fetch> {
    if (typeof window === 'undefined') {
        return (await import('node-fetch')).default as any;
    } else {
        return fetch;
    }
}