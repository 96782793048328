import { CardId } from '../project-types';
import { BoardPlayerId, BoardZone } from './board-types';

export class BoardCard {
    cardId: CardId;
    ownerId: BoardPlayerId;
    exhausted: boolean = false;
    attachedCards: BoardCard[] = [];
    parentCard: BoardCard | null = null;
    parentZone: BoardZone | null = null;
    isFlipped: boolean = false;
    isRevealed: boolean = false;
    isRotated: boolean = false;
    marks: BoardPlayerId[] = [];

    constructor(cardId: CardId, ownerId: BoardPlayerId) {
        this.cardId = cardId;
        this.ownerId = ownerId;
    }

    getSidePlayerId(): BoardPlayerId | null {
        let zone = this.getZone();

        if (!zone) {
            return null;
        } else {
            return zone.playerId;
        }
    }

    getZone(): BoardZone | null {
        return this.parentZone ?? this.parentCard?.parentZone ?? null;
    }

    isVisibleBy(playerId: BoardPlayerId, globalVision: boolean = false): boolean {
        let zone = this.getZone();

        if (this.isRevealed) {
            return true;
        }

        if (!zone || this.isFlipped) {
            return false;
        }

        if (globalVision) {
            return true;
        }

        switch (zone.metadata.visibility) {
            case 'all':
                return true;
            case 'none':
                return false;
            case 'self':
                return playerId === zone.playerId;
        }

        return false;
    }
}
globalThis.ALL_FUNCTIONS.push(BoardCard);