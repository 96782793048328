import { toCssPixels } from 'outpost';
import { CSSProperties, useRef } from 'preact/compat';

export type TextInputProps = {
    onContentChange: (value: string) => void;
    onFocusChange?: (value: boolean) => void;
    content?: string;
    disabled?: boolean;
    autoFocus?: boolean;
    width?: number;
};

export function TextInput(props: TextInputProps) {
    let { onContentChange, onFocusChange, content, disabled, autoFocus, width } = props;
    let ref = useRef<HTMLInputElement | null>(null);
    let style: CSSProperties = {
        width: toCssPixels(width),
    };

    return <input
        type='text'
        style={style}
        ref={elt => ref.current = elt}
        value={content}
        disabled={disabled}
        onChange={() => onContentChange(ref.current?.value ?? '')}
        onFocusIn={() => onFocusChange?.(true)}
        onFocusOut={() => onFocusChange?.(false)}
        autoFocus={autoFocus}
    />;
}