import { logError, logWarning } from '../logging/logging-utils.ts';

export type ParseResultParams<T> = {
    value?: T;
    errors?: string[];
    warnings?: string[]
};

/**
 * A parse result, returned by {@link parseTsv}.
 */
export class ParseResult<T> {
    /**
     * Contains the parsed object it the parsing was successful, `undfined` otherwise.
     */
    value: T | undefined;
    /**
     * Errors that occurred during the parsing.
     * If there is at least one, `value` is guaranteed to be `undefined`.
     */
    errors: string[];
    /**
     * Warnings that occurred during the parsing.
     */
    warnings: string[];

    constructor(params: ParseResultParams<T>) {
        this.value = params.value;
        this.errors = params.errors ?? [];
        this.warnings = params.warnings ?? [];
    }

    /**
     * Display all errors and warnings in the console, then returns the parsed value.
     * If there is at least one error, exits the program.
     * @returns 
     */
    unwrap(): T {
        for (let error of this.errors) {
            logError(error);
        }

        for (let warning of this.warnings) {
            logWarning(warning);
        }

        if (this.errors.length > 0) {
            process.exit(1);
        }

        return this.value!;
    }

    /**
     * Indicates if the parsing was successful.
     * @example
     * if (parseResult.isOk()) {
     *     // at this point `parseResult.value` is correctly inferred to
     *     // have the parsed type, and not `undefind`
     * }
     * @param this 
     * @returns 
     */
    isOk(this: this): this is this & { value: T } {
        return this.errors.length === 0;
    }
};
globalThis.ALL_FUNCTIONS.push(ParseResult);