import { Point } from '../../utils/geometry/point.ts';
import { Rect } from '../../utils/geometry/rect.ts';
import { mix } from '../../utils/language/math.ts';
import { WebglBlendFunction } from '../../utils/webgl/webgl-blend-function.ts';
import { WebglDataTexture } from '../../utils/webgl/webgl-data-texture.ts';
import { WebglImageTexture } from '../../utils/webgl/webgl-image-texture.ts';
import { WebglTextureFilter } from '../../utils/webgl/webgl-texture-filter.ts';
import { DATA_TEXTURE_BLOCK_SIZE } from './graphics-engine-constants.ts';
import { LayerShake, LayerShakeParams } from './layer-shake.ts';
import { LayerId, LayerProperties } from './layer-types.ts';

export type LayerParams = {
    gl: WebGL2RenderingContext,
    index: number,
    id: LayerId,
    viewportWidth: number;
    viewportHeight: number;
    imageTexture?: WebglImageTexture;
};

export class Layer {
    index: number;
    id: LayerId;
    viewport: Rect = Rect.zero();
    cameraX: number = 0;
    cameraY: number = 0;
    cameraOffsetX: number = 0;
    cameraOffsetY: number = 0;
    cameraZoom: number = 1;
    zIndex: number = 0;
    shown: boolean = true;
    blending: WebglBlendFunction = 'source-over';
    textureFilter: WebglTextureFilter = 'linear';
    attributesDataTexture: WebglDataTexture;
    imageTexture: WebglImageTexture;
    shakes: Set<LayerShake> = new Set();

    constructor(params: LayerParams) {
        this.index = params.index;
        this.id = params.id;
        this.viewport = Rect.fromSize(params.viewportWidth, params.viewportHeight);
        this.cameraX = params.viewportWidth / 2;
        this.cameraY = params.viewportHeight / 2;
        this.attributesDataTexture = new WebglDataTexture(params.gl, {
            atomicAllocationSize: DATA_TEXTURE_BLOCK_SIZE
        });
        this.imageTexture = params.imageTexture ?? new WebglImageTexture(params.gl);
    }

    setProperties(properties: Partial<LayerProperties>): Layer {
        if (properties.viewport !== undefined) {
            this.viewport = properties.viewport.clone();
        }
    
        if (properties.cameraX !== undefined) {
            this.cameraX = properties.cameraX;
        }
    
        if (properties.cameraY !== undefined) {
            this.cameraY = properties.cameraY;
        }
    
        if (properties.cameraZoom !== undefined) {
            this.cameraZoom = properties.cameraZoom;
        }

        if (properties.zIndex !== undefined) {
            this.zIndex = properties.zIndex;
        }
    
        if (properties.shown !== undefined) {
            this.shown = properties.shown;
        }

        if (properties.blending) {
            this.blending = properties.blending;
        }

        if (properties.textureFilter) {
            this.imageTexture.setFilter(properties.textureFilter);
        }

        return this;
    }

    getProperties(): LayerProperties {
        return {
            id: this.id,
            cameraX: this.cameraX,
            cameraY: this.cameraY,
            cameraZoom: this.cameraZoom,
            zIndex: this.zIndex,
            shown: this.shown,
            viewport: this.viewport.clone(),
            blending: this.blending,
            textureFilter: this.imageTexture.getFilter()
        };
    }

    getPointInLayer(virtualX: number, virtualY: number): Point {
        let x = (virtualX - this.viewport.x) / this.cameraZoom + this.cameraX;
        let y = (virtualY - this.viewport.y) / this.cameraZoom + this.cameraY;

        return new Point(x, y);
    }

    update(currentTime: number) {
        this.cameraOffsetX = 0;
        this.cameraOffsetY = 0;

        for (let shake of this.shakes) {
            let finished = shake.update(currentTime);

            if (finished) {
                this.shakes.delete(shake);
            }

            this.cameraOffsetX += shake.getOffsetX();
            this.cameraOffsetY += shake.getOffsetY();
        }
    }

    addShake(params: LayerShakeParams) {
        this.shakes.add(new LayerShake(params));
    }

    getCameraX() {
        return this.cameraX + this.cameraOffsetX;
    }

    getCameraY() {
        return this.cameraY + this.cameraOffsetY;
    }
}

export function mixLayerProperties(start: LayerProperties, end: Partial<LayerProperties>, animationRatio: number): Partial<LayerProperties> {
    let result: Partial<LayerProperties> = {};

    if (end.viewport !== undefined) {
        result.viewport = Rect.mix(start.viewport, end.viewport, animationRatio);
    }

    if (end.cameraX !== undefined) {
        result.cameraX = mix(start.cameraX, end.cameraX, animationRatio);
    }

    if (end.cameraY !== undefined) {
        result.cameraY = mix(start.cameraY, end.cameraY, animationRatio);
    }

    if (end.cameraZoom !== undefined) {
        result.cameraZoom = mix(start.cameraZoom, end.cameraZoom, animationRatio);
    }

    if (end.shown !== undefined) {
        if (animationRatio === 1) {
            result.shown = end.shown;
        } else {
            result.shown = start.shown || end.shown;
        }
    }

    return result;
}
globalThis.ALL_FUNCTIONS.push(Layer);