import { isObjectEmpty } from '../../../utils/language/object.ts';
import { GraphicsTransform } from './graphics-transform.ts';
import { GraphicsEngine } from '../graphics-engine.ts';
import { LayerId } from '../layer-types.ts';
import { GraphicsAttributeDetails } from './graphics-attribute-details.ts';
import { UnwrapAttribute, getGraphicsAttributeValue } from './graphics-attribute-types.ts';
import { Cursor, DrawOrder } from './graphics-types.ts';
import { DISPLAY_GRAPHICS_KEYS, GRAPHICS_METADATA, Graphics } from './graphics.ts';

export type GraphicsAttributeListEntry<T> = {
    body: GraphicsAttributeDetails<T> | null;
    modifier: GraphicsAttributeDetails<T> | null;
}

export class GraphicsAttributeList {
    private graphicsEngine: GraphicsEngine;
    private atomKey: string = '';
    private bodyGraphics: Graphics = {};
    private modifierGraphics: Graphics = {};
    private startTimes: Partial<{ [Key in keyof Graphics]: number }> = {};
    private durations: Partial<{ [Key in keyof Graphics]: number }> = {};
    private attributesDataPointer: number = 0;
    private layerId: LayerId | null = null;
    private imageId: string | null = null;
    private audioId: number | null = null;
    private cursor: Cursor | null = null;
    private detectable: boolean = false;
    private drawOrder: DrawOrder = 'before-children';
    private shouldReload: boolean = false;
    private mainStartTime: number = 0;
    private transform: GraphicsTransform | null = null;

    constructor(graphicsEngine: GraphicsEngine) {
        this.graphicsEngine = graphicsEngine;
    }

    clear(atomKey: string) {
        this.atomKey = atomKey;
        this.unload();
        this.resetGraphics(this.bodyGraphics);
        this.resetGraphics(this.modifierGraphics);
        this.imageId = null;
        this.audioId = null;
        this.cursor = null;
        this.detectable = false;
        this.shouldReload = true;
        this.mainStartTime = 0;
        this.transform = null;

        for (let key in this.startTimes) {
            this.startTimes[key as keyof Graphics] = undefined;
        }

        for (let key in this.durations) {
            this.durations[key as keyof Graphics] = undefined;
        }
    }

    private resetGraphics(graphics: Graphics) {
        for (let key in graphics) {
            (graphics as any)[key] = undefined;
        }
    }

    setMainStartTime(startTime: number) {
        this.mainStartTime = startTime;
    }

    getMainStartTime(): number {
        return this.mainStartTime;
    }

    getStartTimes(): { [Key in keyof Graphics]: number } {
        return this.startTimes;
    }

    getStartTime(key: keyof Graphics): number {
        return this.startTimes[key] ?? this.mainStartTime;
    }

    getDurations(): { [Key in keyof Graphics]: number } {
        return this.durations;
    }

    setStartTime(key: keyof Graphics, startTime: number | undefined) {
        if (startTime !== undefined) {
            this.startTimes[key] = startTime;
        }
    }

    setTransform(transform: GraphicsTransform | null = null) {
        this.transform = transform;
    }

    getTransform(): GraphicsTransform | null {
        return this.transform;
    }

    getBodyGraphics(): Graphics {
        return this.bodyGraphics;
    }

    getModifierGraphics(): Graphics {
        return this.modifierGraphics;
    }

    getLayerId(): LayerId {
        return this.layerId;
    }

    getLayer(): DrawOrder {
        return this.drawOrder;
    }

    requireValue<K extends keyof Graphics>(key: K): UnwrapAttribute<Exclude<Graphics[K], undefined>> {
        let value = this.bodyGraphics[key];
        let metadata = GRAPHICS_METADATA[key];

        if (value === undefined) {
            return metadata.defaultValue as UnwrapAttribute<Exclude<Graphics[K], undefined>>;
        } else if (!metadata.animatable) {
            return value as any;
        } else {
            return getGraphicsAttributeValue(value as any);
        }
    }

    isShown(): boolean {
        return !!(this.modifierGraphics.shown ?? this.bodyGraphics.shown ?? true);
    }

    hasDisplayData(): boolean {
        for (let key of DISPLAY_GRAPHICS_KEYS) {
            if (this.bodyGraphics[key] || this.modifierGraphics[key]) {
                return true;
            }
        }

        return false;
    }

    private unload() {
        this.attributesDataPointer = this.graphicsEngine.getAttributesDataTexture(this.getLayerId()).deallocate(this.attributesDataPointer);
    }

    private load() {
        if (this.attributesDataPointer === 0 && isObjectEmpty(this.bodyGraphics) && isObjectEmpty(this.modifierGraphics)) {
            return;
        }

        let graphicsEngine = this.graphicsEngine;
        let prevLayerId = this.layerId;
        let attributesDataTexture = graphicsEngine.getAttributesDataTexture(prevLayerId);

        this.attributesDataPointer = attributesDataTexture.deallocate(this.attributesDataPointer);

        this.layerId = this.requireValue('layerId');
        this.detectable = false;
        this.imageId = null;
        this.audioId = graphicsEngine.loadAudio(this);
        this.cursor = this.requireValue('cursor');

        if (!this.hasDisplayData() || !this.isShown()) {
            return;
        }

        let imageId = this.requireValue('image');
        let text = this.requireValue('text');

        if (text) {
            imageId = graphicsEngine.loadText(this);
        }

        if (imageId && !graphicsEngine.isImageLoaded(imageId)) {
            graphicsEngine.loadImage(imageId, { waitForLoading: false });
        }

        let graphicsLoader = graphicsEngine.getGraphicsLoader();

        this.detectable = this.requireValue('detectable');
        this.drawOrder = this.requireValue('drawOrder');
        this.imageId = imageId;
        this.attributesDataPointer = graphicsLoader.loadGraphics(this, imageId);
    }

    loadIfNecessary() {
        if (this.shouldReload || this.graphicsEngine.hasResourceBeenReloaded(this.imageId)) {
            this.load();
            this.shouldReload = false;
        }
    }

    draw(displayIndex: number) {
        if (this.imageId) {
            this.graphicsEngine.notifyUsedImage(this.imageId);
        }

        if (this.audioId) {
            this.graphicsEngine.playAudio(this.audioId);
        }

        if (this.cursor) {
            this.graphicsEngine.setCursor(this.cursor);
        }

        if (this.attributesDataPointer === 0) {
            return;
        }

        this.graphicsEngine.allocateGraphicsInstance(this.getLayerId())
            .a_attributesDataPointer(this.attributesDataPointer)
            .a_componentId(this.detectable ? displayIndex : 0);
    }

    destroy() {
        this.unload();
    }
}
globalThis.ALL_FUNCTIONS.push(GraphicsAttributeList);