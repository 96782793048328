export class SortedSet<T> {
    private sortFunction: (a: T, b: T) => number;
    private items: Set<T> = new Set();
    private sortedItems: T[] = [];
    private shouldSort: boolean = false;

    constructor(compareFunction: (a: T, b: T) => number = () => 0) {
        this.sortFunction = compareFunction;
    }

    get size() {
        return this.items.size;
    }

    [Symbol.iterator]() {
        this.sortIfNecessary();
        return this.sortedItems[Symbol.iterator]();
    }

    add(item: T): boolean {
        if (!this.items.has(item)) {
            this.shouldSort = true;
            this.items.add(item);
            this.sortedItems.push(item);

            return true;
        }
        
        return false;
    }

    delete(item: T) {
        if (this.items.has(item)) {
            this.items.delete(item);
            this.sortedItems.remove(item);

            return true;
        }

        return false;
    }

    has(item: T): boolean {
        return this.items.has(item);
    }

    clear() {
        this.items.clear();
        this.sortedItems.length = 0;
    }

    unwrap(): T[] {
        this.sortIfNecessary();

        return this.sortedItems;
    }

    indexOf(item: T): number {
        this.sortIfNecessary();

        return this.sortedItems.indexOf(item);
    }

    getFirst(): T | undefined {
        this.sortIfNecessary();

        return this.sortedItems[0];
    }

    getLast(): T | undefined {
        this.sortIfNecessary();

        return this.sortedItems[this.sortedItems.length - 1];
    }

    slice(): T[] {
        this.sortIfNecessary();

        return this.sortedItems.slice();
    }

    private sortIfNecessary() {
        if (this.shouldSort) {
            this.sortedItems.sort(this.sortFunction);
            this.shouldSort = false;
        }
    }
}
globalThis.ALL_FUNCTIONS.push(SortedSet);