import { Collection } from '../language/collection.ts';

export type GameEventLike = GameEvent | GameEventOnEndCallback | GenericGameEventParameters;
export type GameEventOnBeforeStartCallback = () => GameEventCallbackOutput;
export type GameEventOnStartCallback = () => GameEventCallbackOutput;
export type GameEventOnProgressCallback = (elapsed: number, totalElapsed: number) => number | void;
export type GameEventOnResolveCallback = () => GameEventCallbackOutput;
export type GameEventOnEndCallback = () => GameEventCallbackOutput;
export type GameEventOnAfterEndCallback = () => GameEventCallbackOutput;
export type GameEventCallbackOutput = Collection<GameEventLike> | void;

export type GenericGameEventParameters = {
    onBeforeStart?: GameEventOnBeforeStartCallback;
    onStart?: GameEventOnStartCallback;
    onProgress?: GameEventOnProgressCallback;
    onResolve?: GameEventOnResolveCallback;
    onEnd?: GameEventOnEndCallback;
    onAfterEnd?: GameEventOnAfterEndCallback;
}

export class GameEvent {
    static from(value: GameEventLike): GameEvent {
        if (typeof value === 'function') {
            return new GenericGameEvent({ onResolve: value });
        } else if (value instanceof GameEvent) {
            return value;
        } else {
            return new GenericGameEvent(value);
        }
    }

    onBeforeStart(): GameEventCallbackOutput {
        return undefined;
    }

    onStart(): GameEventCallbackOutput {
        return undefined;
    }

    /**
     * 
     * @param allocatedTime Time the event is given to progress.
     * @param totalAllocatedTime Sum of all the `allocatedDuration` values of all the previous `onProgress` call + the current one.
     * @returns Remaining time after the event has finished. If the returned value is < 0, the event has not finished
     * and `onProgress` will be called again. Otherwise the event has finished and `onEnd` will be called. Then other
     * events will be triggered with the remaining time. Returning `allocatedDuration` means the event has finished and has
     * consumed no time.
     */
    onProgress(allocatedTime: number, totalAllocatedTime: number): number | void {
        // return how much time is remaining
        // returning a value < 0 means the event has not finished
        // returning `allocatedDuration` means the event has finished and has consumed no time
        return allocatedTime;
    }

    onResolve(): GameEventCallbackOutput {
        return undefined;
    }

    onEnd(): GameEventCallbackOutput {
        return undefined;
    }

    onAfterEnd(): GameEventCallbackOutput {
        return undefined;
    }

    // isCanceled(): boolean {
    //     return false;
    // }
}

export class GenericGameEvent extends GameEvent {
    private onBeforeStartCallback: GameEventOnBeforeStartCallback;
    private onStartCallback: GameEventOnStartCallback;
    private onProgressCallback: GameEventOnProgressCallback;
    private onResolveCallback: GameEventOnResolveCallback;
    private onEndCallback: GameEventOnEndCallback;
    private onAfterStartCallback: GameEventOnAfterEndCallback;

    constructor(params: GenericGameEventParameters = {}) {
        super();

        this.onBeforeStartCallback = params.onBeforeStart ?? (() => {});
        this.onStartCallback = params.onStart ?? (() => {});
        this.onProgressCallback = params.onProgress ?? ((elapsed: number) => elapsed);
        this.onResolveCallback = params.onResolve ?? (() => {});
        this.onEndCallback = params.onEnd ?? (() => {});
        this.onAfterStartCallback = params.onAfterEnd ?? (() => {});
    }

    onBeforeStart() {
        return this.onBeforeStartCallback();
    }

    onStart() {
        return this.onStartCallback();
    }

    onProgress(elapsed: number, totalElapsed: number) {
        return this.onProgressCallback(elapsed, totalElapsed);
    }

    onResolve() {
        return this.onResolveCallback();
    }

    onEnd() {
        return this.onEndCallback();
    }

    onAfterEnd() {
        return this.onAfterStartCallback();
    }
}
globalThis.ALL_FUNCTIONS.push(GameEvent);
globalThis.ALL_FUNCTIONS.push(GenericGameEvent);