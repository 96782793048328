import { CSSProperties, useEffect, useState } from 'preact/compat';
import { Project } from '../project';
import { toCssPixels } from 'outpost';
import { BoardPlayerComponent } from './board-player-component';
import { BoardLocalData } from './board-local-data';
import { createCanvas } from '../utils';
import { Board } from './board';
import { BoardHeader } from './board-header';
import { BoardCard } from './board-card';

export type BoardComponentProps = {
    init: (localData: BoardLocalData) => void;
    project: Project;
    isGuest: boolean;
    open: boolean;
};

export function BoardComponent(props: BoardComponentProps) {
    let { init, project, isGuest, open } = props;
    let [dummy, setDummy] = useState(1);
    let forceUpdate = () => setDummy(prev => prev + 1);
    let [localData] = useState(() => new BoardLocalData(project, new Board(), isGuest ? 'p2' : 'p1', forceUpdate));
    let [downTimer, setDownTimer] = useState(0);

    let board = localData.board;

    useEffect(() => {
        (window as any).board = board;

        init(localData);
        forceUpdate();
    }, []);

    if (!open) {
        return <></>;
    }

    let style: CSSProperties = {
        userSelect: 'none',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'beige',
        padding: '10px',
        gap: toCssPixels(20),
        display: 'grid',
        gridTemplateRows: '1fr 15fr 15fr',
        gridTemplateColumns: 'auto',
        cursor: localData.currentInteraction ? 'cell' : undefined
    };

    let getCardIndex = (card: BoardCard) => {
        if (!card.parentCard) {
            return 0;
        } else {
            return card.parentCard.attachedCards.indexOf(card) + 1;
        }
    };

    let getAssociatedCard = (elt: Element) => {
        for (let className of elt.classList) {
            let card = board.getCardById(className);

            if (card) {
                return card;
            }
        }

        return undefined;
    };

    let onMouseMove = (evt: MouseEvent) => {
        if (!localData.getInteractedCard('dragged')) {
            let elts = document.elementsFromPoint(evt.clientX, evt.clientY);
            let hoveredCards: BoardCard[] = elts.map(elt => getAssociatedCard(elt)).filter(card => !!card);

            hoveredCards.sort((a, b) => getCardIndex(a) - getCardIndex(b));

            let hoveredCard: BoardCard | null = hoveredCards[0] ?? null;

            if (localData.getInteractedCard('hovered') !== hoveredCard) {
                localData.setHoveredCard(hoveredCard);
                localData.forceRefresh();
            }
        }
    };

    let onMouseDown = () => {
        setDownTimer(performance.now());
        localData.grabbed = true;
        // localData.setInspectedZone(null);
        localData.scheduleHideTooltip(0);

        if (localData.currentInteraction) {
            localData.currentInteraction = null;
            localData.forceRefresh();
        }
    };

    let onMouseUp = () => {
        localData.grabbed = false;
        localData.forceRefresh();
    };

    let onClick = () => {
        localData.setInspectedZone(null, downTimer);
    };

    let onContextMenu = (evt: Event) => evt.preventDefault();

    let topPlayerId = localData.getOpponentPlayerId();
    let bottomPlayerId = localData.getSelfPlayerId();

    return (
        <div
            style={style}
            onMouseMove={onMouseMove}
            onMouseDown={onMouseDown}
            onContextMenu={onContextMenu}
            onMouseUp={onMouseUp}
            onClick={onClick}
        >
            <BoardHeader
                localData={localData}
                isGuest={isGuest}
                style={{ gridRow: '1 / 2', gridColumn: '1 / 2' }}
            />
            <BoardPlayerComponent
                localData={localData}
                player={board.players[topPlayerId]}
                style={{ gridRow: '2 / 3', gridColumn: '1 / 2' }}
            />
            <BoardPlayerComponent
                localData={localData}
                player={board.players[bottomPlayerId]}
                style={{ gridRow: '3 / 4', gridColumn: '1 / 2' }}
            />
        </div>
    );
};

function createMockCard(content: string): HTMLCanvasElement {
    let canvas = createCanvas({ width: 250, height: 350 });
    let ctx = canvas.getContext('2d')!;

    ctx.fillStyle = 'lightgreen';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.fillStyle = 'black';
    ctx.textBaseline = 'middle';
    ctx.textAlign = 'center';
    ctx.font = `${200}px Arial`;
    ctx.fillText(content, canvas.width / 2, canvas.height / 2);

    return canvas;
}