import { Counter } from '../../utils/data-structures/counter.ts';
import { getOrInsertWith } from '../../utils/language/map.ts';
import { objectMatchesPredicate } from '../../utils/language/object.ts';
import { Constructor, ObjectPredicate } from '../../utils/language/types.ts';
import { Component } from '../component/component.ts';
import { GlobalContext } from '../global/global-context.ts';
import { EntityWrapper } from './entity-wrapper.ts';
import { RoomApi } from './room-api.ts';
import { RoomEventListenerEntry, RoomEvent, RoomEventCallback } from './room-event.ts';

export type EntityManagerSnapshot = {
    idCounter: number;
    entities: [number, Component, Component | null][];
}

export class EntityManager {
    private entities: Map<Component, EntityWrapper> = new Map();
    private entitiesById: Map<number, EntityWrapper> = new Map();
    private entitiesByConstructor: Map<Constructor, Set<EntityWrapper>> = new Map();
    private entityIdCounter: Counter = new Counter();

    private preEventPriorities: number[] = [];
    private postEventPriorities: number[] = [];
    private eventEntries: Map<RoomEvent, Map<number, Set<RoomEventListenerEntry>>> = new Map();

    getSnapshot(): EntityManagerSnapshot {
        let result: EntityManagerSnapshot = {
            idCounter: this.entityIdCounter.peek(),
            entities: []
        }

        for (let wrapper of this.entities.values()) {
            result.entities.push([wrapper.id, wrapper.entity, wrapper.parent?.entity ?? null]);
        }

        return result;
    }

    initFromSnapshot(snapshot: EntityManagerSnapshot) {
        this.entityIdCounter.reset(snapshot.idCounter);

        for (let [id, entity, parent] of snapshot.entities) {
            this.register(id, entity, parent);
        }
    }

    getEntity(id: number): Component | undefined {
        return this.entitiesById.get(id)?.entity;
    }

    getId(entity: Component): number | null {
        return this.entities.get(entity)?.id ?? null;
    }

    get<T extends Component = Component>(constructor: Constructor<T> | null, predicate?: ObjectPredicate<T>): T {
        let result = this.getOptional(constructor, predicate);

        if (!result) {
            throw new Error(`no entity with constructor ${constructor?.name} found`);
        }

        return result;
    }

    getOptional<T extends Component = Component>(constructor: Constructor<T> | null, predicate: ObjectPredicate<T> | null = null): T | undefined {
        return this.getAll(constructor, predicate, 1).at(0);
    }

    getAll<T extends Component = Component>(
        constructor: Constructor<T> | null = null,
        predicate: ObjectPredicate<T> | null = null,
        maxCount: number = Infinity
    ): T[] {
        let iterator: IterableIterator<EntityWrapper> | undefined = constructor
            ? this.entitiesByConstructor.get(constructor)?.values()
            : this.entities.values();

        let result: T[] = [];

        if (iterator && maxCount > 0) {
            for (let wrapper of iterator) {
                if (objectMatchesPredicate(wrapper.entity, constructor, predicate)) {
                    result.push(wrapper.entity as T);

                    if (result.length >= maxCount) {
                        break;
                    }
                }
            }
        }

        return result;
    }

    findParent<T extends Component = Component>(
        entity: Component,
        constructor: Constructor<T> | null,
        predicate: ObjectPredicate<T> | null,
    ): T | undefined {
        let wrapper = this.entities.get(entity);

        if (!wrapper) {
            throw new Error(`cannot get parent of non-spawned component`);
        }

        let parent = wrapper.parent?.entity;

        if (!parent || !objectMatchesPredicate(parent, constructor, predicate)) {
            return undefined;
        } else {
            return parent as T;
        }
    }

    getParent<T extends Component = Component>(
        entity: Component,
        constructor: Constructor<T> | null,
        predicate: ObjectPredicate<T> | null,
    ): T {
        let parent = this.findParent(entity, constructor, predicate);
        
        if (!parent) {
            throw new Error(`missing or invalid parent`);
        }

        return parent;
    }

    findAncestor<T extends Component = Component>(
        entity: Component,
        constructor: Constructor<T> | null,
        predicate: ObjectPredicate<T> | null,
    ): T | undefined {
        let wrapper = this.entities.get(entity);

        if (!wrapper) {
            throw new Error(`cannot get ancestor of non-spawned component`);
        }

        let parentWrapper = wrapper.parent;

        while (parentWrapper) {
            if (objectMatchesPredicate(parentWrapper.entity, constructor, predicate)) {
                return parentWrapper.entity as T;
            }

            parentWrapper = parentWrapper.parent;
        }

        return undefined;
    }

    getAncestor<T extends Component = Component>(
        entity: Component,
        constructor: Constructor<T> | null,
        predicate: ObjectPredicate<T> | null,
    ): T {
        let ancestor = this.findAncestor(entity, constructor, predicate);

        if (!ancestor) {
            throw new Error(`no matching ancestor found`);
        }

        return ancestor;
    }

    isSpawned(entity: Component): boolean {
        return this.entities.has(entity);
    }

    spawn(entity: Component, parent: Component | null, api: RoomApi): boolean {
        let result = this.register(undefined, entity, parent);

        if (result) {
            GlobalContext.withRoomApi(api, () => entity.onSpawn?.());
        }

        return result;
    }

    despawn(entity: Component, api: RoomApi): boolean {
        let result = this.unregister(entity);

        if (result) {
            GlobalContext.withRoomApi(api, () => entity.onDespawn?.());
        }

        // TODO: despawn children?

        return result;
    }

    register(id: number | undefined, entity: Component, parent: Component | null): boolean {
        let wrapper = this.entities.get(entity);

        if (wrapper) {
            return false;
        }

        let entityId = id ?? this.entityIdCounter.next();
        let parentWrapper = (parent && this.entities.get(parent)) ?? null;

        wrapper = new EntityWrapper(entityId, entity, 0);
        wrapper.parent = parentWrapper;

        this.entities.set(entity, wrapper);
        this.entitiesById.set(entityId, wrapper);

        for (let ctor of wrapper.constructorChain) {
            let list = getOrInsertWith(this.entitiesByConstructor, ctor, () => new Set());

            list.add(wrapper);
        }

        if (entity.onRegister) {
            let self = this;

            entity.onRegister({
                on(evtFunction, callback, priority = 0) {
                    self.addEventListener(evtFunction, callback, priority, entity);
                    return this;
                },
            });
        }

        return true;
    }

    private unregister(entity: Component): boolean {
        let wrapper = this.entities.get(entity);

        if (!wrapper) {
            return false;
        }

        for (let ctor of wrapper.constructorChain) {
            this.entitiesByConstructor.get(ctor)!.delete(wrapper);
        }

        for (let entry of wrapper.eventListeners) {
            this.removeEventListener(entry);
        }

        this.entities.delete(entity);
        this.entitiesById.delete(wrapper.id);

        return true;
    }

    private usePriority(priority: number) {
        let priorities = priority < 0 ? this.preEventPriorities : this.postEventPriorities;

        if (!priorities.includes(priority)) {
            priorities.push(priority);
            priorities.sort((a, b) => a - b);
        }
    }

    private addEventListener(evtFunction: RoomEvent, callback: RoomEventCallback, priority: number, target: Component) {
        let entriesByPriority = this.eventEntries.getOrInsertWith(evtFunction, () => new Map());
        let entries = entriesByPriority.getOrInsertWith(priority, (priority) => {
            this.usePriority(priority);
            return new Set();
        });
        let entry: RoomEventListenerEntry = { evtFunction, callback, priority, target };

        entries.add(entry);
        this.entities.get(target)?.eventListeners.push(entry);
    }

    private removeEventListener(entry: RoomEventListenerEntry) {
        this.eventEntries.get(entry.evtFunction)?.get(entry.priority)?.delete(entry);
    }

    emitEvent<E extends RoomEvent>(evtFunction: E, ...args: Parameters<E>) {
        let entriesByPriority = this.eventEntries.get(evtFunction);

        for (let priority of this.preEventPriorities) {
            if (this.triggerEntries(entriesByPriority, priority, args)) {
                return;
            }
        }

        if (evtFunction(...args)) {
            return;
        }

        for (let priority of this.postEventPriorities) {
            if (this.triggerEntries(entriesByPriority, priority, args)) {
                return;
            }
        }
    }

    private triggerEntries(entriesByPriority: Map<number, Set<RoomEventListenerEntry>> | undefined, priority: number, args: any[]): boolean {
        if (!entriesByPriority) {
            return false;
        }

        let entries = entriesByPriority.get(priority);

        if (entries) {
            for (let entry of entries) {
                if (entry.callback.call(entry.target, ...args)) {
                    return true;
                }
            }
        }

        return false;
    }

    forEach(callback: (component: Component) => void) {
        for (let wrapper of this.entities.values()) {
            callback(wrapper.entity);
        }
    }

    entityWrappers(): IterableIterator<EntityWrapper> {
        return this.entities.values();
    }
}

// function matchesPredicate(value: any, predicate?: ObjectPredicate<any> | null): boolean {
//     if (!predicate) {
//         return true;
//     } else if (typeof predicate === 'function') {
//         return predicate(value);
//     } else {
//         for (let key in predicate) {
//             if ((value as any)[key] !== (predicate as any)[key]) {
//                 return false;
//             }
//         }

//         return true;
//     }
// }
globalThis.ALL_FUNCTIONS.push(EntityManager);