import { Room } from '../../framework/global/global-room-api.ts';
import { RoomApi } from '../../framework/room/room-api.ts';
import { View } from '../../framework/view/view.ts';
import { ColorLike } from '../../utils/color/color.ts';
import { WidgetStyle, mergeWidgetStyles } from './widget-types.ts';
import { Widget } from './widget.ts';

export type TextBoxStyle = WidgetStyle & {
    placeholderColor?: ColorLike;
}

const DEFAULT_TEXTBOX_STYLE: TextBoxStyle = {
    placeholderColor: 'gray',
    base: {
        all: { borderRadius: '10%' },
        background: { color: 'white' },
        body: { textSize: '40%', textPadding: '10%h', horizontalAlign: 'left' },
        border: { strokeSize: '3%', color: 'black' }
    },
    hovered: {
        all: { cursor: 'text' }
    },
    focused: {
        body: { textCursorShow: true },
        border: { strokeSize: { mult: 2 } },
    }
};

export class TextBox extends Widget {
    private placeholder: string;
    private placeholderColor: ColorLike = 'gray';

    constructor(placeholder: string, style?: TextBoxStyle) {
        super('', mergeWidgetStyles(DEFAULT_TEXTBOX_STYLE, style));
        this.placeholder = placeholder;
        this.placeholderColor = style?.placeholderColor ?? 'gray';
    }

    async $edit() {
        let input = await Room.waitForUserInput({
            selectable: this,
            selectionTrigger: 'text'
        });

        this.setText(input.text);
        Room.render(this);
    }

    render(view: View) {
        super.render(view);

        if (!this.text.content) {
            view.paint({
                key: 'body',
                text: this.placeholder,
                textColor: this.placeholderColor,
                textCursorIndex: -1
            });
        }
    }
}
globalThis.ALL_FUNCTIONS.push(TextBox);