import { evalFunction } from '../../../utils/language/function.ts';
import { makeRequired } from '../../../utils/language/object.ts';
import { Logger } from '../../../utils/logging/logger.ts';
import { AnimationApi } from '../animation-api.ts';
import { DEFAULT_PLAY_AUDIO_PARAMS, PlayAudioParams } from './play-audio-params.ts';

export async function playAudioAnimation(api: AnimationApi, params: PlayAudioParams) {
    let {
        audioUrl,
        animationId,
        delay,
        blocking,
        location,
        volume,
        loop,
        muteOutsideCamera,
        fadeOutDistance,
    } = makeRequired(params, DEFAULT_PLAY_AUDIO_PARAMS);

    api.configure({ animationId, blocking, delay, duration: Infinity });

    let { graphicsEngine } = api;
    let audio = await loadAudio(audioUrl);

    if (!audio) {
        Logger.warn(`cannot load audio at ${audioUrl}`);
        return;
    }

    if (!graphicsEngine.canPlayAudio()) {
        return;
    }

    await api.waitForStart();

    let isPlaying = false;
    
    audio.loop = loop;

    while (await api.waitForFrame()) {
        let finalVolume = volume;

        if (muteOutsideCamera && location) {
            let { x, y, layerId = null } = evalFunction(location);
            let distance = graphicsEngine.getDistanceToCameraEdges(layerId, x, y);

            if (distance < 0) {
                finalVolume = 0;
            } else if (distance < fadeOutDistance) {
                finalVolume = finalVolume * distance / fadeOutDistance;
            }
        }

        audio.volume = finalVolume;

        if (audio.currentTime >= audio.duration) {
            break;
        }

        if (!isPlaying) {
            audio.play();
            isPlaying = true;
        }
    };

    audio.pause();
    audio.currentTime = 0;
}

function loadAudio(url: string): Promise<HTMLAudioElement | null> {
    let audio = new Audio(url);

    return new Promise<HTMLAudioElement | null>(resolve => {
        audio.addEventListener('canplaythrough', () => {
            resolve(audio);
        });
        audio.onerror = () => {
            resolve(null);
        };
    });
}