import { ImageLike, ImageLoader } from '../../../utils/dom/image-loader.ts';
import { HorizontalAlign } from '../../../utils/geometry/horizontal-align.ts';
import { Rect } from '../../../utils/geometry/rect.ts';
import { VerticalAlign } from '../../../utils/geometry/vertical-align.ts';
import { RenderTextResult, TextParameters, TextToken, TextTokenRule } from './render-text-types.ts';

const BOTTOM_CHARACTERS = new Set(['g', 'j', 'p', 'q', 'y']);
const IMAGE_HEIGHT_MULTIPLIER = 0.85;

export type RenderTextParams = {
    imageLoader: ImageLoader;
    iconAliases: { [name: string]: string };
    content: string;
    width: number;
    height: number;
    font: string;
    size: number;
    color: string;
    bold: boolean;
    italic: boolean;
    horizontalAlign: HorizontalAlign;
    verticalAlign: VerticalAlign;
    allowMultiline: boolean;
    padding: number;
    cursorIndex: number;
};

export function renderText(params: RenderTextParams, textSizeMultiplier: number = 1): RenderTextResult {
    let imageLoader = params.imageLoader;
    let iconAliases = params.iconAliases;
    let text = params.content;
    let width = params.width;
    let height = params.height
    let allowMultiline = params.allowMultiline;
    let padding = params.padding;
    let textSize = params.size * textSizeMultiplier;
    let textFont = params.font;
    let textColor = params.color;
    let textBold = params.bold;
    let textItalic = params.italic;
    let textCursorIndex = params.cursorIndex;
    let textFit = true;
    let fixVerticalCenter = true;
    let maxWidth = textFit ? width : 0;
    let maxHeight = textFit ? height : 0;

    if (textCursorIndex === undefined) {
        textCursorIndex = -1;
    }

    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d')!;

    textSize = Math.floor(textSize);
    padding = Math.ceil(padding);

    let baseTextParams: TextParameters = {
        font: textFont,
        size: textSize,
        color: textColor ?? 'rgba(0,0,0,0)',
        bold: textBold,
        italic: textItalic,
        horizontalAlign: 'left',
        verticalAlign: 'middle'
    };
    let lines = [];
    let maxLineWidth = maxWidth ? maxWidth - padding * 2 : Infinity;
    let maxTextHeight = maxHeight ? maxHeight - padding * 2 : Infinity;
    let startX = padding + 1;
    let startY = padding;
    let x = startX;
    let y = startY;
    let currentLine = [];
    let currentLineWidth = 0;
    let longestLineWidth = 0;
    let currentLineHeight = textSize;
    let lineHorizontalAlign = 'left';
    let previousLineHeight = 0;
    let lastToken = { content: '' };
    let suffix = textCursorIndex > -1 ? ' \n' : '\n';
    let tokens = tokenize(text + suffix, baseTextParams);
    let dependsOnAssets: Set<string> = new Set([textFont]);

    for (let token of tokens) {
        let tokenImageUrl = iconAliases[token.content];

        if (tokenImageUrl) {
            dependsOnAssets.add(tokenImageUrl);
        }

        let tokenWidth = measureToken(token, ctx, iconAliases, imageLoader);
        let tokenHeight = token.size || 0;
        let isNewLine = token.content.includes('\n');

        if (isNewLine || (currentLineWidth + tokenWidth > maxLineWidth && currentLine.length > 0 && allowMultiline)) {
            if (currentLine.length === 0) {
                currentLineHeight = Math.ceil(textSize * 2 / 3);
            }

            let m = token.content === '@\n' ? 0.5 : 1;

            y += (currentLineHeight || previousLineHeight) * m;

            for (let obj of currentLine) {
                obj.y = y;
            }

            lines.push({
                tokens: currentLine,
                width: currentLineWidth,
                height: currentLineHeight,
                align: lineHorizontalAlign
            });

            currentLineWidth = isBlank(token) ? 0 : tokenWidth;
            x = startX;
            previousLineHeight = currentLineHeight || previousLineHeight;
            currentLineHeight = 0;
            currentLine = [];
        } else {
            currentLineWidth += tokenWidth;
        }

        if (!isNewLine) {
            currentLineHeight = Math.max(currentLineHeight, tokenHeight + 2);
            lineHorizontalAlign = token.horizontalAlign;
        }

        longestLineWidth = Math.max(longestLineWidth, currentLineWidth);

        if (!isNewLine) {
            token.x = x;

            if (token.content !== ' ' || currentLine.length > 0 || token === tokens[0]) {
                currentLine.push(token);
            }

            if (token.content !== ' ' || x !== startX || lastToken.content.includes('\n')) {
                x += tokenWidth;
            }
        }

        lastToken = token;
    }

    let additionalHeight = 0;

    for (let i = 0; i < lines.length; ++i) {
        let line = lines[i];
        let lineHeight = line.height;
        let widthDif = longestLineWidth - line.width;
        let offsetX = 0;

        if (line.align === 'center') {
            offsetX = widthDif / 2;
        } else if (line.align === 'right') {
            offsetX = widthDif;
        }

        for (let token of line.tokens) {
            let dif = (lineHeight - token.size);
            let m = 0.3;

            if (token.verticalAlign === 'bottom') {
                m = -0.1;

                if (i === lines.length - 1) {
                    additionalHeight = Math.max(additionalHeight, lineHeight * 0.2);
                }
            }

            token.x += offsetX;
            token.y -= dif * m;
        }

        if (i === lines.length - 1 && lines.length === 1) {
            additionalHeight = Math.max(additionalHeight, lineHeight * 0.1);
        }
    }

    if (fixVerticalCenter && lines.length === 1) {
        let line = lines[0];
        let has_bottom_characters = false;

        for (let token of line.tokens) {
            for (let c of token.content) {
                if (BOTTOM_CHARACTERS.has(c)) {
                    has_bottom_characters = true;
                }
            }
        }

        if (true || !has_bottom_characters) {
            for (let token of line.tokens) {
                token.y += line.height * 0.09;
            }
        }
    }

    let totalHeight = Math.round(y - startY + padding * 2 + 1 + additionalHeight);
    let totalWidth = Math.round(longestLineWidth + padding * 2 + 2);

    canvas.width = totalWidth;
    canvas.height = totalHeight;

    // ctx.fillStyle = 'white';
    // ctx.fillRect(0, 0, canvas.width, canvas.height);

    // ctx.fillStyle = 'white'; ctx.fillRect(0, 0, totalWidth, totalHeight);

    // if (backgroundColor) {
    //     ctx.fillStyle = backgroundColor;
    //     ctx.fillRect(0, 0, totalWidth, totalHeight);
    // }

    // if (borderColor) {
    //     ctx.lineWidth = 2;
    //     ctx.strokeStyle = borderColor;
    //     ctx.strokeRect(0, 0, totalWidth, totalHeight);
    // }

    ctx.textBaseline = 'bottom';

    let cursorDone = textCursorIndex < 0;
    let index = 0;
    let cursorRect: Rect = Rect.zero();
    let cursorColor: string = '';

    for (let line of lines) {
        for (let token of line.tokens) {
            let tokenImage = getTokenImage(token, iconAliases, imageLoader);
            let offsetY = Math.floor(token.offset * token.size);

            if (tokenImage) {
                let height = token.size * IMAGE_HEIGHT_MULTIPLIER;
                let width = height * tokenImage.width / tokenImage.height;

                ctx.drawImage(tokenImage, token.x, token.y - token.size + offsetY, width, height);

                index += 1;
            } else {
                setCanvasPropertiesFromToken(ctx, token);
                ctx.fillText(token.content, token.x, token.y + offsetY);
    
                let nextIndex = index + token.content.length;
    
                if (!cursorDone && textCursorIndex <= nextIndex) {
                    // TODO: properly display the cursor on multi-line texts
                    let subText = token.content.substring(0, textCursorIndex - index);
                    let subTextWidth = ctx.measureText(subText).width;
                    let cursorWidth = 1;
                    let cursorHeight = token.size * IMAGE_HEIGHT_MULTIPLIER;
                    let cursorX = token.x + subTextWidth;
                    let cursorY = token.y - token.size * 0.95;
    
                    cursorRect = Rect.from({
                        x1: Math.round(cursorX),
                        y1: Math.round(cursorY),
                        width: cursorWidth,
                        height: Math.round(cursorHeight)
                    });
                    cursorColor = token.color;
                    cursorDone = true;
                }
    
                index = nextIndex;
            }
        }
    }

    if (cursorDone && !cursorRect.isZero()) {
        ctx.rect(cursorRect.x1, cursorRect.y1, cursorRect.width, cursorRect.height);
        ctx.fillStyle = cursorColor;
        ctx.fill();
    }

    if (canvas.width > maxLineWidth && lines.length <= 1 && textSize > 5) {
        return renderText(params, textSizeMultiplier * maxLineWidth / canvas.width);
    }

    return { image: canvas, dependsOnAssets };
}

function getTokenImage(token: TextToken, iconAliases: { [name: string]: string }, imageManager: ImageLoader): ImageLike | null {
    let imageUrl = iconAliases[token.content];

    return (imageUrl && imageManager.get(imageUrl)) || null;
}

function measureToken(token: TextToken, ctx: CanvasRenderingContext2D, iconAliases: { [name: string]: string }, imageManager: ImageLoader): number {
    if (!token.content || !token.size) {
        return 0;
    }

    let image = getTokenImage(token, iconAliases, imageManager);

    if (image) {
        return image.width / image.height * token.size * IMAGE_HEIGHT_MULTIPLIER;
    } else {
        setCanvasPropertiesFromToken(ctx, token);
        return ctx.measureText(token.content).width;
    }
}

function isBlank(token: TextToken): boolean {
    return token.content === ' ' || token.content.includes('\n');
}

function setCanvasPropertiesFromToken(ctx: CanvasRenderingContext2D, token: TextToken) {
    let { font, size, bold, italic, color } = token;

    ctx.font = `${bold ? 'bold ' : ''}${italic ? 'italic ' : ''}${Math.round(size)}px "${font}"`;
    ctx.fillStyle = color;
}

function makeToken(content: string, textParams: TextParameters, rules: TextTokenRule[]): TextToken {
    let token: TextToken = { content, ...textParams, offset: 0, x: 0, y: 0 };

    for (let rule of rules) {
        if (!isNaN(+rule)) {
            token.size *= (+rule);
        } else if (rule.match(/^o-?\d+%$/)) {
            token.offset = parseInt(rule.substring(1)) / 100;
        } else if (rule === 'right') {
            token.horizontalAlign = 'right';
        } else if (rule === 'center') {
            token.horizontalAlign = 'center';
        } else if (rule === 'bold') {
            token.bold = true;
        } else if (rule === 'italic') {
            token.italic = true;
        } else if (rule === 'normal') {
            token.italic = false;
            token.bold = false;
        } else if (rule === 'small') {
            token.size *= 0.75;
        } else if (rule === 'big') {
            token.size *= 1.6;
        } else if (rule === 'sub') {
            token.size *= 0.5;
            token.verticalAlign = 'bottom';
        } else {
            token.color = rule;
        }
    }

    return token;
}

const SHORTCUTS = {
    '*': 'bold',
    '_': 'italic',
    '|': 'center',
    '#': 'big',
    '~': 'small'
};

function tokenize(text: string, textParams: TextParameters): TextToken[] {
    let tokens = [];
    let activeShortcuts = new Set();
    let ruleStack = [];
    let content = '';

    for (let i = 0; i <= text.length; ++i) {
        let c = text[i];

        if (c in SHORTCUTS && text[++i] !== c) {
            i -= 1;
            let rule = SHORTCUTS[c as keyof typeof SHORTCUTS];

            if (activeShortcuts.has(c)) {
                tokens.push(makeToken(content, textParams, ruleStack));
                activeShortcuts.delete(c);
                ruleStack.splice(ruleStack.lastIndexOf(rule), 1);
                content = '';
            } else {
                tokens.push(makeToken(content, textParams, ruleStack));
                activeShortcuts.add(c);
                ruleStack.push(rule);
                content = '';
            }
        } else if (c === '@' && text[i + 1] === '\n') {
            tokens.push(makeToken(content, textParams, ruleStack));
            tokens.push(makeToken('@\n', textParams, ruleStack));
            ruleStack = [];
            activeShortcuts.clear();
            content = '';
            i += 1;
        } else if (c === '@') {
            let startBracketIndex = text.indexOf('{', i + 1);

            if (startBracketIndex === -1) {
                content += c;
            } else {
                let rules = text.substring(i + 1, startBracketIndex).split(',');

                tokens.push(makeToken(content, textParams, ruleStack));
                ruleStack.push(...rules);
                content = '';
            }

            i = startBracketIndex;
        } else if (c === '}' && ruleStack.length) {
            tokens.push(makeToken(content, textParams, ruleStack));
            ruleStack.pop();
            content = '';
        } else if (c === '\n') {
            tokens.push(makeToken(content, textParams, ruleStack));
            tokens.push(makeToken('\n', textParams, ruleStack));
            ruleStack = [];
            activeShortcuts.clear();
            content = '';
        } else if (c === ' ') {
            let token = makeToken(content, textParams, ruleStack);
            let spaceToken = { ...token, content: ' ' };

            tokens.push(token, spaceToken);
            content = '';
        } else if (c === undefined) {
            tokens.push(makeToken(content, textParams, ruleStack));
        } else {
            content += c;
        }
    }

    return tokens.filter(token => token.content);
}