import { ItemBuffer } from '../memory/item-buffer.ts';
import { WebglAttributesValues, WebglAttributesLayoutDetails, WebglAttributesLayout, computeAttributesDetails } from './webgl-attributes.ts';
import { WebglBufferWriter } from './webgl-buffer-writer.ts';
import { WebglRenderPass } from './webgl-render-pass.ts';
import { WebglUniformValues, WebglUniformsLayout, WebglUniformsLayoutDetails, computeUniformsDetails } from './webgl-uniforms.ts';
import { compileWebglProgram } from './webgl-utils.ts';

const INSTANCE_BUFFER_INITIAL_CAPACITY = 256;

export type WebglProgramParams<
    Uniforms extends WebglUniformsLayout,
    PerInstanceAttributes extends WebglAttributesLayout,
    PerVertexAttributes extends WebglAttributesLayout,
    // Instance extends WebglInstance<PerInstanceAttributes>
> = {
    vertexSource: string;
    fragmentSource: string;
    perInstanceAttributes: PerInstanceAttributes;
    perVertexAttributes: PerVertexAttributes;
    perVertexValues: WebglAttributesValues<PerVertexAttributes>[];
    uniforms: Uniforms;
    // createInstance: () => Instance;
};

export class WebglProgram<
    Uniforms extends WebglUniformsLayout,
    PerInstanceAttributes extends WebglAttributesLayout,
    PerVertexAttributes extends WebglAttributesLayout,
    // Instance extends WebglInstance<PerInstanceAttributes, any[]>
> {
    private gl: WebGL2RenderingContext;
    private vertexShaderSource: string;
    private fragmentShaderSource: string;
    private vertexValues: WebglAttributesValues<PerVertexAttributes>[];
    private perInstanceAttributes: PerInstanceAttributes;
    private perVertexAttributes: PerVertexAttributes;
    private perVertexValues: WebglAttributesValues<PerVertexAttributes>[];
    private uniforms: Uniforms;

    private program!: WebGLProgram;
    private uniformsLayout!: WebglUniformsLayoutDetails<Uniforms>;
    private instanceLayout!: WebglAttributesLayoutDetails<PerInstanceAttributes>;
    private vertexLayout!: WebglAttributesLayoutDetails<PerVertexAttributes>;
    private renderPasses: WebglRenderPass<Uniforms, PerInstanceAttributes, PerVertexAttributes>[] = [];
    // private instance: Instance;
    private instanceMetadata: ItemBuffer = new ItemBuffer({ initialItemCapacity: 256, itemByteSize: 8 });

    constructor(gl: WebGL2RenderingContext, params: WebglProgramParams<Uniforms, PerInstanceAttributes, PerVertexAttributes>) {
        this.gl = gl;
        this.vertexShaderSource = params.vertexSource;
        this.fragmentShaderSource = params.fragmentSource;
        this.vertexValues = params.perVertexValues;
        // this.instance = params.createInstance();
        this.perInstanceAttributes = params.perInstanceAttributes;
        this.perVertexAttributes = params.perVertexAttributes;
        this.perVertexValues = params.perVertexValues;
        this.uniforms = params.uniforms;
    }

    async init() {
        let gl = this.gl;

        this.program = await compileWebglProgram(gl, {
            vertex: this.vertexShaderSource,
            fragment: this.fragmentShaderSource
        });
        this.instanceLayout = computeAttributesDetails(gl, this.program, this.perInstanceAttributes);
        this.vertexLayout = computeAttributesDetails(gl, this.program, this.perVertexAttributes);
        this.uniformsLayout = computeUniformsDetails(gl, this.program, this.uniforms);
    }

    private createRenderPass(): WebglRenderPass<Uniforms, PerInstanceAttributes, PerVertexAttributes> {
        let renderPass = new WebglRenderPass({
            gl: this.gl,
            program: this.program,
            instanceInitialCapacity: INSTANCE_BUFFER_INITIAL_CAPACITY,
            uniforms: this.uniformsLayout,
            perInstance: this.instanceLayout,
            perVertex: this.vertexLayout,
            vertexCountPerInstance: this.vertexValues.length,
        });

        for (let vertex of this.vertexValues) {
            let writer = renderPass.allocateVertex();

            for (let [key, value] of Object.entries(vertex)) {
                if (Array.isArray(value)) {
                    (writer[key] as any)(...value);
                } else {
                    (writer[key] as any)(value);
                }
            }
        }

        return renderPass;
    }

    private requireRenderPass(index: number): WebglRenderPass<Uniforms, PerInstanceAttributes, PerVertexAttributes> {
        let renderPass = this.renderPasses[index];

        if (!renderPass) {
            renderPass = this.createRenderPass();
            this.renderPasses[index] = renderPass;
        }

        return renderPass;
    }

    // updateInstance(instanceId: number | null, instance: Instance | null, renderPassIndex: number, ...params: GetWebglInstanceParams<Instance>): number | null {
    //     let result: number | null = null;

    //     if (instanceId !== null) {
    //         let prevInstanceMetadataByteOffset = instanceId * 8;
    //         let prevRenderPassIndex = this.instanceMetadata.dataView.getUint32(prevInstanceMetadataByteOffset);
    //         let prevRenderPassElementIndex = this.instanceMetadata.dataView.getUint32(prevInstanceMetadataByteOffset + 1);
    //         let prevRenderPass = this.getRenderPass(prevRenderPassIndex);

    //         prevRenderPass.deallocateInstance(prevRenderPassElementIndex);
    //         this.instanceMetadata.deallocate(prevInstanceMetadataByteOffset);
    //     }

    //     if (instance) {
    //         let nextRenderPassIndex = renderPassIndex;
    //         let nextRenderPass = this.getRenderPass(nextRenderPassIndex);
    //         let writer = nextRenderPass.allocateInstance();

    //         instance.load(writer, ...params);

    //         let nextRenderPassElementIndex = writer.getElementIndex();
    //         let nextInstanceMetadataByteOffset = this.instanceMetadata.allocate();

    //         this.instanceMetadata.dataView.setUint32(nextInstanceMetadataByteOffset, nextRenderPassIndex);
    //         this.instanceMetadata.dataView.setUint32(nextInstanceMetadataByteOffset + 1, nextRenderPassElementIndex);

    //         result = nextInstanceMetadataByteOffset / 8;
    //     }

    //     return result;
    // }

    draw(renderPassIndex: number, uniforms: WebglUniformValues<Uniforms>) {
        let renderPass = this.renderPasses[renderPassIndex];

        if (!renderPass) {
            return;
        }

        renderPass.draw(uniforms);
    }

    allocateInstance(renderPassIndex: number): WebglBufferWriter<PerInstanceAttributes> {
        return this.requireRenderPass(renderPassIndex).allocateInstance();
    }

    clearInstances() {
        for (let renderPass of this.renderPasses) {
            if (renderPass) {
                renderPass.clearInstances();
            }
        }
    }
}