import { DualMap } from '../data-structures/dual-map.ts';

let globalDualMap: DualMap<number, string> = new DualMap();

export function getGlobalColorId(color: string): number {
    let id = globalDualMap.getByValue(color);

    if (!id) {
        id = globalDualMap.size + 1;
        globalDualMap.set(id, color);
    }

    return id;
}

export function getGlobalColorValue(id: number): string {
    return globalDualMap.getByKey(id) ?? 'black';
}