import { Rect } from '../../utils/geometry/rect.ts';
import { Collection, iterateCollection } from '../../utils/language/collection.ts';
import { Layout } from '../../utils/layout/layout.ts';
import { ComponentModifier, componentModifierToCallback } from '../component/component-modifier.ts';
import { Component, ComponentLike } from '../component/component.ts';
import { View } from './view.ts';

/**
 * Object returned by {@link View.layout}.
 * Provides a chaining API to position items in a parent rectangle.
 * 
 * **Check the "Inherited" member visibility in TypeDoc's setting (on the right) to also see methods inherited from {@link Layout}.**
 * @category Core
 * @example
 * export class RootComponent implements Component {
 *     items = {
 *         children: new ItemArray([
 *             new ChildCompnent(),
 *             new ChildCompnent(),
 *             new ChildCompnent(),
 *             new ChildCompnent(),
 *         ])
 *     }
 * 
 *     render(view: View): void {
 *         view.paint()
 *             .backgroundColor('white')
 * 
 *         view.layout()
 *             .topToBottom() // Set the layout direction
 *             .addChild('header', { // Add a header
 *                 text: 'This is the header',
 *                 textSize: '50%',
 *                 backgroundColor: 'lightgreen'
 *             })
 *             .height('20%') // Set the header's witdth to 20% of the parent's height
 *             .addChild(null, { // Add a line separator (if `null` is passed, a key will be generated by the layout)
 *                 backgroundColor: 'black'
 *             })
 *             .height('1%') // Set the separator height to 1% of the parent's height
 *             .addChild() // Add a child which will serve as the grid container
 *             .grid() // Set the child's layout to "grid"
 *             .rowSize(3) // Set the row size to exactly 3
 *             .columnSize(1, Infinity) // Indicate that the columns can have any size (new rows will be added as items are added)
 *             .margin('5%') // Set both the outer margin (around the grid) and inner margin (between items) to 5% of the parent's height
 *             .addChild(this.items.children, { // Add the child components, which will be layouted in a grid
 *                 borderColor: 'black',
 *                 borderWidth: '2%'
 *             }) // Because we don't specify any height, the grid will take all the remaining space
 *             .parent() // Go back to the parent container (the one we called `topToBottom` on)
 *             .addChild() // Add an invisible item that will serve as the footer container
 *             .leftToRight() // Layout this container from left to right
 *             .height('10%') // Set the container's height to 10% of the parent's height
 *             .addChild('bottom-left', { // Add an item on the left of this container
 *                 backgroundColor: 'pink',
 *                 text: 'This is an example',
 *                 textSize: '50%'
 *                 // Because its container is a line from left to right, the text align is automatically set to "left"
 *             })
 *             .force(2) // Make so it takes twice as much space as the other item
 *             .addChild('bottom-right', { // Add another item
 *                 backgroundColor: 'skyblue',
 *                 text: 'Made with outpost',
 *                 textSize: '50%',
 *                 textHorizontalAlign: 'center',
 *             })
 *             .force(1) // Make so it takes half as much space as the other one
 *     }
 * }
 * 
 * class ChildCompnent implements Component {
 *     render(view: View): void {
 *         view.paint()
 *             .backgroundColor('tomato')
 *     }
 * }
 */
export class ViewLayout extends Layout<ComponentLike, ComponentModifier> {
    private rootView: View;
    private finished: boolean = false;

    constructor(view: View) {
        super();
        this.rootView = view;
    }

    /**
     * Add one or more child components to the layout. They will be positionned when {@link ViewLayout.finish} is called.
     * 
     * The last specified component becomes the current item (see {@link Layout}).
     * @param child Collection of components to add as children.
     * @param modifier Optional graphics to apply to the children.
     * @returns
     */
    addChild(
        child: Collection<ComponentLike>,
        modifier: ComponentModifier = null
    ): this {
        if (child === null) {
            this.push(null, modifier);
        } else {
            for (let item of iterateCollection(child)) {
                this.push(item, modifier);
            }
        }

        return this;
    }

    /**
     * Call {@link View.renderChild} on all components added via {@link ViewLayout.addChild}.
     * 
     * This method is automatically called at the end of {@link Component.render}.
     * @returns 
     */
    finish() {
        if (this.finished) {
            return;
        }

        this.finished = true;

        let objectsToLayout = this.compute(this.rootView.getRect());

        for (let { object, rect, properties } of objectsToLayout) {
            if (!properties) {
                this.rootView.addChild(object, rect ?? Rect.ZERO);
            } else {
                let modifier = componentModifierToCallback(properties);
                let callback = (view: View, component: Component) => {
                    modifier(view, component);
                    view.paint({ rect: rect ?? Rect.ZERO });
                };

                this.rootView.addChild(object, callback);
            }
        }
    }
}
globalThis.ALL_FUNCTIONS.push(ViewLayout);