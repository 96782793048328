import { mix } from '../language/math.ts';

/**
 * Represents a size, which can be expressed as a fixed number or as a ratio to the parent width or height.
 * It is notably used by many of the {@link Rect}'s methods.
 * 
 * A `DisplaySizeLike` can be one of the following:
 * - a fixed size: `5`, `1.5`
 * - a percentage of the parent's width: `'20%w'`, `'1.5%w'`
 * - a percentage of the parent's height: `'20%h'`, `'1.5%h'`
 * - a percentage of either the parent's width or height depending on the context: `'20%'`, `'1.5%'`
 * 
 * When a percentage with no suffix is specified, the dimension it refers to depends on the context.
 * For example in {@link Rect.fromLandmark}, the `width` parameter would refer to the parent's width and the `height` parameter would refer to the parent's height.
 * In cases where it is not clear, it refers to the parent's height.
 * 
 * Internally, a {@link DisplaySizeLike} is converted to a {@link DisplaySize}.
 */
export type DisplaySizeLike =
    | DisplaySize
    | number
    | '*'
    | `${number}${'%' | ''}${'w' | 'h' | ''}`;; // TODO: not sure if it's better than just a regular string

export type DisplaySizeProperties = {
    fixedReal: number;
    fixedVirtual: number;
    scaledFromParentWidth: number;
    scaledFromParentHeight: number;
    scaledFromParentMin: number;
    scaledFromParentMax: number;
};

/**
 * Created from a {@link DisplaySizeLike}, used internally.
 * @category Internal
 */
export class DisplaySize {
    readonly fixedReal: number;
    readonly fixedVirtual: number;
    readonly scaledFromParentWidth: number;
    readonly scaledFromParentHeight: number;
    readonly scaledFromParentMin: number;
    readonly scaledFromParentMax: number;

    constructor(properties: Partial<DisplaySizeProperties> = {}) {
        this.fixedReal = properties.fixedReal ?? 0;
        this.fixedVirtual = properties.fixedVirtual ?? 0;
        this.scaledFromParentWidth = properties.scaledFromParentWidth ?? 0;
        this.scaledFromParentHeight = properties.scaledFromParentHeight ?? 0;
        this.scaledFromParentMin = properties.scaledFromParentMin ?? 0;
        this.scaledFromParentMax = properties.scaledFromParentMax ?? 0;
    }

    static zero(): DisplaySize {
        return new DisplaySize();
    }

    static real(value: number): DisplaySize {
        return new DisplaySize({ fixedReal: value });
    }

    static virtual(value: number): DisplaySize {
        return new DisplaySize({ fixedVirtual: value });
    }

    static scaledFromParentWidth(value: number): DisplaySize {
        return new DisplaySize({ scaledFromParentWidth: value });
    }

    static scaledFromParentHeight(value: number): DisplaySize {
        return new DisplaySize({ scaledFromParentHeight: value });
    }

    static scaledFromParentMin(value: number): DisplaySize {
        return new DisplaySize({ scaledFromParentMin: value });
    }

    static scaledFromParentMax(value: number): DisplaySize {
        return new DisplaySize({ scaledFromParentMax: value });
    }

    static from(value: DisplaySizeLike, defaultPercentKey: keyof DisplaySizeProperties = 'scaledFromParentHeight'): DisplaySize {
        if (value instanceof DisplaySize) {
            return value.clone();
        } else if (typeof value === 'number') {
            return new DisplaySize({ fixedVirtual: value });
        } else if (value === '*') {
            return new DisplaySize({ [defaultPercentKey]: 1 });
        } else {
            let number: number = parseFloat(value);
            let last = value.at(-1);
            let key: keyof DisplaySize = 'fixedVirtual';

            if (value.includes('%')) {
                number /= 100;
                key = defaultPercentKey;
            }

            if (last === 'w') {
                key = 'scaledFromParentWidth';
            } else if (last === 'h') {
                key = 'scaledFromParentHeight';
            } else if (last === 'm') {
                key = 'scaledFromParentMin';
            } else if (last === 'v') {
                key = 'fixedVirtual';
            } else if (last === 'r') {
                key = 'fixedReal';
            }

            return new DisplaySize({ [key]: number });
        }
    }

    clone(): DisplaySize {
        return new DisplaySize({ ...this });
    }

    process(func: (n: number) => number) {
        let fixedReal = func(this.fixedReal);
        let fixedVirtual = func(this.fixedVirtual);
        let scaledFromParentWidth = func(this.scaledFromParentWidth);
        let scaledFromParentHeight = func(this.scaledFromParentHeight);
        let scaledFromParentMin = func(this.scaledFromParentMin);

        return new DisplaySize({ fixedReal, fixedVirtual, scaledFromParentWidth, scaledFromParentHeight, scaledFromParentMin });
    }

    scale(ratio: number): DisplaySize {
        return this.process(n => n * ratio);
    }

    div(ratio: number): DisplaySize {
        return this.process(n => n / ratio);
    }

    static mix(start: DisplaySize, end: DisplaySize, t: number): DisplaySize {
        return new DisplaySize({
            fixedReal: mix(start.fixedReal, end.fixedReal, t),
            fixedVirtual: mix(start.fixedVirtual, end.fixedVirtual, t),
            scaledFromParentWidth: mix(start.scaledFromParentWidth, end.scaledFromParentWidth, t),
            scaledFromParentHeight: mix(start.scaledFromParentHeight, end.scaledFromParentHeight, t),
            scaledFromParentMin: mix(start.scaledFromParentMin, end.scaledFromParentMin, t),
        });
    }

    resolve(virtualWidth: number, virtualHeight: number, virtualToRealRatio: number = 1): number {
        let min = virtualWidth;
        let max = virtualHeight;

        if (virtualWidth > virtualHeight) {
            min = virtualHeight;
            max = virtualWidth;
        }

        return this.fixedReal
            + this.fixedVirtual * virtualToRealRatio
            + this.scaledFromParentWidth * virtualWidth * virtualToRealRatio
            + this.scaledFromParentHeight * virtualHeight * virtualToRealRatio
            + this.scaledFromParentMin * min * virtualToRealRatio
            + this.scaledFromParentMax * max * virtualToRealRatio;
    }

    static resolve(
        value: DisplaySizeLike,
        rect: { width: number, height: number; },
        defaultPercentKey: keyof DisplaySizeProperties = 'scaledFromParentHeight',
        virtualToRealRatio: number = 1
    ): number {
        return DisplaySize.from(value, defaultPercentKey).resolve(rect.width, rect.height, virtualToRealRatio);
    }
}
globalThis.ALL_FUNCTIONS.push(DisplaySize);