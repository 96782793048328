import { Counter } from '../../utils/data-structures/counter.ts';
import { LocalStorage } from '../../utils/dom/local-storage.ts';
import { KeyCode, DomEvent } from '../../utils/dom/dom-events/dom-events-types.ts';
import { Point, PointLike } from '../../utils/geometry/point.ts';
import { Result } from '../../utils/language/result.ts';
import { Connection, connectToServer } from '../../utils/network/connection.ts';
import { getSerializableAssets } from '../builtin-serializable-assets.ts';
import { Server } from '../server/server.ts';
import { ApiMethodName } from '../network/network-api.ts';
import { ViewModifier, ViewModifierParams } from '../view/view-modifier.ts';
import { AnimationQueue, AnimationQueueParams } from '../animation/animation-queue.ts';
import { getNextIndexWrapped } from '../../utils/language/array.ts';
import { RoomManager } from '../room/room-manager.ts';
import { GraphicsEngine } from '../graphics-engine/graphics-engine.ts';
import { WindowManager } from '../../utils/dom/window-manager.ts';
import { ImageLoader } from '../../utils/dom/image-loader.ts';
import { AnimationQueueId, ClientAwaitedMessage, ClientOngoingRequest, INSTANCE_ANIMATION_QUEUE_ID, NON_BLOCKING_ANIMATION_QUEUE_ID } from './client-types.ts';
import { EventParams } from '../room/room-manager-types.ts';
import { StartGameParams } from '../start-game.ts';
import { Component } from '../component/component.ts';
import { CLIENT_INTERACTION_SCHEMA, ProcessClientInteractionsParams } from '../server/server-types.ts';
import { FlexBuffer } from '../../utils/serialization/flex-buffer.ts';
import { Deserializer } from '../../utils/serialization/deserializer.ts';
import { DrawContext } from './draw-context.ts';
import { Logger } from '../../utils/logging/logger.ts';
import { ClockProperties } from '../../utils/time/clock-types.ts';
import { Clock } from '../../utils/time/clock.ts';
import { LayerId, LayerProperties } from '../graphics-engine/layer-types.ts';
import { TypeSchema, serializeValue } from '../../utils/type-schema/type-schema.ts';
import { ServerData } from '../server/server-data.ts';
import { CallbackLoop } from '../../utils/data-structures/callback-loop.ts';
import { SerializableAsset } from '../../utils/serialization/serializable-asset-index.ts';
import { PromiseWithResolvers, makePromise } from '../../utils/language/promise.ts';
import { Serializer } from '../../utils/serialization/serializer.ts';
import { sleep } from '../../utils/language/async.ts';
import { TooltipComponent } from '../tooltip/tooltip-component.ts';
import { TooltipData } from '../tooltip/tooltip-data.ts';
import { View } from '../view/view.ts';
import { Rect } from '../../utils/geometry/rect.ts';
import { UserInputManager } from '../user-input/user-input-manager.ts';
import { TextContentLike } from '../../utils/text/text-content.ts';
import { Collection, iterateCollection } from '../../utils/language/collection.ts';
import { AnimationId } from '../animation/animation-types.ts';
import { Constructor } from '../../utils/language/types.ts';
import { QueueId, TransitionQueue } from '../transition/transition-queue.ts';
import { RoomWrapper } from '../room/room-wrapper.ts';
import { UserInputEntryParams } from '../user-input/user-input-entry.ts';
import { RoomApi } from '../room/room-api.ts';
import { GlobalContext } from '../global/global-context.ts';

const MIN_REFRESH_RATE = 100;

export class Client {
    private params: StartGameParams;
    private roomManager: RoomManager;
    private clock: Clock = new Clock();
    private windowManager: WindowManager = new WindowManager({ aspectRatio: 16 / 9 });
    private imageManager: ImageLoader = new ImageLoader();
    private graphicsEngine: GraphicsEngine = new GraphicsEngine({
        windowManager: this.windowManager,
        imageManager: this.imageManager,
        clock: this.clock,
    });
    private connection: Connection | null = null;
    private viewsByComponent: Map<Component, View> = new Map();
    private displayedViews: Set<View> = new Set();
    private viewStateIdCounter: Counter = new Counter();
    private viewModifiers: Set<ViewModifier> = new Set();
    private pointerPosition: Point = new Point(-1000, -1000);
    private focusChain: Component[] = [];
    private currentFocus: Component | null = null;
    private userInputManager: UserInputManager = new UserInputManager(this);
    private hoveredComponent: Component | null = null;
    private localStorage = new LocalStorage();
    private animationQueues: Map<AnimationQueueId, AnimationQueue> = new Map();
    private transitionQueues: Map<QueueId, TransitionQueue> = new Map();
    private lastUpdateTime: number = 0;
    private elapsedMsSinceLastFrame: number = 0;
    private ongoingRequestIdCounter: Counter = new Counter();
    private ongoingRequests: Map<number, ClientOngoingRequest> = new Map();
    private buffer: FlexBuffer = new FlexBuffer();
    private serializer: Serializer;
    private deserializer: Deserializer;
    private lastFrameTimes: number[] = [];
    private offsecreenRenderLoop: CallbackLoop = new CallbackLoop({
        callback: () => this.offscreenUpdate(),
        intervalMs: MIN_REFRESH_RATE
    });
    private onStop: PromiseWithResolvers = makePromise();
    private onFirstRoom: PromiseWithResolvers = makePromise();
    private onNextFrame: PromiseWithResolvers = makePromise();
    private tooltips: Map<Component, TooltipData> = new Map();
    private renderSourceIdCounter: Counter = new Counter();
    private rootViews: Set<View> = new Set();
    private scheduledViews: View[] = [];
    private onDetectCallbacks: (() => void)[] = [];
    private mouseButtonsDown: Set<string> = new Set();
    private awaitedMessages: Map<string, ClientAwaitedMessage[]> = new Map();
    private clientRoomApi: RoomApi;

    constructor(params: StartGameParams, serializableAssets?: SerializableAsset[]) {
        this.params = params;
        this.serializer = new Serializer(serializableAssets ?? getSerializableAssets());
        this.deserializer = new Deserializer(serializableAssets ?? getSerializableAssets());
        this.roomManager = new RoomManager({
            client: this,
            server: null,
            serializer: this.serializer,
            deserializer: this.deserializer,
        });
        this.clientRoomApi = new RoomApi(this.roomManager);
    }

    async start(): Promise<void> {
        // this.initItemAllocator();
        this.createAnimationQueue(NON_BLOCKING_ANIMATION_QUEUE_ID, { nonBlocking: true });
        this.createAnimationQueue(INSTANCE_ANIMATION_QUEUE_ID, { instant: true });
        this.roomManager.onActiveRoomChange((newActiveRoom, prevActiveRoom) => this.onActiveRoomChange(newActiveRoom, prevActiveRoom));

        await Promise.all([this.connect(), this.graphicsEngine.init()]);
        this.clock.reset();
        // this.roomManager.start(this.params.createRootRoom);
        this.startRendering();
        this.startWindowEventProcessing();
    }

    stop(): void {
        this.windowManager.destroy();
        this.connection?.close();
        this.offsecreenRenderLoop.stop();
        this.onStop.resolve();
    }

    /* === NETWORK === */

    private sendMessage<T extends (keyof Server) & ApiMethodName>(
        methodName: T,
        methodParams: Exclude<ReturnType<Server[T]>, undefined>,
        schema: TypeSchema<Exclude<ReturnType<Server[T]>, undefined>>
    ) {
        this.buffer.reset();
        this.buffer.writeString(methodName);
        serializeValue(schema, this.buffer, methodParams);
        this.connection!.sendMessage(this.buffer);
    }

    private async connect() {
        this.connection = connectToServer({
            onConnect: () => this.onConnect(),
            onDisconnect: () => this.onDisconnect(),
            onMessage: (_connection, data) => this.onMessage(data)
        });

        await this.connection.waitUntilReady();
    }

    private onConnect() {

    }

    private onDisconnect() {

    }

    private onMessage(data: ArrayBuffer) {
        this.tickClock();
        let buffer = new FlexBuffer(data);
        let methodName = buffer.readString();
        let methodParams = this.deserializer.deserialize(buffer);

        // We trust the server, no need to perform any check
        (this as any)[methodName](methodParams);
    }

    $queueEvent(params: EventParams<any>) {
        Logger.debug(params);

        // if (params.kind !== 'updateRoom') {
        //     console.log(params)
        // }

        if (params.kind === 'playerInteraction' && params.data.playerId === this.roomManager.getLocalPlayerId()) {
            let item = this.ongoingRequests.get(params.data.requestId);

            if (item) {
                let { onComplete, resumeCallback } = item;

                this.ongoingRequests.delete(params.data.requestId);
                this.roomManager.queueAnyEvent({
                    kind: 'callback', data: async () => {
                        resumeCallback(params.data.result!);
                        await onComplete.promise;
                    }
                });
            }
        } else {
            this.roomManager.queueAnyEvent(params);
        }
    }

    async sendInteractionToServer(params: Omit<ProcessClientInteractionsParams, 'requestId'> & { onComplete: PromiseWithResolvers; }): Promise<Result<ServerData[]>> {
        return new Promise(resolve => {
            let requestId = this.ongoingRequestIdCounter.next();
            let methodParams: ProcessClientInteractionsParams = { requestId, ...params };

            this.ongoingRequests.set(requestId, {
                resumeCallback: resolve,
                onComplete: params.onComplete
            });
            this.sendMessage('$processClientInteraction', methodParams, CLIENT_INTERACTION_SCHEMA);
        });
    }

    /* === RENDERING === */

    getGraphicsEngine(): GraphicsEngine {
        return this.graphicsEngine;
    }

    private startRendering() {
        this.updateAnimations();
        this.lastUpdateTime = this.clock.getCurrentTime();

        let update = () => {
            this.graphicsEngine.requestAnimationFrame(() => {
                this.drawFrame();
                update();
            });
        };

        this.offsecreenRenderLoop.start();
        update();
    }

    private updateLastUpdateTime(): number {
        let now = this.clock.getCurrentTime();
        let elapsed = now - this.lastUpdateTime;

        this.lastUpdateTime = now;

        return elapsed;
    }

    private offscreenUpdate() {
        this.tickClock();

        let elapsedSinceLastUpdate = this.clock.getElapsedSince(this.lastUpdateTime);

        if (elapsedSinceLastUpdate < MIN_REFRESH_RATE) {
            return;
        }

        this.updateLastUpdateTime();
        this.roomManager.update();

        this.updateAnimations();
    }

    private drawFrame() {
        this.tickClock();

        this.elapsedMsSinceLastFrame = this.updateLastUpdateTime();

        if (this.graphicsEngine.isLoadingBlockingAssets() || !this.roomManager.getActiveRoom()) {
            return;
        }

        let drawContext = new DrawContext(this.clock.getCurrentTime());

        this.updateFps();
        this.renderScheduledComponents();
        this.updateRoomManager();
        this.updateAnimations();
        this.updateInteractions();
        this.updateViewModifiers();

        this.graphicsEngine.prepareFrame();
        this.drawViews(drawContext);
        this.graphicsEngine.renderFrame();
        this.detectHoveredComponent(drawContext);

        this.onNextFrame.resolve();
        this.onNextFrame = makePromise();
    }

    private renderScheduledComponents() {
        for (let view of this.scheduledViews) {
            view.fillSelfFragment();
        }

        this.scheduledViews.length = 0;
    }

    private drawViews(drawContext: DrawContext) {
        for (let view of this.displayedViews) {
            view.markAsNotDisplayed();
        }

        for (let view of this.rootViews) {
            view.draw(drawContext);
        }

        for (let view of this.displayedViews) {
            if (!view.isDisplayed()) {
                view.destroy();
            }
        }
    }

    private updateFps() {
        let currentTime = this.clock.getCurrentTime();

        this.lastFrameTimes.push(currentTime);
        this.lastFrameTimes = this.lastFrameTimes.filter(time => time > currentTime - 1000);
    }

    private updateRoomManager() {
        this.roomManager.update();
    }

    private updateViewModifiers() {
        for (let modifier of this.viewModifiers.values()) {
            modifier.update();
        }
    }

    private updateInteractions() {
        this.userInputManager.update();
    }

    private updateAnimations() {
        for (let animationQueue of this.animationQueues.values()) {
            animationQueue.update();
        }
    }

    getFrameDuration(): number {
        return this.elapsedMsSinceLastFrame;
    }

    hasView(component: Component): boolean {
        return this.viewsByComponent.has(component);
    }

    getView(component: Component): View | undefined {
        return this.viewsByComponent.get(component);
    }

    updateView(component: Component, parent: View | null): View {
        let view = this.viewsByComponent.get(component);

        if (!view) {
            view = new View(this, component, parent);
            this.viewsByComponent.set(component, view);
            this.roomManager.mountComponent(component);
        }

        if (!view.getParentView() && !this.rootViews.has(view)) {
            let { x, y, width, height } = Rect.resolve(this.graphicsEngine.getVirtualViewport());

            this.rootViews.add(view);
            view.fillParentFragment(view => {
                view.paint({ x, y, width, height });
            });
        }

        return view;
    }

    notifyViewBorn(view: View) {
        // TODO: mount component here?
        this.displayedViews.add(view);
    }

    notifyViewDead(view: View) {
        let component = view.getComponent();

        this.rootViews.delete(view);
        this.displayedViews.delete(view);

        if (this.getView(component) === view) {
            this.roomManager.unmountComponent(component);
            this.viewsByComponent.delete(component);
        }
    }

    destroyView(component: Component) {
        this.getView(component)?.destroy();
    }

    renderComponent(component: Collection<Component>) {
        for (let item of iterateCollection(component)) {
            if ((item.isAlive && !item.isAlive()) || !item.render) {
                continue;
            }

            let view = this.updateView(item, null);

            view.fillSelfFragment();
        }
    }

    unmountComponent(component: Collection<Component>) {
        for (let item of iterateCollection(component)) {
            this.destroyView(item);
        }
    }

    scheduleRenderComponent(component: Collection<Component>) {
        for (let item of iterateCollection(component)) {
            if ((item.isAlive && !item.isAlive()) || !item.render) {
                continue;
            }

            let view = this.updateView(item, null);

            view.cancelScheduleDestroy();
            this.scheduledViews.push(view);
        }
    }

    scheduleUnmountComponent(component: Collection<Component>) {
        for (let item of iterateCollection(component)) {
            let view = this.viewsByComponent.get(item);

            view?.scheduleDestroy();
        }
    }

    getViewRect(component: Component): Rect | undefined {
        return this.viewsByComponent.get(component)?.getRect();
    }

    addViewModifier(params: ViewModifierParams): ViewModifier {
        let viewModifier = new ViewModifier(this, params);

        this.viewModifiers.add(viewModifier);

        return viewModifier;
    }

    removeViewModifier(viewModifier: ViewModifier) {
        if (this.viewModifiers.delete(viewModifier)) {
            viewModifier.destroy();
        }
    }

    private startWindowEventProcessing() {
        this.windowManager.onWindowEvent(evt => this.onWindowEvent(evt));
    }

    private onWindowEvent(evt: DomEvent) {
        if (evt.kind === 'mouse') {
            this.pointerPosition = new Point(evt.x, evt.y);

            if (evt.action === 'down') {
                this.preventTooltips();
                this.mouseButtonsDown.add(evt.button);
            } else if (evt.action === 'up') {
                this.mouseButtonsDown.delete(evt.button);
            }
        }

        this.userInputManager.processDomEvent(evt);
    }

    private async detectHoveredComponent(drawContext: DrawContext) {
        let componentIndex = await this.graphicsEngine.getComponentIdAt(this.pointerPosition.x, this.pointerPosition.y);
        let hoveredComponent = drawContext.getComponentAt(componentIndex);

        if (hoveredComponent) {
            this.showTooltip(hoveredComponent);
        }

        this.updateTooltips();
        this.hoveredComponent = hoveredComponent;

        if (this.onDetectCallbacks.length > 0) {
            let callbacks = this.onDetectCallbacks;

            for (let callback of callbacks) {
                callback();
            }

            this.onDetectCallbacks = [];
        }
        // this.userInputManager.processTimeElapsed();
    }

    private showTooltip(component: Component) {
        let view = this.getView(component);
        let canShow = this.mouseButtonsDown.size === 0;

        if (!view || !canShow) {
            return;
        }

        if (component.tooltip) {
            let currentTime = this.clock.getCurrentTime();
            let data = this.tooltips.get(component);

            if (data) {
                data.lastHoverTime = currentTime;
                data.forceHide = false;
            } else {
                for (let data of this.tooltips.values()) {
                    data.forceHide = true;
                }

                let tooltip = new TooltipComponent(component);
                let sourceId: number = this.getNextRenderSourceId();
                let tooltipView = this.updateView(tooltip, null);

                tooltipView.fillSelfFragment();

                this.tooltips.set(component, {
                    component,
                    sourceId,
                    showDelay: tooltipView.getDelay(),
                    hideDelay: tooltipView.getLingerDuration(),
                    lastHoverTime: currentTime,
                    startTime: currentTime,
                    forceHide: false,
                    tooltip,
                    tooltipView,
                    isShown: false
                });
            }
        }

        let tooltipTarget = view.getTooltipTarget();

        if (tooltipTarget) {
            this.showTooltip(tooltipTarget);
        }
    }

    private preventTooltips() {
        for (let data of this.tooltips.values()) {
            if (!data.isShown) {
                this.destroyView(data.tooltip);
                this.tooltips.delete(data.component);
            }
        }
    }

    private updateTooltips() {
        let currentTime = this.clock.getCurrentTime();

        for (let data of this.tooltips.values()) {
            let { component, tooltipView, isShown, startTime, hideDelay, showDelay, lastHoverTime, forceHide } = data;

            tooltipView?.markAsTooltipFor(component);

            if (!forceHide && !isShown && startTime + showDelay <= currentTime) {
                // tooltipView.enableFragment(sourceId)
                data.isShown = true;
            }

            if ((forceHide || (lastHoverTime + hideDelay < currentTime))) {
                this.destroyView(data.tooltip);
                this.tooltips.delete(component);
            }
        }
    }

    private tickClock() {
        this.clock.tick();
    }

    onDetectHoveredComponent(callback: (() => void)) {
        this.onDetectCallbacks.push(callback);
    }

    getAnimationQueue(queueId: AnimationQueueId): AnimationQueue {
        let animationQueue = this.animationQueues.get(queueId);

        if (!animationQueue) {
            animationQueue = new AnimationQueue(this);
            this.animationQueues.set(queueId, animationQueue);
        }

        return animationQueue;
    }

    private createAnimationQueue(queueId: AnimationQueueId, params: AnimationQueueParams) {
        this.animationQueues.set(queueId, new AnimationQueue(this, params));
    }

    now(): AnimationQueue {
        return this.getAnimationQueue(NON_BLOCKING_ANIMATION_QUEUE_ID);
    }

    instant(): AnimationQueue {
        return this.getAnimationQueue(INSTANCE_ANIMATION_QUEUE_ID);
    }

    stopAnimation(predicate: AnimationId | ((id: AnimationId) => boolean)) {
        for (let queue of this.animationQueues.values()) {
            queue.forceStopNow(predicate);
        }
    }

    getTransitionQueue(queueId: QueueId): TransitionQueue {
        let transitionQueue = this.transitionQueues.get(queueId);

        if (!transitionQueue) {
            transitionQueue = new TransitionQueue();
            this.transitionQueues.set(queueId, transitionQueue);
        }

        return transitionQueue;
    }

    waitForUserInput(params: UserInputEntryParams) {
        this.userInputManager.waitForUserInput(params);
    }

    cancelUserInput(sourceId: number) {
        this.userInputManager.cancelUserInput(sourceId);
    }

    cancelAllUserInputs() {
        this.userInputManager.cancelAllUserInput();
    }

    async waitForClientMessage<T>(sourceId: number, kind: string, callback: (() => void) | null): Promise<T> {
        return new Promise(resolve => {
            let items = this.awaitedMessages.getOrInsertWith(kind, () => []);

            items.push({ sourceId, resolve, callback });
        });
    }

    cancelWaitForClientMessage(sourceId: number) {
        for (let array of this.awaitedMessages.values()) {
            let index = array.findIndex(item => item.sourceId === sourceId);

            while (index !== -1) {
                array.splice(index, 1);
                index = array.findIndex(item => item.sourceId === sourceId);
            }
        }
    }

    sendClientMessage<T>(kind: string, value: T) {
        let items = this.awaitedMessages.get(kind);

        if (!items) {
            return;
        }

        this.awaitedMessages.delete(kind);

        for (let { resolve, callback } of items) {
            resolve(value);
            callback?.();
        }
    }

    getClock(): Clock {
        return this.clock;
    }

    getCurrentTime(): number {
        return this.clock.getCurrentTime();
    }

    getClockProperties(queueId: number): ClockProperties {
        return this.getAnimationQueue(queueId).getClockProperties();
    }

    getLayerProperties(layerId: LayerId): LayerProperties {
        return this.graphicsEngine.getLayerProperties(layerId);
    }

    updateLayer(layerId: LayerId, properties: Partial<LayerProperties>) {
        this.graphicsEngine.updateLayer(layerId, properties);
    }

    initLayer(layerId: LayerId, properties?: Partial<LayerProperties>) {
        this.graphicsEngine.initLayer(layerId, properties);
    }

    getRealPointerPosition(): Point {
        return this.pointerPosition;
    }

    getPointerPosition(layerId: LayerId): Point {
        return this.graphicsEngine.getPointInLayer(layerId, this.pointerPosition, true);
    }

    getPointInLayer(layerId: LayerId, point: PointLike): Point {
        return this.graphicsEngine.getPointInLayer(layerId, Point.from(point), false);
    }

    getHoveredComponent(): Component | null {
        return this.hoveredComponent;
    }

    /* === STATE === */

    getPlayerId(): string | null {
        return this.roomManager.getLocalPlayerId();
    }

    getRoomManager(): RoomManager {
        return this.roomManager;
    }

    getNextStateViewId(): number {
        return this.viewStateIdCounter.next();
    }

    withClientRoomApi<T>(callback: () => T): T {
        GlobalContext.push(this.clientRoomApi);
        let result = callback();
        GlobalContext.pop();

        return result;
    }

    /* === LOCAL STORAGE === */

    setLocalStorageKeyPrefix(prefix: string) {
        this.localStorage.setKeyPrefix(prefix);
    }

    getLocalStorageItem(key: string) {
        return this.localStorage.getItem(key);
    }

    setLocalStorageItem(key: string, data: any) {
        this.localStorage.setItem(key, data);
    }

    removeLocalStorageItem(key: string) {
        this.localStorage.removeItem(key);
    }

    clearLocalStorage() {
        this.localStorage.clear();
    }

    /* DOM */

    prompt(message: string, localStorageKey?: string): string | null {
        let value: string | null = null;

        if (localStorageKey) {
            value = this.localStorage.getItem(localStorageKey);

            if (value) {
                return value;
            }
        }

        value = window.prompt(message);

        if (value && localStorageKey) {
            this.localStorage.setItem(localStorageKey, value);
        }

        return value;
    }

    enableTextEdition(targetId: number, priority: number, text: TextContentLike) {
        this.windowManager.enableTextEdition(targetId, priority, text);
    }

    disableTextEdition(targetId: number) {
        this.windowManager.disableTextEdition(targetId);
    }

    /* FOCUS */

    private focusNextOrPrev(direction: number) {
        if (this.focusChain.length === 0) {
            return;
        }

        let currentFocusIndex = -1;

        if (this.currentFocus) {
            currentFocusIndex = this.focusChain.indexOf(this.currentFocus);
        }

        let newFocusIndex = getNextIndexWrapped(this.focusChain, currentFocusIndex, direction);
        let newFocus = this.focusChain[newFocusIndex];

        this.setFocusedComponent(newFocus);
    }

    setFocusChain(chain: Component[]) {
        this.focusChain = chain;
        // TODO: sort?
    }

    setFocusedComponent(component: Component | null) {
        let newFocus = component;

        if (newFocus && !this.focusChain.includes(newFocus)) {
            newFocus = null;
        }

        if (component !== this.currentFocus) {
            this.userInputManager.processNewFocus(this.currentFocus, newFocus);
            this.currentFocus = newFocus;
        }
    }

    focusNext() {
        this.focusNextOrPrev(1);
    }

    focusPrev() {
        this.focusNextOrPrev(-1);
    }

    clearFocus() {
        this.currentFocus = null;
    }

    getFocusedComponent(): Component | null {
        return this.currentFocus;
    }

    getNextRenderSourceId(): number {
        return this.renderSourceIdCounter.next();
    }

    getActiveRoom(): Component | null {
        return this.roomManager.getActiveRoom();
    }

    private onActiveRoomChange(current: RoomWrapper | null, prev: RoomWrapper | null) {
        for (let animationQueue of this.animationQueues.values()) {
            animationQueue.clear();
        }

        if (prev) {
            this.destroyView(prev.room);

            for (let view of this.viewsByComponent.values()) {
                view.destroy();
            }

            this.graphicsEngine.resetLayers();
        }

        if (current) {
            this.clientRoomApi.reset({
                roomWrapper: current,
                capabilities: 'client',
                isLocal: true
            });

            for (let entity of current.getEntityManager().getAll()) {
                this.scheduleRenderComponent(entity);
            }

            this.onFirstRoom.resolve();
        }

        this.now().render();
    }

    // private initItemAllocator() {
    //     let allocateLayoutItem = (...args: Parameters<ViewLayoutItem['onAllocate']>) => this.itemAllocator.allocate(ViewLayoutItem, ...args);
    //     let deallocateLayoutItem = (item: ViewLayoutItem) => this.itemAllocator.deallocate(item);

    //     this.itemAllocator.register(List, () => new List());
    //     this.itemAllocator.register(Interaction, () => new Interaction(this));
    //     this.itemAllocator.register(ComponentWrapper, () => new ComponentWrapper(this));
    //     this.itemAllocator.register(View, () => new View(this));
    //     this.itemAllocator.register(ViewAtom, () => new ViewAtom(this));
    //     this.itemAllocator.register(ViewLayout, () => new ViewLayout(allocateLayoutItem, deallocateLayoutItem));
    //     this.itemAllocator.register(ViewLayoutItem, () => new ViewLayoutItem(deallocateLayoutItem, () => null));
    //     this.itemAllocator.register(Particle, () => new Particle());
    //     this.itemAllocator.register(GraphicsInstance, () => new GraphicsInstance(this));
    //     this.itemAllocator.register(GraphicsAttributeData, () => new GraphicsAttributeData());
    // }

    // allocate<T extends Allocable<any[]>>(constructor: Constructor<T>, ...args: Parameters<T['onAllocate']>): T {
    //     return this.itemAllocator.allocate(constructor, ...args);
    // }

    // deallocate<T extends Allocable<any[]>>(value: T | null): null {
    //     return this.itemAllocator.deallocate(value);
    // }

    // deallocateArray<T extends Allocable<any[]>>(array: (T | null)[]) {
    //     return this.itemAllocator.deallocateArray(array);
    // }

    // isAllocated<T extends Allocable<any[]>>(value: T): boolean {
    //     return this.itemAllocator.isAllocated(value);
    // }

    getFps(): number {
        return Math.min(60, this.lastFrameTimes.length);
    }

    getAllRooms() {
        return this.roomManager.getAllRooms();
    }

    waitForStop(): Promise<void> {
        return this.onStop.promise;
    }

    waitForFirstRoom(): Promise<void> {
        return this.onFirstRoom.promise;
    }

    waitForNextFrame(): Promise<void> {
        return this.onNextFrame.promise;
    }

    async waitForAuthenticated(): Promise<void> {
        let currentId = this.roomManager.getLocalPlayerId();

        while (this.roomManager.getLocalPlayerId() === currentId) {
            await sleep(1);
        }
    }

    emulateKeyPress(code: KeyCode) {
        this.userInputManager.processDomEvent({
            kind: 'keyboard',
            action: 'down',
            altKey: false,
            ctrlKey: false,
            metaKey: false,
            shiftKey: false,
            code,
            key: '',
            nativeEvent: null,
            repeat: false
        });
    }

    emulateComponentClick(component: Component) {
        this.hoveredComponent = component;
        this.userInputManager.processDomEvent({
            kind: 'mouse',
            action: 'down',
            button: 'left',
            x: 0,
            y: 0,
            dx: 0,
            dy: 0,
            startX: 0,
            startY: 0,
            altKey: false,
            ctrlKey: false,
            metaKey: false,
            shiftKey: false,
            nativeEvent: null
        });
        this.userInputManager.processDomEvent({
            kind: 'mouse',
            action: 'up',
            button: 'left',
            x: 0,
            y: 0,
            dx: 0,
            dy: 0,
            startX: 0,
            startY: 0,
            altKey: false,
            ctrlKey: false,
            metaKey: false,
            shiftKey: false,
            nativeEvent: null
        });
    }

    getActiveClient(): any {
        let roomWrapper = this.roomManager.getActiveRoomWrapper();
        let clientId = this.roomManager.getLocalPlayerId();

        return (roomWrapper && clientId && roomWrapper.players.get(clientId)) ?? null;
    }

    async waitForRoom<R extends Component>(roomConstructor: Constructor<R>, predicate?: (room: R) => boolean): Promise<R> {
        let activeRoom = this.getActiveRoom();

        if (!activeRoom || !(activeRoom instanceof roomConstructor) || !(predicate?.(activeRoom) ?? true)) {
            await sleep(10);
            return this.waitForRoom(roomConstructor, predicate);
        }

        return activeRoom;
    }
}
globalThis.ALL_FUNCTIONS.push(Client);