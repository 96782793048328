import http from 'http';
import https from 'https';
import express, { Application } from 'express';
import { WebSocketServer } from 'ws';
import { OUTPOST_ENV } from '../../environment.ts';
import { DEFAULT_ASSET_PROXY_BASE_PATH, DEFAULT_WEBSOCKET_UPGRADE_PATH } from './network-constants.ts';
import { existsSync, readFileSync } from 'fs';

/** */
export type ConfigureHttpServerCallback = (app: Application, server: import('http').Server) => void | Promise<void>;

/**
 * Parameters for {@link openServer}.
 * Default values are based on {@link OUTPOST_ENV}, you don't need to change them.
 * @category Advanced
 */
export type ServerParameters = {
    callback?: ConfigureHttpServerCallback,
    logging?: boolean,
    rootDirectory?: string,
    port?: number,
    sslCertificatePath?: string | null,
    sslPrivateKeyPath?: string | null,
    assetProxy?: boolean | AssetProxyParameters,
    webSockets?: boolean | WebSocketParameters,
    useConnectionFramework?: boolean,
};

/**
 * Used internally.
 * @category Internal
 */
export type AssetProxyParameters = {
    enabled?: boolean,
    basePath?: string;
};

/**
 * Used internally.
 * @category Internal
 */
export type WebSocketParameters = {
    enabled?: boolean,
    upgradePath?: string,
};

/**
 * Returned by {@link openServer}.
 * @category Advanced
 */
export class NetworkServer {
    /**
     * Express application.
     */
    expressApp!: Application;
    /**
     * Http server.
     */
    httpServer!: import('http').Server;
    /**
     * Websocket server.
     */
    webSocketServer: WebSocketServer | null = null;
}

/**
 * Opens an HTTP server based on the variables contained in {@link OUTPOST_ENV} that serves the client files.
 * 
 * It is automatically called by {@link startGame} on the server.
 * @category Advanced
 * @param options 
 * @returns 
 */
export async function openServer(options: ServerParameters | ConfigureHttpServerCallback = {}): Promise<NetworkServer> {
    if (typeof options === 'function') {
        options = { callback: options };
    }

    let parameters: Required<ServerParameters> = Object.assign({
        callback: () => {},
        logging: false,
        port: OUTPOST_ENV.APP_PORT,
        rootDirectory: OUTPOST_ENV.CLIENT_DIR,
        sslCertificatePath: OUTPOST_ENV.SSL_CERT,
        sslPrivateKeyPath: OUTPOST_ENV.SSL_KEY,
        assetProxy: false,
        webSockets: false,
        useConnectionFramework: false,
        webSocketApi: {},
        createUser: () => ({})
    }, options);

    if (typeof parameters.assetProxy === 'boolean') {
        parameters.assetProxy = { enabled: parameters.assetProxy };
    }

    if (typeof parameters.webSockets === 'boolean') {
        parameters.webSockets = { enabled: parameters.webSockets };
    }

    let assetProxyParameters: Required<AssetProxyParameters> = Object.assign({
        enabled: false,
        basePath: DEFAULT_ASSET_PROXY_BASE_PATH
    }, parameters.assetProxy);

    let webSocketsParameters: Required<WebSocketParameters> = Object.assign({
        enabled: false,
        upgradePath: DEFAULT_WEBSOCKET_UPGRADE_PATH
    }, parameters.webSockets);

    let server = new NetworkServer();

    // @ts-ignore
    server.expressApp = express();
    server.httpServer = await createHttpServer(server.expressApp, parameters.sslCertificatePath, parameters.sslPrivateKeyPath);

    server.expressApp.use(express.text());
    server.expressApp.use(express.json());
    configureAssetProxy(server.expressApp, assetProxyParameters);
    await parameters.callback(server.expressApp, server.httpServer);

    if (parameters.rootDirectory) {
        server.expressApp.use(express.static(parameters.rootDirectory));
    }

    if (webSocketsParameters.enabled) {
        server.webSocketServer = createWebSocketServer(server.httpServer, webSocketsParameters.upgradePath);
    }

    return new Promise(resolve => {
        server.httpServer.listen(parameters.port, () => {
            if (parameters.logging) {
                console.log(`=> listening on port ${parameters.port}...`);
            }
            resolve(server);
        });
    });
}

function configureAssetProxy(app: Application, parameters: AssetProxyParameters) {
    if (parameters.enabled) {
        let prefix = `${parameters.basePath}`;

        if (!prefix.startsWith('/')) {
            prefix = '/' + prefix;
        }

        if (!prefix.endsWith('/')) {
            prefix += '/';
        }

        app.get(`${prefix}*`, async (req, res) => {
            let targetUrlIndexStart = req.originalUrl.indexOf(prefix) + prefix.length;

            if (targetUrlIndexStart < prefix.length) {
                res.sendStatus(400);
                return;
            }

            let targetUrlEncoded = req.originalUrl.substring(targetUrlIndexStart);
            let targetUrl = decodeURI(targetUrlEncoded);

            try {
                let assetResponse = await fetch(targetUrl);

                assetResponse.headers.forEach((value, name) => res.setHeader(name, value));
                // res.setHeader('Access-Control-Allow-Origin', '*');
                // res.setHeader('Access-Control-Allow-Methods', 'GET, OPTIONS');
                // res.setHeader('Access-Control-Allow-Headers', 'Origin,DNT,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type,Range');
                // res.setHeader('Access-Control-Expose-Headers', 'Content-Length,Content-Range');

                if (assetResponse.body) {
                    let bytes = await assetResponse.arrayBuffer();
                    let buffer = new Uint8Array(bytes);

                    res.write(buffer);
                }

                res.status(assetResponse.status);
                res.end();
            } catch (e) {
                res.sendStatus(404);
            }
        });
    }
}

async function createHttpServer(app: Application, certPath: string | null, keyPath: string | null) {
    let httpServer: import('http').Server | null = null;

    if (certPath && keyPath && existsSync(certPath) && existsSync(keyPath)) {
        try {
            let cert = readFileSync(certPath, 'utf8');
            let key = readFileSync(keyPath, 'utf8');

            httpServer = https.createServer({ cert, key }, app);
        } catch (e) {
            console.log(e);
            console.log('error: unable to open ssl key & certificate, defaulting to http');
        }
    }

    if (!httpServer) {
        httpServer = http.createServer(app);
    }

    return httpServer;
}

function createWebSocketServer(httpServer: import('http').Server, upgradePath: string): WebSocketServer {
    // @ts-ignore
    let webSocketServer: WebSocketServer = new WebSocketServer({ noServer: true });

    httpServer.on('upgrade', (request, socket, head) => {
        if (request.url === upgradePath) {
            webSocketServer.handleUpgrade(request, socket, head, (ws) => {
                webSocketServer.emit('connection', ws, request);
            });
        } else {
            socket.destroy();
        }
    });

    return webSocketServer;
}

export function getWebSocketServerUrl(params: { upgradePath?: string; } = {}) {
    let upgradePath = params.upgradePath ?? DEFAULT_WEBSOCKET_UPGRADE_PATH;
    let protocol = window.location.protocol.replace('http', 'ws');
    let defaultPort = window.location.protocol === 'https:' ? 443 : 80;
    let port = (window as any).OUTPOST_APP_PORT || parseInt(window.location.port) || defaultPort;
    let base = window.location.hostname;

    if (['localhost', '0.0.0.0'].includes(location.hostname)) {
        base += `:${port}`;
    }

    let url = `${protocol}//${base}${upgradePath}`;

    return url;
}
globalThis.ALL_FUNCTIONS.push(NetworkServer);