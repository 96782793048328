import { CSSProperties, useRef } from 'preact/compat';

export type DropDownListProps = {
    style?: CSSProperties;
    options: DropDownOption[];
    onChange: (value: string | null) => void;
};

export type DropDownOption = {
    value: string | null;
    label: string;
    selected?: boolean;
};

export function DropDownList(props: DropDownListProps) {
    let { style, options, onChange } = props;
    let optionElts = options.map(option => <option value={option.value ?? ''} selected={!!option.selected} >{option.label}</option>);
    let ref = useRef<HTMLSelectElement>(null);

    return (
        <select style={style} ref={ref} onChange={() => onChange(ref.current!.value || null)}>
            {optionElts};
        </select>
    );
};