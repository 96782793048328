import { objectSchema } from '../type-schema/object-schema.ts';

/**
 * Clock properties, returned by {@link ComponentApi.getClockProperties}.
 */
export type ClockProperties = {
    /**
     * Elapsed time in milliseconds since the creation of the animation queue that holds the clock.
     */
    currentTime: number;
    /**
     * Speed at which the clock's time passes. 0 = time freeze, 1 = normal speed, 2 = double speed, etc.
     */
    speed: number;
};

export const CLOCK_PROPERTIES_SCHEMA = objectSchema<ClockProperties>({
    currentTime: 'number',
    speed: 'number'
});