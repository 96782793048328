export type WebglUniformTypeDetails = {
    componentCount: number;
    setFunction: (gl: WebGL2RenderingContext, location: WebGLUniformLocation, value: any) => void;
}

export const WEBGL_UNIFORMS_TYPES = {
    'float': {
        componentCount: 1,
        setFunction: (gl, location, value: number) => gl.uniform1f(location, value),
    },
    'vec2': {
        componentCount: 2,
        setFunction: (gl, location, value: [number, number]) => gl.uniform2fv(location, value),
    },
    'vec3': {
        componentCount: 3,
        setFunction: (gl, location, value: [number, number, number]) => gl.uniform3fv(location, value),
    },
    'vec4': {
        componentCount: 4,
        setFunction: (gl, location, value: [number, number, number, number]) => gl.uniform4fv(location, value),
    },
    'int': {
        componentCount: 1,
        setFunction: (gl, location, value: number) => gl.uniform1i(location, value),
    },
    'ivec2': {
        componentCount: 2,
        setFunction: (gl, location, value: [number, number]) => gl.uniform2iv(location, value),
    },
    'ivec3': {
        componentCount: 3,
        setFunction: (gl, location, value: [number, number, number]) => gl.uniform3iv(location, value),
    },
    'ivec4': {
        componentCount: 4,
        setFunction: (gl, location, value: [number, number, number, number]) => gl.uniform4iv(location, value),
    },
    'uint': {
        componentCount: 1,
        setFunction: (gl, location, value: number) => gl.uniform1ui(location, value),
    },
    'uvec2': {
        componentCount: 2,
        setFunction: (gl, location, value: [number, number]) => gl.uniform2uiv(location, value),
    },
    'uvec3': {
        componentCount: 3,
        setFunction: (gl, location, value: [number, number, number]) => gl.uniform3uiv(location, value),
    },
    'uvec4': {
        componentCount: 4,
        setFunction: (gl, location, value: [number, number, number, number]) => gl.uniform4uiv(location, value),
    },
    'sampler2D': {
        componentCount: 1,
        setFunction: (gl, location, value: number) => gl.uniform1i(location, value),
    },
    'sampler2DArray': {
        componentCount: 1,
        setFunction: (gl, location, value: number) => gl.uniform1i(location, value),
    },
} satisfies { [name: string]: WebglUniformTypeDetails };

export type WebglUniformType = keyof typeof WEBGL_UNIFORMS_TYPES;
export type WebglUniformsLayout = { [name: string]: WebglUniformType };
export type WebglUniformValues<T extends WebglUniformsLayout> = {
    [Name in keyof T]: Parameters<typeof WEBGL_UNIFORMS_TYPES[T[Name]]['setFunction']>[2]
};

export type WebglUniformDetails<T extends WebglUniformType> = {
    name: string;
    location: WebGLUniformLocation;
    setFunction: typeof WEBGL_UNIFORMS_TYPES[T]['setFunction']
};

export type WebglUniformsLayoutDetails<T extends WebglUniformsLayout> = {
    [Name in keyof T]: WebglUniformDetails<T[Name]>
};

export function computeUniformsDetails<T extends WebglUniformsLayout>(
    gl: WebGL2RenderingContext,
    program: WebGLProgram,
    uniformsLayout: T
): WebglUniformsLayoutDetails<T> {
    let result: WebglUniformsLayoutDetails<any> = {} as any;

    for (let [name, type] of Object.entries(uniformsLayout)) {
        let location = gl.getUniformLocation(program, name)!;
        let setFunction = WEBGL_UNIFORMS_TYPES[type].setFunction;

        result[name] = { name, location, setFunction };
    }

    return result;
}