export function getFunctionBody(func: (...args: any[]) => any): string {
    let content = func.toString();
    let start = content.indexOf('{');
    let end = content.lastIndexOf('}');

    if (end !== content.length - 1) {
        // Arrow function with a single line
        start = content.indexOf('=>') + 2;

        return `return ${content.substring(start).trim()}`;
    } else {
        return content.substring(start + 1, end).trim();
    }
}

export function getFunctionArgumentNames(func: (...args: any[]) => any): string[] {
    let content = func.toString();
    let start = content.indexOf('(');
    let end = content.indexOf(')');
    
    return content.substring(start + 1, end).split(',').map(arg => arg.trim());
}

export function isFunction(value: any): value is Function {
    return typeof value === 'function';
}

export function evalFunction<T>(value: T | (() => T)): T {
    if (typeof value === 'function') {
        return (value as () => T)();
    } else {
        return value;
    }
}

export function getFunction<T extends (...args: any[]) => any>(value: ReturnType<T> | T): T {
    if (typeof value === 'function') {
        return value;
    } else {
        return (() => value) as T;
    }
}

export function wrapIntoFunction<T>(value: T | (() => T)): () => T {
    if (typeof value === 'function') {
        return value as () => T;
    } else {
        return () => value;
    }
}

export function isFunctionBodyEmpty(func: ((...args: any[]) => any)): boolean {
    return getFunctionBody(func).length === 0;
}