import CryptoJS from 'crypto-js';

export function encryptAesMessage(message: string, key: string, ivBase64: string): string {
    let keyHash = CryptoJS.SHA256(key);
    let iv = CryptoJS.enc.Base64.parse(ivBase64);
    let obfuscatedString = CryptoJS.AES.encrypt(message, keyHash, { iv }).toString();

    return obfuscatedString;
}

export function decryptAesMessage(ciphertext: string, key: string, ivBase64: string): string | null {
    let keyHash = CryptoJS.SHA256(key);
    let iv = CryptoJS.enc.Base64.parse(ivBase64);
    let wordArray = CryptoJS.AES.decrypt(ciphertext, keyHash, { iv });
    let decrypted: string = wordArray.toString(CryptoJS.enc.Utf8);

    if (wordArray.sigBytes < 0) {
        // decryption error
        return null;
    } else {
        return decrypted;
    }
}
