import { TypeKind, TypeSchema } from './type-schema.ts';

export type NumberTypeParams = {
    min?: number,
    max?: number,
    integer?: boolean;
};

export function numberSchema(params: NumberTypeParams = {}): TypeSchema<number> {
    let {
        min = -Infinity,
        max = Infinity,
        integer = false
    } = params;

    return {
        kind: TypeKind.Number,
        optional: false,
        check(ctx, value) {
            let ok = typeof value === 'number'
                && isFinite(value)
                && (!integer || value % 1 === 0)
                && value >= min
                && value <= max;

            if (ok) {
                return true;
            }

            let expected = integer ? 'integer' : 'number';

            if (isFinite(min) && !isFinite(max)) {
                expected += ` >= ${min}`;
            } else if (!isFinite(min) && isFinite(max)) {
                expected += ` <= ${max}`;
            } else if (isFinite(min) && isFinite(max)) {
                expected += ` >= ${min} and <= ${max}`;
            }

            return ctx.expected(expected, value);
        },
        serialize(buffer, value) {
            buffer.writeNumber(value);
        },
        deserialize(buffer) {
            return buffer.readNumber();
        },
    };
}