import { TypeKind, TypeSchema } from './type-schema.ts';

export function bigintSchema(): TypeSchema<bigint> {
    return {
        kind: TypeKind.Number,
        optional: false,
        check(ctx, value) {
            if (typeof value !== 'bigint') {
                return ctx.expected('bigint', value);;
            }

            return true;
        },
        serialize(buffer, value) {
            buffer.writeInt64(value);
        },
        deserialize(buffer) {
            return buffer.readInt64();
        },
    };
}