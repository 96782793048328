import { getLandmarkCoefficients } from '../geometry/rect-landmark.ts';

/**
 * Parameter of {@link ViewLayout.anchor}.
 */
export type LayoutAnchor =
    | 'center'
    | 'top'
    | 'top-right'
    | 'right'
    | 'bottom-right'
    | 'bottom'
    | 'bottom-left'
    | 'left'
    | 'top-left'

export const LAYOUT_ANCHOR_TO_VALUES: { [Key in LayoutAnchor]: { x: number; y: number; } } = {
    'center': getLayoutAnchorValues('center'),
    'top': getLayoutAnchorValues('top'),
    'top-right': getLayoutAnchorValues('top-right'),
    'right': getLayoutAnchorValues('right'),
    'bottom-right': getLayoutAnchorValues('bottom-right'),
    'bottom': getLayoutAnchorValues('bottom'),
    'bottom-left': getLayoutAnchorValues('bottom-left'),
    'left': getLayoutAnchorValues('left'),
    'top-left': getLayoutAnchorValues('top-left'),
};

function getLayoutAnchorValues(anchor: LayoutAnchor): { x: number; y: number; } {
    let { x, y } = getLandmarkCoefficients(anchor);

    return {
        x: (x + 1) / 2,
        y: (y + 1) / 2,
    };
}