import { Collection, collectionToArray, collectionToSet } from '../../utils/language/collection.ts';
import { getFunction } from '../../utils/language/function.ts';
import { DEFAULT_DRAG_THRESHOLD } from '../client/client-constants.ts';
import { Client } from '../client/client.ts';
import { isComponent } from '../component/component-types.ts';
import { Component } from '../component/component.ts';
import { LayerId } from '../graphics-engine/layer-types.ts';
import { ViewModifier } from '../view/view-modifier.ts';
import { ViewFragmentPriority } from '../view/view-types.ts';
import { GenericButtonName, PressData, UserInputHighlight, UserInputIsEnabledTarget, UserInputTargetData, UserInputTrigger, WaitForUserInputParams, getIsEnabledCallback } from './user-input-types.ts';
import { UserInput } from './user-input.ts';

export type UserInputEntryParams = {
    sourceId: number;
    priority: number;
    inputParams: WaitForUserInputParams<any, any, any, any>;
    onComplete: (userInput: UserInput | null) => (() => void) | void | null;
};

export class UserInputEntry {
    client: Client;
    sourceId: number;
    priority: number;
    params: WaitForUserInputParams<any, any, any, any>;
    shortcuts: Record<string, any>;
    isComponentEnabled: (component: Component) => boolean;
    checkComponentEnabledOn: UserInputIsEnabledTarget;
    selectionTrigger: UserInputTrigger[];
    selectionButton: GenericButtonName[];
    shortcutTrigger: UserInputTrigger[];
    allowFocus: boolean;
    capture: boolean;
    layerId: LayerId;
    abortOnInvalidSelection: boolean;
    predicate: (input: UserInput) => boolean;
    predicateOnly: boolean;
    selectableComponents: Set<Component> = new Set();
    groups: { [Key in UserInputHighlight]: ViewModifier };
    allModifiers: ViewModifier[];
    hoveredComponent: Component | null = null;
    hoveredComponentDetected: boolean = false;
    pressLocations: Map<any, PressData> = new Map();
    focusChain: Component[] = [];
    isCompletedFlag: boolean = false;
    textEditionComponent: Component | null = null;
    onComplete: (userInput: UserInput | null) => (() => void) | void | null;
    onDestroyCallback: (() => void) | null = null;
    
    constructor(client: Client, params: UserInputEntryParams) {
        let { sourceId, priority, inputParams, onComplete } = params;

        this.client = client;
        this.sourceId = sourceId;
        this.priority = priority;
        this.params = inputParams;
        this.shortcuts = inputParams.shortcuts ?? {};
        this.isComponentEnabled = getIsEnabledCallback(inputParams);
        this.checkComponentEnabledOn = inputParams.checkIsEnabledOn ?? 'both';
        this.selectionTrigger = [...collectionToArray(inputParams.selectionTrigger ?? 'click'), 'unknown'];
        this.selectionButton = collectionToArray(inputParams.selectionButton ?? 'MouseLeft');
        this.shortcutTrigger = collectionToArray(inputParams.shortcutTrigger ?? 'down');
        this.allowFocus = this.selectionTrigger.includes('text');
        this.capture = inputParams.capture ?? false;
        this.layerId = inputParams.layerId ?? null;
        this.abortOnInvalidSelection = inputParams.abortOnInvalidSelection ?? false;
        this.predicate = inputParams.predicate ?? (() => true);
        this.predicateOnly = !!inputParams.predicate && !inputParams.selectable && !inputParams.shortcuts;
        this.groups = {
            enabled: client.addViewModifier({ modifier: inputParams.highlightEnabled ?? 'highlightEnabled', priority: ViewFragmentPriority.Highlighted }),
            dimmed: client.addViewModifier({ modifier: inputParams.highlightDimmed ?? 'highlightDimmed', priority: ViewFragmentPriority.Dimmed }),
            hovered: client.addViewModifier({ modifier: inputParams.highlightHovered ?? 'highlightHovered', priority: ViewFragmentPriority.Hovered }),
            linked: client.addViewModifier({ modifier: inputParams.highlightLinked ?? 'highlightLinked', priority: ViewFragmentPriority.Linked }),
            focused: client.addViewModifier({ modifier: inputParams.highlightFocused ?? 'highlightFocused', priority: ViewFragmentPriority.Focused }),
            pressed: client.addViewModifier({ modifier: inputParams.highlightPressed ?? 'highlightPressed', priority: ViewFragmentPriority.Pressed }),
            disabled: client.addViewModifier({ modifier: inputParams.highlightDisabled ?? 'highlightDisabled', priority: ViewFragmentPriority.Disabled }),
        };
        this.allModifiers = Object.values(this.groups);
        this.onComplete = onComplete;
    }

    private resolve(trigger: UserInputTrigger, target: UserInputTargetData, userInput: UserInput): boolean {
        let requiredTrigger = target.isShortcut ? this.shortcutTrigger : this.selectionTrigger;

        userInput.nativeEvent?.preventDefault();

        if (!requiredTrigger.includes(trigger) || this.isCompletedFlag) {
            return false;
        }

        if (this.abortOnInvalidSelection && !target.isShortcut && !this.groups.enabled.has(target.value)) {
            this.isCompletedFlag = true;
            this.onComplete(null);
            return true;
        }

        userInput.selection = target.value;
        userInput.trigger = trigger;

        if (!this.predicate(userInput)) {
            return false;
        }

        this.isCompletedFlag = true;
        this.onDestroyCallback = this.onComplete(userInput) ?? null;

        return true;
    }

    cancel() {
        if (this.isCompletedFlag) {
            return;
        }

        this.isCompletedFlag = true;
        this.onComplete(null);
        this.destroy();
    }

    destroy(): (() => void) | null {
        for (let modifier of this.allModifiers) {
            this.client.removeViewModifier(modifier);
        }

        this.client.disableTextEdition(this.sourceId);

        return this.onDestroyCallback;
    }

    private evalComponents(callback: Collection<Component> | (() => Collection<Component>)): Set<Component> {
        let getter = getFunction(callback);
        let collection = this.client.withClientRoomApi(getter);

        return collectionToSet(collection);
    }

    update() {
        let selectableComponents = this.evalComponents(this.params.selectable);
        let dimmedComponents = this.evalComponents(this.params.dimmed);

        for (let component of selectableComponents) {
            let enabled = this.isComponentEnabled(component);

            if (enabled && !this.groups.enabled.has(component)) {
                this.groups.disabled.delete(component);
                this.groups.enabled.add(component);
            } else if (!enabled && !this.groups.disabled.has(component)) {
                this.groups.enabled.delete(component);
                this.groups.disabled.add(component);
            }
        }

        for (let component of this.selectableComponents) {
            if (!selectableComponents.has(component)) {
                for (let modifier of this.allModifiers) {
                    modifier.delete(component);
                }
            }
        }

        for (let component of this.groups.dimmed.values()) {
            if (!this.groups.dimmed.has(component)) {
                this.groups.dimmed.delete(component);
            }
        }

        for (let component of dimmedComponents) {
            this.groups.dimmed.add(component);
        }

        if (this.textEditionComponent && (!this.groups.enabled.has(this.textEditionComponent) || this.textEditionComponent !== this.client.getFocusedComponent())) {
            this.client.disableTextEdition(this.sourceId);
            this.textEditionComponent = null;
        }

        if (this.allowFocus) {
            this.focusChain = [...this.groups.enabled.values()];
        }

        this.selectableComponents = selectableComponents;
    }

    computeLinkedComponents() {
        if (!this.params.linked) {
            return;
        }

        if (!this.hoveredComponent || !this.selectableComponents.has(this.hoveredComponent)) {
            this.groups.linked.clear();
        } else {
            let linkedComponents = this.params.linked(this.hoveredComponent);

            this.groups.linked.set(linkedComponents);
        }
    }

    isCompleted(): boolean {
        return this.isCompletedFlag;
    }

    doesCapture(): boolean {
        return this.capture;
    }

    getFocusChain(): Component[] {
        return this.focusChain;
    }

    getPriority(): number {
        return this.priority;
    }

    getSourceId(): number {
        return this.sourceId;
    }

    processUserInput(userInput: UserInput) {
        let hoveredComponent = this.client.getHoveredComponent();

        userInput.position = this.client.getPointerPosition(this.layerId);

        if (hoveredComponent !== this.hoveredComponent) {
            let toUnhover: Component | undefined = undefined;

            if (this.hoveredComponent && this.groups.hovered.has(this.hoveredComponent)) {
                this.groups.hovered.delete(this.hoveredComponent);
                toUnhover = this.hoveredComponent;
            }

            if (hoveredComponent && this.groups.enabled.has(hoveredComponent)) {
                this.groups.hovered.add(hoveredComponent);

                if (this.hoveredComponentDetected) {
                    if (this.resolve('hover', { value: hoveredComponent }, userInput)) {
                        toUnhover = undefined;
                    }
                }
            }

            if (toUnhover) {
                this.resolve('unhover', { value: toUnhover }, userInput);
            }

            this.hoveredComponent = hoveredComponent;
            this.hoveredComponentDetected = true;
            this.computeLinkedComponents();
        }

        let target = this.getTarget(userInput);

        if (target && target.isShortcut && userInput.nativeEvent) {
            userInput.nativeEvent.preventDefault();
        }

        if (userInput.action === 'down' && target) {
            this.pressLocations.set(target.value, {
                value: target.value,
                position: this.client.getRealPointerPosition(),
                timestamp: this.client.getCurrentTime(),
            });
            this.resolve('down', target, userInput);
        }

        if (userInput.action === 'up' && target) {
            if (this.pressLocations.has(target.value)) {
                this.resolve('click', target, userInput);
            }

            this.resolve('up', target, userInput);
            this.pressLocations.delete(target.value);
        }

        if (userInput.action === 'move') {
            let pressed = this.getPressedValue();

            if (!pressed && target) {
                this.resolve('move', target, userInput);
            }

            if (pressed && pressed.position.getDistanceTo(this.client.getRealPointerPosition()) >= DEFAULT_DRAG_THRESHOLD) {
                this.resolve('drag', pressed, userInput);
            }
        }

        if (userInput.action === 'text' && this.textEditionComponent) {
            this.resolve('text', { value: this.textEditionComponent }, userInput);
        }

        if (this.predicateOnly && this.predicate(userInput)) {
            this.resolve('unknown', { value: undefined }, userInput);
        }
    }

    processFocusInput(userInput: UserInput, prevFocus: Component | null, newFocus: Component | null) {
        if (prevFocus && this.groups.focused.has(prevFocus)) {
            this.groups.focused.delete(prevFocus);
            this.resolve('unfocus', { value: prevFocus }, userInput);
        }

        if (newFocus && this.groups.enabled.has(newFocus)) {
            this.groups.focused.add(newFocus);
            this.resolve('focus', { value: newFocus }, userInput);

            if (this.selectionTrigger.includes('text') && typeof newFocus.getText === 'function') {
                this.client.enableTextEdition(this.sourceId, this.priority, newFocus.getText());
                this.textEditionComponent = newFocus;
            }
        }
    }

    private getPressedValue(): PressData | null {
        if (this.pressLocations.size === 0) {
            return null;
        } else {
            for (let data of this.pressLocations.values()) {
                return data;
            }

            throw new Error(`unreachable`);
        }
    }

    private getTarget(userInput: UserInput): UserInputTargetData | null {
        let value: any = undefined;
        let isShortcut: boolean = false;

        if (userInput.combination && userInput.combination in this.shortcuts) {
            value = this.shortcuts[userInput.combination];
            isShortcut = true;
        }

        if (isComponent(value) && !this.groups.enabled.has(value)) {
            value = undefined;
        }

        let isSelectionButton = userInput.button && this.selectionButton.includes(userInput.button);

        if (value === undefined && isSelectionButton && ((this.hoveredComponent && this.groups.enabled.has(this.hoveredComponent)) || this.abortOnInvalidSelection)) {
            value = this.hoveredComponent;
            isShortcut = false;
        }

        if (value !== undefined) {
            return { value, isShortcut };
        } else {
            return null;
        }
    }
}
globalThis.ALL_FUNCTIONS.push(UserInputEntry);