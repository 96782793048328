// https://en.wikipedia.org/wiki/Buddy_memory_allocation

export type BuddyMemoryAllocatorParams = {
    offset: number;
    totalMemorySize: number;
};

export class BuddyMemoryAllcator {
    private offset: number;
    private totalMemorySize: number;
    private tree: Uint8Array;

    constructor(params: BuddyMemoryAllocatorParams) {
        this.offset = params.offset;
        this.totalMemorySize = params.totalMemorySize;
        this.tree = new Uint8Array(this.totalMemorySize * 2);
    }

    allocate(size: number): number {
        return 0;
    }

    deallocate(address: number) {

    }
}
globalThis.ALL_FUNCTIONS.push(BuddyMemoryAllcator);