import { ParseResult } from './parse-result.ts';

export type ParseLocation = {
    fileName: string  | null;
    line: number | null;
    column: number | null;
    fieldPath: string[];
};

export type ParseMessage = {
    location: ParseLocation;
    content: string;
};

type ParseContextParams = {
    location: ParseLocation;
    errors: ParseMessage[];
    warnings: ParseMessage[];
    disabled: boolean;
};

export class ParseContext {
    private location: ParseLocation;
    private errors: ParseMessage[];
    private warnings: ParseMessage[];
    private disabled: boolean;

    constructor(params?: ParseContextParams) {
        this.location = params?.location ?? { fileName: null, line: null, column: null, fieldPath: [] };
        this.errors = params?.errors ?? [];
        this.warnings = params?.warnings ?? [];
        this.disabled = params?.disabled ?? false;
    }

    private pushLocation(callback: (location: ParseLocation) => void): ParseContext {
        let newParams: ParseContextParams = {
            location: structuredClone(this.location),
            errors: this.errors,
            warnings: this.warnings,
            disabled: this.disabled
        };

        callback(newParams.location);

        return new ParseContext(newParams);
    }

    private addMessage(kind: 'error' | 'warning', content: string): false {
        if (this.disabled) {
            return false;
        }

        let list = kind === 'error' ? this.errors : this.warnings;
        let location = structuredClone(this.location);

        list.push({ location, content });

        return false;
    }

    fileName(fileName: string): ParseContext {
        return this.pushLocation(location => location.fileName = fileName);
    }

    fileLocation(line: number, column: number): ParseContext {
        return this.pushLocation(location => {
            location.line = line;
            location.column = column;
        });
    }

    property(property: string): ParseContext {
        return this.pushLocation(location => location.fieldPath.push(property));
    }

    index(index: number): ParseContext {
        return this.pushLocation(location => location.fieldPath.push(index.toString()));
    }

    expected(expected: string, value: unknown): false {
        return this.addMessage('error', `expected ${expected}, got ${JSON.stringify(value)}`);
    }

    error(message: string): false {
        return this.addMessage('error', message);
    }

    warning(message: string): false {
        return this.addMessage('warning', message);
    }

    disableErrors(): ParseContext {
        return new ParseContext({
            location: structuredClone(this.location),
            errors: this.errors,
            warnings: this.warnings,
            disabled: true
        });
    }

    toParseResult<T>(value: T): ParseResult<T> {
        let hasErrors = this.errors.length > 0;

        return new ParseResult<T>({
            errors: this.errors.map(error => formatParseMessage(error)),
            warnings: this.warnings.map(error => formatParseMessage(error)),
            value: hasErrors ? undefined : value
        });
    }

    toParseError<T>(error: string) {
        this.error(error);

        return new ParseResult<T>({
            errors: this.errors.map(error => formatParseMessage(error)),
            warnings: this.warnings.map(error => formatParseMessage(error)),
            value: undefined
        });
    }

    hasErrors(): boolean {
        return this.errors.length > 0;
    }

    getErrorMessage(): string {
        return this.errors.map(error => formatParseMessage(error)).join('\n');
    }
}

function formatParseMessage(parseMessage: ParseMessage): string {
    let fragments: string[] = [];
    let location = parseMessage.location;
    let result: string = parseMessage.content;

    if (location.fileName) {
        fragments.push(`file ${location.fileName}`);
    }

    if (location.line !== null) {
        fragments.push(`line ${location.line}`);
    }

    if (location.column !== null) {
        fragments.push(`column ${location.column}`);
    }

    if (location.fieldPath.length > 0) {
        fragments.push(`field "${location.fieldPath.join('.')}"`);
    }

    if (fragments.length > 0) {
        result = `${fragments.join(', ')}: ${result}`;
    }

    return result;
}
globalThis.ALL_FUNCTIONS.push(ParseContext);