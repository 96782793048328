import { Component } from '../../framework/component/component.ts';
import { Room } from '../../framework/global/global-room-api.ts';
import { View } from '../../framework/view/view.ts';

export class FocusController implements Component {
    async $controller(): Promise<void> {
        let input = await Room.waitForUserInput({
            shortcuts: {
                'Tab': () => Room.focusNext(),
                'Shift_Tab': () => Room.focusPrev(),
                'Escape': () => Room.clearFocus(),
            }
        });

        input.selection();
    }

    render(view: View) {
        
    }
}
globalThis.ALL_FUNCTIONS.push(FocusController);