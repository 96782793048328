import { Stack } from './stack.ts';

/**
 * Used internally.
 * @category Internal
 */
export interface Allocable<P extends any[] = []> {
    onAllocate(...args: P): void;
    onDeallocate(): void;
}

export type GetAllocateParameters<T> = T extends Allocable<infer P> ? P : never;

export type ItemPoolParams<T> = {
    onAllocate?: (item: T) => void;
    onDeallocate?: (item: T) => void;
};

export class ItemPool<T extends Allocable<any[]>> {
    private allocatedItems: WeakSet<T> = new WeakSet();
    private availableItems: Stack<T> = new Stack();
    private createItem: () => T;
    private onAllocate?: (item: T) => void;
    private onDeallocate?: (item: T) => void;
    private allocatedCount: number = 0;

    constructor(createItem: () => T, params?: ItemPoolParams<T>) {
        this.createItem = createItem;
        this.onAllocate = params?.onAllocate;
        this.onDeallocate = params?.onDeallocate;
    }

    allocate(...args: Parameters<T['onAllocate']>): T {
        let item = this.availableItems.pop();

        if (!item) {
            item = this.createItem();
        }

        this.allocatedItems.add(item);
        this.allocatedCount += 1;

        // if (this.allocatedCount > 10000) {
        //     console.warn(`more than 10000 items allocated and not released, is there a memory issue? last item allocated was:`);
        //     console.warn(item);
        // }

        item.onAllocate(...args);
        this.onAllocate?.(item);

        return item;
    }

    deallocate(item: T | null): null {
        if (!item) {
            return null;
        }

        if (!this.allocatedItems.has(item)) {
            throw new Error(`attempt to release a non-allocated item`);
        }

        this.allocatedItems.delete(item);
        this.allocatedCount -= 1;

        item.onDeallocate();
        this.onDeallocate?.(item);

        this.availableItems.push(item);

        return null;
    }

    isAllocated(item: T): boolean {
        return this.allocatedItems.has(item);
    }

    getAllocatedCount(): number {
        return this.allocatedCount;
    }

    deallocateArray(array: (T | null)[]): (T | null)[] {
        for (let i = 0; i < array.length; ++i) {
            array[i] = this.deallocate(array[i]);
        }

        return array;
    }
}