import { IMAGES_STORE_NAME } from './constants';

type StoredImage = {
    id: string;
    width: number;
    height: number;
    blob: Blob;
};

export async function openIndexedDb(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
        let request = indexedDB.open('image-cache');

        request.onupgradeneeded = function (event) {
            let db = (event.target as any).result;
            let objectStore = db.createObjectStore('images', { keyPath: 'id' });
            objectStore.createIndex('id', 'id', { unique: false });
        };

        request.onsuccess = function (event) {
            let db = (event.target as any).result;

            resolve(db);
        };

        request.onerror = function (event) {
            reject(event);
        };
    });
}

export async function storeImagesToDb(items: { id: string, image: HTMLCanvasElement }[]): Promise<void> {
    let db = await openIndexedDb();

    let blobs = await Promise.all(items.map(item => canvasToBlob(item.image)));
    let storedImages: StoredImage[] = blobs.map((blob, i) => ({
        id: items[i].id,
        blob,
        width: items[i].image.width,
        height: items[i].image.height,
    }));

    return new Promise((resolve, reject) => {
        let transaction = db.transaction([IMAGES_STORE_NAME], 'readwrite');
        let objectStore = transaction.objectStore(IMAGES_STORE_NAME);
        let requests: Promise<void>[] = storedImages.map(image => {
            let addRequest = objectStore.add(image);

            return new Promise(((resolve, reject) => {
                addRequest.onsuccess = () => resolve();
                addRequest.onerror = (err: unknown) => reject(err);
            }));
        });

        Promise.all(requests).then(() => resolve());
    });
}

export async function loadImageFromDb(id: string): Promise<HTMLCanvasElement | null> {
    let db = await openIndexedDb();

    return new Promise((resolve, reject) => {
        let transaction = db.transaction([IMAGES_STORE_NAME], 'readonly');
        let objectStore = transaction.objectStore(IMAGES_STORE_NAME);
        let getRequest = objectStore.get(id);

        getRequest.onsuccess = () => {
            let result = getRequest.result as StoredImage;

            if (result) {
                let blob = result.blob;
                let img = new Image();

                img.src = URL.createObjectURL(blob);

                img.onload = function () {
                    let canvas = document.createElement('canvas');
                    let ctx = canvas.getContext('2d')!;

                    canvas.width = result.width;
                    canvas.height = result.height;

                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.drawImage(img, 0, 0);

                    resolve(canvas);
                };
            } else {
                resolve(null);
            }
        };

        getRequest.onerror = (event) => {
            reject(event);
        };
    });
}

export async function clearDb(): Promise<void> {
    let db = await openIndexedDb();

    return new Promise((resolve, reject) => {
        let transaction = db.transaction([IMAGES_STORE_NAME], 'readwrite');
        let objectStore = transaction.objectStore(IMAGES_STORE_NAME);
        let clearRequest = objectStore.clear();

        clearRequest.onsuccess = () => resolve();
        clearRequest.onerror = (err: unknown) => reject(err);
    });
}

async function canvasToBlob(canvas: HTMLCanvasElement): Promise<Blob> {
    return new Promise(resolve => canvas.toBlob(blob => resolve(blob!)));
}