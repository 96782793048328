import { ComponentHookMethodName } from '../component/component-types.ts';
import { Component } from '../component/component.ts';
import { RoomApi } from '../room/room-api.ts';

export class GlobalContext {
    static api: RoomApi;

    private static stack: RoomApi[] = [];

    static push(api: RoomApi) {
        this.stack.push(this.api);
        this.api = api;
    }

    static pop() {
        this.api = this.stack.pop()!;
        if (!this.api) {
            throw new Error(`Global RoomApi is undefined after a pop(); this shoult not happen`);
        }
    }

    static withRoomApi<T>(api: RoomApi, callback: () => T): T {
        this.push(api);
        let result = callback();
        this.pop();

        return result;
    }

    static runComponentMethod(api: RoomApi, component: Component, key: ComponentHookMethodName) {
        this.api = api;
        component[key]?.();
    }

    static runCallback(api: RoomApi, callback: () => void) {
        this.api = api;
        callback();
    }
}

export class Wrapped<T> {
    api: RoomApi;
    value: T;
    
    constructor(api: RoomApi, value: T) {
        this.api = api;
        this.value = value;
    }

    unwrap(): T {
        GlobalContext.api = this.api;
        return this.value;
    }
}
globalThis.ALL_FUNCTIONS.push(GlobalContext);
globalThis.ALL_FUNCTIONS.push(Wrapped);