type MapType<T> = T extends number ? string : T;

export function toCssPixels<T extends number | string | undefined>(value: T): MapType<T> {
    if (typeof value === 'string') {
        return value as any;
    } else if (typeof value === 'number') {
        return `${value}px` as any;
    } else {
        return undefined as any;
    }
}