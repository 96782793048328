import { CallbackQueue } from '../../utils/data-structures/callback-queue.ts';
import { Result } from '../../utils/language/result.ts';
import { Logger } from '../../utils/logging/logger.ts';
import { Client } from '../client/client.ts';
import { Server, isPersistentClientId } from '../server/server.ts';
import { RoomApi } from './room-api.ts';
import { AddPlayerToRoomParams, CreateRoomParams, AuthenticatePlayerParams, RoomEventParams, RemovePlayerFromRoomParams, DeleteRoomParams, EventParams, PlayerConnectParams, PlayerDisconnectParams, PlayerInteractionParams, EventOutput, RoomInfo, RoomUpdateParams, PostAddPlayerToRoomParams, PostRemovePlayerFromRoomParams, EventKindMapping, PostCreateRoomParams, PreDeleteRoomParams, OngoingInteraction, InteractionCallbackResult, StartRoomParams } from './room-manager-types.ts';
import { RoomWrapper } from './room-wrapper.ts';
import { RoomGlobalHookManager } from './room-global-hook-manager.ts';
import { Counter } from '../../utils/data-structures/counter.ts';
import { ServerData } from '../server/server-data.ts';
import { RoomClientWrapper } from './room-client-wrapper.ts';
import { Serializer } from '../../utils/serialization/serializer.ts';
import { Deserializer } from '../../utils/serialization/deserializer.ts';
import { MaybeAsync } from '../../utils/language/async.ts';
import { getObjectCallback, getObjectPath } from '../../utils/language/object.ts';
import { RoomApiState } from './room-api-types.ts';
import { GracefulAbort } from '../../utils/language/error.ts';
import { makePromise } from '../../utils/language/promise.ts';
import { ClientData } from '../client/client-data.ts';
import { RoomEvent } from './room-event.ts';
import { ComponentHookMethodName } from '../component/component-types.ts';
import { Component } from '../component/component.ts';
import { GlobalContext } from '../global/global-context.ts';
import { DefaultPlayer } from './default-player.ts';

export const ROOT_ROOM_ID = 'root';

export type RoomManagerParams = {
    client: Client | null;
    server: Server | null;
    serializer: Serializer;
    deserializer: Deserializer;
};

export class RoomManager {
    private client: Client | null;
    private server: Server | null;
    private serializer: Serializer;
    private deserializer: Deserializer;
    private rooms: Map<string, RoomWrapper> = new Map();
    private players: Map<string, RoomClientWrapper> = new Map();
    private eventQueue: CallbackQueue = new CallbackQueue();
    private localPlayerId: string | null = null;
    private activeRoom: RoomWrapper | null = null;
    private onActiveRoomChangeCallbacks: ((activeRoom: RoomWrapper | null, prevActiveRoom: RoomWrapper | null) => void)[] = [];
    private globalHookManager: RoomGlobalHookManager = new RoomGlobalHookManager();
    private eventIdCounter: Counter = new Counter();
    private eventsResolveCallback: Map<number, () => void> = new Map();
    private commonApi: RoomApi;
    private ongoingInteractions: Map<Component, Set<OngoingInteraction>> = new Map();
    private eventCallbackPaths: Map<RoomEvent, string[] | null> = new Map();
    private constructorToInteractionKeys: Map<Function, string[]> = new Map();
    private onNewFrameComponents: Set<Component> = new Set();
    private processEventMapping: { [Key in keyof EventKindMapping]: (params: EventKindMapping[Key]) => MaybeAsync<EventOutput<EventKindMapping[Key]>> } = {
        playerConnect: params => this.processPlayerConnect(params),
        playerDisconnect: params => this.processPlayerDisconnect(params),
        authenticatePlayer: params => this.processAuthenticateClient(params),
        createRoom: params => this.processCreateRoom(params),
        startRoom: params => this.processStartRoom(params),
        deleteRoom: params => this.processDeleteRoom(params),
        addPlayerToRoom: params => this.processAddPlayerToRoom(params),
        removePlayerFromRoom: params => this.processRemovePlayerFromRoom(params),
        updateRoom: params => this.processUpdateRoom(params),
        emitRoomEvent: params => this.processEmitRoomEvent(params),
        playerInteraction: params => this.processPlayerInteraction(params),
        postCreateRoom: params => this.processPostCreateRoom(params),
        preDeleteRoom: params => this.processPreDeleteRoom(params),
        postAddPlayerToRoom: params => this.processPostAddPlayerToRoom(params),
        postRemovePlayerFromRoom: params => this.processPostRemovePlayerFromRoom(params),
        callback: params => this.processCallback(params),
    };

    constructor(params: RoomManagerParams) {
        this.client = params.client;
        this.server = params.server;
        this.serializer = params.serializer;
        this.deserializer = params.deserializer;
        this.commonApi = new RoomApi(this);

        GlobalContext.api = this.commonApi;
    }

    getClient(): Client | null {
        return this.client;
    }

    getServer(): Server | null {
        return this.server;
    }

    start(createRootRoom: () => Component) {
        if (this.server) {
            this.queueCreateRoom({ roomId: ROOT_ROOM_ID, room: createRootRoom() });
            this.queueStartRoom({ roomId: ROOT_ROOM_ID });
        }
    }

    update() {
        if (this.client) {
            this.updateClientInteractions();

            for (let component of this.onNewFrameComponents) {
                this.runComponentHookMethod(component, 'onNewFrame');
            }
        } else {
            for (let roomWrapper of this.rooms.values()) {
                this.queueUpdateRoom({ roomId: roomWrapper.roomId });
            }
        }
    }

    queueClientConnect(params: PlayerConnectParams, immediate: boolean = false): number {
        return this.queueAnyEvent({ kind: 'playerConnect', data: params }, immediate);
    }

    queueClientDisconnect(params: PlayerDisconnectParams, immediate: boolean = false): number {
        return this.queueAnyEvent({ kind: 'playerDisconnect', data: params }, immediate);
    }

    queueAuthenticatePlayer(params: AuthenticatePlayerParams, immediate: boolean = false): number {
        return this.queueAnyEvent({ kind: 'authenticatePlayer', data: params }, immediate);
    }

    queueCreateRoom(params: CreateRoomParams, immediate: boolean = false): number {
        return this.queueAnyEvent({ kind: 'createRoom', data: params }, immediate);
    }

    queueDeleteRoom(params: DeleteRoomParams, immediate: boolean = false): number {
        return this.queueAnyEvent({ kind: 'deleteRoom', data: params }, immediate);
    }

    queueAddClientToRoom(params: AddPlayerToRoomParams, immediate: boolean = false): number {
        return this.queueAnyEvent({ kind: 'addPlayerToRoom', data: params }, immediate);
    }

    queueRemoveClientFromRoom(params: RemovePlayerFromRoomParams, immediate: boolean = false): number {
        return this.queueAnyEvent({ kind: 'removePlayerFromRoom', data: params }, immediate);
    }

    queueStartRoom(params: StartRoomParams, immediate: boolean = false): number {
        return this.queueAnyEvent({ kind: 'startRoom', data: params }, immediate);
    }

    queueUpdateRoom(params: RoomUpdateParams, immediate: boolean = false): number {
        return this.queueAnyEvent({ kind: 'updateRoom', data: params }, immediate);
    }

    queueEmitRoomEvent(params: RoomEventParams, immediate: boolean = false): number {
        return this.queueAnyEvent({ kind: 'emitRoomEvent', data: params }, immediate);
    }

    queueClientInteraction(params: PlayerInteractionParams, immediate: boolean = false): number {
        return this.queueAnyEvent({ kind: 'playerInteraction', data: params }, immediate);
    }

    queueAnyEvent<K extends keyof EventKindMapping>(params: EventParams<K>, immediate: boolean = false): number {
        params.eventId ??= this.eventIdCounter.next();

        // this.eventQueue.queue(() => this.processEvent(params).catch(e => { console.error(e); process.exit() }), immediate);
        this.eventQueue.queue(() => this.processEvent(params), immediate);

        return params.eventId;
    }

    queueImmediateFromServer<K extends keyof EventKindMapping>(kind: K, data: EventKindMapping[K]) {
        if (this.server) {
            this.eventQueue.queue(() => this.processEvent({ kind, data }), true);
        }
    }

    queueInstantFromServer<K extends keyof EventKindMapping>(kind: K, data: EventKindMapping[K]) {
        if (this.server) {
            this.processEvent({ kind, data });
        }
    }

    getEventResolvePromise(eventId: number): Promise<void> {
        return new Promise(resolve => {
            this.eventsResolveCallback.set(eventId, resolve);
        });
    }

    private async processEvent<K extends keyof EventKindMapping>(params: EventParams<K>) {
        let { kind, data, eventId = 0 } = params;
        let output = this.processEventMapping[kind](data);

        if (output instanceof Promise) {
            output = await output;
        }

        let resolve = this.eventsResolveCallback.get(eventId);

        if (resolve) {
            resolve();
            this.eventsResolveCallback.delete(eventId);
        }

        if (!this.server || !output || (output.affectedRooms.length === 0 && !output.sendCustomParams)) {
            // console.timeEnd(kind);
            return;
        }

        let {
            affectedRooms,
            sendCustomParams
        } = output;

        let clientIds: Set<string> = new Set();

        for (let roomWrapper of affectedRooms) {
            for (let clientId of roomWrapper.players.keys()) {
                clientIds.add(clientId);
            }
        }

        if (sendCustomParams) {
            let [clientId, customParams] = sendCustomParams;

            clientIds.delete(clientId);

            if (customParams) {
                this.server.emitEvent({
                    clientIds: clientId,
                    kind,
                    data: customParams,
                    eventId,
                });
            }
        }

        this.server.emitEvent({ clientIds, kind, data, eventId });
    }

    private async processCallback(callback: () => Promise<void>): Promise<null> {
        await callback();

        return null;
    }

    private processPlayerConnect(params: PlayerConnectParams): EventOutput<PlayerConnectParams> {
        params.aggregatedServerData ??= {};

        let { playerId, createRooms, aggregatedServerData } = params;

        if (this.client && createRooms) {
            this.localPlayerId = playerId;
            this.rooms.clear();
            this.players.clear();
            this.globalHookManager.clear();

            for (let roomSnapshot of createRooms!) {
                this.processCreateRoom(this.deserializer.deserialize(roomSnapshot));
            }
        }

        let clientWrapper = this.players.get(playerId);
        let affectedRooms = clientWrapper?.roomWrappers.slice() ?? [];

        if (this.server && !affectedRooms.length) {
            let playerData = this.serializer.serialize(new DefaultPlayer(playerId)).copyBytes();

            this.queueAddClientToRoom({ playerId, roomId: ROOT_ROOM_ID, playerData }, true);
        }

        if (!createRooms && this.server) {
            createRooms = affectedRooms.map(roomWrapper => roomWrapper.getSnapshot());
        }

        for (let roomWrapper of affectedRooms) {
            roomWrapper.runHookMethod(aggregatedServerData, 'onPlayerReconnected', playerId);
        }

        this.computeClientActiveRoom();

        return {
            affectedRooms,
            sendCustomParams: [playerId, { ...params, createRooms }]
        };
    }

    private processPlayerDisconnect(params: PlayerDisconnectParams): EventOutput<PlayerDisconnectParams> {
        params.aggregatedServerData ??= {};

        let { playerId, aggregatedServerData } = params;
        let clientWrapper = this.players.get(playerId);

        if (!clientWrapper) {
            return null;
        }

        let affectedRooms = clientWrapper.roomWrappers.slice();
        let isPersistentClient = isPersistentClientId(playerId);

        for (let roomWrapper of affectedRooms) {
            let roomId = roomWrapper.roomId;

            roomWrapper.runHookMethod(aggregatedServerData, 'onPlayerDisconnected', playerId);

            if (!isPersistentClient) {
                this.processRemovePlayerFromRoom({ roomId, playerId });
            }
        }

        return {
            affectedRooms,
            sendCustomParams: [playerId, null]
        };
    }

    private processAuthenticateClient(params: AuthenticatePlayerParams): EventOutput<AuthenticatePlayerParams> {
        params.aggregatedServerData ??= {};

        let { currentPlayerId, newPlayerId, createRooms, aggregatedServerData } = params;
        let currentClientWrapper = this.players.get(currentPlayerId ?? '');

        if (this.server?.isClientConnected(newPlayerId) || (currentPlayerId && !currentClientWrapper)) {
            return null;
        }

        let affectedRooms: RoomWrapper[] = [];

        if (currentPlayerId !== this.localPlayerId && currentPlayerId && currentClientWrapper) {
            let disconnectOutput = this.processPlayerDisconnect({ playerId: currentPlayerId, aggregatedServerData });
            this.server?.updateClientId(currentPlayerId, newPlayerId);

            affectedRooms.push(...disconnectOutput!.affectedRooms);
        }

        let connectOutput = this.processPlayerConnect({ playerId: newPlayerId, createRooms, aggregatedServerData });

        createRooms ??= connectOutput!.sendCustomParams![1]!.createRooms!;

        affectedRooms.push(...connectOutput!.affectedRooms);

        return {
            affectedRooms,
            sendCustomParams: [newPlayerId, { ...params, createRooms }]
        };
    }

    private processCreateRoom(params: CreateRoomParams): EventOutput<CreateRoomParams> {
        let { roomId } = params;

        if (this.rooms.has(roomId)) {
            return null;
        }

        let roomWrapper = new RoomWrapper(this, params);

        this.rooms.set(roomId, roomWrapper);

        for (let clientId of roomWrapper.players.keys()) {
            this.addToClientRoomList(clientId, roomWrapper);
        }

        if (this.server) {
            roomWrapper.spawn(roomWrapper.room);
        }

        this.globalHookManager.notifyRoomCreated(roomWrapper);

        this.computeClientActiveRoom();
        this.queueImmediateFromServer('postCreateRoom', { roomId });

        return {
            affectedRooms: [roomWrapper],
        };
    }

    private processDeleteRoom(params: DeleteRoomParams): EventOutput<DeleteRoomParams> {
        let { roomConstructor, roomId } = params;
        let roomWrapper = this.rooms.get(roomId);

        if (!roomWrapper || !roomWrapper.matchesConstructor(roomConstructor)) {
            return null;
        }

        this.queueInstantFromServer('preDeleteRoom', { roomId });

        for (let playerId of roomWrapper.players.keys()) {
            this.queueInstantFromServer('postRemovePlayerFromRoom', { roomId, playerId });
        }

        for (let playerId of roomWrapper.players.keys()) {
            this.removeFromClientRoomList(playerId, roomWrapper);
        }

        this.globalHookManager.notifyRoomDeleted(roomWrapper);

        this.rooms.delete(roomId);
        this.computeClientActiveRoom();

        return {
            affectedRooms: [roomWrapper],
        };
    }

    private processAddPlayerToRoom(params: AddPlayerToRoomParams): EventOutput<AddPlayerToRoomParams> {
        params.aggregatedServerData ??= {};

        if (params.playerId === this.localPlayerId && params.createRoom) {
            this.processCreateRoom(this.deserializer.deserialize(params.createRoom));
        }

        let { roomId, playerId, playerData, aggregatedServerData } = params;
        let roomWrapper = this.rooms.get(roomId);

        if (!roomWrapper || roomWrapper.players.has(playerId)) {
            return null;
        }

        let createRoom: Uint8Array | undefined = undefined;

        if (this.server) {
            createRoom = roomWrapper.getSnapshot();
        }

        let player = this.deserializer.deserialize<Component>(playerData);

        this.addToClientRoomList(playerId, roomWrapper);
        roomWrapper.players.set(playerId, player);

        if (playerId === this.localPlayerId) {
            this.computeClientActiveRoom();
        }

        // roomWrapper.spawn(player); // TODO: do not render component if it is local player
        roomWrapper.runHookMethod(aggregatedServerData, 'onPlayerAdded', playerId);

        this.queueImmediateFromServer('postAddPlayerToRoom', { roomId, playerId });

        return {
            affectedRooms: [roomWrapper],
            sendCustomParams: [playerId, { ...params, createRoom }]
        };
    }

    private processRemovePlayerFromRoom(params: RemovePlayerFromRoomParams): EventOutput<RemovePlayerFromRoomParams> {
        params.aggregatedServerData ??= {};
        let { roomId, playerId, aggregatedServerData } = params;
        let roomWrapper = this.rooms.get(roomId);

        if (!roomWrapper || !roomWrapper.players.has(playerId)) {
            return null;
        }

        if (playerId === this.localPlayerId) {
            this.removeFromClientRoomList(playerId, roomWrapper);
            this.rooms.delete(roomId);
            this.computeClientActiveRoom();
            return null;
        }

        let player = roomWrapper.players.get(playerId)!;

        roomWrapper.despawn(player);
        roomWrapper.runHookMethod(aggregatedServerData, 'onPlayerRemoved', playerId);

        roomWrapper.players.delete(playerId);
        this.removeFromClientRoomList(playerId, roomWrapper);

        this.queueImmediateFromServer('postRemovePlayerFromRoom', { roomId, playerId });

        return {
            affectedRooms: [roomWrapper],
            sendCustomParams: [playerId, params]
        };
    }

    private processStartRoom(params: StartRoomParams): EventOutput<StartRoomParams> {
        params.aggregatedServerData ??= {};

        let { roomId, aggregatedServerData } = params;
        let roomWrapper = this.rooms.get(roomId);

        if (!roomWrapper) {
            return null;
        }

        roomWrapper.runHookMethod(aggregatedServerData, 'onRoomStart', null);

        return {
            affectedRooms: [roomWrapper]
        };
    }

    private processUpdateRoom(params: RoomUpdateParams): EventOutput<RoomUpdateParams> {
        params.aggregatedServerData ??= {};

        let { roomId, aggregatedServerData } = params;
        let roomWrapper = this.rooms.get(roomId);

        if (!roomWrapper) {
            return null;
        }

        roomWrapper.runHookMethod(aggregatedServerData, 'onUpdate', null);

        if (this.client) {
            // this.client.cancelNonStartedInteractions();
        }

        return {
            affectedRooms: [roomWrapper]
        };
    }

    private processEmitRoomEvent(params: RoomEventParams): EventOutput<RoomEventParams> {
        let { roomId, eventPath, eventCallback, eventData } = params;
        let roomWrapper = this.rooms.get(roomId);
        let api = this.commonApi;

        if (!roomWrapper) {
            return warn(`unkown room id ${roomId}`);
        }

        if (eventCallback) {
            let eventCallbackPath = this.getEventCallbackPath(roomWrapper, eventCallback);

            if (!eventCallbackPath) {
                return warn(`attempt to emit an invalid event callback`);
            } else {
                eventPath = eventCallbackPath;
                params.eventPath = eventPath;
                params.eventCallback = undefined;
            }
        }

        let callback = getObjectCallback<RoomEvent>(roomWrapper.room, eventPath);

        if (!callback) {
            return warn(`path ${JSON.stringify(eventPath)} is not a valid event callback`);
        }

        let [component, key] = callback;

        api.reset({
            capabilities: 'server',
            roomWrapper,
            serverData: params.serverData
        });

        GlobalContext.runCallback(api, () => component[key](eventData))

        params.serverData = api.getLoadedServerData();

        return {
            affectedRooms: [roomWrapper],
        };
    }

    private async processPlayerInteraction(params: PlayerInteractionParams): Promise<EventOutput<PlayerInteractionParams>> {
        let { roomId, entityId, interactionKey, playerId, clientData } = params;
        let roomWrapper = this.rooms.get(roomId);
        let result: Result<ServerData[]>;
        let localResponse = true;

        if (!roomWrapper) {
            result = Result.error(`unkown room id ${roomId}`);
        } else if (!roomWrapper.players.has(playerId)) {
            result = Result.error(`unkown client id ${playerId}`);
        } else {
            let interactionResult = await this.processCompletedInteraction(roomWrapper, entityId, interactionKey, playerId, clientData, params.result);

            result = interactionResult.map(result => result.serverData);
            localResponse = !interactionResult.isOk() || interactionResult.data!.local;
        }

        params.result = result;

        return {
            affectedRooms: (result.isOk() && !localResponse) ? [roomWrapper!] : [],
            sendCustomParams: [playerId, params],
        };
    }

    private processPostCreateRoom(params: PostCreateRoomParams): EventOutput<PostCreateRoomParams> {
        return this.processPostCreateOrDeleteRoom('onOtherRoomCreated', params);
    }

    private processPreDeleteRoom(params: PreDeleteRoomParams): EventOutput<PreDeleteRoomParams> {
        return this.processPostCreateOrDeleteRoom('onOtherRoomDeleted', params);
    }

    private processPostAddPlayerToRoom(params: PostAddPlayerToRoomParams): EventOutput<PostAddPlayerToRoomParams> {
        return this.processPostAddOrRemoveClient('onPlayerAddedToOtherRoom', params);
    }

    private processPostRemovePlayerFromRoom(params: PostRemovePlayerFromRoomParams): EventOutput<PostRemovePlayerFromRoomParams> {
        return this.processPostAddOrRemoveClient('onPlayerRemovedFromOtherRoom', params);
    }

    private processPostCreateOrDeleteRoom(methodName: 'onOtherRoomCreated' | 'onOtherRoomDeleted', params: PostCreateRoomParams | PreDeleteRoomParams): EventOutput<PostCreateRoomParams | PreDeleteRoomParams> {
        params.aggregatedServerData ??= {};

        let { roomId, aggregatedServerData } = params;
        let affectedRooms = this.globalHookManager.triggerHook(aggregatedServerData, methodName, roomId, null);

        return { affectedRooms };
    }

    private processPostAddOrRemoveClient(methodName: 'onPlayerAddedToOtherRoom' | 'onPlayerRemovedFromOtherRoom', params: PostAddPlayerToRoomParams | PostRemovePlayerFromRoomParams): EventOutput<PostAddPlayerToRoomParams | PostRemovePlayerFromRoomParams> {
        params.aggregatedServerData ??= {};

        let { playerId, roomId, aggregatedServerData } = params;
        let affectedRooms = this.globalHookManager.triggerHook(aggregatedServerData, methodName, roomId, playerId);

        return { affectedRooms };
    }

    private addToClientRoomList(clientId: string, roomWrapper: RoomWrapper) {
        let clientWrapper = this.players.get(clientId);

        if (!clientWrapper) {
            clientWrapper = new RoomClientWrapper();
            this.players.set(clientId, clientWrapper);
        }

        clientWrapper.roomWrappers.push(roomWrapper);
    }

    private removeFromClientRoomList(clientId: string, roomWrapper: RoomWrapper) {
        let clientWrapper = this.players.get(clientId);

        if (clientWrapper) {
            clientWrapper.roomWrappers.remove(roomWrapper);

            if (clientWrapper.roomWrappers.length === 0) {
                this.players.delete(clientId);
            }
        }
    }

    private computeClientActiveRoom() {
        if (!this.localPlayerId) {
            return;
        }

        let activeRoomWrapper = this.players.get(this.localPlayerId)?.roomWrappers.at(-1) ?? null;

        if (activeRoomWrapper !== this.activeRoom) {
            let prevActiveRoomWrapper = this.activeRoom;

            this.activeRoom = activeRoomWrapper;
            this.triggerActiveRoomChange(this.activeRoom, prevActiveRoomWrapper);
        }
    }

    private triggerActiveRoomChange(newActiveRoom: RoomWrapper | null, prevActiveRoom: RoomWrapper | null) {
        for (let callback of this.onActiveRoomChangeCallbacks) {
            callback(newActiveRoom ?? null, prevActiveRoom ?? null);
        }
    }

    onActiveRoomChange(callback: (activeRoom: RoomWrapper | null, prevActiveRoom: RoomWrapper | null) => void) {
        this.onActiveRoomChangeCallbacks.push(callback);
    }

    getRoom(roomId: string): RoomWrapper | undefined {
        return this.rooms.get(roomId);
    }

    getActiveRoomWrapper(): RoomWrapper | null {
        return this.activeRoom ?? null;
    }

    getActiveRoom(): Component | null {
        return this.activeRoom?.room ?? null;
    }

    getLocalPlayerId(): string | null {
        return this.localPlayerId;
    }

    isClientInRoom(clientId: string, roomId: string): boolean {
        return this.players.get(clientId)?.roomWrappers.some(roomWrapper => roomWrapper.roomId === roomId) ?? false;
    }

    private runComponentHookMethod<K extends ComponentHookMethodName>(component: Component, methodName: K) {
        if (!(methodName in component) || !this.activeRoom) {
            return;
        }

        let api = this.commonApi.reset({
            roomWrapper: this.activeRoom,
            capabilities: 'client',
            sourcePlayerId: this.localPlayerId
        });

        GlobalContext.runComponentMethod(api, component, methodName);

        api.destroy();
    }

    getRoomInfo(roomId: string): RoomInfo | undefined {
        return this.rooms.get(roomId)?.getInfo();
    }

    getAllRoomInfo(): RoomInfo[] {
        return [...this.rooms.values()].map(roomWrapper => roomWrapper.getInfo());
    }

    getAllRooms(): Component[] {
        return [...this.rooms.values()].map(wrapper => wrapper.room);
    }

    getSerializer(): Serializer {
        return this.serializer;
    }

    getDeserializer(): Deserializer {
        return this.deserializer;
    }

    mountComponent(component: Component) {
        this.registerComponentHooks(component);
        this.runComponentHookMethod(component, 'onMount');
        this.startComponentInteractions(component);
    }

    unmountComponent(component: Component) {
        this.unregisterComponentHooks(component);
        this.stopComponentInteractions(component);
        this.runComponentHookMethod(component, 'onUnmount');
    }

    private registerComponentHooks(component: Component) {
        if (component.onNewFrame) {
            this.onNewFrameComponents.add(component);
        }
    }

    private unregisterComponentHooks(component: Component) {
        if (component.onNewFrame) {
            this.onNewFrameComponents.delete(component);
        }
    }

    private startComponentInteractions(component: Component) {
        let interactionKeys = this.constructorToInteractionKeys.get(component.constructor);

        if (!interactionKeys) {
            interactionKeys = Object.getOwnPropertyNames(component.constructor.prototype)
                .filter(key => key.startsWith('$') && typeof (component as any)[key] === 'function');
            this.constructorToInteractionKeys.set(component.constructor, interactionKeys);
        }

        if (interactionKeys.length === 0) {
            return;
        }

        let interactions: Set<OngoingInteraction> = new Set();

        for (let key of interactionKeys) {
            interactions.add({
                apis: [],
                component,
                entityId: this.activeRoom?.getEntityManager().getId(component) ?? null,
                key,
                aborted: false,
            });
        }

        this.ongoingInteractions.set(component, interactions);
    }

    private stopComponentInteractions(component: Component) {
        let interactions = this.ongoingInteractions.get(component);

        if (!interactions) {
            return;
        }

        for (let interaction of interactions.values()) {
            for (let api of interaction.apis) {
                api.destroy();
            }

            interaction.aborted = true;
        }

        this.ongoingInteractions.delete(component);
    }

    updateClientInteractions() {
        for (let interactions of this.ongoingInteractions.values()) {
            for (let interaction of interactions.values()) {
                if (interaction.apis.length > 0 || interaction.aborted) {
                    continue;
                }

                this.runClientInteraction(interaction);
            }
        }
    }

    private async runClientInteraction(interaction: OngoingInteraction) {
        let playerId = this.client?.getPlayerId()!;
        let player = playerId && this.players.get(playerId);

        if (!player || !this.activeRoom || interaction.aborted) {
            return;
        }

        let { component, key, entityId } = interaction;
        let api = new RoomApi(this);

        interaction.apis.push(api);

        await this.runInteractionCallback(api, this.activeRoom, component, entityId, key, playerId, null, null, () => {
            this.runClientInteraction(interaction);
        });

        if (api.getState() === RoomApiState.Aborted || api.getConcurrency() === 'singleton') {
            interaction.aborted = true;
            interaction.apis.remove(api);
            return;
        }

        let hasInteracted = false;

        if (api.didUserInteract()) {
            hasInteracted = true;
            let promise = api.getInteractionCompletionPromise();

            if (promise) {
                await promise;
            }
        }

        interaction.apis.remove(api);

        if (api.getConcurrency() === 'sequential' && !interaction.aborted && hasInteracted) {
            this.runClientInteraction(interaction);
        }
    }

    async processCompletedInteraction(
        roomWrapper: RoomWrapper,
        entityId: number,
        interactionKey: string,
        playerId: string,
        clientData: ClientData[],
        serverData: Result<ServerData[]> | null
    ): Promise<InteractionCallbackResult> {
        let entity = roomWrapper.getEntityManager().getEntity(entityId);

        if (!entity) {
            return Result.error(`unknown entity id ${entityId}`);
        }

        if (!interactionKey.startsWith('$')) {
            return Result.error(`invalid interaction key "${interactionKey}"`);
        }

        if (typeof (entity as any)[interactionKey] !== 'function') {
            return Result.error(`invalid interaction key "${interactionKey}" for entity with id ${entityId}`);
        }

        let api = this.commonApi;

        return this.runInteractionCallback(api, roomWrapper, entity, entityId, interactionKey, playerId, clientData, serverData, null);
    }

    private async runInteractionCallback(
        api: RoomApi,
        roomWrapper: RoomWrapper,
        component: Component,
        sourceEntityId: number | null,
        interactionKey: string,
        sourcePlayerId: string,
        clientData: ClientData[] | null,
        serverData: Result<ServerData[]> | null,
        restartInteraction: (() => void) | null
    ): Promise<InteractionCallbackResult> {
        let onComplete = makePromise();

        api.reset({
            capabilities: 'client',
            roomWrapper,
            sourcePlayerId,
            sourceEntityId,
            interactionKey,
            saveData: clientData === null,
            preLoadedData: clientData,
            serverData: serverData?.unwrap(),
            restartInteraction,
            onComplete
        });

        let result: InteractionCallbackResult;

        GlobalContext.api = api;

        try {
            let sourceId = await (component as any)[interactionKey]();

            if (sourceId !== undefined && sourceId !== api.getSourceId()) {
                console.warn([
                    `Unexpected RoomApi signature at the end of ${interactionKey}.`,
                    `This should not happen and indicates an internal problem within the framework.`,
                ].join(' '));
            }

            if (this.server && !api.hasServerResponseBeenRequested()) {
                result = Result.error(`request failed: did not not reach \`waitForServerResponse\` (not an issue)`);
            } else {
                result = Result.ok({
                    serverData: api.getLoadedServerData(),
                    local: api.isLocalServerResponse()
                });
            }
        } catch (error: unknown) {
            if (error && error instanceof Error) {
                Logger.error(error);
                result = Result.error(error.message);
            } else {
                result = Result.error();
            }

            if (error && error instanceof GracefulAbort && error.message) {
                Logger.warn(error.message);
            }

            if (!error || !(error instanceof GracefulAbort)) {
                // api.setAborted();
            }
        }

        onComplete.resolve();
        api.destroy();

        return result;
    }

    getEventCallbackPath(roomWrapper: RoomWrapper, callback: RoomEvent): string[] | null {
        let path = this.eventCallbackPaths.get(callback);

        if (path === undefined) {
            path = getObjectPath(roomWrapper.room, callback, true) ?? null;
            this.eventCallbackPaths.set(callback, path);
        }

        return path;
    }
}

function warn(message: string): EventOutput<any> {
    Logger.warn(message);

    return null;
}
globalThis.ALL_FUNCTIONS.push(RoomManager);