type MemoryGridParams = {
    initialWidth: number;
    initialHeight: number;
    maxWidth: number;
    maxHeight: number;
};

export class MemoryGrid {
    private maxWidth: number;
    private maxHeight: number;
    private currentWidth: number;
    private currentHeight: number;
    private cells: Uint8Array;

    constructor(params: MemoryGridParams) {
        this.maxWidth = params.maxWidth;
        this.maxHeight = params.maxHeight;
        this.currentWidth = params.initialWidth;
        this.currentHeight = params.initialHeight;
        this.cells = new Uint8Array(this.currentWidth * this.currentHeight);
    }

    private isFree(x: number, y: number): boolean {
        // if (x < 0 || x >= this.currentWidth || y < 0 || y >= this.currentHeight) {
        //     return false;
        // }

        return !this.cells[y * this.currentWidth + x];
    }

    private expand(): boolean {
        let newWidth = this.currentWidth;
        let newHeight = this.currentHeight;

        if (newWidth <= newHeight) {
            newWidth *= 2;
        } else {
            newHeight *= 2;
        }

        if (newWidth > this.maxWidth || newHeight > this.maxHeight) {
            return false;
        }

        let newCells = new Uint8Array(newWidth * newHeight);

        for (let x = 0; x < this.currentWidth; ++x) {
            for (let y = 0; y < this.currentHeight; ++y) {
                let oldIndex = y * this.currentWidth + x;
                let newIndex = y * newWidth + x;

                newCells[newIndex] = this.cells[oldIndex];
            }
        }

        this.currentWidth = newWidth;
        this.currentHeight = newHeight;
        this.cells = newCells;

        return true;
    }

    allocate(width: number, height: number, expanded: boolean = false): { x: number, y: number, expanded: boolean } | null {
        let maxX = this.currentWidth - width;
        let maxY = this.currentHeight - height;
        let x = 0;
        let y = 0;

        while (y < maxY) {
            while (x < maxX) {
                if (!this.isFree(x + width, y)) {
                    x += width;
                } else if (this.canAllocateCoords(x, y, width, height)) {
                    return { x, y, expanded };
                } else {
                    x += 1;
                }
            }

            y += 1;
        }

        if (this.expand()) {
            return this.allocate(width, height, true);
        } else {
            return null;
        }
    }

    private canAllocateCoords(x: number, y: number, width: number, height: number): boolean {
        let startX = x;
        let startY = y;
        let endX = x + width;
        let endY = y + height;

        for (let x = startX; x< endX; ++x) {
            for (let y = startY; y < endY; ++y) {
                if (!this.isFree(x, y)) {
                    return false;
                }
            }
        }

        return true;
    }
}
globalThis.ALL_FUNCTIONS.push(MemoryGrid);