import { Point } from '../../geometry/point.ts';
import { BUTTON_TO_STRING, DEFAULT_DRAG_THRESHOLD, DELTA_MODE_TO_STRING, DOM_NAME_ALIASES, DomEvent, DomEventName, DomEventNameToType, DomKeybordEvent, DomMouseEvent, KeyboardAction, KeyCode, MouseAction, MouseButton } from './dom-events-types.ts';
import { DragData, EventMap, getDomEventName } from './dom-input-manager-utils.ts';

export type DomInputManagerParams = {
    dragThreshold?: number;
    stopDragOnLeave?: boolean;
};

export class DomInputManager {
    private element: HTMLElement | null = null;
    private eventCallbacks: Partial<{ [Key in DomEventName]: ((evt: DomEventNameToType[Key]) => void)[] }> = {};
    private ongoingDragEvents: Map<MouseButton, DragData> = new Map();
    private domEventListeners: [Window | Document | HTMLElement, string, (evt: any) => void][] = [];
    private pointer: Point | null = null;
    private dragThreshold: number;
    private stopDragOnLeave: boolean;

    constructor(params: DomInputManagerParams = {}) {
        this.dragThreshold = params.dragThreshold ?? DEFAULT_DRAG_THRESHOLD;
        this.stopDragOnLeave = params.stopDragOnLeave ?? false;
    }

    on<K extends DomEventName>(name: K, callback: ((evt: DomEventNameToType[K]) => void)) {
        let aliases = DOM_NAME_ALIASES[name];

        if (aliases) {
            for (let alias of aliases) {
                // @ts-ignore
                this.on(alias, callback);
            }

            return;
        }

        let array = this.eventCallbacks[name];

        if (!array) {
            array = [];
            this.eventCallbacks[name] = array;
        }

        array.push(callback);
    }

    init(element: HTMLElement) {
        this.clearDom();

        this.element = element;

        this.clearDom();
        this.addEventListener(document, 'mousemove', evt => this.onMouseMove(evt));
        this.addEventListener(element, 'mousedown', evt => this.onMouseDown(evt));
        this.addEventListener(element, 'mouseup', evt => this.onElementMouseUp(evt));
        this.addEventListener(document, 'mouseup', evt => this.onDocumentMouseUp(evt));
        this.addEventListener(document, 'mouseleave', evt => this.onMouseLeave(evt));
        this.addEventListener(element, 'wheel', evt => this.onWheel(evt));
        this.addEventListener(document, 'keydown', evt => this.onKeyDown(evt));
        this.addEventListener(document, 'keyup', evt => this.onKeyUp(evt));
        this.addEventListener(element, 'contextmenu', evt => evt.preventDefault());
    }

    destroy() {
        this.clearDom();
    }

    getPointer(): Point | undefined {
        return this.pointer?.clone();
    }

    private addEventListener<T extends Window | Document | HTMLElement, K extends keyof EventMap<T> & string>(
        element: T,
        type: K,
        callback: (event: EventMap<T>[K]) => void,
    ) {
        element.addEventListener(type, callback as EventListener, { passive: false });
        this.domEventListeners.push([element, type, callback]);
    }

    private clearDom() {
        for (let [element, kind, callback] of this.domEventListeners) {
            element.removeEventListener(kind, callback);
        }

        this.domEventListeners = [];
        this.ongoingDragEvents.clear();
    }

    private emitEvent(event: DomEvent) {
        let eventName = getDomEventName(event);
        let specializedCallbacks = this.eventCallbacks[eventName];

        if (specializedCallbacks) {
            for (let callback of specializedCallbacks) {
                callback(event as any);
            }
        }
    }

    private parseMouveEvent(nativeEvent: MouseEvent, action: MouseAction): DomMouseEvent {
        return {
            kind: 'mouse',
            action,
            button: BUTTON_TO_STRING[nativeEvent.button] ?? 'left',
            x: nativeEvent.clientX,
            y: nativeEvent.clientY,
            dx: nativeEvent.clientX - (this.pointer?.x ?? nativeEvent.clientX),
            dy: nativeEvent.clientY - (this.pointer?.y ?? nativeEvent.clientY),
            startX: nativeEvent.clientX,
            startY: nativeEvent.clientY,
            altKey: nativeEvent.altKey,
            ctrlKey: nativeEvent.ctrlKey,
            shiftKey: nativeEvent.shiftKey,
            metaKey: nativeEvent.metaKey,
            nativeEvent: nativeEvent,
        };
    }

    private emitMouseEvent(nativeEvent: MouseEvent, action: MouseAction) {
        let evt = this.parseMouveEvent(nativeEvent, action);

        this.pointer = new Point(evt.x, evt.y);
        this.emitEvent(evt);

        return evt;
    }

    private onMouseMove(nativeEvent: MouseEvent) {
        let evt = this.emitMouseEvent(nativeEvent, 'move');

        for (let dragData of this.ongoingDragEvents.values()) {
            let distance = Math.hypot(evt.x - dragData.startX, evt.y - dragData.startY);

            if (distance > this.dragThreshold) {
                if (!dragData.started) {
                    dragData.started = true;

                    this.emitEvent({
                        kind: 'mouse',
                        action: 'drag-start',
                        button: dragData.button,
                        x: dragData.startX,
                        y: dragData.startY,
                        dx: 0,
                        dy: 0,
                        startX: dragData.startX,
                        startY: dragData.startY,
                        metaKey: dragData.metaKey,
                        ctrlKey: dragData.ctrlKey,
                        shiftKey: dragData.shiftKey,
                        altKey: dragData.altKey,
                        nativeEvent
                    });
                }

                this.emitEvent({
                    kind: 'mouse',
                    action: 'drag-progress',
                    button: dragData.button,
                    x: evt.x,
                    y: evt.y,
                    dx: evt.dx,
                    dy: evt.dy,
                    startX: dragData.startX,
                    startY: dragData.startY,
                    metaKey: dragData.metaKey,
                    ctrlKey: dragData.ctrlKey,
                    shiftKey: dragData.shiftKey,
                    altKey: dragData.altKey,
                    nativeEvent,
                });
            }
        }
    }

    private onMouseDown(nativeEvent: MouseEvent) {
        let evt = this.emitMouseEvent(nativeEvent, 'down');

        this.ongoingDragEvents.set(evt.button, {
            started: false,
            button: evt.button,
            startX: evt.startX,
            startY: evt.startY,
            metaKey: evt.metaKey,
            ctrlKey: evt.ctrlKey,
            shiftKey: evt.shiftKey,
            altKey: evt.altKey,
        });
    }

    private onElementMouseUp(nativeEvent: MouseEvent) {
        this.emitMouseEvent(nativeEvent, 'up');
    }

    private onDocumentMouseUp(nativeEvent: MouseEvent) {
        let evt = this.parseMouveEvent(nativeEvent, 'up');

        let dragData = this.ongoingDragEvents.get(evt.button);

        if (dragData) {
            this.ongoingDragEvents.delete(evt.button);
            
            let distance = Math.hypot(evt.x - dragData.startX, evt.y - dragData.startY);

            if (dragData.started) {
                this.emitEvent({
                    kind: 'mouse',
                    action: 'drag-end',
                    x: evt.x,
                    y: evt.y,
                    dx: 0,
                    dy: 0,
                    startX: dragData.startX,
                    startY: dragData.startY,
                    button: dragData.button,
                    metaKey: dragData.metaKey,
                    ctrlKey: dragData.ctrlKey,
                    shiftKey: dragData.shiftKey,
                    altKey: dragData.altKey,
                    nativeEvent
                });
            } else if (distance < this.dragThreshold) {
                this.emitEvent({
                    kind: 'mouse',
                    action: 'click',
                    x: evt.x,
                    y: evt.y,
                    dx: 0,
                    dy: 0,
                    startX: dragData.startX,
                    startY: dragData.startY,
                    button: dragData.button,
                    metaKey: dragData.metaKey,
                    ctrlKey: dragData.ctrlKey,
                    shiftKey: dragData.shiftKey,
                    altKey: dragData.altKey,
                    nativeEvent
                });
            }
        }
    }

    private onMouseLeave(nativeEvent: MouseEvent) {
        if (this.stopDragOnLeave) {
            for (let dragData of this.ongoingDragEvents.values()) {
                if (dragData.started) {
                    this.emitEvent({
                        kind: 'mouse',
                        action: 'drag-end',
                        x: nativeEvent.clientX,
                        y: nativeEvent.clientY,
                        dx: nativeEvent.clientX - (this.pointer?.x ?? nativeEvent.clientX),
                        dy: nativeEvent.clientY - (this.pointer?.y ?? nativeEvent.clientY),
                        startX: dragData.startX,
                        startY: dragData.startY,
                        button: dragData.button,
                        metaKey: dragData.metaKey,
                        ctrlKey: dragData.ctrlKey,
                        shiftKey: dragData.shiftKey,
                        altKey: dragData.altKey,
                        nativeEvent
                    });
                }
            }

            this.ongoingDragEvents.clear();
        }

        this.pointer = null;
    }

    private emitKeyboardEvent(nativeEvent: KeyboardEvent, action: KeyboardAction): DomKeybordEvent {
        let evt: DomKeybordEvent = {
            kind: 'keyboard',
            action,
            key: nativeEvent.key,
            code: nativeEvent.code as KeyCode,
            metaKey: nativeEvent.metaKey,
            ctrlKey: nativeEvent.ctrlKey,
            shiftKey: nativeEvent.shiftKey,
            altKey: nativeEvent.altKey,
            repeat: nativeEvent.repeat,
            nativeEvent,
        };

        this.emitEvent(evt);

        return evt;
    }

    private onKeyDown(nativeEvent: KeyboardEvent) {
        this.emitKeyboardEvent(nativeEvent, 'down');
    }

    private onKeyUp(nativeEvent: KeyboardEvent) {
        this.emitKeyboardEvent(nativeEvent, 'up');
    }

    private onWheel(nativeEvent: WheelEvent) {
        this.emitEvent({
            kind: 'wheel',
            action: nativeEvent.deltaY < 0 ? 'down' : 'up',
            x: nativeEvent.clientX,
            y: nativeEvent.clientY,
            deltaX: nativeEvent.deltaX,
            deltaY: nativeEvent.deltaY,
            deltaZ: nativeEvent.deltaZ,
            deltaMode: DELTA_MODE_TO_STRING[nativeEvent.deltaMode],
            metaKey: nativeEvent.metaKey,
            ctrlKey: nativeEvent.ctrlKey,
            shiftKey: nativeEvent.shiftKey,
            altKey: nativeEvent.altKey,
            nativeEvent,
        });
    }
}
globalThis.ALL_FUNCTIONS.push(DomInputManager);