import { Component } from '../../framework/component/component.ts';
import { Room } from '../../framework/global/global-room-api.ts';
import { View } from '../../framework/view/view.ts';
import { Button } from '../../helpers/widgets/button.ts';
import { makePromise } from '../../utils/language/promise.ts';
import { GameHubParams } from '../hub.ts';
import { DeckBuildingPanel } from './deck-building-panel.ts';
import { GearCategorySelectionPanel } from './gear-category-selection-panel.ts';

export class GearSelectionPanel implements Component {
    onComplete = makePromise<Map<Component, number>>();
    categoryPanels: GearCategorySelectionPanel[] = [];
    finishButton = new Button('Finish');
    selectedItems: Map<Component, number> = new Map();

    constructor(params: GameHubParams) {
        for (let category of params.gearCategories ?? []) {
            let panel: GearCategorySelectionPanel;

            if (category.kind === 'item') {
                throw new Error(`unimplemented`);
            } else if (category.kind === 'deck') {
                panel = new DeckBuildingPanel(category);
            } else {
                throw new Error(`unreachable`);
            }

            this.categoryPanels.push(panel);
        }
    }

    async $complete() {
        await Room.waitForUserInput({
            selectable: this.finishButton
        });

        this.onComplete.resolve(this.selectedItems);
    }

    isGearValid(data: Map<Component, number>): boolean {
        for (let selectionPanel of this.categoryPanels) {
            if (!selectionPanel.isGearValid(data)) {
                return false;
            }
        }

        return true;
    }

    waitForData() {
        return this.onComplete.promise;
    }

    render(view: View) {
        view.paint({
            color: 'pink'
        });

        view.addChild(this.finishButton, view.rect.fromBottomInwards('30%', '*', '5%').stripToMatchAspectRatio(5, 'bottom'));

        let firstPanel = this.categoryPanels[0];

        if (firstPanel) {
            view.addChild(firstPanel, view.rect.fromTopInwards('*', '80%'));
        }
    }
}
globalThis.ALL_FUNCTIONS.push(GearSelectionPanel);