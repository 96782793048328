import { generateCardImages } from './card-drawing';
import { App } from './components/app';
import { SPREADSHEET_URL_STORAGE_KEY } from './constants';
import { parseSpreadsheet } from './spreadsheet-parsing';
import { LocalStorage } from './local-storage';
import { fetchGoogleSpreadsheet } from './google-docs-api';
import { onSaveProject } from './action-cache';
import { saveProjectToCache } from './cache';

export async function onPreviewCards() {
    let url: string = App.instance.state.spreadsheetUrl;
    let hasError = false;
    let hasCards = true;

    if (!url.startsWith('https://docs.google.com/spreadsheets/d/')) {
        alert(`Invalid spreadsheet URL.`);
        return;
    }

    LocalStorage.writeString(SPREADSHEET_URL_STORAGE_KEY, url);

    App.instance.setState({
        showCards: false
    });

    try {
        let spreadsheet = await App.instance.runAction('Fetching spreadsheet', () => fetchGoogleSpreadsheet({ url }));
        let project = await App.instance.runAction('Parsing spreadsheet', () => parseSpreadsheet(spreadsheet));
        let cards = await App.instance.runAction('Generating cards', () => generateCardImages(project, {
            imageLoader: App.instance.assetLoader,
            pdfQuality: false
        }));

        await App.instance.runAction('Saving to cache', () => saveProjectToCache(project))

        hasCards = cards.length > 0;

        App.instance.setState({
            spreadsheet,
            project,
            showCards: true,
            // justSaved: false
            hasCache: true,
            justSaved: true,
        });
    } catch (err: any) {
        hasError = true;

        if (err.result?.error?.message) {
            console.error(err);
            alert(err.result.error.message);
        } else if (typeof err === 'string') {
            console.error(err);
            alert(err);
        } else {
            throw err;
        }
    }

    if (!hasError && !hasCards) {
        setTimeout(() => alert(`No card to generate!`), 100);
    }
};