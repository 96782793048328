// https://developer.mozilla.org/en-US/docs/Web/API/WebGL_API/Constants

export const WEBGL_SHADER_KINDS = {
    'vertex': 0x8B31,
    'fragment': 0x8B30,
};

export const WEBGL_TYPES = {
    'byte': {
        glConstant: 0x1400,
        byteSize: 1,
    },
    'unsigned-byte': {
        glConstant: 0x1401,
        byteSize: 1,
    },
    'short': {
        glConstant: 0x1402,
        byteSize: 2,
    },
    'unsigned-short': {
        glConstant: 0x1403,
        byteSize: 2,
    },
    'int': {
        glConstant: 0x1404,
        byteSize: 4,
    },
    'unsigned-int': {
        glConstant: 0x1405,
        byteSize: 4,
    },
    'float': {
        glConstant: 0x1406,
        byteSize: 4,
    },
} satisfies { [key: string]: WebglTypeDetails };

export type WebglShaderKind = keyof typeof WEBGL_SHADER_KINDS;
export type WebglType = keyof typeof WEBGL_TYPES;
export type WebglTypeDetails = {
    glConstant: number;
    byteSize: number;
};

export const WEBGL_BUFFER_USAGES = {
    'static-draw': 0x88E4,
    'dynamic-draw': 0x88E8
};

export type WebglBufferUsage = keyof typeof WEBGL_BUFFER_USAGES;