import { JSX } from 'preact/jsx-runtime';

export type NativeElementTag = keyof JSX.IntrinsicElements;

export type DynamicComponentProps = JSX.HTMLAttributes & {
    tag: NativeElementTag;
};

export function DynamicComponent(props: DynamicComponentProps) {
    let Component = props.tag as any;

    return <Component {...props} />;
};