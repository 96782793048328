import { getFunctionArgumentNames, getFunctionBody } from '../language/function.ts';
import { ParametersExceptFirstAndSecond } from '../language/types.ts';
import { WEBGL_ATTRIBUTE_TYPES, WebglAttributesLayout, WebglAttributesLayoutDetails } from './webgl-attributes.ts';

export type WebglBufferWriterParams<T extends WebglAttributesLayout> = {
    dataView: DataView;
    attributes: WebglAttributesLayoutDetails<T>;
};

export type WebglBufferWriter<T extends WebglAttributesLayout> = {
    [Key in keyof T]: (...args: ParametersExceptFirstAndSecond<typeof WEBGL_ATTRIBUTE_TYPES[T[Key]]['setFunction']>) => WebglBufferWriter<T>
} & {
    getElementIndex(): number;
};

export class WebglBufferWriterImplementation<T extends WebglAttributesLayout> {
    private itemByteSize: number;

    // These two fields MUST NOT be removed, as they are used by the function created in the constructor
    private dataView: DataView;
    private writeIndex: number;

    constructor(params: WebglBufferWriterParams<T>) {
        this.itemByteSize = params.attributes.byteSize;
        this.dataView = params.dataView;
        this.writeIndex = 0;

        for (let [name, details] of Object.entries(params.attributes.attributes)) {
            let typeDetails = WEBGL_ATTRIBUTE_TYPES[details.attributeType];
            let { offset } = details;
            let { setFunction } = typeDetails;
            let sourceArgs = getFunctionArgumentNames(setFunction);
            let sourceBody = getFunctionBody(setFunction);
            let args = sourceArgs.slice(2);
            let body = sourceBody
                .replaceAll(sourceArgs[0], 'this.dataView')
                .replaceAll(sourceArgs[1], `this.writeIndex + ${offset}`)
                + 'return this;'
            let func = new Function(...args, body);

            (this as any)[name] = func;
        }
    }

    getElementIndex(): number {
        return this.writeIndex / this.itemByteSize;
    }

    setWriteIndex(dataView: DataView, value: number): WebglBufferWriter<T> {
        this.dataView = dataView;
        this.writeIndex = value;

        return this as WebglBufferWriter<T>;
    }
}
globalThis.ALL_FUNCTIONS.push(WebglBufferWriterImplementation);