export const WEBGL_BLEND_FUNCTIONS = {
    'source-over': gl => gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA),
    'source-opaque': gl => gl.blendFunc(gl.ONE, gl.ZERO),
} satisfies { [key: string]: (gl: WebGL2RenderingContext) => void };

export type WebglBlendFunction = keyof typeof WEBGL_BLEND_FUNCTIONS;

export const WEBGL_BLEND_FUNCTIONS_ENUM: WebglBlendFunction[] = Object.keys(WEBGL_BLEND_FUNCTIONS) as WebglBlendFunction[];

export function applyWebglBlending(gl: WebGL2RenderingContext, blending: WebglBlendFunction) {
    WEBGL_BLEND_FUNCTIONS[blending](gl);
}