export type LayerShakeParams = {
    offsetX: number;
    offsetY: number;
    duration: number;
    bounceCount: number;
    lossPercentPerBounce?: number;
};

export class LayerShake {
    private offsetX: number;
    private offsetY: number;
    private duration: number;
    private bounceCount: number;
    private lossPercentPerBounce: number;
    private startTime: number = 0;

    private cx = 0;
    private cy = 0;
    
    constructor(params: LayerShakeParams) {
        this.offsetX = params.offsetX;
        this.offsetY = params.offsetY;
        this.duration = params.duration;
        this.bounceCount = params.bounceCount;
        this.lossPercentPerBounce = params.lossPercentPerBounce ?? 1;
    }

    update(currentTime: number): boolean {
        if (this.startTime === 0) {
            this.startTime = currentTime;
        }

        let elapsed = currentTime - this.startTime;
        let bounceDuration = this.duration / this.bounceCount;
        let elapsedBounceCount = Math.floor(elapsed / bounceDuration);
        let currentBounceDuration = elapsed % bounceDuration;
        let strengthPercent = currentBounceDuration / bounceDuration * 2;
        let sign = elapsedBounceCount % 2 === 0 ? 1 : -1;

        if (strengthPercent > 1) {
            strengthPercent = 2 - strengthPercent;
        }

        strengthPercent *= this.lossPercentPerBounce ** elapsedBounceCount;

        this.cx = this.offsetX * strengthPercent * sign;
        this.cy = this.offsetY * strengthPercent * sign;

        return currentTime > this.startTime + this.duration;
    }

    getOffsetX() {
        return this.cx;
    }

    getOffsetY() {
        return this.cy;
    }
}
globalThis.ALL_FUNCTIONS.push(LayerShake);