import { Counter } from '../../utils/data-structures/counter.ts';
import { Collection, iterateCollection } from '../../utils/language/collection.ts';
import { ComponentModifier, ComponentModifierCallback } from '../component/component-modifier.ts';
import { Component } from '../component/component.ts';
import { ViewModifier } from '../view/view-modifier.ts';
import { ViewFragmentPriority } from '../view/view-types.ts';
import { ClientApiAction } from './client-api-action.ts';
import { AddModifierParams, ClientApiInteractionParams } from './client-api-types.ts';

export class ClientApiInteraction extends ClientApiAction {
    private viewModifierIdCounter: Counter = new Counter();
    private viewModifiers: Map<number, ViewModifier> = new Map();
    private priority: number = 0;

    reset(params: ClientApiInteractionParams) {
        super.reset(params);
        this.priority = 0;
    }

    destroy() {
        for (let viewModifier of this.viewModifiers.values()) {
            this.client?.removeViewModifier(viewModifier);
        }

        this.viewModifiers.clear();

        super.destroy();
    }

    setPriority(priority: number) {
        this.priority = priority;
    }

    getPriority(): number {
        return this.priority;
    }

    /* MODIFIERS */

    addModifier<T extends Component>(components: Collection<T>, modifier: ComponentModifier<T>): number {
        if (!this.client) {
            return 0;
        }
        
        let id = this.viewModifierIdCounter.next();
        let viewModifier = this.client.addViewModifier({
            modifier: modifier as ComponentModifier,
            components,
            priority: ViewFragmentPriority.Interaction,
        });

        this.viewModifiers.set(id, viewModifier);

        return id;
    }

    removeModifier(modifierId: number) {
        let viewModifier = this.viewModifiers.get(modifierId);

        if (viewModifier) {
            this.client?.removeViewModifier(viewModifier);
            this.viewModifiers.delete(modifierId);
        }
    }

    assignModifier(modifierId: number, components: Collection<Component>) {
        this.withModifier(modifierId, modifier => {
            for (let component of iterateCollection(components)) {
                modifier.add(component);
            }
        });
    }

    unassignModifier(modifierId: number, components: Collection<Component>) {
        this.withModifier(modifierId, modifier => {
            for (let component of iterateCollection(components)) {
                modifier.delete(component);
            }
        });
    }

    clearModifier(modifierId: number) {
        this.withModifier(modifierId, modifier => modifier.clear());
    }

    setModifier(modifierId: number, components: Collection<Component>) {
        this.withModifier(modifierId, modifier => modifier.set(components));
    }

    private withModifier(modifierId: number, callback: (modifier: ViewModifier) => void) {
        let modifier = this.viewModifiers.get(modifierId);

        if (modifier) {
            callback(modifier);
        }
    }
}
globalThis.ALL_FUNCTIONS.push(ClientApiInteraction);