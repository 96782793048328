import { Easing } from '../../../utils/time/easing.ts';
import { Loop } from '../../../utils/time/loop-rule.ts';
import { GlslType } from './graphics-attribute-metadata.ts';
import { GraphicsAttributeOperation } from './graphics-attribute-types.ts';
import { ImageFit, RotationDirection, FillDirection } from './graphics-types.ts';

export const GLSL_TYPE_TO_BIT: { [Key in GlslType]: number } = {
    'number': 0,
    'color': 1,
    'display-size': 2,
    'progress': 3,
};

export const LOOP_TO_BITS: { [Key in Loop]: number } = {
    'clamp': 0,
    'repeat': 1,
    'mirror': 2,
};

export const EASING_TO_BITS: { [Key in Exclude<Easing, Function>]: number } = {
    'linear': 0,
    'quadratic-in': 1,
    'quadratic-out': 2,
    'quadratic-in-out': 3,
    'cubic-in': 4,
    'cubic-out': 5,
    'cubic-in-out': 6,
};

export const MODIFIER_OPERATION_TO_BITS: { [Key in GraphicsAttributeOperation]: number } = {
    'ignore': 0,
    'set': 1,
    'add': 2,
    'mult': 3
};

export const IMAGE_FIT_TO_BITS: { [Key in ImageFit]: number } = {
    'contain': 0,
    'cover': 1,
    'fill': 2,
    'raw': 3
};

export const ROTATION_DIRECTION_TO_BITS: { [Key in RotationDirection]: number } = {
    'clockwise': 0,
    'counter-clockwise': 1
};

export const FILL_DIRECTION_TO_BITS: { [Key in FillDirection]: number } = {
    'left-to-right': 0,
    'right-to-left': 1,
    'top-to-bottom': 2,
    'bottom-to-top': 3,
};