const STARTING_CAPACITY = 1024;

export class PointerStack {
    pointers: Uint32Array = new Uint32Array(STARTING_CAPACITY);
    topOfStackIndex: number = -1;

    clear() {
        this.pointers.fill(0);
        this.topOfStackIndex = -1;
    }

    push(pointer: number) {
        this.topOfStackIndex += 1;

        if (this.topOfStackIndex === this.pointers.length) {
            let newPointers = new Uint32Array(this.pointers.length * 2);
            newPointers.set(this.pointers);
            this.pointers = newPointers;
        }

        this.pointers[this.topOfStackIndex] = pointer;
    }

    pop(): number | undefined {
        if (this.topOfStackIndex === -1) {
            return undefined;
        }

        let result = this.pointers[this.topOfStackIndex];
        this.topOfStackIndex -= 1;

        return result;
    }

    isEmpty(): boolean {
        return this.topOfStackIndex === -1;
    }
}
globalThis.ALL_FUNCTIONS.push(PointerStack);