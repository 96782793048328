import { Component } from '../../framework/component/component.ts';
import { View } from '../../framework/view/view.ts';
import { Button } from '../../helpers/widgets/button.ts';
import { getRangeStart, getRangeEnd } from '../../utils/language/range.ts';
import { GameHubParams } from '../hub.ts';

export type LobbyPreviewParams = {
    id: string;
    name: string;
};

export class LobbyPreview implements Component {
    params: GameHubParams;
    id: string;
    name: string;
    playerCount: number = 0;
    joinButton = new Button('Join');
    minPlayerCount: number;
    maxPlayerCount: number;

    constructor(baseParams: GameHubParams, params: LobbyPreviewParams) {
        this.params = baseParams;
        this.id = params.id;
        this.name = params.name;
        this.minPlayerCount = getRangeStart(baseParams.playerCount, 1);
        this.maxPlayerCount = getRangeEnd(baseParams.playerCount, Infinity);
    }

    canAcceptPlayers() {
        return this.playerCount < this.maxPlayerCount;
    }

    onMount(): void {
        this.joinButton.setStyle(this.params.buttonStyle);
    }

    render(view: View): void {
        view.paint('stroke', {
            strokeSize: '3%',
            color: 'black'
        });

        let text = `@o-13%{@0.65{👤}}${this.playerCount}`;

        if (this.maxPlayerCount !== Infinity) {
            text += `/${this.maxPlayerCount}`;
        }

        view.layout()
            .leftToRight()
            .outerMargin('10%')
            .childHeight('100%')
            .addChild({
                text: this.name,
                horizontalAlign: 'left',
            })
            .addChild({ text })
            .aspectRatio(1.1)
            .addChild(this.joinButton)
            .height('50%')
            .aspectRatio(2)
    }
}
globalThis.ALL_FUNCTIONS.push(LobbyPreview);