/**
 * Represents a commonly used point in a rectangle.
 * 
 * It is possible to pass a `{ x, y }` object to refer to a more specific point on the rectangle, with values between -1 and 1.
 * For example:
 * - (0, 0) refers to the center of the rectangle.
 * - (-1, -1) refers to the top-left corner of the rectangle.
 * - (-1, 1) refers to the bottom-left corner of the rectangle.
 * - (0.5, 0.5) refers to the point halfway between the center of the rectangle and its the bottom-right corner.
 * @prop x Value between -1 (left) and 1 (right).
 * @prop y Value between -1 (top) and 1 (bottom).
 */
export type RectLandmark =
    | 'center'
    | 'top'
    | 'top-right'
    | 'right'
    | 'bottom-right'
    | 'bottom'
    | 'bottom-left'
    | 'left'
    | 'top-left'
    | { x: number, y: number; };

export function getLandmarkCoefficients(landmark: RectLandmark): { x: number, y: number; } {
    switch (landmark) {
        case "center":
            return { x: 0, y: 0 };
        case "top":
            return { x: 0, y: -1 };
        case "top-right":
            return { x: 1, y: -1 };
        case "right":
            return { x: 1, y: 0 };
        case "bottom-right":
            return { x: 1, y: 1 };
        case "bottom":
            return { x: 0, y: 1 };
        case "bottom-left":
            return { x: -1, y: 1 };
        case "left":
            return { x: -1, y: 0 };
        case "top-left":
            return { x: -1, y: -1 };
        default:
            return landmark;
    }
}