import { Component, ComponentChild } from 'preact';
import { SpreadsheetData } from '../google-docs-api';
import { wait } from '../utils';
import { HomePage } from './home-page';
import { AssetLoader } from '../asset-loader';
import { MaybeAsync } from 'outpost';
import { PROJECT_STOARGE_KEY, SPREADSHEET_URL_STORAGE_KEY } from '../constants';
import { Project } from '../project';
import { BoardComponent } from '../board/board-component';
import { LocalStorage } from '../local-storage';
import { BoardLocalData } from '../board/board-local-data';

export type AppState = {
    spreadsheet: SpreadsheetData | null;
    project: Project | null;
    currentAction: string | null;
    spreadsheetUrl: string;
    showCards: boolean;
    justSaved: boolean;
    hasCache: boolean;
    setupPlayOpen: boolean;
    initBoardCallback: ((localData: BoardLocalData) => void) | null;
    playPanelOpen: boolean,
    isGuest: boolean,
};

export class App extends Component<{}, AppState> {
    assetLoader = new AssetLoader();
    state: Readonly<AppState> = {
        spreadsheet: null,
        project: null,
        currentAction: null,
        spreadsheetUrl: LocalStorage.readString(SPREADSHEET_URL_STORAGE_KEY),
        showCards: false,
        justSaved: false,
        hasCache: !!localStorage.getItem(PROJECT_STOARGE_KEY),
        setupPlayOpen: false,
        initBoardCallback: null,
        playPanelOpen: false,
        isGuest: false,
    };

    static readonly instance: App;

    constructor() {
        super();
        (App as any).instance = this;
        (window as any).App = this;
    }

    get project() {
        return this.state.project;
    }

    componentDidMount(): void {

    }

    render(): ComponentChild {
        let homepage = <HomePage
            spreadsheetUrl={this.state.spreadsheetUrl}
            project={this.state.project}
            currentAction={this.state.currentAction}
            showCards={this.state.showCards && !this.state.playPanelOpen}
            hasCache={this.state.hasCache}
            justSaved={this.state.justSaved}
            setupPlayOpen={this.state.setupPlayOpen}
            hasOngoingPlay={!!this.state.initBoardCallback}
        />;

        let board = undefined;

        if (this.state.initBoardCallback && this.state.project) {
            board = <BoardComponent
                init={this.state.initBoardCallback}
                project={this.state.project}
                isGuest={this.state.isGuest}
                open={this.state.playPanelOpen}
            />;
        }

        return <>
            {homepage}
            {board}
        </>;
    }

    private setCurrentAction(label: string | null) {
        this.setState({
            currentAction: label
        });
    }

    async runAction<T>(label: string | null, callback: () => MaybeAsync<T>) {
        if (label) {
            console.time(label);
            this.setCurrentAction(`${label}...`);
            await wait(100);
        }

        let result = await callback();

        if (label) {
            console.timeEnd(label);
        }

        this.setCurrentAction(null);

        return result;
    };
}