import { Color } from '../color/color.ts';
import { WebglBlendFunction } from './webgl-blend-function.ts';

export class WebglFrameBuffer {
    private gl: WebGL2RenderingContext;
    private glColorBuffer: WebGLRenderbuffer | null = null;
    private glHitBuffer: WebGLRenderbuffer | null = null;
    private glDrawFrameBuffer: WebGLFramebuffer | null = null;
    private glHitFrameBuffer: WebGLFramebuffer | null = null;
    private backgroundColor: [number, number, number, number] = [1, 1, 1, 1];
    private emptyId: [number, number, number, number] = [0, 0, 0, 0];
    private pixel: Uint8Array = new Uint8Array(4);

    constructor(gl: WebGL2RenderingContext) {
        this.gl = gl;
        this.glColorBuffer = gl.createRenderbuffer();
        this.glHitBuffer = gl.createRenderbuffer();
        this.glDrawFrameBuffer = gl.createFramebuffer();
        this.glHitFrameBuffer = gl.createFramebuffer();
    }

    notifyResize() {
        let gl = this.gl;
        let { width, height } = gl.canvas;

        gl.bindRenderbuffer(gl.RENDERBUFFER, this.glColorBuffer);
        gl.renderbufferStorage(gl.RENDERBUFFER, gl.RGBA8, width, height);
        // gl.renderbufferStorageMultisample(gl.RENDERBUFFER, 4, gl.RGB565, width, height);

        gl.bindRenderbuffer(gl.RENDERBUFFER, this.glHitBuffer);
        gl.renderbufferStorage(gl.RENDERBUFFER, gl.RGBA8, width, height);
        // gl.renderbufferStorageMultisample(gl.RENDERBUFFER, 4, gl.RGBA8, width, height);

        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, this.glDrawFrameBuffer);
        gl.framebufferRenderbuffer(gl.DRAW_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.RENDERBUFFER, this.glColorBuffer);
        gl.framebufferRenderbuffer(gl.DRAW_FRAMEBUFFER, gl.COLOR_ATTACHMENT1, gl.RENDERBUFFER, this.glHitBuffer);

        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, this.glHitFrameBuffer);
        gl.framebufferRenderbuffer(gl.DRAW_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.RENDERBUFFER, this.glHitBuffer);

        gl.viewport(0, 0, width, height);
        gl.enable(gl.BLEND);
        gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
    }

    setBackgroundColor(color: Color) {
        this.backgroundColor = [color.r, color.g, color.b, color.a];
    }

    clear() {
        let gl = this.gl;

        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, this.glHitFrameBuffer);
        gl.drawBuffers([gl.COLOR_ATTACHMENT0]);
        gl.clearColor(...this.emptyId);
        gl.clear(gl.COLOR_BUFFER_BIT);

        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, this.glDrawFrameBuffer);
        gl.drawBuffers([gl.COLOR_ATTACHMENT0]);
        gl.clearColor(...this.backgroundColor);
        gl.clear(gl.COLOR_BUFFER_BIT);

        gl.drawBuffers([gl.COLOR_ATTACHMENT0, gl.COLOR_ATTACHMENT1]);
    }

    show() {
        let gl = this.gl;
        let { width, height } = gl.canvas;

        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, this.glDrawFrameBuffer);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, null);
        gl.blitFramebuffer(0, 0, width, height, 0, 0, width, height, gl.COLOR_BUFFER_BIT, gl.NEAREST);
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, null);
    }

    getComponentIdAt(x: number, y: number): number {
        let gl = this.gl;

        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, this.glHitFrameBuffer);
        gl.readPixels(x, gl.canvas.height - y - 1, 1, 1, gl.RGBA, gl.UNSIGNED_BYTE, this.pixel);
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, null);

        return (this.pixel[0] << 16) + (this.pixel[1] << 8) + this.pixel[2];
    }

    // https://developer.mozilla.org/en-US/docs/Web/API/WebGL_API/WebGL_best_practices#use_non-blocking_async_data_readback
    async getComponentIdAtAsync(x: number, y: number): Promise<number> {
        let gl = this.gl;
        let glBuffer = gl.createBuffer();

        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, this.glHitFrameBuffer);

        gl.bindBuffer(gl.PIXEL_PACK_BUFFER, glBuffer);
        gl.bufferData(gl.PIXEL_PACK_BUFFER, this.pixel.byteLength, gl.STREAM_READ);
        gl.readPixels(x, gl.canvas.height - y - 1, 1, 1, gl.RGBA, gl.UNSIGNED_BYTE, 0);
        gl.bindBuffer(gl.PIXEL_PACK_BUFFER, null);

        let glSync = gl.fenceSync(gl.SYNC_GPU_COMMANDS_COMPLETE, 0)!;
        gl.flush();

        await glClientWaitAsync(gl, glSync);
        gl.deleteSync(glSync);

        gl.bindBuffer(gl.PIXEL_PACK_BUFFER, glBuffer);
        gl.getBufferSubData(gl.PIXEL_PACK_BUFFER, 0, this.pixel, 0);
        gl.bindBuffer(gl.PIXEL_PACK_BUFFER, null);

        gl.deleteBuffer(glBuffer);

        return (this.pixel[0] << 16) + (this.pixel[1] << 8) + this.pixel[2];
    }
}

function glClientWaitAsync(gl: WebGL2RenderingContext, sync: WebGLSync): Promise<void> {
    return new Promise((resolve, reject) => {
        function test() {
            let res = gl.clientWaitSync(sync, 0, 0);

            if (res === gl.TIMEOUT_EXPIRED) {
                setTimeout(test, 10);
            } else if (res === gl.WAIT_FAILED) {
                reject();
            } else {
                resolve();
            }
        }
        test();
    });
}

globalThis.ALL_FUNCTIONS.push(WebglFrameBuffer);