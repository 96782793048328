import { objectSchema } from '../type-schema/object-schema.ts';
import { Line, LineLike } from './line.ts';
import { Point } from './point.ts';

/**
 *  Union type used by {@link Vector.from} to create a new vector, depending on what is specified:
 * - `Vector`: creates a copy of the vector
 * - `[x: number, y: number]`: creates a vector with coordinates (x, y)
 * - `{ x?: number, y?: number }`: creates a vector with coordinates (x, y), unspecified coordinates default to 0
 * - `LineLike`: creates a vector that goes from one line segment extremity to the other
 */
export type VectorLike =
    | Vector
    | [ number, number ]
    | { x?: number, y?: number }
    | LineLike;

export type VectorProperties = { x: number; y: number; };

/**
 * Represents a 2D vector with coordinates (x, y).
 * 
 * All methods return a new instance unless specified otherwise.
 */
export class Vector {
    /**
     * X coordinate of the vector.
     */
    x: number;
    /**
     * Y coordinate of the vector.
     */
    y: number;

    private static RESOLVE_VECTOR = new Vector(0, 0);

    static readonly ZERO = new Vector(0, 0);

    /**
     * 
     * @param x 
     * @param y 
     */
    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    static schema = objectSchema({
        x: 'number',
        y: 'number'
    }, Vector);

    /**
     * Creates a new line from the specified {@link VectorLike}.
     * @param vectorLike 
     * @returns 
     */
    static from(vectorLike: VectorLike, target = new Vector(0, 0)): Vector {
        if (typeof vectorLike === 'number') {
            target.x = vectorLike;
            target.y = vectorLike;
        } else if (vectorLike instanceof Vector) {
            target.x = vectorLike.x;
            target.y = vectorLike.y;
        } else if (Array.isArray(vectorLike)) {
            if (typeof vectorLike[0] === 'number') {
                let [x, y] = vectorLike as [number, number];

                target.x = x;
                target.y = y;
            } else {
                let { x1, y1, x2, y2 } = Line.from(vectorLike as LineLike);

                target.x = x2 - x1;
                target.y = y2 - y1;
            }
        } else if ('x1' in vectorLike) {
            target.x = vectorLike.x2 - vectorLike.x1;
            target.y = vectorLike.y2 - vectorLike.y1;
        } else {
            target.x = vectorLike.x ?? 0;
            target.y = vectorLike.y ?? 0;
        }

        return target;
    }

    static resolve(vectorLike: VectorLike): Vector {
        return Vector.from(vectorLike, Vector.RESOLVE_VECTOR);
    }

    /**
     * Creates a vector with coordinates (0, 0).
     * @returns 
     */
    static zero(): Vector {
        return new Vector(0, 0);
    }

    static left(): Vector {
        return new Vector(-1, 0);
    }

    static right(): Vector {
        return new Vector(1, 0);
    }

    static top(): Vector {
        return new Vector(0, -1);
    }

    static bottom(): Vector {
        return new Vector(0, 1);
    }

    /**
     * Creates a copy of the vector.
     * @returns 
     */
    clone(): Vector {
        return new Vector(this.x, this.y);
    }

    /**
     * Creates a point with the same coordinates as the vector.
     * @returns 
     */
    toPoint(): Point {
        return new Point(this.x, this.y);
    }

    /**
     * Creates a new vector by adding the specified vector.
     * @param vector 
     * @returns 
     */
    add(vector: VectorLike): Vector {
        let { x, y } = Vector.from(vector);

        return new Vector(this.x + x, this.y + y);
    }

    /**
     * Creates a new vector by substracting the specified vector.
     * @param vector 
     * @returns 
     */
    sub(vector: VectorLike): Vector {
        let { x, y } = Vector.from(vector);

        return new Vector(this.x - x, this.y - y);
    }

    /**
     * Creates a new vector by multiplying both coordinates of the vector by the specified value.
     * If a tuple of values is specified, x is multiplied by the first value and y by the second.
     * @param value
     * @returns 
     */
    mult(value: number | [number, number]) {
        if (typeof value === 'number') {
            return new Vector(this.x * value, this.y * value);
        } else {
            return new Vector(this.x * value[0], this.y * value[1]);
        }
    }

    /**
     * Creates a new vector by dividing both coordinates of the vector by the specified value.
     * If a tuple of values is specified, x is divided by the first value and y by the second.
     * @param value
     * @returns 
     */
    div(value: number | [number, number]) {
        if (typeof value === 'number') {
            return new Vector(this.x / value, this.y / value);
        } else {
            return new Vector(this.x / value[0], this.y / value[1]);
        }
    }

    /**
     * Creates a new vector with the same direction and a length of 1.
     * @returns 
     */
    normalize(): Vector {
        let len = this.getLength();

        if (len === 0) {
            return new Vector(0, 0);
        }

        return new Vector(this.x / len, this.y / len);
    }

    /**
     * Creates a new vector with both coordinates negated.
     * @returns 
     */
    negate(): Vector {
        return this.mult(-1);
    }

    /**
     * Returns the length of the vector.
     * @returns 
     */
    getLength(): number {
        return Math.sqrt(this.x ** 2 + this.y ** 2);
    }

    /**
     * Creates a new vector with the same direction and the specified length.
     * @param length 
     * @returns 
     */
    withLength(length: number): Vector {
        return this.normalize().mult(length);
    }

    /**
     * Creates a new vector with the same direction and a length increased by the specified value.
     * @param length 
     * @returns 
     */
    addLength(length: number): Vector {
        let currentLength = this.getLength();
        let ratio = (length + currentLength) / currentLength;
        let x = this.x * ratio;
        let y = this.y * ratio;

        return new Vector(x, y);
    }

    /**
     * Returns both orthogonal vectors.
     * @returns 
     */
    getOrthogonals(): [Vector, Vector] {
        return [
            new Vector(this.y, -this.x),
            new Vector(-this.y, this.x),
        ];
    }

    /**
     * Returns the angle of the vector.
     * @returns 
     */
    getAngle(): number {
        return Math.atan2(this.y, this.x);
    }

    /**
     * Creates a new vector with the same length and an angle rotated by the specified angle.
     * @param angle 
     * @returns 
     */
    rotate(angle: number): Vector {
        let cos = Math.cos(angle);
        let sin = Math.sin(angle);
        let x = cos * this.x - sin * this.y;
        let y = sin * this.x + cos * this.y;

        return new Vector(x, y);
    }

    isZero(): boolean {
        return this.x === 0 && this.y === 0;
    }
}
globalThis.ALL_FUNCTIONS.push(Vector);