import { useEffect, useState } from 'preact/hooks';
import { ClientConnection } from '../board/client-connection';
import { GAME_ID_PARAM_NAME, HASH_STOARGE_KEY } from '../constants';
import { BoardInitParams } from '../board/client-connection-types';
import { LocalStorage } from '../local-storage';
import { Project } from '../project';
import { loadProjectFromCache, saveProjectToCache } from '../cache';
import { App } from './app';
import { fetchGoogleSpreadsheet } from '../google-docs-api';
import { parseSpreadsheet } from '../spreadsheet-parsing';
import { generateCardImages } from '../card-drawing';
import { BoardLocalData } from '../board/board-local-data';

export type AutoConnectProps = {

};

export function AutoConnect(props: AutoConnectProps) {
    let [guestConnection, setGuestConnection] = useState<ClientConnection | null>(null);

    let connectToGame = async (gameId: string) => {
        let initParams = await App.instance.runAction('Connecting to game', async () => {
            guestConnection = await ClientConnection.start({ label: 'guest' });
            setGuestConnection(guestConnection);
            guestConnection.joinRoom(gameId);

            return guestConnection.whenPeerConnected();
        });

        if (!initParams) {
            setTimeout(() => alert(`Invalid game ID.`));
            return;
        }

        let { spreadsheetId, projectHash } = initParams;
        let project: Project | null = null;

        if (LocalStorage.readString(HASH_STOARGE_KEY) === projectHash) {
            project = await App.instance.runAction('Loading cache', () => loadProjectFromCache());
        }

        if (!project) {
            let spreadsheet = await App.instance.runAction('Fetching spreadsheet', () => fetchGoogleSpreadsheet({ id: spreadsheetId }));
            project = await App.instance.runAction('Parsing spreadsheet', () => parseSpreadsheet(spreadsheet));

            await App.instance.runAction('Generating cards', () => generateCardImages(project!, {
                imageLoader: App.instance.assetLoader,
                pdfQuality: false
            }));
            await App.instance.runAction('Saving to cache', () => saveProjectToCache(project));
        }

        return new Promise<BoardLocalData>(resolve => {
            App.instance.setState({
                project,
                showCards: true,
                hasCache: true,
                justSaved: true,
                playPanelOpen: true,
                isGuest: true,
                initBoardCallback: localData => {
                    guestConnection!.notifyGuestReady(localData);
                    resolve(localData);
                }
            });
        });
    };

    useEffect(() => {
        let urlParams = new URLSearchParams(window.location.search);
        let gameId = urlParams.get(GAME_ID_PARAM_NAME);

        if (gameId) {
            connectToGame(gameId);
        }
    }, []);

    return <></>;
};