import { ImageLike } from '../../utils/dom/image-loader.ts';

export function getImageSize(image: ImageLike | null): [number, number] {
    if (!image) {
        return [0, 0];
    } else {
        return [image.width, image.height];
    }
}

export function destructureSidesTuple(tuple: undefined | number | [number, number] | [number, number, number, number]): [number, number, number, number] {
    let top = 0;
    let right = 0;
    let bottom = 0;
    let left = 0;

    if (typeof tuple === 'number') {
        top = tuple;
        right = tuple;
        bottom = tuple;
        left = tuple;
    } else if (Array.isArray(tuple) && tuple.length === 2) {
        top = tuple[0];
        right = tuple[1];
        bottom = tuple[0];
        left = tuple[1];
    } else if (Array.isArray(tuple) && tuple.length === 4) {
        top = tuple[0];
        right = tuple[1];
        bottom = tuple[2];
        left = tuple[3];
    }

    return [top, right, bottom, left];
}