import { KeyCode } from '../dom/dom-events/dom-events-types.ts';

export type KeyCombination = `${'Meta_' | ''}${'Ctrl_' | ''}${'Shift_' | ''}${'Alt_' | ''}${KeyCode}`;

export function getKeyboardEventCombination(evt: KeyboardEvent): KeyCombination {
    let result: string = '';

    if (evt.metaKey) {
        result += `Meta_`;
    }

    if (evt.ctrlKey) {
        result += `Ctrl_`;
    }

    if (evt.shiftKey) {
        result += `Shift_`;
    }

    if (evt.altKey) {
        result += `Alt_`;
    }

    result += evt.code;

    return result as KeyCombination;
}