import { CSSProperties } from 'preact/compat';
import { BoardLocalData } from './board-local-data';
import { BoardPlayerId } from './board-types';
import { Row } from '../base-components/container';
import { OVERLAY_CLASS_NAME } from '../components/ui-constants';

export type BoardCardTooltipComponentProps = {
    localData: BoardLocalData;
    sideId: BoardPlayerId;
};

export function BoardCardTooltipComponent(props: BoardCardTooltipComponentProps) {
    let { localData, sideId } = props;
    let card = localData.getInteractedCard('tooltip');
    let inspectedZone = localData.getInspectedZone();

    if (inspectedZone && inspectedZone === card?.getZone()) {
        sideId = sideId === 'p1' ? 'p2' : 'p1';
    }

    if (!card || card.getSidePlayerId() === sideId || !localData.isCardTooltipable(card) || localData.grabbed) {
        return <></>;
    }

    let tooltipStyle: CSSProperties = {
        pointerEvents: 'none',
        zIndex: 100,
        maxHeight: '100%',
    };

    return (
        <Row className={OVERLAY_CLASS_NAME} alignItems='center' justifyContent='center' >
            <img
                style={tooltipStyle}
                src={localData.getImageSrc(card.cardId)}
            />
        </Row>
    );
};