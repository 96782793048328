import { Component } from '../../framework/component/component.ts';
import { View } from '../../framework/view/view.ts';
import { SERIALIZABLE } from '../../utils/serialization/serialization-constants.ts';
import { TextContent, TextContentLike } from '../../utils/text/text-content.ts';
import { WidgetStyle, mergeWidgetStyles } from './widget-types.ts';

export class Widget implements Component {
    protected style: WidgetStyle;
    protected text: TextContent = new TextContent();
    protected isEnabledCallback: (() => boolean) | null = null;

    constructor(text: string, style: WidgetStyle = {}) {
        this.style = style;
        this.text.content = text;
    }

    [SERIALIZABLE](key: string): boolean {
        return key !== 'isEnabledCallback';
    }

    getText(): TextContent {
        return this.text;
    }

    setText(text: TextContentLike) {
        this.text = TextContent.from(text);
    }

    setStyle(style: WidgetStyle | undefined) {
        this.style = mergeWidgetStyles(this.style, style)!;
    }

    setEnabledCondition(callback: () => boolean) {
        this.isEnabledCallback = callback;
    }

    render(view: View): void {
        view.paint(this.style.base?.all);
        view.paint('background', this.style.base?.background);
        view.paint('body', this.style.base?.body);
        view.paint('overlay', this.style.base?.overlay);
        view.paint('border', this.style.base?.border);
        view.paint('body', {
            text: this.text.content,
            textCursorIndex: this.text.cursorIndex,
        });
    }

    highlightHovered(view: View) {
        view.paint(this.style.hovered?.all);
        view.paint('background', this.style.hovered?.background);
        view.paint('body', this.style.hovered?.body);
        view.paint('overlay', this.style.hovered?.overlay);
        view.paint('border', this.style.hovered?.border);
    }

    highlightFocused(view: View) {
        view.paint(this.style.focused?.all);
        view.paint('background', this.style.focused?.background);
        view.paint('body', this.style.focused?.body);
        view.paint('overlay', this.style.focused?.overlay);
        view.paint('border', this.style.focused?.border);
    }

    highlightDisabled(view: View) {
        view.paint(this.style.disabled?.all);
        view.paint('background', this.style.disabled?.background);
        view.paint('body', this.style.disabled?.body);
        view.paint('overlay', this.style.disabled?.overlay);
        view.paint('border', this.style.disabled?.border);
    }

    isEnabled(): boolean {
        return this.isEnabledCallback?.() ?? true;
    }
}
globalThis.ALL_FUNCTIONS.push(Widget);