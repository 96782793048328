// @ts-ignore
import Worker from './pdf-generation.worker.ts';
import { CardImageData, generateCardImages } from './card-drawing';
import { App } from './components/app';

export async function onExportAsPdf() {
    let projectName = App.instance.state.project?.title ?? 'untitled';

    let cardImages = await App.instance.runAction('Generating high resolution cards', () => generateCardImages(App.instance.project!, {
        imageLoader: App.instance.assetLoader,
        pdfQuality: true
    }));

    if (!cardImages.length) {
        alert('Nothing to export.');
        return;
    }

    await App.instance.runAction('Exporting PDF', () => exportPdf(cardImages, projectName));
}

async function exportPdf(cardImages: CardImageData[], projectName: string) {
    let bytes = await generatePdfInWorker(cardImages);
    let blob = new Blob([bytes], { type: 'application/pdf' });
    let blobUrl = URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.href = blobUrl;
    link.download = `${projectName || 'cards'}.pdf`;

    link.dispatchEvent(new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
    }));
}

async function generatePdfInWorker(cardImages: CardImageData[]): Promise<Uint8Array> {
    return new Promise(resolve => {
        let worker = new Worker();
        let imagesToExport = cardImages.map((card) => ({ quantity: card.quantity, dataUrl: card.canvas.toDataURL() }));
        worker.postMessage(imagesToExport);
        worker.onmessage = (evt: MessageEvent) => resolve(evt.data);
    });
}