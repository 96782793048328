import { removeItem } from './index.ts';
import { getOrInsertWith } from './utils/language/map.ts';

declare global {
    interface Array<T> {
        remove(item: T): boolean;
    }

    interface Map<K, V> {
        getOrInsertWith(key: K, insertCallback: (key: K) => V): V;
    }
}

Array.prototype.remove = function (item) {
    return removeItem(this, item);
};

Map.prototype.getOrInsertWith = function (key, insertCallback) {
    let value = this.get(key);

    if (value === undefined) {
        value = insertCallback(key);
        this.set(key, value);
    }

    return value;
};