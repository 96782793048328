export type PromiseWithResolvers<T = void> = {
    promise: Promise<T>;
    resolve: (value: T) => void;
    reject: (error: unknown) => void;
};

export function makePromise<T = void>(): PromiseWithResolvers<T> {
    let resolve!: PromiseWithResolvers<T>['resolve'];
    let reject!: PromiseWithResolvers<T>['reject'];
    let promise = new Promise<T>((resolveCb, rejectCb) => {
        resolve = resolveCb;
        reject = rejectCb;
    });

    return { promise, resolve, reject };
}

export type PromiseWrapper = {
    promise: Promise<unknown>;
    isSettled: boolean;
};

const DEFAULT_PROMISE = Promise.resolve();

export function wrapPromise(promise: Promise<unknown> = DEFAULT_PROMISE): PromiseWrapper {
    let isSettled = promise === DEFAULT_PROMISE;
    let wrapper: PromiseWrapper = { promise, isSettled };

    if (!isSettled) {
        promise.finally(() => wrapper.isSettled = true);
    }

    return wrapper;
}