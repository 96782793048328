export const SPREADSHEET_URL_STORAGE_KEY = 'spreadsheet-url';
export const PROJECT_STOARGE_KEY = 'project-data';
export const HASH_STOARGE_KEY = 'project-hash';
export const DECK_1_STORAGE_KEY = 'deck-1';
export const DECK_2_STORAGE_KEY = 'deck-2';
export const GAME_ID_PARAM_NAME = 'gameId';

export const IMAGES_STORE_NAME = 'images';

export const OUTPOST_SERVER_URL = `https://api.symil.dev`;
export const OUTPOST_EMAIL = 'viewer@outpost-423018.iam.gserviceaccount.com';

export const QUANTITY_ATTRIBUTE_NAME = '#';
export const DECK_ATTRIBUTE_NAME = 'deck';
export const DIRECTIVES_SHEET_NAME = '#directives';

export const INCH_TO_POINT = 72;
export const PDF_DPI = 300;

export const CARD_WIDTH_INCHES = 2.5;
export const CARD_HEIGHT_INCHES = 3.5;
export const CARD_ASPECT_RATIO = CARD_WIDTH_INCHES / CARD_HEIGHT_INCHES;
export const PDF_CARD_WIDTH_PIXELS = CARD_WIDTH_INCHES * PDF_DPI;
export const PDF_CARD_HEIGHT_PIXELS = CARD_HEIGHT_INCHES * PDF_DPI;
export const CARD_WIDTH_POINTS = CARD_WIDTH_INCHES * INCH_TO_POINT;
export const CARD_HEIGHT_POINTS = CARD_HEIGHT_INCHES * INCH_TO_POINT;

export const PDF_PAGE_WIDTH_POINTS = 595;
export const PDF_PAGE_HEIGHT_POINTS = 842;

export const PDF_CARDS_PER_LINE = 3;
export const PDF_CARDS_PER_COLUMN = 3;
export const PDF_CARDS_PER_PAGE = PDF_CARDS_PER_LINE * PDF_CARDS_PER_COLUMN;

export const DEFAULT_TEMPLATE_FONTS = {
    montserrat: 'assets/fonts/montserrat.ttf',
};

export const BUILTIN_FONTS = <const>['arial'];

export type TemplateFont = keyof typeof DEFAULT_TEMPLATE_FONTS | typeof BUILTIN_FONTS[number];

export const DEFAULT_TEMPLATE_WIDTH = 1000;
export const DEFAULT_TEMPLATE_HEIGHT = 1400;

export const PREVIEW_CARD_WIDTH_PIXELS = 400;
export const PREVIEW_CARD_HEIGHT_PIXELS = PREVIEW_CARD_WIDTH_PIXELS / CARD_ASPECT_RATIO;