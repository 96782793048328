import { isFunctionBodyEmpty } from '../../utils/language/function.ts';
import { ParametersExceptFirst } from '../../utils/language/types.ts';
import { ComponentHookMethodName, RoomHookMethodName } from '../component/component-types.ts';
import { AggregatedServerData } from './room-manager-types.ts';
import { RoomWrapper } from './room-wrapper.ts';

export const GLOBAL_HOOK_METHOD_NAMES = <const>[
    'onOtherRoomCreated',
    'onOtherRoomDeleted',
    'onPlayerAddedToOtherRoom',
    'onPlayerRemovedFromOtherRoom',
] satisfies RoomHookMethodName[];

export type GlobalHookmethodName = typeof GLOBAL_HOOK_METHOD_NAMES[number];

export class RoomGlobalHookManager {
    private enabled: boolean = true;
    private rooms: { [Key in GlobalHookmethodName]: Set<RoomWrapper> } = {
        onOtherRoomCreated: new Set(),
        onOtherRoomDeleted: new Set(),
        onPlayerAddedToOtherRoom: new Set(),
        onPlayerRemovedFromOtherRoom: new Set(),
    };

    setEnabled(value: boolean) {
        this.enabled = value;
    }

    notifyRoomCreated(roomWrapper: RoomWrapper) {
        if (!this.enabled) {
            return;
        }

        for (let methodName of GLOBAL_HOOK_METHOD_NAMES) {
            if (typeof roomWrapper.room[methodName] === 'function' && !isFunctionBodyEmpty(roomWrapper.room[methodName]!)) {
                this.rooms[methodName].add(roomWrapper);
            }
        }
    }

    notifyRoomDeleted(roomWrapper: RoomWrapper) {
        if (!this.enabled) {
            return;
        }

        for (let methodName of GLOBAL_HOOK_METHOD_NAMES) {
            this.rooms[methodName].delete(roomWrapper);
        }
    }

    triggerHook<K extends GlobalHookmethodName>(
        aggregatedServerData: AggregatedServerData | null,
        methodName: K,
        sourceRoomId: string,
        sourcePlayerId: string | null
    ): RoomWrapper[] {
        let result: RoomWrapper[] = [];

        for (let roomWrapper of this.rooms[methodName]) {
            if (roomWrapper.roomId !== sourceRoomId && (!sourcePlayerId || roomWrapper.players.has(sourcePlayerId))) {
                roomWrapper.runRoomHookMethod(aggregatedServerData, methodName, sourceRoomId, sourcePlayerId);

                result.push(roomWrapper);
            }
        }

        return result;
    }

    clear() {
        for (let methodName of GLOBAL_HOOK_METHOD_NAMES) {
            this.rooms[methodName].clear();
        }
    }
}
globalThis.ALL_FUNCTIONS.push(RoomGlobalHookManager);