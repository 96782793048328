import { WebSocketServer } from 'ws';
import { MaybeAsync } from '../language/async.ts';
import { DEFAULT_WEBSOCKET_UPGRADE_PATH } from './network-constants.ts';
import { PromiseWithResolvers, makePromise } from '../language/promise.ts';
import { FlexBuffer } from '../serialization/flex-buffer.ts';

export type ConnectionParams<T> = {
    webSocket: WebSocket;
    onMessage: (connection: Connection<T>, data: ArrayBuffer) => MaybeAsync<any>;
    onConnect: (connection: Connection<T>) => void;
    onDisconnect: (connection: Connection<T>) => void;
    expectAnswer: boolean;
    sendAnswer: boolean;
};

export type ConnectionMessage = 
    | { messageId: number; payload: any }
    | { messageId: number, response: any }

export class Connection<T = void> {
    data: T = undefined as any;
    private webSocket: WebSocket;
    private onMessageCallback: (connection: Connection<T>, data: ArrayBuffer) => MaybeAsync<any>;
    private onConnectCallback: (connection: Connection<T>) => void;
    private onDisconnectCallback: (connection: Connection<T>) => void;
    private expectAnswer: boolean;
    private sendAnswer: boolean;
    private messageIdCounter: number = 1;
    private messagePromiseResolves: Map<number, (value: any) => void> = new Map();
    private onReady: PromiseWithResolvers<this> = makePromise();
    private isReady: boolean = false;

    constructor(params: ConnectionParams<T>) {
        this.webSocket = params.webSocket;
        this.onMessageCallback = params.onMessage;
        this.onConnectCallback = params.onConnect;
        this.onDisconnectCallback = params.onDisconnect;
        this.expectAnswer = params.expectAnswer;
        this.sendAnswer = params.sendAnswer;

        this.webSocket.binaryType = 'arraybuffer';
        this.webSocket.addEventListener('message', (event) => this.onMessage(event));
        this.webSocket.addEventListener("close", () => this.onDisconnect());

        if (this.webSocket.readyState === this.webSocket.OPEN) {
            setTimeout(() => this.onConnect(), 0);
        } else {
            this.webSocket.addEventListener("open", () => this.onConnect());
        }
    }

    shouldSerialize(): boolean {
        return false;
    }

    close() {
        this.webSocket.close();
    }

    async waitUntilReady(): Promise<this> {
        return this.onReady.promise;
    }

    private onConnect() {
        this.onConnectCallback(this);
        this.onReady.resolve(this);
        this.isReady = true;
    }

    private onDisconnect() {
        this.onDisconnectCallback(this);
    }

    private onMessage(event: MessageEvent<any>) {
        this.onMessageCallback(this, event.data);
        // let message: ConnectionMessage = this.deserializer.deserialize(event.data);

        // if ('payload' in message) {
        //     let result = this.onMessageCallback(this, message.payload);

        //     if (this.sendAnswer) {
        //         Promise.resolve(result).then(value => {
        //             let buffer = this.serializer.serialize({ messageId: message.messageId, response: value });

        //             this.webSocket.send(buffer);
        //         });
        //     }
        // } else {
        //     // answer received
        //     let resolve = this.messagePromiseResolves.get(message.messageId);

        //     if (resolve) {
        //         this.messagePromiseResolves.delete(message.messageId);
        //         resolve(message.response);
        //     }
        // }
    }

    sendMessage(buffer: FlexBuffer, delay = false) {
        let data = buffer.copyBytes();

        // The ArrayBuffer is not processed synchronously, so the sender needs to make sure it's not modified anywhere
        if (this.isReady && !delay) {
            this.webSocket.send(data);
        } else {
            this.onReady.promise.then(() => this.webSocket.send(data));
        }
        // let messageId = this.messageIdCounter++;
        // let message: ConnectionMessage = { messageId, payload };
        // let buffer = this.serializer.serialize(message);

        // await this.readyPromise;

        // if (this.expectAnswer) {
        //     return new Promise(resolve => {
        //         this.messagePromiseResolves.set(messageId, resolve);
        //         this.webSocket.send(buffer);
        //     });
        // } else {
        //     this.webSocket.send(buffer);

        //     return Promise.resolve(undefined as any);
        // }
    }
}

export type ServerInitParameters<T> = {
    webSocketServer: WebSocketServer,
    onMessage?: (connection: Connection<T>, data: ArrayBuffer) => void;
    onConnect?: (connection: Connection<T>) => void;
    onDisconnect?: (connection: Connection<T>) => void;
};

export function initWebSocketServer<T = void>(params: ServerInitParameters<T>) {
    let webSocketServer = params.webSocketServer;
    let onConnect = params.onConnect ?? (() => {});
    let onDisconnect = params.onDisconnect ?? (() => {});
    let onMessage = params.onMessage ?? (() => {});

    webSocketServer.on('connection', (webSocket: WebSocket) => {
        new Connection({
            webSocket,
            expectAnswer: false,
            sendAnswer: true,
            onMessage,
            onConnect,
            onDisconnect
        });
    });
}

export type ClientConnectionParameters = {
    url?: string,
    upgradePath?: string,
    onMessage?: (connection: Connection, buffer: ArrayBuffer) => void;
    onConnect?: (connection: Connection) => void,
    onDisconnect?: (connection: Connection) => void,
};

export function connectToServer(params: ClientConnectionParameters): Connection {
    let upgradePath = params.upgradePath ?? DEFAULT_WEBSOCKET_UPGRADE_PATH;
    let url = params.url ?? computeWebSocketUrl(upgradePath);
    let webSocket = new WebSocket(url);
    let expectAnswer = true;
    let sendAnswer = false;
    let onMessage = (params.onMessage ?? (() => {}));
    let onConnect = params.onConnect ?? (() => {});
    let onDisconnect = params.onDisconnect ?? (() => {});

    return new Connection({
        webSocket,
        expectAnswer,
        sendAnswer,
        onMessage,
        onConnect,
        onDisconnect
    });
}

function computeWebSocketUrl(webSocketUpgradePath: string): string {
    let protocol = 'ws';
    let port = window.location.port || 80;
    let hostname = window.location.hostname;

    if (hostname === 'localhost') {
        port = (window as any).OUTPOST_APP_PORT;
    }

    if (window.location.protocol === 'https:') {
        protocol = 'wss';
        port = 443;
    }

    return `${protocol}://${hostname}:${port}${webSocketUpgradePath}`;
}
globalThis.ALL_FUNCTIONS.push(Connection);