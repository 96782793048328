import { collectionToArray } from '../language/collection.ts';
import { GameEvent, GameEventCallbackOutput, GameEventLike } from './game-event.ts';

class GameEventQueueEntry {
    event: GameEvent;
    totalElapsed: number = 0;
    onBeforeStartDone: boolean = false;
    onStartDone: boolean = false;
    onResolveDone: boolean = false;
    onEndDone: boolean = false;
    onAfterEndDone: boolean = false;
    started: boolean = false;

    constructor(event: GameEventLike) {
        this.event = GameEvent.from(event);
    }
}

export class GameEventQueue {
    chain: GameEventQueueEntry[] = [];
    currentIndex: number = -1;
    insertIndex: number = 0;

    private reset() {
        this.chain = [];
        this.currentIndex = -1;
        this.insertIndex = 0;
    }

    add(event: GameEventLike) {
        let entry = new GameEventQueueEntry(event);

        this.chain.splice(this.insertIndex, 0, entry);
        this.insertIndex += 1;

        if (this.currentIndex === -1) {
            this.currentIndex = 0;
        }
    }

    append(event: GameEventLike): GameEvent {
        let entry = new GameEventQueueEntry(event);

        this.chain.push(entry);

        if (this.currentIndex === -1) {
            this.currentIndex = 0;
            this.insertIndex = 1;
        }

        return entry.event;
    }

    wait(duration: number) {
        this.add({
            onProgress: (_elapsed: number, totalElapsed: number) => {
                return totalElapsed - duration;
            }
        });
    }

    isEmpty(): boolean {
        return this.chain.length === 0;
    }

    next(elapsed: number): number {
        this.currentIndex += 1;

        return this.trigger(elapsed);
    }

    private addChildEvents(elapsed: number, callback: () => GameEventCallbackOutput): number {
        this.insertIndex = this.currentIndex;

        let eventList = collectionToArray(callback() ?? undefined);

        for (let event of eventList) {
            this.add(event);
        }

        return this.trigger(elapsed);
    }

    trigger(elapsed: number = 0): number {
        let entry = this.chain[this.currentIndex];

        if (!entry) {
            this.reset();

            return elapsed;
        }

        let event = entry.event;

        if (!entry.onBeforeStartDone) {
            entry.onBeforeStartDone = true;

            return this.addChildEvents(elapsed, () => event.onBeforeStart());
        }

        if (!entry.onStartDone) {
            entry.onStartDone = true;

            return this.addChildEvents(elapsed, () => event.onStart());
        }

        // if (event.isCanceled()) {
        //     return this.next(elapsed);
        // }

        if (!entry.onResolveDone) {
            this.insertIndex = this.currentIndex + 1;

            entry.totalElapsed += elapsed;
            elapsed = event.onProgress(elapsed, entry.totalElapsed) ?? elapsed;

            if (elapsed < 0) {
                return elapsed;
            }

            entry.onResolveDone = true;

            return this.addChildEvents(elapsed, () => event.onResolve());
        }

        if (!entry.onEndDone) {
            entry.onEndDone = true;

            return this.addChildEvents(elapsed, () => event.onEnd());
        }

        if (!entry.onAfterEndDone) {
            entry.onAfterEndDone = true;

            return this.addChildEvents(elapsed, () => event.onAfterEnd());
        }
        
        return this.next(elapsed);
    }
}
globalThis.ALL_FUNCTIONS.push(GameEventQueueEntry);
globalThis.ALL_FUNCTIONS.push(GameEventQueue);