import { partitionArray } from '../../utils/language/array.ts';
import { MatchmakingEntry } from './matchmaking-entry.ts';

export type MatchmakingParams = {
    teamCount: number;
    teamSize: number;
}

export class MatchmakingManager<T> {
    private entries: MatchmakingEntry<T>[] = [];
    private teamCount: number;
    private teamSize: number;

    constructor(params: MatchmakingParams) {
        this.teamCount = params.teamCount;
        this.teamSize = params.teamSize;
    }

    addPlayer(player: T): boolean {
        this.addPlayerList([player]);

        return true;
    }

    removePlayer(player: T): boolean {
        for (let entry of this.entries) {
            if (entry.removePlayer(player)) {
                return true;
            }
        }

        return false;
    }

    addPlayerList(players: T[]) {
        for (let entry of this.entries) {
            if (entry.getAvailableSlotCount() >= players.length) {
                for (let player of players) {
                    entry.addPlayer(player);
                }

                return;
            }
        }

        let entry = new MatchmakingEntry<T>(this.teamCount, this.teamSize);

        for (let player of players) {
            entry.addPlayer(player);
        }

        this.entries.push(entry);
    }

    getEntries(): (T | null)[][][] {
        return this.entries.map(entry => entry.getTeams());
    }

    extractMatches(): T[][][] {
        let [fullEntries, notFullEntries] = partitionArray(this.entries, entry => entry.getAvailableSlotCount() === 0);

        this.entries = notFullEntries;

        return fullEntries.map(entry => entry.getTeams() as T[][]);
    }
}
globalThis.ALL_FUNCTIONS.push(MatchmakingManager);