import { collectionToArray } from '../../utils/language/collection.ts';
import { Graphics } from '../graphics-engine/graphics/graphics.ts';
import { View } from '../view/view.ts';
import { Component, ComponentLike } from './component.ts';

export class DummyComponent implements Component {
    private content: ((view: View) => void) | Graphics[] | null = null;

    render(view: View): void {
        if (typeof this.content === 'function') {
            this.content(view);
        } else if (this.content) {
            for (let graphics of this.content) {
                view.paint(graphics);
            }
        }
    }

    setContent(content: Exclude<ComponentLike, Component> = null): this {
        if (!content || typeof content === 'function') {
            this.content = content;
        } else {
            this.content = collectionToArray(content);
        }

        return this;
    }
}

export function formatComponent(
    componentLike: ComponentLike | undefined,
    getDummyComponent: () => DummyComponent = () => new DummyComponent(),
): Component {
    if (componentLike && 'render' in componentLike) {
        return componentLike;
    } else {
        return getDummyComponent().setContent(componentLike);
    }
}

export function formatOptionalComponent(
    componentLike: ComponentLike | undefined,
    getDummyComponent?: () => DummyComponent
): Component | null {
    return componentLike ? formatComponent(componentLike, getDummyComponent) : null;
}
globalThis.ALL_FUNCTIONS.push(DummyComponent);