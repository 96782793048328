import { Counter } from '../data-structures/counter.ts';
import { SimpleMemoryAllocator } from '../memory/simple-memory-allocator.ts';

export const INITIAL_DATA_TEXTURE_SIZE = 32;
export const MAX_DATA_TEXTURE_SIZE = 4096;

export type WebglDataTextureParams = {
    atomicAllocationSize: number;
}

export class WebglDataTexture {
    private gl: WebGL2RenderingContext;
    private atomicAllocationSize: number;
    private width: number = 0;
    private height: number = 0;
    private indexCounter: Counter = new Counter(1);
    private data: Float32Array = new Float32Array();
    private mustSyncWithGpu: boolean = false;
    private texture: WebGLTexture | null = null;
    private memoryAllocator: SimpleMemoryAllocator;

    constructor(gl: WebGL2RenderingContext, params: WebglDataTextureParams) {
        this.gl = gl;
        this.atomicAllocationSize = params.atomicAllocationSize;
        this.memoryAllocator = new SimpleMemoryAllocator({
            totalMemorySize: Math.floor(MAX_DATA_TEXTURE_SIZE ** 2 / params.atomicAllocationSize)
        });
    }

    allocate(size: number): number {
        let atomicAddress = this.memoryAllocator.allocate(Math.ceil(size / this.atomicAllocationSize));

        if (atomicAddress === null) {
            console.warn('cannot allocate memory from data texture');
            return 0;
        }

        let address = atomicAddress * this.atomicAllocationSize;

        this.resizeIfNecessary(address);
        this.mustSyncWithGpu = true;

        return address;
    }

    deallocate(address: number): number {
        if (address === 0) {
            return 0;
        }

        let atomicAddress = address / this.atomicAllocationSize;

        this.memoryAllocator.dealllocate(atomicAddress);

        return 0;
    }

    setValue(address: number, value: number) {
        this.data[address] = value;
    }

    private resizeIfNecessary(address: number) {
        if (address >= this.data.length) {
            // TODO: handle max texture size?
            if (this.width === 0) {
                this.width = INITIAL_DATA_TEXTURE_SIZE;
                this.height = INITIAL_DATA_TEXTURE_SIZE;
            } else if (this.width === this.height) {
                this.width *= 2;
            } else {
                this.height *= 2;
            }

            let newData = new Float32Array(this.width * this.height);
            newData.set(this.data);
            this.data = newData;
        }
    }

    clear() {
        this.indexCounter.reset();
    }

    syncWithGpu() {
        if (!this.mustSyncWithGpu && this.texture) {
            return;
        }

        // console.log('sync: ' + this.width * this.height)

        if (!this.texture) {
            this.initTexture();
        }

        let gl = this.gl;

        gl.bindTexture(gl.TEXTURE_2D, this.texture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.R32F, this.width, this.height, 0, gl.RED, gl.FLOAT, this.data);

        this.mustSyncWithGpu = false;
    }

    bindGlTexture(index: number): number {
        let gl = this.gl;

        gl.activeTexture(gl.TEXTURE0 + index);
        gl.bindTexture(gl.TEXTURE_2D, this.texture);

        return index;
    }

    private initTexture() {
        let gl = this.gl;

        this.texture = gl.createTexture();

        gl.bindTexture(gl.TEXTURE_2D, this.texture);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
    }
}
globalThis.ALL_FUNCTIONS.push(WebglDataTexture);