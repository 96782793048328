export class WebglIndexBuffer {
    private gl: WebGL2RenderingContext;
    private glBuffer: WebGLBuffer | null = null;
    private uint16Array: Uint16Array = new Uint16Array(4);
    private uint32Array: Uint32Array = new Uint32Array(4);

    constructor(gl: WebGL2RenderingContext) {
        this.gl = gl;
    }

    load(indexes: number[] | Uint32Array): number {
        let gl = this.gl;
        let indexCount = indexes.length;
        let use16Bits = indexCount < 65536;
        let typedArray = use16Bits ? this.uint16Array : this.uint32Array;
        let shouldResize = false;

        if (typedArray.length < indexCount) {
            let newLength = typedArray.length;
            shouldResize = true;

            while (newLength < indexCount) {
                newLength *= 2;
            }

            if (use16Bits) {
                this.uint16Array = new Uint16Array(newLength);
                typedArray = this.uint16Array;
            } else {
                this.uint32Array = new Uint32Array(newLength);
                typedArray = this.uint32Array;
            }
        }

        typedArray.set(indexes);

        if (!this.glBuffer) {
            this.glBuffer = gl.createBuffer()!;
        }

        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.glBuffer);

        if (shouldResize) {
            gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, typedArray, gl.DYNAMIC_DRAW);
        } else {
            gl.bufferSubData(gl.ELEMENT_ARRAY_BUFFER, 0, typedArray);
        }

        return use16Bits ? gl.UNSIGNED_SHORT : gl.UNSIGNED_INT;
    }
}
globalThis.ALL_FUNCTIONS.push(WebglIndexBuffer);