const POINTER_SIZE = 2;

export type BufferPoolParams = {
    slotByteSize: number;
    slotCountPerBuffer: number;
}

export type BufferPoolMemorySlot = {
    buffer: ArrayBuffer;
    bufferIndex: number;
    byteOffset: number;
    byteSize: number;
}

type BufferEntry = {
    buffer: ArrayBuffer;
    uint32Array: Uint32Array;
}

export class BufferPool {
    private slotByteSize: number;
    private slotCountPerBuffer: number;
    private bufferByteSize: number;
    private bufferEntries: BufferEntry[] = [];
    private pointerStack: Uint32Array = new Uint32Array(0);
    private pointerStackIndex: number = -POINTER_SIZE;

    constructor(params: BufferPoolParams) {
        this.slotByteSize = params.slotByteSize;
        this.slotCountPerBuffer = params.slotCountPerBuffer;
        this.bufferByteSize = Math.ceil(this.slotByteSize * this.slotCountPerBuffer / 4) * 4;
    }

    allocateSlot(): BufferPoolMemorySlot {
        if (this.pointerStackIndex < 0) {
            let buffer = new ArrayBuffer(this.bufferByteSize);
            let uint32Array = new Uint32Array(buffer);
            let bufferIndex = this.bufferEntries.length;
            let pointerStack = new Uint32Array((this.bufferEntries.length + 1) * this.slotCountPerBuffer * POINTER_SIZE);
            let bufferEntry: BufferEntry = { buffer, uint32Array };

            for (let i = 0; i < this.slotCountPerBuffer; ++i) {
                let index = i * 2;

                pointerStack[index] = bufferIndex;
                pointerStack[index + 1] = (this.slotCountPerBuffer - i - 1) * this.slotByteSize;
            }

            this.bufferEntries.push(bufferEntry);
            this.pointerStack = pointerStack;
            this.pointerStackIndex = (this.slotCountPerBuffer - 1) * POINTER_SIZE;
        }

        let bufferIndex = this.pointerStack[this.pointerStackIndex];
        let byteOffset = this.pointerStack[this.pointerStackIndex + 1];
        let buffer = this.bufferEntries[bufferIndex].buffer;
        let byteSize = this.slotByteSize;

        this.pointerStackIndex -= POINTER_SIZE;

        return { bufferIndex, buffer, byteOffset, byteSize }

    }

    freeSlot(slot: BufferPoolMemorySlot) {
        this.pointerStackIndex += POINTER_SIZE;
        this.pointerStack[this.pointerStackIndex] = slot.bufferIndex;
        this.pointerStack[this.pointerStackIndex + 1] = slot.byteOffset;
    }

    reset() {
        for (let { uint32Array } of this.bufferEntries) {
            uint32Array.fill(0);
        }
    }
}
globalThis.ALL_FUNCTIONS.push(BufferPool);