import { Graphics } from '../graphics-engine/graphics/graphics.ts';
import { GraphicsTransform } from '../graphics-engine/graphics/graphics-transform.ts';
import { MAIN_ATOM_KEY, ViewFragmentPriority } from './view-types.ts';
import { View } from './view.ts';

export class ViewFragment {
    priority: number;
    atoms: { [key: string]: Graphics | undefined; } = {};
    isEmpty: boolean = true;
    children: View[] = [];
    birthTime: number = 0;
    deathTime: number = Infinity;
    duration: number = 0;
    delay: number = 0;
    linger: number = 0;
    isEnabled: boolean = false;
    autoRefresh: boolean = false;
    transform: GraphicsTransform | null = null;
    next: ViewFragment | null = null;
    lingerFrame: boolean = false;

    constructor(priority: number) {
        this.priority = priority;
    }

    setNext(fragment: ViewFragment) {
        this.next = fragment;
    }

    addGraphics(key: string, graphics: Graphics, unsafeOpti: boolean) {
        this.isEmpty = false;

        if (unsafeOpti) {
            this.atoms[key] = graphics;
            return;
        }

        let atom = this.atoms[key];

        if (!atom) {
            atom = {};
            this.atoms[key] = atom;
        }

        for (let key in graphics) {
            // TODO: this is problematic when assigning a mutable object (e.g "Rect"), because it doesn't perform a deep copy
            (atom as any)[key] = graphics[key as keyof Graphics];
        }
    }

    getGraphics(key: string): Graphics | undefined {
        return this.atoms[key];
    }

    addChild(view: View) {
        this.children.push(view);
    }

    isModifier(): boolean {
        return this.priority >= ViewFragmentPriority.Interaction;
    }

    computeGlobalAttributes(startTime: number, animationEnabled: boolean) {
        let mainGraphics = this.atoms[MAIN_ATOM_KEY];

        if (!mainGraphics && !this.isEmpty) {
            for (let key in this.atoms) {
                let atom = this.atoms[key];

                if (atom) {
                    mainGraphics = atom;
                    break;
                }
            }
        }

        if (mainGraphics) {
            if (mainGraphics.autoRefresh !== undefined) {
                this.autoRefresh = mainGraphics.autoRefresh;
            }

            if (mainGraphics.transformX !== undefined) {
                this.withTransform().x = mainGraphics.transformX;
            }

            if (mainGraphics.transformY !== undefined) {
                this.withTransform().y = mainGraphics.transformY;
            }

            if (mainGraphics.transformScale !== undefined) {
                this.withTransform().scale = mainGraphics.transformScale;
            }

            if (mainGraphics.duration !== undefined) {
                this.duration = mainGraphics.duration;
            }

            if (mainGraphics.delay !== undefined) {
                this.delay = mainGraphics.delay;
            }

            if (mainGraphics.linger !== undefined) {
                this.linger = mainGraphics.linger;
            }
        }

        if (animationEnabled) {
            this.birthTime = startTime + this.delay;
            this.deathTime = this.duration > 0 ? this.birthTime + this.duration + this.linger : Infinity;

            if (this.transform) {
                this.transform.startTime = this.birthTime;
                this.transform.duration = this.duration;
            }
        }
    }

    private withTransform(): GraphicsTransform {
        if (!this.transform) {
            this.transform = new GraphicsTransform();
        }

        return this.transform;
    }

    destroy(): ViewFragment | null {
        let next = this.next;

        for (let atomKey in this.atoms) {
            let atom = this.atoms[atomKey]!;

            for (let graphicsKey in atom) {
                (atom as any)[graphicsKey] = undefined
            }
        }

        this.isEmpty = true;
        this.children.length = 0;
        this.birthTime = 0;
        this.deathTime = Infinity;
        this.duration = 0;
        this.delay = 0;
        this.linger = 0;
        this.isEnabled = false;
        this.autoRefresh = false;
        this.transform = null;
        this.next = null;
        this.lingerFrame = false;

        return next;
    }

    consumeLingerFrame(): boolean {
        if (this.lingerFrame) {
            this.lingerFrame = false;

            return true;
        } else {
            return false;
        }
    }
}
globalThis.ALL_FUNCTIONS.push(ViewFragment);