export function requireValue<T>(value: T | undefined, errorMessage: string): T {
    if (!value) {
        throw new Error(errorMessage);
    }

    return value;
}

export class GracefulAbort {
    message?: string;

    constructor(message?: string) {
        this.message = message;
    }
}
globalThis.ALL_FUNCTIONS.push(GracefulAbort);