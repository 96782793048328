import hash from 'object-hash';
import { IconMapping } from './text-rendering';
import { CardTemplate, TemplateInference, TextReplacements, CardData, CardId, DeckData, DeckId } from './project-types';
import { normalizeString } from './utils';

export class Project {
    spreadsheetId: string;
    title: string;
    templates: { [name: string]: CardTemplate; } = {};
    templateInferences: TemplateInference[] = [];
    icons: IconMapping = {};
    replacements: TextReplacements = {};
    cards: CardData[] = [];
    decks: DeckData[] = [];

    constructor(spreaadsheetId: string, title: string) {
        this.spreadsheetId = spreaadsheetId;
        this.title = title;
    }

    getCardImages(): HTMLCanvasElement[] {
        return this.cards.map(card => card.image).filter(image => !!image);
    }

    getCardsWithImage(): (CardData & { imageId: string; image: HTMLCanvasElement; })[] {
        return this.cards.filter(card => !!card.imageId) as (CardData & { imageId: string; image: HTMLCanvasElement; })[];
    }

    getCardById(cardId: CardId): CardData | undefined {
        return this.cards.find(card => card.id === cardId);
    }

    getDeckById(deckId: DeckId): DeckData | undefined {
        return this.decks.find(deck => deck.id === deckId);
    }

    getOrCreateDeck(deckName: string) {
        let deckId = normalizeString(deckName);
        let deck = this.getDeckById(deckId);

        if (!deck) {
            deck = {
                id: deckId,
                name: deckName,
                cardIds: [],
            };
            this.decks.push(deck);
        }

        return deck;
    }

    getHash(): string {
        return hash(this, {
            respectType: false,
            replacer: (value: any) => {
                if (value && value instanceof Element) {
                    return null;
                } else {
                    return value;
                }
            }
        });
    }
}
globalThis.ALL_FUNCTIONS.push(Project);