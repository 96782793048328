import { Layout } from '../layout/layout.ts';
import { Rect } from './rect.ts';

export class RectLayout<T extends string> extends Layout<T, null> {
    finish(): Partial<{ [Key in T]: Rect }> {
        let result = {} as any;
        let fragments = this.compute();

        for (let { object, rect } of fragments) {
            if (object && rect) {
                result[object] = rect;
            }
        }

        return result;
    }
}
globalThis.ALL_FUNCTIONS.push(RectLayout);