import { PNG } from 'pngjs';
import { Color, ColorLike } from '../color/color.ts';
import { RectLike, Rect } from '../geometry/rect.ts';
import { Writeable } from '../language/object.ts';
import { ColorComparator, ColorComparatorLike } from './color-comparator.ts';
import { readFileSync } from 'fs';

export type PixelGridParams = {
    width: number;
    height: number;
    data?: Uint8Array;
}

export class PixelGrid {
    width: number;
    height: number;
    data: Uint8Array;

    private pixel: Color;

    constructor(params?: PixelGridParams) {
        this.width = params?.width ?? 0;
        this.height = params?.height ?? 0;
        this.data = new Uint8Array(this.width * this.height * 4);
        this.pixel = new Color(0, 0, 0, 0);

        if (params?.data) {
            this.data.set(params?.data);
        }
    }

    static loadFromFile(filePath: string) {
        let content = readFileSync(filePath, null);
        let image = PNG.sync.read(content);

        return new PixelGrid({
            width: image.width,
            height: image.height,
            data: image.data
        });
    }

    _get(x: number, y: number): Color {
        let i = (y * this.width + x) * 4;
        let target = this.pixel as Writeable<Color>;

        target.r = this.data[i] / 255;
        target.g = this.data[i + 1] / 255;
        target.b = this.data[i + 2] / 255;
        target.a = this.data[i + 3] / 255;

        return this.pixel;
    }

    get(x: number, y: number): Color {
        return this._get(x, y).clone();
    }

    set(x: number, y: number, color: ColorLike) {
        const i = (y * this.width + x) * 4;

        Color.from(color).toUint8Array(this.data, i);
    }

    cloneEmpty() {
        return new PixelGrid({
            width: this.width,
            height: this.height
        });
    }

    crop(rect: RectLike): PixelGrid {
        let { x1, y1, width, height } = Rect.floor(rect);
        let result = new PixelGrid({ width, height });

        for (let j = 0; j < height; ++j) {
            for (let i = 0; i < width; ++i) {
                result.set(i, j, this._get(x1 + i, y1 + j));
            }
        }

        return result;
    }

    getTrimBoundingBox(backgroundColor: ColorLike, comparator?: ColorComparatorLike): Rect {
        let minX = Infinity;
        let minY = Infinity;
        let maxX = -Infinity;
        let maxY = -Infinity;
        let backgroundColorFmt = Color.from(backgroundColor);
        let comparatorFmt = ColorComparator.from(comparator);

        for (let y = 0; y < this.height; ++y) {
            for (let x = 0; x < this.width; ++x) {
                let color = this._get(x, y);

                if (comparatorFmt.equal(color, backgroundColorFmt)) {
                    minX = Math.min(minX, x);
                    minY = Math.min(minY, y);
                    maxX = Math.max(maxX, x);
                    maxY = Math.max(maxY, y);
                }
            }
        }

        if (minX === Infinity) {
            minX = 0;
            minY = 0;
            maxX = 0;
            maxY = 0;
        }

        return Rect.from({
            x1: minX,
            y1: minY,
            x2: maxX,
            y2: maxY
        });
    }

    trim(backgroundColor: ColorLike, comparator?: ColorComparatorLike): PixelGrid {
        return this.crop(this.getTrimBoundingBox(backgroundColor, comparator));
    }

    forEach(callback: (color: Color, x: number, y: number) => void) {
        for (let y = 0; y < this.height; ++y) {
            for (let x = 0; x < this.width; ++x) {
                let color = this._get(x, y);

                callback(color, x, y);
            }
        }
    }

    map(callback: (color: Color, x: number, y: number) => ColorLike): PixelGrid {
        const result = this.cloneEmpty();

        for (let y = 0; y < this.height; ++y) {
            for (let x = 0; x < this.width; ++x) {
                let color = this._get(x, y);
                let newColor = callback(color, x, y);

                result.set(x, y, newColor);
            }
        }

        return result;
    }

    filter(callback: (color: Color, x: number, y: number) => boolean, emptyColor: ColorLike = Color.black()): PixelGrid {
        let result = this.cloneEmpty();
        let emptyColorFmt = Color.from(emptyColor);

        for (let y = 0; y < this.height; ++y) {
            for (let x = 0; x < this.width; ++x) {
                let color = this._get(x, y);

                if (callback(color, x, y)) {
                    result.set(x, y, color);
                } else {
                    result.set(x, y, emptyColorFmt);
                }
            }
        }

        return result;
    }

    private compare(pixelGrid: PixelGrid, comparator: ColorComparatorLike | undefined, compareFunction: (x: number, y: number, equal: boolean) => void) {
        let image1 = this;
        let image2 = pixelGrid;
        let width = Math.min(image1.width, image2.width);
        let height = Math.min(image1.height, image2.height);
        let cmp = ColorComparator.from(comparator);

        for (let y = 0; y < height; ++y) {
            for (let x = 0; x < width; ++x) {
                let color1 = image1._get(x, y);
                let color2 = image2._get(x, y);
                let equal = cmp.equal(color1, color2);

                compareFunction(x, y, equal);
            }
        }
    }

    merge(pixelGrid: PixelGrid, callback: (color1: Color, color2: Color, x: number, y: number) => ColorLike): PixelGrid {
        let width = Math.min(this.width, pixelGrid.width);
        let height = Math.min(this.height, pixelGrid.height)
        let result = new PixelGrid({ width, height });

        for (let y = 0; y < height; ++y) {
            for (let x = 0; x < width; ++x) {
                let color1 = this._get(x, y);
                let color2 = pixelGrid._get(x, y);
                let mergedColor = callback(color1, color2, x, y);
                
                result.set(x, y, mergedColor);
            }
        }

        return result;
    }

    diff(pixelGrid: PixelGrid, comparator?: ColorComparatorLike) {
        let black = Color.black();
        let white = Color.white();
        let cmp = ColorComparator.from(comparator);

        return this.merge(pixelGrid, (color1, color2) => cmp.equal(color1, color2) ? black : white);
    }

    intersection(pixelGrid: PixelGrid, comparator?: ColorComparatorLike) {
        let transparent = Color.transparent();
        let cmp = ColorComparator.from(comparator);

        return this.merge(pixelGrid, (color1, color2) => cmp.equal(color1, color2) ? color1 : transparent);
    }

    diffBoundingBox(pixelGrid: PixelGrid, comparator?: ColorComparatorLike): Rect {
        let minX = Infinity;
        let minY = Infinity;
        let maxX = -Infinity;
        let maxY = -Infinity;
        let compareFunction = (x: number, y: number, equal: boolean) => {
            if (!equal) {
                minX = Math.min(minX, x);
                minY = Math.min(minY, y);
                maxX = Math.max(maxX, x);
                maxY = Math.max(maxY, y);
            }
        };

        this.compare(pixelGrid, comparator, compareFunction);

        if (minX === Infinity) {
            minX = 0;
            minY = 0;
            maxX = 0;
            maxY = 0;
        }

        return Rect.from({
            x1: minX,
            y1: minY,
            x2: maxX,
            y2: maxY
        });
    }

    // equal(other, accuracy = DEFAULT_ACCURACY) {
    //     if (this.width !== other.width || this.height !== other.height) {
    //         return false;
    //     }

    //     for (let y = 0; y < this.height; ++y) {
    //         for (let x = 0; x < this.width; ++x) {
    //             const p1 = this._get(x, y);
    //             const p2 = other._get(x, y);

    //             if (!Color.equal(p1, p2, accuracy)) {
    //                 return false;
    //             }
    //         }
    //     }

    //     return true;
    // }

    // findLongestRectangle(color, accuracy = DEFAULT_ACCURACY) {
    //     let best = { x: 0, y: 0, width: 0, height: 0 };
    //     let current = null;

    //     for (let y = 0; y < this.height; ++y) {
    //         for (let x = 0; x < this.width; ++x) {
    //             if (Color.equal(this._get(x, y), color, accuracy)) {
    //                 if (!current) {
    //                     current = { x, y, width: 1, height: 1 };
    //                 } else {
    //                     current.width += 1;
    //                 }
    //             } else if (current && current.width > best.width) {
    //                 best = current;
    //                 current = null;
    //             }
    //         }

    //         current = null;
    //     }

    //     let end = false;

    //     for (let y = best.y + 1; y < this.height; ++y) {
    //         for (let x = best.x; x < best.x + best.width; ++x) {
    //             if (!Color.equal(this._get(x, y), color)) {
    //                 end = true;
    //                 break;
    //             }
    //         }

    //         if (!end) {
    //             best.height += 1;
    //         } else {
    //             break;
    //         }
    //     }

    //     if (!best.width || !best.height) {
    //         return null;
    //     } else {
    //         return best;
    //     }
    // }

    // find(image, accuracy) {
    //     return this.findAll(image, accuracy, true)[0] || null;
    // }

    // findAll(image, accuracy = DEFAULT_ACCURACY, stopAtFirst = false) {
    //     if (typeof accuracy === 'number') {
    //         accuracy = [accuracy, accuracy, accuracy];
    //     }

    //     const maxX = this.width - image.width; 
    //     const maxY = this.height - image.height; 
    //     const occurences = [];
    //     const firstPixel = image._get(0, 0).slice();

    //     for (let y = 0; y <= maxY; ++y) {
    //         for (let x = 0; x <= maxX; ++x) {
    //             if (Color.equal(this._get(x, y), firstPixel, accuracy)) {
    //                 let ok = true;

    //                 for (let j = 0; j < image.height && ok; ++j) {
    //                     for (let i = 0; i < image.width; ++i) {
    //                         const p1 = this._get(x + i, y + j);
    //                         const p2 = image._get(i, j);

    //                         if (!Color.equal(p1, p2, accuracy)) {
    //                             ok = false;
    //                             break;
    //                         }
    //                     }
    //                 }

    //                 if (ok) {
    //                     occurences.push({ x, y });

    //                     if (stopAtFirst) {
    //                         return occurences;
    //                     }
    //                 }
    //             }
    //         }
    //     }

    //     return occurences;
    // }

    // getAverageColor() {
    //     let rSum = 0;
    //     let gSum = 0;
    //     let bSum = 0;

    //     for (let y = 0; y < this.height; ++y) {
    //         for (let x = 0; x < this.width; ++x) {
    //             const [r, g, b] = this._get(x, y);

    //             rSum += r;
    //             gSum += g;
    //             bSum += b;
    //         }
    //     }

    //     return [rSum, gSum, bSum].map(n => Math.round(n / (this.width * this.height)));
    // }

    // findAllHorizontalLines(color, minLength = 1, accuracy = DEFAULT_ACCURACY) {
    //     const lines = [];
    //     let currentLine = null;

    //     for (let y = 0; y < this.height; ++y) {
    //         for (let x = 0; x < this.width; ++x) {
    //             const pixel = this._get(x, y);

    //             if (Color.equal(pixel, color, accuracy)) {
    //                 if (!currentLine) {
    //                     currentLine = { x, y, length: 1 };
    //                 } else {
    //                     currentLine.length += 1;
    //                 }
    //             } else if (currentLine) {
    //                 if (currentLine.length >= minLength) {
    //                     lines.push(currentLine);
    //                 }

    //                 currentLine = null;
    //             }
    //         }

    //         if (currentLine) {
    //             if (currentLine.length >= minLength) {
    //                 lines.push(currentLine);
    //             }
    //             currentLine = null;
    //         }
    //     }

    //     return lines;
    // }

    // findHorizontalColorLine(length, color, accuracy, boundingBox) {
    //     const {
    //         topLeft,
    //         bottomRight
    //     } = BoundingBox.format(boundingBox);

    //     let count = 0;

    //     for (let y = topLeft.y; y < bottomRight.y; ++y) {
    //         count = 0;

    //         for (let x = topLeft.x; x < bottomRight.x; ++x) {
    //             const pixel = this._get(x, y);

    //             if (Color.equal(pixel, color, accuracy)) {
    //                 count += 1;

    //                 if (count === length) {
    //                     return {
    //                         x: x - length + 1,
    //                         y: y
    //                     };
    //                 }
    //             } else {
    //                 count = 0;
    //             }
    //         }
    //     }

    //     return null;
    // }

    // findVerticalColorLine(length, color, accuracy, boundingBox) {
    //     const {
    //         topLeft,
    //         bottomRight
    //     } = BoundingBox.format(boundingBox);

    //     let count = 0;

    //     for (let x = topLeft.x; x < bottomRight.x; ++x) {
    //         count = 0;

    //         for (let y = topLeft.y; y < bottomRight.y; ++y) {
    //             const pixel = this._get(x, y);

    //             if (Color.equal(pixel, color, accuracy)) {
    //                 count += 1;

    //                 if (count === length) {
    //                     return {
    //                         x: x,
    //                         y: y - length + 1
    //                     };
    //                 }
    //             } else {
    //                 count = 0;
    //             }
    //         }
    //     }

    //     return null;
    // }

    // markAll(pattern, accuracy = 0, color = RED) {
    //     const occurences = this.findAll(pattern, accuracy);

    //     for (const {x, y} of occurences) {
    //         for (let i = 0; i < pattern.width; ++i) {
    //             for (let j = 0; j < pattern.height; ++j) {
    //                 this.set(x + i, y + j, color);
    //             }
    //         }
    //     }

    //     return this;
    // }

    // findPixel(color, accuracy = DEFAULT_ACCURACY) {
    //     for (let y = 0; y < this.height; ++y) {
    //         for (let x = 0; x < this.width; ++x) {
    //             const pixel = this._get(x, y);

    //             if (Color.equal(pixel, color, accuracy)) {
    //                 return { x, y };
    //             }
    //         }
    //     }

    //     return null;
    // }

    // countPixels(callback) {
    //     let count = 0;

    //     for (let y = 0; y < this.height; ++y) {
    //         for (let x = 0; x < this.width; ++x) {
    //             const pixel = this._get(x, y);

    //             if (callback(pixel, x, y)) {
    //                 count += 1;
    //             }
    //         }
    //     }

    //     return count;
    // }

    // read(glyphs) {
    //     const matches = [];

    //     for (const glyph of glyphs) {
    //         const { image, value } = glyph;
    //         const glyphMatches = this.findAll(image);

    //         for (const {x, y} of glyphMatches) {
    //             matches.push({ value, x, y })
    //         }
    //     }

    //     matches.sort((m1, m2) => m1.x - m2.x);

    //     return matches.map(m => m.value).join('');
    // }

    // fill(color) {
    //     for (let y = 0; y < this.height; ++y) {
    //         for (let x = 0; x < this.width; ++x) {
    //             this.set(x, y, color);
    //         }
    //     }

    //     return this;
    // }

    // split(image) {
    //     const result = [];
    //     const occurences = this.findAll(image);

    //     const y = 0;
    //     let x = 0;

    //     for (const position of occurences) {
    //         const width = position.x - x;
    //         const height = this.height;
    //         const bbox = {x, y, width, height};

    //         if (width > 0) {
    //             result.push(this.crop(bbox));
    //         }

    //         x = position.x + image.width;
    //     }

    //     return result;
    // }

    // mirrorVertically() {
    //     const mirror = this.cloneEmpty();

    //     for (let y = 0; y < this.height; ++y) {
    //         for (let x = 0; x < this.width; ++x) {
    //             mirror.set(this.width - 1 - x, y, this._get(x, y));
    //         }
    //     }

    //     return mirror;
    // }

    // getDarkestColor(componentToCompare = 'r') {
    //     const i = COMPONENT_TO_INDEX[componentToCompare];
    //     let px = 0;
    //     let py = 0;
    //     let min = 255;

    //     for (let y = 0; y < this.height; ++y) {
    //         for (let x = 0; x < this.width; ++x) {
    //             const v = this._get(x, y)[i];

    //             if (v < min) {
    //                 px = x;
    //                 py = y;
    //                 min = v;
    //             }
    //         }
    //     }

    //     return this._get(px, py).slice();
    // }

    // getLightestColor(componentToCompare = 'r') {
    //     const i = COMPONENT_TO_INDEX[componentToCompare];
    //     let px = 0;
    //     let py = 0;
    //     let max = 0;

    //     for (let y = 0; y < this.height; ++y) {
    //         for (let x = 0; x < this.width; ++x) {
    //             const v = this._get(x, y)[i];

    //             if (v > max) {
    //                 px = x;
    //                 py = y;
    //                 max = v;
    //             }
    //         }
    //     }

    //     return this._get(px, py).slice();
    // }

    // save(path) {
    //     writePng(path, this);
    // }

    // static load(path) {
    //     return new PixelGrid(readPng(path));
    // }

    // static screenshot() {
    //     // return Image.load('screenshot.png');
    //     return new PixelGrid(dotpix.screenshot());
    // }

    // static create(width, height) {
    //     return new PixelGrid({width, height});
    // }
}
globalThis.ALL_FUNCTIONS.push(PixelGrid);