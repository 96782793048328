import { makeRequired } from '../../../utils/language/object.ts';
import { AnimationApi } from '../animation-api.ts';
import { DEFAULT_EMIT_PARTICLES_PARAMS, EmitParticlesParams, InitParticlesParams } from './emit-particles-params.ts';
import { Particle } from './particle.ts';

export async function emitParticlesAnimation(api: AnimationApi, params: EmitParticlesParams) {
    let {
        animationId,
        duration,
        blocking,
        delay,
        easing,
        initParticle,
        countPerSecond,
        layerId,
    } = makeRequired(params, DEFAULT_EMIT_PARTICLES_PARAMS);
    let { client } = api;
    let initParticleParams: InitParticlesParams = {
        index: 0,
        elapsedTime: 0
    };

    api.configure({ animationId, blocking, duration, delay, easing });

    await api.waitForStart();

    let startTime = api.getCurrentTime();
    let currentTime = startTime;
    let delayBetweenParticles = 1000 / countPerSecond;
    let accumulatedTime = 0;

    while (await api.waitForFrame()) {
        let elapsed = api.getFrameDuration();

        accumulatedTime += elapsed;

        while (accumulatedTime >= 0) {
            let particle = new Particle();
            initParticleParams.elapsedTime = currentTime - startTime;

            initParticle(particle, initParticleParams);

            initParticleParams.index += 1;
            accumulatedTime -= delayBetweenParticles;
            currentTime += delayBetweenParticles;

            if (currentTime + particle.duration < currentTime) {
                continue;
            }

            particle.layerId = layerId;
            particle.startTime = currentTime;
            
            client.renderComponent(particle);
        }
    }

    await api.waitForEnd();
}