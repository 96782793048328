import { Point } from 'outpost';
import { CardId } from '../project-types';
import { Board } from './board';
import { BoardCard } from './board-card';
import { BoardPlayer, BoardPlayerId, BoardZone, BoardZoneName } from './board-types';
import { TOOLTIP_HIDE_DELAY, TOOLTIP_QUICK_SHOW_DELAY, TOOLTIP_SHOW_DELAY } from './board-constants';
import { Project } from '../project';
import { ClientConnection } from './client-connection';

export type InteractedCardIds = Partial<{
    hovered: CardId;
    dragged: CardId;
    tooltip: CardId;
}>;

export class BoardLocalData {
    project: Project;
    board: Board;
    playerId: BoardPlayerId;
    forceRefresh: () => void;
    connection: ClientConnection | null = null;
    pointer: Point = new Point(0, 0);
    grabbed: boolean = false;
    interactedCardIds: InteractedCardIds = {};
    inspectedZoneId: [BoardPlayerId, BoardZoneName] | null = null;
    lastInspectedZoneTime: number = 0;
    currentInteraction: ((card: BoardCard) => void) | null = null;
    showTooltipTimeout: NodeJS.Timeout | null = null;
    hideTooltipTimeout: NodeJS.Timeout | null = null;
    lastTooltipTime: number = 0;
    globalVision: boolean = true;

    private cardImages: { [key: CardId]: string; } = {};

    constructor(project: Project, board: Board, playerId: BoardPlayerId, forceRefresh: () => void) {
        this.project = project;
        this.board = board;
        this.playerId = playerId;
        this.forceRefresh = forceRefresh;
    }

    getImageSrc(cardId: CardId): string {
        let imageSrc = this.cardImages[cardId];

        if (!imageSrc) {
            imageSrc = this.project.getCardById(cardId)!.image!.toDataURL();
            this.cardImages[cardId] = imageSrc;
        }

        return imageSrc;
    }

    getAllImagesSource() {
        let i = 0;
        for (let card of this.board.cards()) {
            setTimeout(() => this.getImageSrc(card.cardId), 100 * (i + 1));
            i += 1;
        }
    }

    isSelfPov(card: BoardCard) {
        return card.ownerId === this.playerId;
    }

    getPlayerColor(playerId: BoardPlayerId): string {
        return playerId === this.playerId ? 'dodgerblue' : 'red';
    }

    getLocalPlayer(): BoardPlayer {
        return this.board.players[this.playerId];
    }

    setInteraction(callback: ((card: BoardCard) => void) | null = null) {
        // this.scheduleHideTooltip();
        this.currentInteraction = callback;
        this.forceRefresh();
    }

    getInspectedZone(): BoardZone | null {
        if (!this.inspectedZoneId) {
            return null;
        }

        let [playerId, zoneName] = this.inspectedZoneId;

        return this.board.players[playerId].zones[zoneName];
    }

    setInspectedZone(zone: BoardZone | null, srcTimer?: number) {
        let now = performance.now();
        let inspectedZone = this.getInspectedZone();

        if (srcTimer && srcTimer - 100 < this.lastInspectedZoneTime) {
            return;
        }

        if (zone !== inspectedZone) {
            this.lastInspectedZoneTime = now;
            this.inspectedZoneId = zone && [zone.playerId, zone.name];
            this.forceRefresh();
        }
    }

    setHoveredCard(card: BoardCard | null) {
        this.setInteractedCard('hovered', card);

        if (card) {
            if (this.isCardTooltipable(card)) {
                this.scheduleShowTooltip(card);
            }
        } else {
            this.scheduleHideTooltip(TOOLTIP_HIDE_DELAY);
        }
    }

    scheduleShowTooltip(card: BoardCard) {
        let delay = (performance.now() - this.lastTooltipTime) < TOOLTIP_QUICK_SHOW_DELAY ? 0 : TOOLTIP_SHOW_DELAY;

        if (!this.isCardTooltipable(card)) {
            return;
        }

        this.clearSchedules();

        this.showTooltipTimeout = setTimeout(() => {
            this.showTooltipTimeout = null;
            this.setInteractedCard('tooltip', card);
            this.forceRefresh();
        }, delay);
    }

    scheduleHideTooltip(delay: number = 0) {
        this.clearSchedules();

        if (this.getInteractedCard('tooltip')) {
            this.lastTooltipTime = performance.now();
        }

        this.hideTooltipTimeout = setTimeout(() => {
            this.hideTooltipTimeout = null;
            this.setInteractedCard('tooltip', null);
            this.forceRefresh();
        }, delay);
    }

    clearSchedules() {
        if (this.showTooltipTimeout) {
            clearTimeout(this.showTooltipTimeout);
            this.showTooltipTimeout = null;
        }

        if (this.hideTooltipTimeout) {
            clearTimeout(this.hideTooltipTimeout);
            this.hideTooltipTimeout = null;
        }
    }

    isCardTooltipable(card: BoardCard): boolean {
        return card.isVisibleBy(this.playerId, this.globalVision) && !this.getInteractedCard('dragged');
    }

    getSelfPlayerId(): BoardPlayerId {
        return this.playerId;
    }

    getOpponentPlayerId(): BoardPlayerId {
        return this.playerId === 'p1' ? 'p2' : 'p1';
    }

    getInteractedCard(kind: keyof InteractedCardIds): BoardCard | null {
        let cardId = this.interactedCardIds[kind];

        if (!cardId) {
            return null;
        }

        return this.board.getCardById(cardId) ?? null;
    }

    setInteractedCard(kind: keyof InteractedCardIds, card: BoardCard | null) {
        this.interactedCardIds[kind] = card?.cardId;
    }

    setConnection(connection: ClientConnection) {
        this.connection = connection;
        this.globalVision = false;
        this.forceRefresh();
    }

    notifyBoardUpdated() {
        if (this.connection) {
            this.connection.sendBoardState();
        }

        this.forceRefresh();
    }
}
globalThis.ALL_FUNCTIONS.push(BoardLocalData);