import { objectSchema } from '../../utils/type-schema/object-schema.ts';

export const CLIENT_DATA_KINDS = <const>[
    'frame-duration',
    'point-in-layer',
    'pointer-position',
    'clock-properties',
    'layer-properties',
    'native-prompt',
    'client-data',
    'user-input',
    'random-number',
    'view-rect',
    'client-message',
];

export type ClientDataKind = typeof CLIENT_DATA_KINDS[number];

export type ClientData = {
    kind: ClientDataKind;
    data: Uint8Array;
};

export const CLIENT_DATA_SCHEMA = objectSchema<ClientData>({
    kind: CLIENT_DATA_KINDS,
    data: 'buffer'
});