import { Component } from '../../framework/component/component.ts';
import { View } from '../../framework/view/view.ts';
import { Button } from '../../helpers/widgets/button.ts';
import { getFunction } from '../../utils/language/function.ts';
import { getRangeEnd, getRangeStart } from '../../utils/language/range.ts';
import { GearCategory } from '../hub.ts';
import { GearSelectableItem } from './gear-selectable-item.ts';

export class GearCategorySelectionPanel implements Component {
    params: GearCategory;
    openButton: Button;
    items: Set<Component>;
    minRequiredTotalSelectedCount: number;
    maxRequireSotalSelectedCount: number;
    getItemMaxSelectableCount: ((item: Component) => number);

    availableItems: GearSelectableItem[] = [];
    selectedItems: GearSelectableItem[] = [];

    constructor(params: GearCategory) {
        this.params = params;
        this.openButton = new Button(params.name);
        this.items = new Set(params.selectable);
        this.minRequiredTotalSelectedCount = getRangeStart(params.requiredSelectedItemCount, 1);
        this.maxRequireSotalSelectedCount = getRangeEnd(params.requiredSelectedItemCount, 1);
        this.getItemMaxSelectableCount = getFunction(params.itemMaxSelectableCount ?? 1);

        this.availableItems = params.selectable.map((item, i) => new GearSelectableItem(item, i));
    }

    isGearValid(selectedItems: Map<Component, number>): boolean {
        let selectedCount: number = 0;

        for (let [item, count] of selectedItems.entries()) {
            if (this.items.has(item)) {
                selectedCount += count;

                let maxItemCount = this.getItemMaxSelectableCount(item);

                if (count > maxItemCount) {
                    return false;
                }
            }
        }

        if (selectedCount < this.minRequiredTotalSelectedCount || selectedCount > this.maxRequireSotalSelectedCount) {
            return false;
        }

        return true;
    }

    render(view: View) {

    }
}
globalThis.ALL_FUNCTIONS.push(GearCategorySelectionPanel);