/**
 * Represents a mouse action.
 */
export type MouseAction = typeof MOUSE_ACTIONS[number];
/** */
export const MOUSE_ACTIONS = <const>['move', 'down', 'up', 'drag-start', 'drag-progress', 'drag-end', 'click'];

/**
 * Represents a mouse button.
 */
export type MouseButton = typeof MOUSE_BUTTONS[number];
/** */
export const MOUSE_BUTTONS = <const>['left', 'middle', 'right'];

/**
 * Indicates whether a key is pressed or released.
 */
export type KeyboardAction = typeof KEYBOARD_ACTIONS[number];
/** */
export const KEYBOARD_ACTIONS = <const>['down', 'up'];

/**
 * Indicates whether the scroll is up or down.
 */
export type WheelAction = typeof WHEEL_ACTIONS[number];
/** */
export const WHEEL_ACTIONS = <const>['down', 'up'];

/**
 * Represents a [wheel delta mode](https://developer.mozilla.org/en-US/docs/Web/API/WheelEvent/deltaMode).
 */
export type WheelDeltaMode = typeof WHEEL_DELTA_MODES[number];
/** */
export const WHEEL_DELTA_MODES = <const>['pixel', 'line', 'page'];

/**
* Represents a [key code](https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code),
* which refers to the physical location of the key on the keyboard (rather than the character it produces).
*/
export type KeyCode = typeof KEY_CODES[number];
/** */
export const KEY_CODES = <const>['Escape', 'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12', 'Backquote', 'Digit1', 'Digit2', 'Digit3', 'Digit4', 'Digit5', 'Digit6', 'Digit7', 'Digit8', 'Digit9', 'Digit0', 'Minus', 'Equal', 'Backspace', 'Tab', 'KeyQ', 'KeyW', 'KeyE', 'KeyR', 'KeyT', 'KeyY', 'KeyU', 'KeyI', 'KeyO', 'KeyP', 'BracketLeft', 'BracketRight', 'Enter', 'CapsLock', 'KeyA', 'KeyS', 'KeyD', 'KeyF', 'KeyG', 'KeyH', 'KeyJ', 'KeyK', 'KeyL', 'Semicolon', 'Quote', 'Backslash', 'ShiftLeft', 'IntlBackslash', 'KeyZ', 'KeyX', 'KeyC', 'KeyV', 'KeyB', 'KeyN', 'KeyM', 'Comma', 'Period', 'Slash', 'ShiftRight', 'ControlLeft', 'MetaLeft', 'AltLeft', 'Space', 'AltRight', 'ContextMenu', 'ControlRight', 'ArrowUp', 'ArrowLeft', 'ArrowDown', 'ArrowRight', 'Insert', 'Home', 'PageUp', 'Delete', 'End', 'PageDown', 'NumLock', 'NumpadDivide', 'NumpadMultiply', 'NumpadSubstract', 'Numpad7', 'Numpad8', 'Numpad9', 'NumpadAdd', 'Numpad4', 'Numpad5', 'Numpad6', 'Numpad1', 'Numpad2', 'Numpad3', 'NumpadEnter', 'Numpad0', 'NumpadDecimal'];

export type TextInputType = typeof TEXT_INPUT_TYPES[number];
export const TEXT_INPUT_TYPES = <const>['cursor-move', 'insertText', 'insertReplacementText', 'insertLineBreak', 'insertParagraph', 'insertOrderedList', 'insertUnorderedList', 'insertHorizontalRule', 'insertFromYank', 'insertFromDrop', 'insertFromPaste', 'insertFromPasteAsQuotation', 'insertTranspose', 'insertCompositionText', 'insertLink', 'deleteWordBackward', 'deleteWordForward', 'deleteSoftLineBackward', 'deleteSoftLineForward', 'deleteEntireSoftLine', 'deleteHardLineBackward', 'deleteHardLineForward', 'deleteByDrag', 'deleteByCut', 'deleteContent', 'deleteContentBackward', 'deleteContentForward', 'historyUndo', 'historyRedo', 'formatBold', 'formatItalic', 'formatUnderline', 'formatStrikeThrough', 'formatSuperscript', 'formatSubscript', 'formatJustifyFull', 'formatJustifyCenter', 'formatJustifyRight', 'formatJustifyLeft', 'formatIndent', 'formatOutdent', 'formatRemove', 'formatSetBlockTextDirection', 'formatSetInlineTextDirection', 'formatBackColor', 'formatFontColor', 'formatFontNam'];

export type DomMouseEvent = {
    kind: 'mouse';
    nativeEvent: MouseEvent | null;
    action: MouseAction;
    button: MouseButton;
    x: number;
    y: number;
    dx: number;
    dy: number;
    startX: number;
    startY: number;
    metaKey: boolean;
    ctrlKey: boolean;
    shiftKey: boolean;
    altKey: boolean;
};

export type DomKeybordEvent = {
    kind: 'keyboard';
    nativeEvent: KeyboardEvent | null;
    action: KeyboardAction;
    key: string;
    code: KeyCode;
    repeat: boolean;
    metaKey: boolean;
    ctrlKey: boolean;
    shiftKey: boolean;
    altKey: boolean;
};

export type DomWheelEvent = {
    kind: 'wheel';
    nativeEvent: WheelEvent | null;
    action: WheelAction;
    x: number;
    y: number;
    deltaX: number;
    deltaY: number;
    deltaZ: number;
    deltaMode: WheelDeltaMode;
    metaKey: boolean;
    ctrlKey: boolean;
    shiftKey: boolean;
    altKey: boolean;
};

export type DomResizeEvent = {
    kind: 'resize';
};

export type DomTextInputEvent = {
    kind: 'text-input';
    nativeEvent: InputEvent | null;
    targetId: number;
    text: string;
    selectionStart: number;
    selectionEnd: number;
    inputType: TextInputType;
};

export type DomEvent = DomMouseEvent | DomKeybordEvent | DomWheelEvent | DomResizeEvent | DomTextInputEvent;

export const BUTTON_TO_STRING: { [key: number]: MouseButton; } = ['left', 'middle', 'right'];
export const DELTA_MODE_TO_STRING: { [key: number]: WheelDeltaMode; } = ['pixel', 'line', 'page'];
export const DEFAULT_DRAG_THRESHOLD = 5;

export type DomEventNameToType = {
    'mouse': DomMouseEvent;
    'mouse-move': DomMouseEvent;
    'mouse-down': DomMouseEvent;
    'mouse-up': DomMouseEvent;
    'click': DomMouseEvent;
    'drag-start': DomMouseEvent;
    'drag': DomMouseEvent;
    'drag-end': DomMouseEvent;
    'keyboard': DomKeybordEvent;
    'key-up': DomKeybordEvent;
    'key-down': DomKeybordEvent;
    'scroll': DomWheelEvent;
    'scroll-up': DomWheelEvent;
    'scroll-down': DomWheelEvent;
};

export type DomEventName = keyof DomEventNameToType;

export const DOM_NAME_ALIASES: Partial<{ [Key in DomEventName]: DomEventName[] }> = {
    'mouse': ['mouse-down', 'mouse-up'],
    'keyboard': ['key-down', 'key-up'],
    'scroll': ['scroll-down', 'scroll-up'],
};