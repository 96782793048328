import { makeArray } from '../../utils/language/array.ts';

export class MatchmakingEntry<T> {
    private teamCount: number;
    private teamSize: number;
    private teams: (T | null)[][];

    constructor(teamCount: number, teamSize: number) {
        this.teamCount = teamCount;
        this.teamSize = teamSize;
        this.teams = makeArray(teamCount, () => makeArray(teamSize, () => null));
    }

    getAvailableSlotCount(): number {
        let availableSlotCount = 0;

        for (let team of this.teams) {
            for (let player of team) {
                if (!player) {
                    availableSlotCount += 1;
                }
            }
        }

        return availableSlotCount;
    }

    addPlayer(player: T): boolean {
        for (let team of this.teams) {
            for (let i = 0; i < team.length; ++i) {
                if (!team[i]) {
                    team[i] = player;

                    return true;
                }
            }
        }

        return false;
    }

    removePlayer(player: T): boolean {
        for (let team of this.teams) {
            for (let i = 0; i < team.length; ++i) {
                if (team[i] === player) {
                    team[i] = null;

                    return true;
                }
            }
        }

        return false;
    }

    getTeams(): (T | null)[][] {
        return this.teams;
    }
}
globalThis.ALL_FUNCTIONS.push(MatchmakingEntry);