import { Counter } from './counter.ts';
import { DualMap } from './dual-map.ts';

export class ItemStore<T> {
    private items: DualMap<number, T> = new DualMap();
    private idCounter: Counter = new Counter();

    constructor(items?: readonly T[]) {
        for (let item of items ?? []) {
            this.add(item);
        }
    }

    add(item: T) {
        if (this.items.hasValue(item)) {
            return;
        }

        this.items.set(this.idCounter.next(), item);
    }

    getId(item: T): number | undefined {
        return this.items.getByValue(item);
    }

    getItem(id: number) {
        return this.items.getByKey(id);
    }
}
globalThis.ALL_FUNCTIONS.push(ItemStore);