export enum DataType {
    Undefined,
    Null,
    True,
    False,
    Number,
    BigInt,
    String,
    Symbol,
    Array,
    Buffer,
    Object,
    Function,
    Set,
    Map,
    Promise,
    StaticAsset
}

export const MAX_UINT_32 = (2 ** 32) - 1;

export const SERIALIZABLE = Symbol();