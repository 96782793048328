import { Methods, toCssPixels } from 'outpost';
import { ComponentChildren, JSX } from 'preact';
import { CSSProperties } from 'preact/compat';
import { DynamicComponent, NativeElementTag } from './dynamic-component';

export type ContainerKind = 'row' | 'column';
export type JustifyContent = 'center' | 'start' | 'end' | 'flex-start' | 'flex-end' | 'left' | 'right' | 'normal' | 'space-between' | 'space-around' | 'space-evenly' | 'stretch';
export type AlignItems = 'normal' | 'stretch' | 'center' | 'start' | 'end' | 'flex-start' | 'flex-end' | 'self-start' | 'self-end' | 'anchor-center';
export type FlexWrap = 'nowrap' | 'wrap' | 'wrap-reverse';
export type FlexDirection = 'row' |'row-reverse' |'column' |'column-reverse';

type HtmlListeners = Pick<JSX.HTMLAttributes, keyof JSX.HTMLAttributes & `on${string}`>;

export type ContainerProperties = HtmlListeners & {
    kind: ContainerKind;
    style?: CSSProperties;
    className?: string;
    tag?: NativeElementTag;
    children?: ComponentChildren;
    inline?: boolean;
    justifyContent?: JustifyContent;
    alignItems?: AlignItems;
    wrap?: FlexWrap;
    gap?: number;
    hidden?: boolean;
    grow?: boolean | number;
    shrink?: boolean | number;
    reverse?: boolean;
    basis?: number;
    draggable?: boolean;
};

export function Container(props: ContainerProperties) {
    let { className, tag = 'div' } = props;
    let style: CSSProperties = {
        display: props.hidden ? 'none' : (props.inline ? 'inline-flex' : 'flex'),
        flexDirection: props.kind,
        justifyContent: props.justifyContent,
        alignItems: props.alignItems,
        flexWrap: props.wrap,
        gap: toCssPixels(props.gap),
        flexGrow: growToNumber(props.grow),
        flexShrink: growToNumber(props.shrink),
        flexBasis: toCssPixels(props.basis),
        ...props.style
    };

    if (props.reverse) {
        style.flexDirection += '-reverse';
    }

    let listenerKeys = Object.keys(props).filter(key => key.startsWith('on'));
    let listeners = Object.fromEntries(listenerKeys.map(key => [key, (props as any)[key]]))

    return (
        <DynamicComponent
            tag={tag}
            className={className}
            style={style}
            draggable={props.draggable}
            {...listeners}
        >
            {props.children}
        </DynamicComponent>
    );
}

export type RowProperties = Omit<ContainerProperties, 'kind'>;
export type ColumnProperties = Omit<ContainerProperties, 'kind'>;

export function Row(props: RowProperties) {
    return <Container kind='row' {...props} />;
}

export function Column(props: ColumnProperties) {
    return <Container kind='column' {...props} />;
}

function growToNumber(value: boolean | number | undefined): number | undefined {
    if (typeof value === 'boolean') {
        return +value;
    } else if (typeof value === 'number') {
        return value;
    } else {
        return undefined;
    }
}