import { ParseContext } from '../parsing/parse-context.ts';
import { FlexBuffer } from '../serialization/flex-buffer.ts';
import { Logger } from '../logging/logger.ts';
import { TypeSchemaLike, formatTypeSchema } from './type-schema-like.ts';
import { Result } from '../language/result.ts';
import { SerializableAssetIndexLike } from '../serialization/serializable-asset-index.ts';

export enum TypeKind {
    Boolean,
    Number,
    String,
    Enum,
    Other
}

export type SerializeOptions = {
    includedProperties?: string[] | undefined;
    serializableAssets?: SerializableAssetIndexLike;
};

export type TypeSchema<T> = {
    optional?: boolean;
    kind?: TypeKind;
    check: (ctx: ParseContext, value: unknown) => boolean;
    serialize: (buffer: FlexBuffer, value: T, options: SerializeOptions | null, key: string | null) => void;
    deserialize: (buffer: FlexBuffer, options: SerializeOptions | null) => T;
};

export function serializeValue<T>(type: TypeSchemaLike<T>, buffer: FlexBuffer, value: T, options?: SerializeOptions): FlexBuffer {
    let formatted = formatTypeSchema(type);

    formatted.serialize(buffer, value, options ?? null, null);

    return buffer;
}

export function deserializeValue<T>(type: TypeSchemaLike<T>, buffer: FlexBuffer, options?: SerializeOptions): T {
    let formatted = formatTypeSchema(type);

    return formatted.deserialize(buffer, options ?? null);
}

export function checkValue<T>(type: TypeSchemaLike<T>, ctx: ParseContext, value: T): boolean {
    return formatTypeSchema(type).check(ctx, value);
}

export function deserializeValueSafe<T>(type: TypeSchemaLike<T>, buffer: FlexBuffer): Result<T> {
    try {
        return Result.ok(deserializeValue(type, buffer));
    } catch (error: unknown) {
        Logger.error(error);
        return Result.error();
    }
}