import { copyCanvasTo, createCanvas } from '../dom/canvas.ts';
import { ImageLike } from '../dom/image-loader.ts';
import { Rect } from '../geometry/rect.ts';
import { WebglTextureFilter, applyWebglTextureFilter } from './webgl-texture-filter.ts';

export const BASE_TEXTURE_SIZE = 128;
export const MAX_TEXTURE_SIZE = 4096;

export type StoreImageData = {
    rect: Rect | null;
    image: ImageLike;
}

export class WebglImageTexture {
    private gl: WebGL2RenderingContext;
    private texture: WebGLTexture | null = null;
    private internalFormat: number;
    private srcFormat: number;
    private srcType: number;
    private canvas: HTMLCanvasElement;
    private ctx: CanvasRenderingContext2D;
    private width: number = BASE_TEXTURE_SIZE;
    private height: number = BASE_TEXTURE_SIZE;
    private depth: number = 1;
    private availableRects: Rect[];
    private mustSyncEverything: boolean = false;
    private imagesToSync: string[] = [];
    private imageIdToStoreData: Map<string, StoreImageData> = new Map();
    private filter: WebglTextureFilter = 'linear';

    constructor(gl: WebGL2RenderingContext) {
        this.gl = gl;
        this.canvas = createCanvas({ width: BASE_TEXTURE_SIZE, height: BASE_TEXTURE_SIZE });
        this.ctx = this.canvas.getContext('2d')!;
        this.availableRects = [ Rect.fromSize(BASE_TEXTURE_SIZE, BASE_TEXTURE_SIZE) ];
        this.internalFormat = this.gl.RGBA;
        this.srcFormat = this.gl.RGBA;
        this.srcType = this.gl.UNSIGNED_BYTE;
    }

    getWidth(): number {
        return this.width;
    }

    getHeight(): number {
        return this.height;
    }

    getDepth(): number {
        return this.depth;
    }

    getImageRect(imageId: string | null): Rect | null {
        if (!imageId) {
            return null;
        }

        return this.imageIdToStoreData.get(imageId)?.rect ?? null;
    }

    hasImage(imageId: string): boolean {
        return this.imageIdToStoreData.has(imageId);
    }

    addImage(imageId: string, image: ImageLike) {
        if (this.imageIdToStoreData.has(imageId)) {
            return;
        }

        let data = this.storeImage(imageId, image);

        this.imagesToSync.push(imageId);
        this.imageIdToStoreData.set(imageId, data);
    }

    removeImage(imageId: string) {
        let data = this.imageIdToStoreData.get(imageId);

        if (data === undefined) {
            return;
        }

        if (data.rect) {
            this.releaseRect(data.rect);
        }

        this.imageIdToStoreData.delete(imageId);
    }

    setFilter(filter: WebglTextureFilter) {
        if (this.filter !== filter) {
            this.mustSyncEverything = true;
        }

        this.filter = filter;
    }

    private releaseRect(releasedRect: Rect) {
        for (let i = 0; i < this.availableRects.length; ++i) {
            let rect = this.availableRects[i];
            let merged = getMergedRect(releasedRect, rect);

            if (merged) {
                this.availableRects.splice(i, 1);
                this.releaseRect(merged);
                return;
            }
        }

        this.availableRects.unshift(releasedRect);
    }

    private storeImage(imageId: string, image: ImageLike): StoreImageData {
        if (image.width > MAX_TEXTURE_SIZE || image.height > MAX_TEXTURE_SIZE) {
            return { image, rect: null };
        }

        for (let i = 0; i < this.availableRects.length; ++i) {
            let rect = this.availableRects[i];

            if (rect.width >= image.width && rect.height >= image.height) {
                let imageRect = Rect.from({
                    x1: rect.x1,
                    y1: rect.y1,
                    width: image.width,
                    height: image.height
                });

                let leftoverRect1 = Rect.from({
                    x1: imageRect.x2,
                    y1: imageRect.y1,
                    width: rect.width - image.width,
                    height: image.height
                });

                let leftoverRect2 = Rect.from({
                    x1: imageRect.x1,
                    y1: imageRect.y2,
                    width: rect.width,
                    height: rect.height - image.height
                });

                let leftovers: Rect[] = [];

                if (!leftoverRect1.isEmpty()) {
                    leftovers.push(leftoverRect1);
                }

                if (!leftoverRect2.isEmpty()) {
                    leftovers.push(leftoverRect2);
                }

                this.availableRects.splice(i, 1, ...leftovers);

                this.ctx.clearRect(imageRect.x1, imageRect.y1, imageRect.width, imageRect.height);
                this.ctx.drawImage(image, imageRect.x1, imageRect.y1);

                return { rect: imageRect, image };
            }
        }

        if (this.width === MAX_TEXTURE_SIZE) {
            // texture array has multiple layers, need to add one
            this.releaseRect(Rect.from({
                x1: 0,
                y1: this.height * this.depth,
                width: this.width,
                height: this.height,
            }));
            this.depth += 1;
        } else if (this.width < this.height) {
            // there is a single layer that is larger vertically, need to double its size towards the right
            this.releaseRect(Rect.from({
                x1: this.width,
                y1: 0,
                width: this.width,
                height: this.height,
            }));
            this.width *= 2;
        } else {
            // there is a single square layer, need to double its size towards the bottom
            this.releaseRect(Rect.from({
                x1: 0,
                y1: this.height,
                width: this.width,
                height: this.height,
            }));
            this.height *= 2;
        }

        this.canvas = copyCanvasTo(this.canvas, this.width, this.height * this.depth);
        this.ctx = this.canvas.getContext('2d')!;
        this.mustSyncEverything = true;

        return this.storeImage(imageId, image);
    }

    syncWithGpu() {
        if (this.imagesToSync.length === 0 && this.texture && !this.mustSyncEverything) {
            return;
        }

        let gl = this.gl;

        if (!this.texture) {
            this.texture = gl.createTexture();
            this.mustSyncEverything = true;
        }

        let areaToSync = this.imagesToSync.reduce((acc, item) => acc + (this.imageIdToStoreData.get(item)?.rect?.getArea() ?? 0), 0);

        if (areaToSync > this.canvas.width * this.canvas.height / 3) {
            this.mustSyncEverything = true;
        }

        gl.bindTexture(gl.TEXTURE_2D_ARRAY, this.texture);

        if (this.mustSyncEverything) {
            applyWebglTextureFilter(gl, gl.TEXTURE_2D_ARRAY, this.filter);
            gl.texImage3D(gl.TEXTURE_2D_ARRAY, 0, this.internalFormat, this.width, this.height, this.depth, 0, this.srcFormat, this.srcType, this.canvas);
            // gl.texStorage3D(gl.TEXTURE_2D_ARRAY, 0, this.internalFormat, this.width, this.height, this.depth);
            // gl.texSubImage3D(gl.TEXTURE_2D_ARRAY, 0, 0, 0, 0, this.canvas.width, this.canvas.height, this.depth, this.srcFormat, this.srcType, this.canvas);
        } else {
            for (let imageId of this.imagesToSync) {
                let metadata = this.imageIdToStoreData.get(imageId);

                if (!metadata?.rect) {
                    continue;
                }

                let { rect, image } = metadata;
                let xOffset = rect.x1;
                let yOffset = rect.y1 % MAX_TEXTURE_SIZE;
                let zOffset = (rect.y1 / MAX_TEXTURE_SIZE) | 0;

                gl.texSubImage3D(gl.TEXTURE_2D_ARRAY, 0, xOffset, yOffset, zOffset, rect.width, rect.height, 1, this.srcFormat, this.srcType, image);
            }
        }

        gl.generateMipmap(gl.TEXTURE_2D_ARRAY);

        this.mustSyncEverything = false;
        this.imagesToSync.length = 0;
    }

    bindGlTextureArray(index: number): number {
        let gl = this.gl;

        gl.activeTexture(gl.TEXTURE0 + index);
        gl.bindTexture(gl.TEXTURE_2D_ARRAY, this.texture);

        return index;
    }

    getFilter(): WebglTextureFilter {
        return this.filter;
    }

    debugAppendToBody() {
        while (document.body.firstChild) {
            document.body.removeChild(document.body.firstChild);
        }
        document.body.appendChild(this.canvas);
        console.log({ width: this.width, height: this.height });
        console.log(this.imageIdToStoreData);
        console.log(this.availableRects);
    }
}

// TODO: do not merge rects if they are on two different z-index
function getMergedRect(a: Rect, b: Rect): Rect | null {
    if (a.x === b.x && a.width === b.width) {
        if (a.y2 === b.y1) {
            return new Rect(a.x, (a.y1 + b.y2) / 2 , a.width, a.height + b.height)
        } else if (b.y2 === a.y1) {
            return new Rect(a.x, (b.y1 + a.y2) / 2 , a.width, a.height + b.height)
        }
    } else if (a.y === b.y && a.height === b.height) {
        if (a.x2 === b.x1) {
            return new Rect((a.x1 + b.x2) / 2, a.y, a.width + b.width, a.height);
        } else if (b.x2 === a.x1) {
            return new Rect((b.x1 + a.x2) / 2, a.y, a.width + b.width, a.height);
        }
    }

    return null;
}
globalThis.ALL_FUNCTIONS.push(WebglImageTexture);