import { Button } from '../../helpers/widgets/button.ts';
import { AddGridParams, GridPanel } from '../../helpers/widgets/grid-panel.ts';
import { Point } from '../../utils/geometry/point.ts';
import { Rect, RectLike } from '../../utils/geometry/rect.ts';
import { Collection, iterateCollection } from '../../utils/language/collection.ts';
import { objectMatchesPredicate } from '../../utils/language/object.ts';
import { Constructor } from '../../utils/language/types.ts';
import { ClientApiReading } from '../client/client-api-reading.ts';
import { Client } from '../client/client.ts';
import { DrawContext } from '../client/draw-context.ts';
import { ComponentModifier, componentModifierToCallback } from '../component/component-modifier.ts';
import { Component, ComponentLike } from '../component/component.ts';
import { DummyComponent, formatComponent } from '../component/dummy-component.ts';
import { GraphicsEngine } from '../graphics-engine/graphics-engine.ts';
import { GraphicsAttributeList } from '../graphics-engine/graphics/graphics-attribute-list.ts';
import { GraphicsAttributeAlias } from '../graphics-engine/graphics/graphics-attribute-metadata.ts';
import { getGraphicsAttributeValue, isAddGraphicsAttribute, isAnimatedGraphicsAttribute, isMultGraphicsAttribute, isSimpleValue } from '../graphics-engine/graphics/graphics-attribute-types.ts';
import { DrawOrder } from '../graphics-engine/graphics/graphics-types.ts';
import { COPIED_ATTRIBUTES_FROM_MAIN_ATOM, GRAPHICS_METADATA, Graphics } from '../graphics-engine/graphics/graphics.ts';
import { LayerId } from '../graphics-engine/layer-types.ts';
import { ViewFragment } from './view-fragment.ts';
import { ViewHelperStack } from './view-helper-stack.ts';
import { ViewLayout } from './view-layout.ts';
import { GraphicsTransform } from '../graphics-engine/graphics/graphics-transform.ts';
import { MAIN_ATOM_KEY, TRANSITION_PRIORITIES, VIEW_FRAGMENT_PRIORITY_COUNT, ViewBasicAttributes, ViewFragmentPriority, ViewState, getDefaultBasicAttributes } from './view-types.ts';
import { ColorLike } from '../../utils/color/color.ts';
import { Counter } from '../../utils/data-structures/counter.ts';

const DEBUG_RECT_COUNTER = new Counter();

export class View extends ClientApiReading {
    readonly client: Client;
    private graphicsEngine: GraphicsEngine;
    private parent: View | null;
    private component: Component;
    private sortedFragmentIds: number[] = [];
    private atoms: { [key: string]: GraphicsAttributeList; } = {};
    private fragmentsByPriority: (ViewFragment | undefined)[] = new Array(VIEW_FRAGMENT_PRIORITY_COUNT).fill(undefined);
    private activeFragment: ViewFragment | null = null;
    private layouts: ViewLayout[] = [];
    private isTooltipFor: Component | null = null;
    private shouldReload: boolean = false;
    private displayIndex: number = 0;
    private dummyComponents: ViewHelperStack<DummyComponent> = new ViewHelperStack(() => new DummyComponent());
    private gridPanels: ViewHelperStack<GridPanel> = new ViewHelperStack(() => new GridPanel());
    private basicAttributesFromParent: ViewBasicAttributes = getDefaultBasicAttributes();
    private basicAttributes: ViewBasicAttributes = getDefaultBasicAttributes();
    private isHiddenFlag: boolean = false;
    private birthTime: number = 0;
    private transitionStartTime: number = 0;
    private transitionEndTime: number = 0;
    private state: ViewState = ViewState.Pending;
    private isDisplayedFlag: boolean = false;
    private minStartTime: number = 0;
    private animationEnabled: boolean = true;
    private transform: GraphicsTransform | null = null;
    private destroyOnTransitionEnd: boolean = false;

    constructor(client: Client, component: Component, parent: View | null) {
        super(client);
        this.client = client;
        this.graphicsEngine = client.getGraphicsEngine();
        this.component = component;
        this.parent = parent;
    }

    get rect(): Rect {
        return this.basicAttributes.rect;
    }

    getComponent(): Component {
        return this.component;
    }

    private getCurrentFragmentFor(priority: number): ViewFragment | undefined {
        let fragment = this.fragmentsByPriority[priority];

        while (fragment?.next) {
            fragment = fragment.next;
        }

        return fragment;
    }

    addFragment(priority: number, callback: (view: View, component: Component) => void): ViewFragment {
        let currentTime = this.graphicsEngine.getCurrentTime();
        let fragment = this.getCurrentFragmentFor(priority);
        let startTime = Math.max(currentTime, this.minStartTime, this.transitionEndTime);
        let recomputeTransform = false;

        if (!fragment) {
            fragment = new ViewFragment(priority);
            this.fragmentsByPriority[priority] = fragment;
            this.sortedFragmentIds.push(priority);
            this.sortedFragmentIds.sort((a, b) => a - b);
        } else if (!this.animationEnabled || currentTime >= fragment.deathTime || (fragment.deathTime === Infinity && currentTime >= startTime)) {
            recomputeTransform = true;
            fragment.destroy();
        } else {
            let nextFragment = new ViewFragment(priority);

            fragment.setNext(nextFragment);
            fragment = nextFragment;
        }

        this.activeFragment = fragment;
        this.client.withClientRoomApi(() => callback(this, this.component));

        fragment.computeGlobalAttributes(startTime, this.animationEnabled);

        if (fragment.priority === ViewFragmentPriority.Self && this.birthTime === 0) {
            this.birthTime = fragment.birthTime;
        }

        if (TRANSITION_PRIORITIES.includes(fragment.priority)) {
            this.transitionStartTime = fragment.birthTime;
            this.transitionEndTime = fragment.birthTime + fragment.duration;
            this.minStartTime = Math.max(this.minStartTime, this.transitionEndTime);
        }

        if (recomputeTransform || fragment.transform) {
            this.recomputeTransform();
        }

        // this.notifyShouldReload();

        return fragment;
    }

    deleteFragment(priority: number, lingerFrame: boolean = false) {
        let fragment = this.fragmentsByPriority[priority];

        if (fragment) {
            fragment.deathTime = this.graphicsEngine.getCurrentTime();
            fragment.lingerFrame = lingerFrame;

            // let hasTransform = fragment.transform !== null;

            // fragment.destroy();
            // this.notifyShouldReload();

            // if (hasTransform) {
            //     this.recomputeTransform();
            // }
        }
    }

    updateFragments(currentTime: number) {
        if (this.destroyOnTransitionEnd && currentTime >= this.transitionEndTime) {
            this.destroy();
            return;
        }

        let updateTransform = false;

        for (let priority = 0; priority < this.fragmentsByPriority.length; ++priority) {
            let fragment = this.fragmentsByPriority[priority];

            if (!fragment) {
                continue;
            }

            while ((currentTime >= fragment.deathTime && !fragment.consumeLingerFrame()) || (fragment.next && currentTime > fragment.next.birthTime)) {
                updateTransform ||= fragment.transform !== null;
                let next = fragment.destroy();

                if (next) {
                    this.fragmentsByPriority[priority] = next;
                    fragment = next;
                } else if (priority === ViewFragmentPriority.Self) {
                    this.destroy();
                }

                this.notifyShouldReload();
            }

            if (currentTime >= fragment.birthTime && !fragment.isEnabled) {
                fragment.isEnabled = true;
                updateTransform ||= fragment.transform !== null;

                if (priority === ViewFragmentPriority.Self && this.state === ViewState.Pending) {
                    this.client.notifyViewBorn(this);
                    this.state = ViewState.Alive;
                    this.birthTime = fragment.birthTime;
                }

                this.notifyShouldReload();
            }
        }

        if (updateTransform) {
            this.recomputeTransform();
        }
    }

    scheduleDestroy() {
        this.destroyOnTransitionEnd = true;
    }

    cancelScheduleDestroy() {
        this.destroyOnTransitionEnd = false;
    }

    hasSelfFragment(): boolean {
        return !!this.fragmentsByPriority[ViewFragmentPriority.Self];
    }

    private notifyShouldReload() {
        this.shouldReload = true;
    }

    private recomputeTransform() {
        let prevTransform = this.transform;

        this.transform = null;

        for (let fragment of this.fragmentsByPriority) {
            if (fragment?.transform) {
                this.transform = fragment.transform;
            }
        }

        if (prevTransform !== this.transform) {
            for (let view of this.viewTree()) {
                view.notifyShouldReload();
            }
        }
    }

    destroy() {
        this.state = ViewState.Dead;

        for (let key in this.atoms) {
            let atom = this.atoms[key];

            atom.destroy();
        }

        this.client.notifyViewDead(this);
    }

    setMinStartTime(minStartTime: number) {
        this.minStartTime = minStartTime;
    }

    setAnimationsEnabled(enabled: boolean) {
        this.animationEnabled = enabled;
    }

    private getActiveFragment(): ViewFragment {
        return this.activeFragment!;
    }

    paint(key: string, graphics: Graphics | undefined): void;
    paint(graphics: Graphics | undefined): void;
    paint(arg1: Graphics | undefined | string, arg2?: Graphics | undefined) {
        let graphics: Graphics | undefined = undefined;;
        let forceKey: string | undefined = undefined;

        if (arguments.length > 1) {
            graphics = arg2 as Graphics;
            forceKey = arg1 as string;
        } else {
            graphics = arg1 as Graphics;
        }

        if (!graphics) {
            return;
        }

        let activeFragment = this.getActiveFragment();
        let key = forceKey ?? graphics.key ?? MAIN_ATOM_KEY;
        let unsafeOpti = graphics.unsafeOpti ?? false;

        activeFragment.addGraphics(key, graphics, unsafeOpti);

        if (!this.atoms[key]) {
            this.atoms[key] = new GraphicsAttributeList(this.graphicsEngine);
        }

        if (key === MAIN_ATOM_KEY && !activeFragment.isModifier()) {
            let rectLike = getGraphicsAttributeValue(graphics.rect);
            let positionLike = getGraphicsAttributeValue(graphics.position);
            let rect = rectLike && Rect.resolve(rectLike);
            let position = positionLike && Point.resolve(positionLike);
            let x = getGraphicsAttributeValue(graphics.x);
            let y = getGraphicsAttributeValue(graphics.y);
            let width = getGraphicsAttributeValue(graphics.width);
            let height = getGraphicsAttributeValue(graphics.height);
            let detectable = getGraphicsAttributeValue(graphics.detectable);
            let layerId = getGraphicsAttributeValue(graphics.layerId);
            let mirrorX = getGraphicsAttributeValue(graphics.mirrorX);
            let mirrorY = getGraphicsAttributeValue(graphics.mirrorY);
            let target = activeFragment.priority === ViewFragmentPriority.Parent ? this.basicAttributesFromParent : this.basicAttributes;

            target.rect.x = x ?? position?.x ?? rect?.x ?? target.rect.x;
            target.rect.y = y ?? position?.y ?? rect?.y ?? target.rect.y;
            target.rect.width = width ?? rect?.width ?? target.rect.width;
            target.rect.height = height ?? rect?.height ?? target.rect.height;
            target.detectable = detectable ?? target.detectable;
            target.layerId = layerId !== undefined ? layerId : target.layerId;
            target.mirrorX = mirrorX ?? target.mirrorX;
            target.mirrorY = mirrorY ?? target.mirrorY;
        }
    }

    paintRect(rect: Rect, color: ColorLike = 'purple') {
        this.paint({
            key: DEBUG_RECT_COUNTER.next().toString(),
            rect,
            color
        });
    }

    addChild(child: Collection<ComponentLike>, modifier: ComponentModifier = null) {
        let activeFragment = this.getActiveFragment();
        let modifierCallback = modifier ? componentModifierToCallback(modifier) : null;

        for (let childComponentLike of iterateCollection(child)) {
            let childComponent = formatComponent(childComponentLike, () => this.getNextDummyComponent());
            let childView = this.client.updateView(childComponent, this);

            childView.fillParentFragment(childView => {
                let { layerId, detectable, rect } = this.basicAttributes;
                let { x, y, width, height } = rect;
                let mirrorX = this.basicAttributes.mirrorX ?? this.basicAttributesFromParent.mirrorX;
                let mirrorY = this.basicAttributes.mirrorY ?? this.basicAttributesFromParent.mirrorY;

                childView.paint({ layerId, detectable, x, y, width, height, mirrorX, mirrorY });
                modifierCallback?.(childView, childComponent);
            });

            if (childView.parent === this) {
                activeFragment.addChild(childView);
                childView.setMinStartTime(this.minStartTime);
                childView.setAnimationsEnabled(this.animationEnabled);
                childView.fillSelfFragment();
            }
        }
    }

    fillParentFragment(callback: (view: View) => void) {
        this.addFragment(ViewFragmentPriority.Parent, self => {
            callback(self);
        });
    }

    fillSelfFragment() {
        this.addFragment(ViewFragmentPriority.Self, self => {
            self.resetBasicAttributes();
            self.resetHelpers();
            self.component.render(self);
            self.processLayouts();
        });
    }

    addGrid(params: AddGridParams) {
        let gridPanel = this.gridPanels.next();
        let rect = this.rect;

        if (typeof params.rect === 'function') {
            rect = params.rect(rect);
        } else if (params.rect) {
            rect = Rect.from(params.rect);
        }

        gridPanel.setup(params);

        this.addChild(gridPanel, rect);
    }

    layout(rect?: RectLike): ViewLayout {
        let layout = new ViewLayout(this);

        this.layouts.push(layout);

        if (rect) {
            layout.setRootRect(rect);
        }

        return layout;
    }

    processLayouts() {
        for (let layout of this.layouts) {
            layout.finish();
        }

        this.layouts.length = 0;
    }

    getRect(): Rect {
        return this.basicAttributes.rect.clone();
    }

    getMirror(): { x: number | null, y: number | null; } {
        return {
            x: this.basicAttributes.mirrorX ?? this.basicAttributesFromParent.mirrorX,
            y: this.basicAttributes.mirrorY ?? this.basicAttributesFromParent.mirrorY,
        };
    }

    getParentView(): View | null {
        return this.parent;
    }

    getTransitionStartTime(): number {
        return this.transitionStartTime;
    }

    getTransitionEndTime(): number {
        return this.transitionEndTime;
    }

    private getAtom(key: string): GraphicsAttributeList | undefined {
        return this.atoms[key];
    }

    private computeAtoms() {
        let mainAtomAttributes = this.getAtom(MAIN_ATOM_KEY);
        let mainBody = mainAtomAttributes?.getBodyGraphics();
        let mainModifiers = mainAtomAttributes?.getModifierGraphics();
        let mainStartTimes = mainAtomAttributes?.getStartTimes();

        let transform = this.transform;
        let parent = this.parent;

        while (!transform && parent) {
            transform = parent.transform;
            parent = parent.parent;
        }

        for (let atomKey in this.atoms) {
            let attributes = this.atoms[atomKey];
            let atomBody = attributes.getBodyGraphics();
            let atomModifier = attributes.getModifierGraphics();
            let atomStartTimes = attributes.getStartTimes();
            let atomDurations = attributes.getDurations();

            attributes.clear(atomKey);
            attributes.setMainStartTime(this.birthTime);
            attributes.setTransform(transform);

            if (transform) {
                if (transform.x !== 0) {
                    atomBody.transformX = transform.x;
                    atomStartTimes.transformX = transform.startTime;
                    atomDurations.transformX = transform.duration;
                }

                if (transform.y !== 0) {
                    atomBody.transformY = transform.y;
                    atomStartTimes.transformY = transform.startTime;
                    atomDurations.transformY = transform.duration;
                }

                if (transform.scale !== 1) {
                    atomBody.transformScale = transform.scale;
                    atomStartTimes.transformScale = transform.startTime;
                    atomDurations.transformScale = transform.duration;
                }
            }

            if (atomKey !== MAIN_ATOM_KEY && mainBody && mainModifiers && mainStartTimes) {
                for (let attributeKey of COPIED_ATTRIBUTES_FROM_MAIN_ATOM) {
                    if (mainBody[attributeKey] !== undefined) {
                        (atomBody as any)[attributeKey] = mainBody[attributeKey];
                    }

                    if (mainModifiers[attributeKey] !== undefined) {
                        (atomModifier as any)[attributeKey] = mainModifiers[attributeKey];
                    }

                    if (mainStartTimes[attributeKey] !== undefined) {
                        atomStartTimes[attributeKey] = mainStartTimes[attributeKey];
                    }
                }

                if (atomBody.anchorX === undefined) {
                    atomBody.anchorX = mainBody.x;
                }

                if (atomBody.anchorY === undefined) {
                    atomBody.anchorY = mainBody.y;
                }
            }

            for (let fragment of this.fragmentsByPriority) {
                if (!fragment) {
                    continue;
                }

                let fragmentAtom = fragment.getGraphics(atomKey);
                let isFragmentModifier = fragment.isModifier();

                if (!fragmentAtom || !fragment.isEnabled) {
                    continue;
                }

                for (let key in fragmentAtom) {
                    let graphicsKey = key as keyof Graphics;
                    let value = fragmentAtom[graphicsKey];

                    if (value === undefined) {
                        continue;
                    }

                    let metadata = GRAPHICS_METADATA[graphicsKey];
                    let isModifier = !!(metadata.glsl && isFragmentModifier);
                    let target = (isModifier ? atomModifier : atomBody) as any;
                    let alias = metadata.alias as GraphicsAttributeAlias<any> | undefined;
                    let startTime = (isModifier || fragment.duration !== 0) ? Math.max(fragment.birthTime, this.birthTime) : undefined;

                    if (!alias) {
                        target[graphicsKey] = value;

                        if (!isSimpleValue(value)) {
                            attributes.setStartTime(graphicsKey, startTime);
                        }
                    } else if (isSimpleValue(value)) {
                        alias(value, (key, value) => target[key] = value);
                    } else if (isAnimatedGraphicsAttribute<any>(value)) {
                        alias(value.end, (childKey, childValue) => {
                            attributes.setStartTime(childKey, startTime);
                            target[childKey] = {
                                start: undefined,
                                end: childValue,
                                operation: value.operation,
                                duration: value.duration,
                                delay: value.delay,
                                delayBetweenLoops: value.delayBetweenLoops,
                                loop: value.loop,
                                easing: value.easing,
                                reverse: value.reverse,
                            };
                        });

                        if (value.start !== undefined) {
                            alias(value.start, (childKey, childValue) => target[childKey].start = childValue);
                        }
                    } else if (isAddGraphicsAttribute<any>(value)) {
                        alias(value.add, (childKey, childValue) => {
                            attributes.setStartTime(childKey, startTime);
                            target[key] = {
                                add: childValue,
                                duration: value.duration,
                                delay: value.delay,
                                delayBetweenLoops: value.delayBetweenLoops,
                                loop: value.loop,
                                easing: value.easing,
                                reverse: value.reverse,
                            };
                        });
                    } else if (isMultGraphicsAttribute<any>(value)) {
                        alias(value.mult, (childKey, childValue) => {
                            attributes.setStartTime(childKey, startTime);
                            target[childKey] = {
                                mult: childValue,
                                duration: value.duration,
                                delay: value.delay,
                                delayBetweenLoops: value.delayBetweenLoops,
                                loop: value.loop,
                                easing: value.easing,
                                reverse: value.reverse,
                            };
                        });
                    } else {
                        console.error(value);
                        throw new Error(`didn't correctly parse graphics attribute (shoult not happen)`);
                    }
                }
            }
        }
    }

    private loadIfNecessary() {
        if (this.shouldReload) {
            this.computeAtoms();
            this.shouldReload = false;
        }

        for (let key in this.atoms) {
            this.atoms[key].loadIfNecessary();
        }
    }

    draw(context: DrawContext) {
        this.updateFragments(context.currentTime);

        if (this.state !== ViewState.Alive) {
            return;
        }

        this.isDisplayedFlag = true;
        this.displayIndex = context.register(this.basicAttributes.detectable ? this.component : null);

        this.loadIfNecessary();
        this.drawAtoms(this.displayIndex, 'before-children');
        this.drawChildren(context);
        this.drawAtoms(this.displayIndex, 'after-children');
    }

    private drawAtoms(displayIndex: number, layer: DrawOrder) {
        for (let key in this.atoms) {
            let atom = this.atoms[key];

            if (atom.getLayer() === layer) {
                atom.draw(displayIndex);
            }
        }
    }

    private drawChildren(context: DrawContext) {
        for (let priority of this.sortedFragmentIds) {
            let fragment = this.fragmentsByPriority[priority];

            if (!fragment?.isEnabled) {
                continue;
            }

            for (let childView of fragment.children) {
                childView.draw(context);
            }
        }
    }

    getPointerPosition(layerId?: LayerId): Point {
        if (layerId === undefined) {
            layerId = this.basicAttributes.layerId;
        }

        return super.getPointerPosition(layerId);
    }

    *viewTree(): IterableIterator<View> {
        yield this;

        for (let fragment of this.fragmentsByPriority.values()) {
            if (!fragment) {
                continue;
            }

            for (let view of fragment.children) {
                yield* view.viewTree();
            }
        }
    }

    getLocalPlayerId(): string {
        return this.client.getPlayerId()!;
    }

    getLocalPlayer<T extends Component>(constructor: Constructor<T>): T {
        let roomManager = this.client.getRoomManager();
        let roomWrapper = roomManager.getActiveRoomWrapper();
        let playerId = roomManager.getLocalPlayerId()!;
        let player = roomWrapper?.players.get(playerId);

        if (player && player instanceof constructor) {
            return player;
        } else {
            throw new Error(`active client is not an instance of ${constructor.name}`);
        }
    }

    getComponentView(component: Component): View | undefined {
        return this.client.getView(component);
    }

    markAsTooltipFor(component: Component) {
        for (let view of this.viewTree()) {
            view.isTooltipFor = component;
        }
    }

    getTooltipTarget(): Component | null {
        return this.isTooltipFor;
    }

    getDisplayIndex(): number {
        return this.displayIndex;
    }

    setHidden(value: boolean) {
        this.isHiddenFlag = value;
    }

    isHidden(): boolean {
        return this.isHiddenFlag;
    }

    private resetBasicAttributes() {
        this.basicAttributes.layerId = this.basicAttributesFromParent.layerId;
        this.basicAttributes.detectable = this.basicAttributesFromParent.detectable;
        this.basicAttributes.rect.x = this.basicAttributesFromParent.rect.x;
        this.basicAttributes.rect.y = this.basicAttributesFromParent.rect.y;
        this.basicAttributes.rect.width = this.basicAttributesFromParent.rect.width;
        this.basicAttributes.rect.height = this.basicAttributesFromParent.rect.height;
    }

    private resetHelpers() {
        this.dummyComponents.reset();
        this.gridPanels.reset();
    }

    private getNextDummyComponent(): DummyComponent {
        return this.dummyComponents.next();
    }

    getCurrentTime() {
        return this.graphicsEngine.getCurrentTime();
    }

    private getPrevFragment(priority: ViewFragmentPriority): ViewFragment | undefined {
        let fragment = this.fragmentsByPriority[priority];

        if (!fragment || !fragment.next) {
            return;
        }

        let prev = fragment;

        while (prev.next) {
            prev = prev.next;
        }

        return prev;
    }

    getPrevRect(): Rect | undefined {
        let parentFragment = this.getPrevFragment(ViewFragmentPriority.Parent);
        let selfFragment = this.getPrevFragment(ViewFragmentPriority.Self);
        let attribute = selfFragment?.getGraphics(MAIN_ATOM_KEY)?.rect ?? parentFragment?.getGraphics(MAIN_ATOM_KEY)?.rect;
        let value = getGraphicsAttributeValue(attribute);

        return value && Rect.from(value);
    }

    markAsNotDisplayed() {
        this.isDisplayedFlag = false;
    }

    isDisplayed(): boolean {
        return this.isDisplayedFlag;
    }

    getDelay(): number {
        return this.fragmentsByPriority[ViewFragmentPriority.Self]?.delay ?? 0;
    }

    getLingerDuration(): number {
        return this.fragmentsByPriority[ViewFragmentPriority.Self]?.linger ?? 0;
    }
}
globalThis.ALL_FUNCTIONS.push(View);