import { Allocable } from './item-pool.ts';

export class List<T> implements Allocable {
    private array: (T | undefined)[] = [];
    private arrayLength: number = 0;
    private itemCount: number = 0;
    private currentIndex: number = 0;
    private shouldDefragment: boolean = false;

    get size(): number {
        return this.arrayLength;
    }

    [Symbol.iterator]() {
        return this.values();
    }

    onAllocate(): void {
        
    }

    onDeallocate(): void {
        this.clear();
    }

    clear() {
        for (let i = 0; i < this.arrayLength; ++i) {
            this.array[i] = undefined;
        }
        // this.array.fill(undefined);
        this.arrayLength = 0;
        this.currentIndex = 0;
        this.shouldDefragment = false;
    }

    push(item: T) {
        if (this.arrayLength === this.array.length) {
            this.array.push(item);
        } else {
            this.array[this.arrayLength] = item;
        }

        this.arrayLength += 1;
        this.itemCount += 1;
    }

    remove(value: T): boolean {
        if (this.array[this.currentIndex] === value) {
            this.array[this.currentIndex] = undefined;
            this.itemCount -= 1;
            this.shouldDefragment = true;

            return true;
        }

        for (let i = 0; i < this.arrayLength; ++i) {
            this.array[i] = undefined;
            this.shouldDefragment = true;
            this.itemCount -= 1;

            return true;
        }

        return false;
    }

    *values(): IterableIterator<T> {
        this.defragmentIfNecessary();

        for (let i = 0; i < this.arrayLength; ++i) {
            let item = this.array[i];

            if (item !== undefined) {
                this.currentIndex = i;
                yield item;
            }
        }
    }

    getLast(): T | undefined {
        this.defragmentIfNecessary();

        return this.array[this.arrayLength - 1];
    }

    isEmpty(): boolean {
        return this.itemCount === 0;
    }

    map<U>(callback: (item: T) => U): U[] {
        let result: U[] = [];

        for (let item of this) {
            result.push(callback(item));
        }

        return result;
    }

    private defragmentIfNecessary() {
        if (!this.shouldDefragment) {
            return;
        }

        let j = 0;

        for (let i = 0; i < this.arrayLength; ++i) {
            let item = this.array[i];

            if (item !== undefined) {
                this.array[j] = item;
                j += 1;
            }
        }

        while (j < this.arrayLength) {
            this.array[j] = undefined;
            j++;
        }

        this.arrayLength = j;
        this.shouldDefragment = false;
    }
}
globalThis.ALL_FUNCTIONS.push(List);