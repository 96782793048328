import { Constructor } from '../language/types.ts';
import { Allocable, ItemPool } from './item-pool.ts';

export class ItemAllocator {
    private itemPools: Map<Constructor<Allocable<any[]>>, ItemPool<Allocable<any[]>>> = new Map();

    register<T extends Constructor<Allocable<any[]>>>(constructor: T, constructFunction: () => InstanceType<T>) {
        if (this.itemPools.has(constructor)) {
            throw new Error(`allocator already registered for type ${constructor.name}`);
        }

        this.itemPools.set(constructor, new ItemPool(constructFunction) as any);
    }

    allocate<T extends Allocable<any[]>>(constructor: Constructor<T>, ...args: Parameters<T['onAllocate']>): T {
        return this.getItemPool(constructor).allocate(...args) as unknown as T;
    }

    deallocate<T extends Allocable<any[]>>(value: T | null): null {
        if (!value) {
            return null;
        }

        return this.getItemPool(value.constructor as any).deallocate(value);
    }

    deallocateArray<T extends Allocable<any[]>>(array: (T | null)[]) {
        for (let i = 0; i < array.length; ++i) {
            array[i] = this.deallocate(array[i]);
        }
    }

    isAllocated<T extends Allocable<any[]>>(value: T): boolean {
        return this.getItemPool(value.constructor as any).isAllocated(value);
    }

    getAllocatedCounts(): { [key: string]: number } {
        let result: { [key: string]: number } = {};

        for (let [constructor, itemPool] of this.itemPools.entries()) {
            result[constructor.name] = itemPool.getAllocatedCount();
        }

        return result;
    }

    private getItemPool<T extends Constructor<Allocable<any[]>>>(constructor: T): ItemPool<Allocable<any[]>> {
        let itemPool = this.itemPools.get(constructor);

        if (!itemPool) {
            throw new Error(`class ${constructor.name} was not registered as allocable`);
        }

        return itemPool;
    }
}
globalThis.ALL_FUNCTIONS.push(ItemAllocator);