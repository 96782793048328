import { objectSchema } from '../type-schema/object-schema.ts';

export type TextContentLike =
    | Partial<TextContent>
    | HTMLInputElement
    | string
    | null;

export class TextContent {
    content: string = ''
    cursorIndex: number = 0;

    static from(value: TextContentLike): TextContent {
        let content: string = '';
        let cursorIndex: number | undefined;
        let result = new TextContent();

        if (value) {
            if (typeof value === 'string') {
                content = value;
            } else if (value instanceof HTMLInputElement) {
                content = value.value;
                cursorIndex = value.selectionEnd ?? undefined;
            } else if (typeof value === 'object') {
                content = value.content ?? '';
                cursorIndex = value.cursorIndex;
            }
        }

        cursorIndex = cursorIndex ?? content.length;

        result.content = content;
        result.cursorIndex = cursorIndex;

        return result;
    }

    equals(other: TextContent): boolean {
        return this.content === other.content && this.cursorIndex === other.cursorIndex;
    }
};

export const TEXT_CONTENT_SCHEMA = objectSchema<TextContent>({
    content: 'string',
    cursorIndex: 'integer'
}, {
    ctor: TextContent
});
globalThis.ALL_FUNCTIONS.push(TextContent);