import { HubRoom } from '../hub-room/hub-room.ts';
import { HubPlayer } from '../hub-room/hub-player.ts';
import { BaseHubRoom } from '../base-hub-room.ts';
import { FocusController } from '../../helpers/components/focus-controller.ts';
import { View } from '../../framework/view/view.ts';
import { Button } from '../../helpers/widgets/button.ts';
import { Label } from '../../helpers/widgets/label.ts';
import { TextBox } from '../../helpers/widgets/textbox.ts';
import { Component } from '../../framework/component/component.ts';
import { Room } from '../../framework/global/global-room-api.ts';

export class LoginRoom extends BaseHubRoom implements Component {
    titleLabel = new Label();
    usernameTextbox = new TextBox('Username');
    loginButton = new Button('Login');
    mainRoomId: string = '';
    autoLogin: boolean = false;

    onRoomStart(): void {
        this.mainRoomId = Room.createRoom(HubRoom, {
            constructorArgs: [this.params],
            players: []
        });
        this.autoLogin = this.params.autoLogin ?? true;
    }

    onMount(): void {
        setTimeout(() => Room.focusNext(), 100);
        Room.configureRenderer(this.params.renderer);
        this.titleLabel.setText(this.gameName);
        this.loginButton.setStyle(this.params.buttonStyle);
        Room.render(new FocusController());
    }

    render(view: View) {
        if (this.params.renderLoginRoom) {
            this.params.renderLoginRoom(view, this);
        } else {
            this.defaultRender(view);
        }
    }

    defaultRender(view: View): void {
        view.layout()
            .centerToBottom()
            .width('30%')
            .innerMargin('5%')
            .childAspectRatio(5)
            .addChild(this.usernameTextbox)
            .addChild(this.loginButton)
            .scale(0.9);

        view.addChild(this.titleLabel, view.rect.fromTopInwards('*', '20%'));
    }

    async $loginInteraction() {
        let storedUsername = Room.getClientData(() => window.localStorage.getItem('username'));
        
        if (!this.autoLogin) {
            storedUsername = null;
        }

        if (!storedUsername) {
            await Room.waitForUserInput({
                selectable: this.loginButton,
                shortcuts: { 'Enter': this.loginButton },
                isEnabled: () => this.usernameTextbox.getText().content.length > 0,
                checkIsEnabledOn: 'client'
            });
        }

        let username = storedUsername || Room.getClientData(() => this.usernameTextbox.getText().content);

        if (!username || username.length === 0) {
            return;
        }

        await Room.waitForLocalServerResponse();

        if (Room.isClientConnected(username)) {
            Room.withSourcePlayer(() => {
                console.log(`client "${username}" is already connected`);
                window.localStorage.removeItem('username')
            });
            return;
        }

        if (this.autoLogin) {
            Room.withSourcePlayer(() => window.localStorage.setItem('username', username));
        }

        Room.addPlayerToRoom(this.mainRoomId, username, new HubPlayer(username));
        Room.authenticatePlayer(Room.getSourcePlayerId(), username);
    }
}
globalThis.ALL_FUNCTIONS.push(LoginRoom);