import { SERVER_FULL_URL } from '../../admin/admin-public-constants.ts';
import { FetchSpreadsheetRequest } from '../../admin/endpoints/endpoint-utils-spreadsheet.ts';
import { RequestResponse } from '../../admin/request/request-types.ts';
import { SpreadsheetData } from '../../third-party/google/google-api-types.ts';
import { getSpreadsheetId } from '../../third-party/google/google-api-utils.ts';
import { getJson } from '../http/http.ts';

export async function fetchGoogleSpreadsheet(url: string): Promise<SpreadsheetData> {
    let spreadsheetId = getSpreadsheetId(url);
    let response = await getJson<RequestResponse, FetchSpreadsheetRequest>(`${SERVER_FULL_URL}/utils/spreadsheet`, { spreadsheetId });

    if (response.code !== 200) {
        throw new Error(`invalid response code ${response.code}: ${response.error}`);
    }

    return response.data;
}