import { PromiseWithResolvers, PromiseWrapper, makePromise, wrapPromise } from '../../utils/language/promise.ts';
import { List } from '../../utils/memory/list.ts';
import { Clock } from '../../utils/time/clock.ts';
import { TimeFrame } from '../../utils/time/time-frame.ts';
import { ClientApiInteraction } from '../client/client-api-interaction.ts';
import { ClientApiInteractionParams } from '../client/client-api-types.ts';
import { Client } from '../client/client.ts';
import { GraphicsEngine } from '../graphics-engine/graphics-engine.ts';
import { AnimationQueue } from './animation-queue.ts';
import { AnimationCallback, AnimationId, ConfigureAnimationParams, WaitForDurationData } from './animation-types.ts';

export type AnimationApiParams<T> = {
    clock: Clock;
    animation: AnimationCallback<T>;
    params: T;
};

enum AnimationStep {
    Pending,
    Initialized,
    Started,
    Ended,
    Completed,
}

export class AnimationApi extends ClientApiInteraction {
    readonly client: Client
    readonly graphicsEngine: GraphicsEngine;
    readonly clock: Clock;
    private animationCallback: AnimationCallback<any>;
    private animationParams: any;
    private onInit: PromiseWithResolvers<void> = makePromise();
    private onStart: PromiseWithResolvers<void> = makePromise();
    private onEnd: PromiseWithResolvers<void> = makePromise();
    private onComplete: PromiseWithResolvers<void> = makePromise();
    private onNextFrame: PromiseWithResolvers<boolean> | null = null;
    private onWaitList: WaitForDurationData[] = [];
    private step: AnimationStep = AnimationStep.Pending;
    private lastUpdateTime: number = 0;
    private frameDuration: number = 0;
    private forceStop: boolean = false;
    private animationId: AnimationId = null;
    private timeFrame: TimeFrame = new TimeFrame();
    private startWhen: PromiseWrapper = wrapPromise();
    private endWhen: PromiseWrapper = wrapPromise();
    private onStopCallbacks: (() => void)[] = [];
    private onCompleteCallback: () => void = () => {};
    private blocking: boolean = true;
    private isConfigured: boolean = false;
    private hasCallbackFinished: boolean = false;
    private followupAnimation: AnimationCallback<void> | undefined = undefined;

    constructor(client: Client, params: AnimationApiParams<any>) {
        super(client);
        this.client = client;
        this.graphicsEngine = client.getGraphicsEngine();
        this.clock = params.clock;
        this.animationCallback = params.animation;
        this.animationParams = params.params;
    }

    runCallback() {
        this.animationCallback(this, this.animationParams).then(followupAnimation => {
            this.hasCallbackFinished = true;
            this.onCompleteCallback();
            if (followupAnimation) {
                this.followupAnimation = followupAnimation;
            }
        });
    }

    reset(params: AnimationApiParams<any> & ClientApiInteractionParams) {
        super.reset(params);
        Object.assign(this, new AnimationApi(this.client, params));
        this.runCallback();
    }

    destroy(): void {
        super.destroy();
    }

    update() {
        if (this.step === AnimationStep.Completed) {
            return;
        }

        let currentTime = this.clock.getCurrentTime();

        if (this.step === AnimationStep.Pending) {
            this.step = AnimationStep.Initialized;
            this.timeFrame.startTime += currentTime;
            this.lastUpdateTime = currentTime;
            this.onInit.resolve();
        }

        let frameDuration = currentTime - this.lastUpdateTime;
        let shouldEnd = (currentTime >= this.timeFrame.getEndTime() && this.endWhen.isSettled) || this.forceStop;

        if (this.step === AnimationStep.Initialized) {
            if ((currentTime >= this.timeFrame.startTime && this.startWhen.isSettled) || shouldEnd) {
                this.step = AnimationStep.Started;
                this.onStart.resolve();
            }
        }

        if (this.step === AnimationStep.Started) {
            if (this.onNextFrame) {
                this.onNextFrame.resolve(!shouldEnd);
                this.onNextFrame = null;
            }

            if (shouldEnd) {
                this.step = AnimationStep.Ended;
                this.onEnd.resolve();
            }
        }

        if (this.step === AnimationStep.Ended && this.hasCallbackFinished) {
            this.step = AnimationStep.Completed;
            this.onComplete.resolve();
        }

        for (let onWait of this.onWaitList) {
            if (this.forceStop || onWait.timeFrame.getEndTime() <= currentTime) {
                onWait.resolve();
                this.onWaitList.remove(onWait);
            }
        }

        this.lastUpdateTime = currentTime;
        this.frameDuration = frameDuration;
    }

    stop() {
        for (let callback of this.onStopCallbacks) {
            callback();
        }

        this.onStopCallbacks = [];
        this.forceStop = true;
        this.update();
    }

    getId(): AnimationId {
        return this.animationId;
    }

    isBlocking(): boolean {
        return this.blocking && !this.isCompleted();
    }

    isCompleted(): boolean {
        return this.step === AnimationStep.Completed;
    }

    getFollowupAnimation(): AnimationCallback<void> | undefined {
        return this.followupAnimation;
    }


    configure(params: ConfigureAnimationParams) {
        if (this.isConfigured) {
            return;
        }

        if (params.animationId !== undefined) {
            this.animationId = params.animationId;
        }

        if (params.blocking !== undefined) {
            this.blocking = params.blocking;
        }

        if (params.delay !== undefined && this.step === AnimationStep.Pending) {
            this.timeFrame.startTime = params.delay;
        }

        if (params.duration !== undefined) {
            this.timeFrame.duration = params.duration;
        }

        if (params.easing !== undefined) {
            this.timeFrame.easing = params.easing;
        }

        if (params.startWhen !== undefined) {
            this.startWhen = wrapPromise(params.startWhen);
        }

        if (params.endWhen !== undefined) {
            this.endWhen = wrapPromise(params.endWhen);
        }

        if (params.onComplete) {
            this.onCompleteCallback = params.onComplete;
        }

        this.isConfigured = true;
    }

    getElapsedRatio(): number {
        if (this.step === AnimationStep.Pending || this.step === AnimationStep.Initialized) {
            return 0;
        } else if (this.step === AnimationStep.Ended || this.step === AnimationStep.Completed) {
            return 1;
        }

        return this.timeFrame.getAnimationRatio(this.lastUpdateTime);
    }

    getElapsedDuration(): number {
        return this.lastUpdateTime - this.timeFrame.startTime;
    }

    getFrameDuration(): number {
        return this.frameDuration;
    }

    getCurrentTime(): number {
        return this.clock.getCurrentTime();
    }

    async waitForInit() {
        return this.onInit.promise;
    }

    async waitForStart() {
        return this.onStart.promise;
    }

    async waitForFrame(): Promise<boolean> {
        if (this.step >= AnimationStep.Ended) {
            return false;
        }

        if (!this.onNextFrame) {
            this.onNextFrame = makePromise();
        }

        return this.onNextFrame.promise;
    }

    async waitForEnd() {
        return this.onEnd.promise;
    }

    async waitForDuration(duration: number) {
        if (duration === 0) {
            return;
        }

        let onWaitData: WaitForDurationData = {
            timeFrame: new TimeFrame(this.clock.getCurrentTime(), duration),
            ...makePromise()
        };

        this.onWaitList.push(onWaitData);

        return onWaitData.promise;
    }

    async waitForPromise(promise: Promise<any>) {
        if (this.step >= AnimationStep.Ended) {
            return;
        }

        let guard = makePromise();

        this.onStopCallbacks.push(guard.resolve);

        return Promise.race([guard.promise, promise]).then(() => undefined, () => undefined);
    }

    now(): AnimationQueue {
        return this.client.now();
    }

    instant(): AnimationQueue {
        return this.client.instant();
    }

    getOnCompletePromise(): Promise<void> {
        return this.onComplete.promise;
    }
};
globalThis.ALL_FUNCTIONS.push(AnimationApi);