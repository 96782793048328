import { Plugin, PluginBuild } from 'esbuild';
import { readFile } from 'fs/promises';
import { EXPOSED_ASYNC_METHOD_NAMES } from '../../../framework/global/global-room-api.ts';

export const GLOBAL_FUNCTION_ARRAY_NAME = 'ALL_FUNCTIONS';

const CLASS_DECLARATION_REGEXP = /(?<=(\n|^))\s*(export\s+)?class\s+\w+(\s*<[^>]*>)?(\s+extends\s+\w+(\s*<[^>]*>)?)?(\s+implements\s+(\w+(\s*<[^>]*>)?\s*(,\s*)?)+)*\s*{/g;
const FUNCTION_DECLARATION_REGEXP = /(?<=(\n|^))(export\s+)?(async\s+)?function\s+\w+(\s*<[^>]*>)?\s*\(?/g;

export function codeInjectionPlugin(): Plugin {
    return {
        name: 'code-injection',
        setup(build: PluginBuild) {
            build.onLoad({ filter: /\.(ts|js)$/ }, async (args) => {
                let contents = await readFile(args.path, 'utf8');
                let declaredClassNames = getDeclaredFunctionNames(contents, CLASS_DECLARATION_REGEXP, 'class', '{');
                let declaredFunctionNames: string[] = [];

                // if (!args.path.includes('node_modules')) {
                //     declaredFunctionNames = getDeclaredFunctionNames(text, FUNCTION_DECLARATION_REGEXP, 'function', '(');
                // }

                let declaredNames = [...declaredClassNames, ...declaredFunctionNames];

                // contents = addUnwraps(contents);

                if (declaredNames.length > 0) {
                    contents += declaredNames.map(name => `\nglobalThis.${GLOBAL_FUNCTION_ARRAY_NAME}.push(${name});`).join('');
                }

                return {
                    contents,
                    loader: 'ts',
                };
            });
        }
    };
}

const REGEXP = new RegExp(`\\bawait\\s+Room\\s*\\.\\s*(${EXPOSED_ASYNC_METHOD_NAMES.join('|')})\\s*\\(`, 'g');

function addUnwraps(content: string): string {
    let inserts: [number, number][] = [];
    let invalid = false;

    while (true) {
        let match = REGEXP.exec(content);

        if (!match) {
            break;
        }

        let closingParenthesisIndex = getMatchingClosingParenthesis(content, match.index);

        if (closingParenthesisIndex === -1) {
            invalid = true;
            break;
        }

        inserts.push([match.index, closingParenthesisIndex]);
    }

    if (inserts.length === 0 || invalid) {
        return content;
    }

    let i = 0;
    let result = '';

    for (let [start, end] of inserts) {
        result += content.substring(i, start);
        result += `(${content.substring(start, end)}).unwrap()`;
        i = end;
    }

    result += content.substring(i);

    return result;
}

function getMatchingClosingParenthesis(content: string, index: number): number {
    let length = content.length;
    let i = content.indexOf('(', index) + 1;
    let openCount = 1;

    while (openCount > 0 && i < length) {
        let c = content[i];

        if (c === ')') {
            openCount -= 1;
        } else if (c === '(') {
            openCount += 1;
        }

        i += 1;
    }

    if (i < length) {
        return i;
    } else {
        return -1;
    }
}

function getDeclaredFunctionNames(text: string, regexp: RegExp, keyword: string, delimiter: string): string[] {
    let matches = text.match(regexp);

    if (!matches) {
        return [];
    }

    return matches.map(str => {
        let i = str.indexOf(keyword) + keyword.length;

        while (str[i] === ' ') {
            i += 1;
        }

        let start = i;

        while (str[i] !== ' ' && str[i] !== delimiter && str[i] !== '<') {
            i += 1;
        }

        let end = i;
        let className = str.substring(start, end);

        return className;
    });
}