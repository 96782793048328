import { useEffect, useRef } from 'preact/hooks';

export type CardPreviewProps = {
    canvas: HTMLCanvasElement;
    quantity: number;
};

export function CardPreview(props: CardPreviewProps) {
    let { canvas } = props;
    let canvasRef = useRef<HTMLDivElement>(null);
    let height = canvas.height === 1 ? '1px' : undefined;

    useEffect(() => {
        canvas.style.width = '300px';

        if (canvasRef.current) {
            canvasRef.current.appendChild(canvas);
        }
    }, []);

    return (
        // <Column inline justifyContent='center' >
        <div ref={canvasRef} style={{ height }} />
        // {/* <span style={{ textAlign: 'center', font: '20px sans-serif' }}>{`x${quantity}`}</span> */}
        // </Column>
    );
}