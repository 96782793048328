import { CSSProperties } from 'preact/compat';
import { BoardCardSlotComponent } from './board-card-slot-component';
import { BoardLocalData } from './board-local-data';
import { BoardPlayerId } from './board-types';
import { Row } from '../base-components/container';

export type BoardInspectZoneComponentProps = {
    localData: BoardLocalData;
    sideId: BoardPlayerId;
};

export function BoardInspectZoneComponent(props: BoardInspectZoneComponentProps) {
    let { localData, sideId } = props;
    let zone = localData.getInspectedZone();

    if (!zone || zone.playerId === sideId) {
        return <></>;
    }

    let items = zone.cards.map((card) => <BoardCardSlotComponent
        localData={localData}
        card={card}
        sourceZone={zone}
        hidden={false}
        height='80%'
        marginTop={20}
    />);

    let margin = '20px';
    let style: CSSProperties = {
        position: 'absolute',
        background: 'white',
        border: '1px solid black',
        left: margin,
        right: margin,
        top: margin,
        bottom: margin,
        overflowX: 'auto',
        // boxSizing: 'border-box',
        // padding: margin,
    };

    let sepStyle: CSSProperties = {
        flexGrow: 1
    };

    let onClick = (evt: MouseEvent) => {
        evt.stopPropagation();
    };

    let label = [
        zone.playerId === localData.playerId ? 'Your' : 'Opponent',
        zone.metadata.label.toLowerCase(),
        `(${zone.cards.length} card${zone.cards.length > 1 ? 's' : ''})`
    ].join(' ');

    let labelStyle: CSSProperties = {
        position: 'absolute',
        width: '100%',
        top: '35px',
        textAlign: 'center',
        fontSize: 'medium',
        pointerEvents: 'none',
    };

    return (
        <>
            <Row
                style={style}
                alignItems='center'
                wrap='nowrap'
                gap={20}
                grow
                onClick={onClick}
            >
                <div style={sepStyle} />
                {items}
                <div style={sepStyle} />
            </Row>
            <div style={labelStyle}>
                {label}
            </div>
        </>
    );
};