import { List } from '../../utils/memory/list.ts';
import { Clock } from '../../utils/time/clock.ts';
import { AnimationApi } from './animation-api.ts';

export class AnimationGroup {
    private clock: Clock;
    private animationApis: List<AnimationApi> = new List();

    constructor(clock: Clock) {
        this.clock = clock;
    }

    add(animationApi: AnimationApi): AnimationApi {
        this.animationApis.push(animationApi);
        animationApi.runCallback();

        return animationApi;
    }

    update() {
        for (let api of this.animationApis) {
            api.update();

            if (api.isCompleted()) {
                let followupAnimation = api.getFollowupAnimation();

                this.animationApis.remove(api);
                api.destroy();

                if (followupAnimation) {
                    api.reset({
                        clock: this.clock,
                        animation: followupAnimation,
                        params: undefined,
                    });

                    this.add(api);
                }
            }
        }
    }

    isCompleted(): boolean {
        return this.animationApis.size === 0;
    }

    isBlocking(): boolean {
        for (let api of this.animationApis) {
            if (api.isBlocking()) {
                return true;
            }
        }

        return false;
    }

    *animations() {
        yield* this.animationApis;
    }

    getLast(): AnimationApi | undefined {
        return this.animationApis.getLast();
    }
}
globalThis.ALL_FUNCTIONS.push(AnimationGroup);