import { DefaultValues } from '../../../utils/language/types.ts';
import { Easing } from '../../../utils/time/easing.ts';
import { LayerId } from '../../graphics-engine/layer-types.ts';
import { AnimationId } from '../animation-types.ts';
import { Particle } from './particle.ts';

/**
 * Parameters for {@link AnimationQueue.emitParticles}.
 */
export type EmitParticlesParams = {
    /**
     * Animation id, allowing the animation to be stopped via {@link AnimationQueue.stop}.
     * @default null
     */
    animationId?: AnimationId;
    /**
     * Delay after which to start emitting particles.
     * @default 0
     */
    delay?: number;
    /**
     * Duration for which to emit particles.
     * @default Infinity
     */
    duration?: number;
    /**
     * Easing for the particle speed.
     * @default "linear"
     */
    easing?: Easing;
    /**
     * Whether or not to block animations queued after this one (via {@link AnimationQueue.queue}) from starting
     * until the animation has finished playing.
     * 
     * Default: `true` if `duration` is finite, `false` otherwise.
     * @default isFinite(duration)
     */
    blocking?: boolean;
    /**
     * Callback run when a particle is created, to set its properties.
     * @param particle The particle to modify.
     * @returns 
     */
    initParticle?: (particle: Particle, params: InitParticlesParams) => void;
    /**
     * Number of particles emitted per second.
     * @default 100
     */
    countPerSecond?: number;
    /**
     * Shape of the particle.
     * @default "circle"
     */
    shape?: ParticleShape;
    /**
     * Scene on which to display the particles.
     * @default DEFAULT_SCENE_ID
     */
    layerId?: LayerId;
    /**
     * [Composite operation](https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/globalCompositeOperation)
     * to use when drawing the particles.
     */
    globalCompositeOperation?: GlobalCompositeOperation;
};

export type ParticleShape = 'circle' | 'square';

export type InitParticlesParams = {
    index: number;
    elapsedTime: number;
};

export const DEFAULT_EMIT_PARTICLES_PARAMS: DefaultValues<EmitParticlesParams> = {
    animationId: null,
    delay: 0,
    duration: Infinity,
    easing: 'linear',
    blocking: { compute: (params) => !!isFinite(params.duration) },
    countPerSecond: 100,
    initParticle: () => {},
    shape: 'circle',
    globalCompositeOperation: 'source-over',
    layerId: -1,
};