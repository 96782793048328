import { minimatch } from 'minimatch';
import { BUILTIN_FONTS, DECK_ATTRIBUTE_NAME, DEFAULT_TEMPLATE_FONTS, DEFAULT_TEMPLATE_HEIGHT, DEFAULT_TEMPLATE_WIDTH, DIRECTIVES_SHEET_NAME, QUANTITY_ATTRIBUTE_NAME } from './constants.ts';
import { SpreadsheetData } from './google-docs-api.ts';
import { isImageUrl, mergeObjects, normalizeString } from './utils.ts';
import { Color, isHorizontalAlign, isVerticalAlign, RectProperties } from 'outpost';
import { FontSize } from './types.ts';
import { Project } from './project.ts';
import { CardTemplate, CardData, TemplateBox } from './project-types.ts';

export function parseSpreadsheet(spreadsheet: SpreadsheetData): Project {
    let project = new Project(spreadsheet.id, spreadsheet.title);

    parseDirectives(spreadsheet, project);
    parseCards(spreadsheet, project);

    return project;
}

export function parseDirectives(spreadsheet: SpreadsheetData, project: Project) {
    let directivesSheet = spreadsheet.sheets.find(sheet => sheet.name === DIRECTIVES_SHEET_NAME);

    if (!directivesSheet) {
        return;
    }

    let prevKeyword = '';
    let prevName = '';

    for (let row of directivesSheet.rows) {
        let keyword = row[0] || prevKeyword;
        let name = row[1] || prevName;
        let definition = row[2] || '';
        let details = row[3];

        prevKeyword = keyword;
        prevName = name;

        if (!keyword || !name) {
            continue;
        }

        if (keyword === 'template') {
            let key = normalizeString(name);
            let template = project.templates[key];

            if (!template) {
                template = {
                    name: key,
                    boxAttributes: {},
                    size: { width: DEFAULT_TEMPLATE_WIDTH, height: DEFAULT_TEMPLATE_HEIGHT },
                    defaultAttributes: {},
                    fonts: { ...DEFAULT_TEMPLATE_FONTS },
                };
                project.templates[key] = template;

                let inheritFrom = project.templates[normalizeString(row[2])];

                if (inheritFrom) {
                    mergeObjects(template.size, inheritFrom.size);
                    mergeObjects(template.fonts, inheritFrom.fonts);
                    mergeObjects(template.defaultAttributes, inheritFrom.defaultAttributes);
                    mergeObjects(template.boxAttributes, inheritFrom.boxAttributes);
                }

                if (row[3]) {
                    let { width, height } = parseRect(row[3]);

                    template.size = { width, height };
                }

                if (row[4]) {
                    mergeObjects(template.defaultAttributes, parseTextAttributes(row[4]));
                }

                if (row[5]) {
                    mergeObjects(template.defaultAttributes, parseContentAttributes(row[5]));
                }
            }

            let boxName = normalizeString(row[2]);

            if (boxName) {
                let box = template.boxAttributes[boxName];

                if (!box) {
                    box = {
                        rect: { x: 0, y: 0, ...template.size },
                    };
                    template.boxAttributes[boxName] = box;
                }

                box.rect = parseRect(row[3], box.rect);
                mergeObjects(box, parseTextAttributes(row[4]));
                mergeObjects(box, parseContentAttributes(row[5]));
            }
        } else if (keyword === 'icon' && definition) {
            project.icons[`[${name}]`] = {
                url: definition,
                scale: parseFloat(details) || 1
            };
        } else if (keyword === 'replace') {
            project.replacements[name] = definition;
        } else if (keyword === 'infer' && definition) {
            project.templateInferences.push({
                pattern: normalizeString(name),
                columnName: normalizeString(definition),
            });
        }
    }
}

export function parseCards(spreadsheet: SpreadsheetData, project: Project) {
    let sheetId = 0;

    for (let sheet of spreadsheet.sheets) {
        let attributes = (sheet.rows[0] || []).map(normalizeString);
        let quantityIndex = attributes.indexOf(QUANTITY_ATTRIBUTE_NAME);
        let sheetName = normalizeString(sheet.name);

        let baseTemplateId = sheetName;
        let baseTemplate: CardTemplate | undefined = project.templates[sheetName];
        let inferByAttributeIndex: number = -1;

        if (!baseTemplate) {
            for (let { pattern, columnName } of project.templateInferences) {
                if (minimatch(sheetName, pattern)) {
                    inferByAttributeIndex = attributes.indexOf(columnName);
                    break;
                }
            }
        }

        if (!baseTemplate && inferByAttributeIndex === -1) {
            continue;
        }

        sheetId += 1;

        for (let row of sheet.rows.slice(1)) {
            let quantity = parseQuantity(row, quantityIndex);
            let template = baseTemplate;
            let templateId = baseTemplateId;

            if (!template && inferByAttributeIndex !== -1) {
                let templateName = normalizeString(row[inferByAttributeIndex]);

                template = project.templates[templateName];
                templateId = templateName;
            }

            if (!template || quantity <= 0) {
                continue;
            }

            let cardName = normalizeString(row[0]) || '?';
            let cardIndex = sheet.rows.indexOf(row);
            let cardId = `${cardName}_${sheetId}_${cardIndex}`;

            let card: CardData = {
                id: cardId,
                quantity,
                templateId,
                columns: {},
                image: null,
                imageId: null,
                deckIds: []
            };

            for (let i = 0; i < row.length; ++i) {
                let key = attributes[i];
                let value = row[i] ?? '';

                if (key === QUANTITY_ATTRIBUTE_NAME || !key) {
                    continue;
                }

                value = value.replaceAll('\n', '\\n');

                card.columns[key] = value;

                if (key === DECK_ATTRIBUTE_NAME && quantity > 0) {
                    let deckNames = value.split(',').map(str => str.trim());

                    for (let deckName of deckNames) {
                        let deck = project.getOrCreateDeck(deckName);

                        deck.cardIds.push(cardId);
                    }
                }
            }

            project.cards.push(card);
        }
    }
}

function parseQuantity(row: string[], quantityIndex: number) {
    if (quantityIndex === -1) {
        return 1;
    }

    let value = row[quantityIndex];

    if (!value) {
        return 0;
    }

    let quantity = parseInt(value);

    if (isNaN(quantity)) {
        return 1;
    }

    return quantity;
}

function parseRect(content: string = '', defaultRect: Partial<RectProperties> = {}): RectProperties {
    let fragments = content.split(',');
    let x = defaultRect.x ?? 0;
    let y = defaultRect.y ?? 0;
    let width = defaultRect.width ?? DEFAULT_TEMPLATE_WIDTH;
    let height = defaultRect.height ?? DEFAULT_TEMPLATE_HEIGHT;

    if (fragments.length === 2) {
        width = parseNumber(fragments[0], width);
        height = parseNumber(fragments[1], height);
    } else if (fragments.length >= 4) {
        x = parseNumber(fragments[0], x);
        y = parseNumber(fragments[1], y);
        width = parseNumber(fragments[2], width);
        height = parseNumber(fragments[3], height);
    }

    return { x, y, width, height };
}

function parseNumber(fragment: string, defaultValue: number): number {
    let value = parseFloat(fragment.trim());

    if (isNaN(value)) {
        return defaultValue;
    } else {
        return value;
    }
}

function parseTextAttributes(content: string = ''): Partial<TemplateBox> {
    let result: Partial<TemplateBox> = {};
    let items = content.split(',').map(str => str.trim()).filter(str => str);

    for (let item of items) {
        if (isFontSize(item)) {
            result.textSize = item;
        } else if (isHorizontalAlign(item)) {
            result.textHorizontalAlign = item;
        } else if (isVerticalAlign(item)) {
            result.textVerticalAlign = item;
        } else if (item === 'bold') {
            result.textBold = true;
        } else if (item === 'italic') {
            result.textItalic = true;
        } else if (Color.isColor(item)) {
            result.textColor = item;
        } else if (BUILTIN_FONTS.includes(item as any) || item in DEFAULT_TEMPLATE_FONTS) {
            result.textFont = item;
        }
    }

    return result;
}

function parseContentAttributes(content: string = ''): Partial<TemplateBox> {
    let result: Partial<TemplateBox> = {};
    let items = content.split(',').map(str => str.trim()).filter(str => str);

    for (let item of items) {
        if (item.match(/^".*"$/)) {
            result.textContent = item.substring(1, item.length - 1);
        } else if (Color.isColor(item)) {
            result.backgroundColor = item;
        } else if (isImageUrl(item)) {
            result.imageUrl = item;
        }
    }

    return result;
}

function isFontSize(content: string): content is FontSize {
    return /^\d+(px|%)$/.test(content);
}