import { GameHubParams } from './hub.ts';

export class BaseHubRoom {
    params: GameHubParams;
    gameName: string;

    constructor(params: GameHubParams) {
        this.params = params;
        this.gameName = params.gameName ?? 'Game';
    }
}
globalThis.ALL_FUNCTIONS.push(BaseHubRoom);