import { readFileSync, writeFileSync } from 'fs';
import { join } from 'path';

export class FileHandler {
    private filePath: string;

    constructor(...filePath: string[]) {
        this.filePath = join(...filePath);
    }

    read(): string {
        return readFileSync(this.filePath, 'utf8');
    }

    write(content: string) {
        writeFileSync(this.filePath, content, 'utf8');
    }
}
globalThis.ALL_FUNCTIONS.push(FileHandler);