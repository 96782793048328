import { Point, PointLike } from '../../utils/geometry/point.ts';
import { CLOCK_PROPERTIES_SCHEMA, ClockProperties } from '../../utils/time/clock-types.ts';
import { Component } from '../component/component.ts';
import { LAYER_PROPERTIES_SCHEMA, LayerId, LayerProperties } from '../graphics-engine/layer-types.ts';
import { ClientApiReadingParams } from './client-api-types.ts';
import { ClientApi } from './client-api.ts';

export class ClientApiReading extends ClientApi {
    reset(params?: ClientApiReadingParams): void {
        super.reset(params);
    }

    destroy(): void {
        super.destroy();
    }

    /**
    * Returns the specified animation queue's clock properties.
    * @returns 
    * @see {@link AnimationQueue.updateClock}
    */
    getClockProperties(queueId: number = 0): ClockProperties {
        return this.retrieveClientData({
            kind: 'clock-properties',
            schema: CLOCK_PROPERTIES_SCHEMA,
            callback: client => client.getClockProperties(queueId)
        });
    }

    /**
    * Returns the specified layer's properties.
    * @param layerId 
    * @returns 
    * @see {@link AnimationQueue.updateLayer}
    */
    getLayerProperties(layerId: LayerId = null): LayerProperties {
        return this.retrieveClientData({
            kind: 'layer-properties',
            schema: LAYER_PROPERTIES_SCHEMA,
            callback: client => client.getLayerProperties(layerId)
        });
    }

    /**
    * Returns the pointer position in the specified scene space.
    * @param layerId 
    * @returns 
    * @see {@link AnimationQueue.updateScene}
    */
    getPointerPosition(layerId: LayerId = null): Point {
        return this.retrieveClientData({
            kind: 'pointer-position',
            schema: Point.schema,
            callback: client => client.getPointerPosition(layerId)
        });
    }

    getPointInLayer(layerId: LayerId, point: PointLike): Point {
        return this.retrieveClientData({
            kind: 'point-in-layer',
            schema: Point.schema,
            callback: client => client.getPointInLayer(layerId, point)
        });
    }

    getFrameDuration(): number {
        return this.retrieveClientData({
            kind: 'frame-duration',
            callback: client => client.getFrameDuration(),
            schema: 'number'
        });
    }

    getHoveredComponent(): Component | null {
        return this.client?.getHoveredComponent() ?? null;
    }

    getFps(): number {
        return this.client?.getFps() ?? 0;
    }
}
globalThis.ALL_FUNCTIONS.push(ClientApiReading);