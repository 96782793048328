import { PointerStack } from './pointer-stack.ts';

export type ItemBufferParams = {
    initialItemCapacity: number;
    itemByteSize: number;
};

export class ItemBuffer {
    private itemByteSize: number;
    private pointerStack: PointerStack = new PointerStack();
    private firstUnallocatedByteOffset = 0;
    private size: number = 0;
    uint8Array: Uint8Array;
    dataView: DataView;

    constructor(params: ItemBufferParams) {
        this.itemByteSize = params.itemByteSize;
        this.uint8Array = new Uint8Array(params.initialItemCapacity * params.itemByteSize);
        this.dataView = new DataView(this.uint8Array.buffer, this.uint8Array.byteOffset, this.uint8Array.byteLength);
    }

    get byteSize(): number {
        return this.firstUnallocatedByteOffset;
    }

    get byteCapacity(): number {
        return this.uint8Array.length;
    }

    getItemCount(): number {
        return this.size;
    }

    allocate(): number {
        let byteOffset = this.pointerStack.pop();

        if (byteOffset === undefined) {
            byteOffset = this.firstUnallocatedByteOffset;
            this.firstUnallocatedByteOffset += this.itemByteSize;
            this.size += 1;

            if (byteOffset >= this.uint8Array.byteLength) {
                let newUint8Array = new Uint8Array(this.uint8Array.length * 2);
                newUint8Array.set(this.uint8Array);
                this.uint8Array = newUint8Array;
                this.dataView = new DataView(this.uint8Array.buffer, this.uint8Array.byteOffset, this.uint8Array.byteLength);
            }
        }

        return byteOffset;
    }

    deallocate(byteOffset: number): null {
        this.pointerStack.push(byteOffset);

        return null;
    }

    clear() {
        this.pointerStack.clear();
        this.firstUnallocatedByteOffset = 0;
        this.size = 0;
    }
}
globalThis.ALL_FUNCTIONS.push(ItemBuffer);