/**
 * Parameter of {@link ViewLayout.verticalAlign}.
 */
export type LayoutVerticalAlign = 'top' | 'middle' | 'bottom';

export function verticalAlignToNumber(verticalAlign: LayoutVerticalAlign | number): number {
    if (typeof verticalAlign === 'number') {
        return verticalAlign;
    }

    switch (verticalAlign) {
        case 'top': return 0;
        case 'middle': return 0.5;
        case 'bottom': return 1;
    }
}