/**
 * Parameter of {@link ViewLayout.horizontalAlign}.
 */
export type LayoutHorizontalAlign = 'left' | 'center' | 'right';

export function horizontalAlignToNumber(horizontalAlign: LayoutHorizontalAlign | number): number {
    if (typeof horizontalAlign === 'number') {
        return horizontalAlign;
    }

    switch (horizontalAlign) {
        case 'left': return 0;
        case 'center': return 0.5;
        case 'right': return 1;
    }
}