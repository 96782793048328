import { CSSProperties } from 'preact/compat';
import { Row } from '../base-components/container';
import { BoardZone } from './board-types';
import { BoardLocalData } from './board-local-data';
import { ComponentChild, ComponentChildren, JSX } from 'preact';
import { validateCallback } from '../utils';
import { BoardCardSlotComponent } from './board-card-slot-component';
import { HIGHLIGHT_CLASS_NAME, OVERLAY_CLASS_NAME } from '../components/ui-constants';

export type BoardZoneComponentProps = {
    style?: CSSProperties;
    localData: BoardLocalData;
    zone: BoardZone;
};

export function BoardZoneComponent(props: BoardZoneComponentProps) {
    let { localData, zone } = props;
    let { label } = zone.metadata;
    let style: CSSProperties = {
        borderColor: 'black',
        position: 'relative',
        padding: 0,
        margin: 0,
        ...props.style,
    };
    let legend = label;

    if (zone.cards.length > 0) {
        legend += ` (${zone.cards.length})`;
    }

    let showLast = zone.metadata.kind === 'stack';
    let gap = zone.metadata.kind === 'stack' ? 0 : 15;
    let topOfStack = zone.cards.at(-1);

    let items: ComponentChild[] = zone.cards.map((card) => <BoardCardSlotComponent
        localData={localData}
        card={card}
        sourceZone={zone}
        hidden={showLast && card !== topOfStack}
    />);
    let content: ComponentChildren = items;

    let isValidDropTarget = !!localData.getInteractedCard('dragged') && (localData.getInteractedCard('dragged')?.getZone() !== zone || zone.metadata.kind === 'spread');

    let onDrop = (evt: DragEvent) => {
        let droppedCard = localData.getInteractedCard('dragged');

        if (droppedCard) {
            localData.board.moveCardToZone(droppedCard, zone);
            localData.notifyBoardUpdated();
        }

        (evt.target as HTMLElement).classList.remove(HIGHLIGHT_CLASS_NAME);
    };

    let onDragEnter = (evt: DragEvent) => {
        (evt.target as HTMLElement).classList.add(HIGHLIGHT_CLASS_NAME);
    };

    let onDragLeave = (evt: DragEvent) => {
        (evt.target as HTMLElement).classList.remove(HIGHLIGHT_CLASS_NAME);
    };

    let onDragOver = (evt: DragEvent) => {
        evt.preventDefault();
    };

    let overlay: any = <div
        className={OVERLAY_CLASS_NAME}
        style={{ pointerEvents: isValidDropTarget ? 'auto' : 'none' }}
        onDragEnter={validateCallback(onDragEnter, isValidDropTarget)}
        onDragOver={validateCallback(onDragOver, isValidDropTarget)}
        onDragLeave={validateCallback(onDragLeave, isValidDropTarget)}
        onDrop={validateCallback(onDrop, isValidDropTarget)}
    ></div>;

    let legentStyle: CSSProperties = {
        fontSize: 'small',
        whiteSpace: 'nowrap',
        marginLeft: '20px'
    };

    return (
        <Row
            tag='fieldset'
            className='zone'
            style={style}
            justifyContent='center'
            alignItems='center'
            gap={gap}
        >
            {overlay}
            <legend style={legentStyle}>{legend}</legend>
            {content}
        </Row>
    );
};