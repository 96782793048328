/**
 * Outpost's renderer uses a virtual viewport, which is internally mapped to the actual canvas.
 * It also defines the canvas' aspect ratio.
 * 
 * For example this means that by default, a component positionned at (0, 0) will be displayed on the top-left corner of the canvas,
 * and a component positionned at (1600, 900) will be displayed on the bottom-right corner of the canvas.
 * 
 * - You can change the virtual viewport size with {@link ComponentApi.configureRenderer}.
 * - You can change how a scene maps to the virtual viewport with {@link AnimationQueue.updateScene}.
 */
export const DEFAULT_VIRTUAL_VIEWPORT: [number, number] = [1600, 900];

export const DEFAULT_IMAGE_COLOR_REPLACE_DISTANCE_THRESHOLD = 0.05;

// export const DATA_TEXTURE_BLOCK_SIZE = 12;
export const DATA_TEXTURE_BLOCK_SIZE = 16;

// Number of milliseconds an image stays loaded in the GPU after it is no longer used.
export const DEFAULT_IMAGE_CACHE_DURATION = 0;