import { Room } from '../../framework/global/global-room-api.ts';
import { View } from '../../framework/view/view.ts';
import { GearCategory } from '../hub.ts';
import { GearCategorySelectionPanel } from './gear-category-selection-panel.ts';
import { GearSelectableItem } from './gear-selectable-item.ts';

export class DeckBuildingPanel extends GearCategorySelectionPanel {
    constructor(params: GearCategory) {
        super(params);
    }

    render(view: View) {
        view.paint({
            color: 'lightgreen'
        });

        let availableSectionName = this.params.availableSectionName ?? 'Available';
        let selectedSectionName = this.params.selectedSectionName ?? 'Selected';
        let [availableRect, selectedRect] = view.rect.split('left', ['50%w']).map(rect => rect.strip(20));

        let selectedCount = this.selectedItems.reduce((acc, item) => acc + item.selectedCount, 0);

        selectedSectionName += ` @0.5{(${selectedCount}/${this.maxRequireSotalSelectedCount})}`;

        view.addGrid({
            rect: availableRect,
            items: this.availableItems,
            header: { text: availableSectionName, textSize: '50%' },
            itemAspectRatio: 4,
            allowScroll: true,
            rowSize: 2,
            columnSize: 6,
            background: [
                { color: 'blanchedalmond', borderRadius: 50 },
                { key: 'border', color: 'black', strokeSize: 5}
            ],
            scrollBar: { color: 'black', borderRadius: '50%w', offsetX: -4 }
        });

        view.addGrid({
            rect: selectedRect,
            items: this.selectedItems,
            header: { text: selectedSectionName, textSize: '50%' },
            itemAspectRatio: 4,
            allowScroll: true,
            rowSize: 1,
            columnSize: 6,
            background: [
                { color: 'blanchedalmond', borderRadius: 50 },
                { key: 'border', color: 'black', strokeSize: 5}
            ],
            scrollBar: { color: 'black', borderRadius: '50%w', offsetX: -4 }
        });
    }

    async $selectItem() {
        let input = await Room.waitForUserInput({
            selectable: this.availableItems,
        });

        let selectedItem = this.selectedItems.find(item => item.target === input.selection.target);
        let itemSelectedCount = selectedItem?.selectedCount ?? 0;
        let itemMaxSelectableCount = this.getItemMaxSelectableCount(input.selection.target);
        let totalSelectedCount = this.selectedItems.reduce((acc, item) => acc + item.selectedCount, 0);
        let totalSelectableCount = this.maxRequireSotalSelectedCount;

        if (itemSelectedCount >= itemMaxSelectableCount || totalSelectedCount >= totalSelectableCount) {
            return;
        }

        if (!selectedItem) {
            selectedItem = new GearSelectableItem(input.selection.target, input.selection.targetIndex);
            this.selectedItems.push(selectedItem);
            this.selectedItems.sort((a, b) => a.targetIndex - b.targetIndex);
        }

        selectedItem.selectedCount += 1;

        Room.refresh(this);
    }

    async $unselectItem() {
        let input = await Room.waitForUserInput({
            selectable: this.selectedItems,
        });

        let selectedItem = input.selection;

        selectedItem.selectedCount -= 1;

        if (selectedItem.selectedCount <= 0) {
            this.selectedItems.remove(selectedItem);
        }

        Room.refresh(this);
    }
}
globalThis.ALL_FUNCTIONS.push(DeckBuildingPanel);