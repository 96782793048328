import { forwardComponentHighlightMethods } from '../../framework/component/component-utils.ts';
import { Component } from '../../framework/component/component.ts';
import { View } from '../../framework/view/view.ts';

export class GearSelectableItem implements Component {
    target: Component;
    targetIndex: number;
    selectedCount: number = 0;

    constructor(target: Component, targetIndex: number) {
        this.target = target;
        this.targetIndex = targetIndex;

        forwardComponentHighlightMethods(target, this);
    }

    render(view: View) {
        this.target.render(view);

        if (this.selectedCount > 1) {
            let rect = view.rect.fromRightOutwards('*', '*').stripToMatchAspectRatio(1, 'left');
            // let rect = view.rect.fromLeftOutwards('*', '*').stripToMatchAspectRatio(1, 'right');

            view.paint({
                key: 'count',
                rect,
                text: `x${this.selectedCount}`,
                textSize: '50%'
            });
        }
    }
}
globalThis.ALL_FUNCTIONS.push(GearSelectableItem);