import { OUTPOST_SERVER_URL } from './constants.ts';

export type SpreadsheetData = {
    id: string;
    title: string;
    sheets: SheetData[];
};

export type SheetData = {
    name: string;
    rows: string[][];
};

type FetchSpreadsheetRequest = {
    spreadsheetId: string;
};

type RequestResponse = {
    code: number;
    content?: string;
    error?: string;
    data?: any;
};

export async function fetchGoogleSpreadsheet(params: { url: string } | { id: string }): Promise<SpreadsheetData> {
    let spreadsheetId: string;

    if ('url' in params) {
        let parsed = getSpreadsheetId(params.url);

        if (!parsed) {
            throw new Error(`Invalid google sheet url: ${params.url}`);
        }

        spreadsheetId = parsed;
    } else {
        spreadsheetId = params.id;
    }

    let response = await getJson<RequestResponse, FetchSpreadsheetRequest>(`${OUTPOST_SERVER_URL}/utils/spreadsheet`, { spreadsheetId });

    if (response.code !== 200) {
        throw new Error(`Invalid response code ${response.code}: ${response.error}`);
    }

    return response.data;
}

export function getSpreadsheetId(url: string): string | undefined {
    let match = url.match(/^https?:\/\/docs\.google\.com\/spreadsheets\/d\/([\w-]+).*$/);
    let id = match?.[1];

    return id;
}

export async function getJson<T, U extends object = {}>(url: string, params: U): Promise<T> {
    let fullUrl = url;
    let entries = Object.entries(params ?? {});

    if (entries.length > 0) {
        fullUrl += '?';
    }

    fullUrl += entries.map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join('&');

    let response = await fetch(fullUrl);

    let raw = await response.text();

    try {
        return JSON.parse(raw) as T;
    } catch (err) {
        console.error(raw);
        throw new Error(`did not receive JSON`);
    }
}