import { BoardLocalData } from './board-local-data';
import { CSSProperties } from 'preact/compat';
import { BoardCard } from './board-card';

export type BoardCardFrontComponentProps = {
    localData: BoardLocalData;
    card: BoardCard;
};

export function BoardCardFrontComponent(props: BoardCardFrontComponentProps) {
    let { localData, card } = props;
    let color = localData.getPlayerColor(card.ownerId);
    let style: CSSProperties = {
        boxSizing: 'border-box',
        border: `1px solid ${color}`,
        maxWidth: '100%',
        maxHeight: '100%',
        display: 'block',
        pointerEvents: 'none'
    };

    return <img
        style={style}
        src={localData.getImageSrc(card.cardId)}
    />
};