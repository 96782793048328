import { Rect } from '../../utils/geometry/rect.ts';
import { GraphicsAttributeList } from '../graphics-engine/graphics/graphics-attribute-list.ts';
import { LayerId } from '../graphics-engine/layer-types.ts';

export const MAIN_ATOM_KEY = '_main';

export enum ViewFragmentPriority {
    Invalid,
    Parent,
    Self,
    Transition,
    Interaction,
    Flash,
    Dimmed,
    Highlighted,
    Disabled,
    Focused,
    Linked,
    Hovered,
    Pressed,
}

export const TRANSITION_PRIORITIES: ViewFragmentPriority[] = [ViewFragmentPriority.Self, ViewFragmentPriority.Transition, ViewFragmentPriority.Flash];

export enum ViewState {
    Pending,
    Alive,
    Dead
}

export type ViewTooltipOptions = {
    showDelay?: number;
    hideDelay?: number;
};

export type ViewAtom = {
    key: string;
    index: number;
    attributes: GraphicsAttributeList;
};

export type ViewBasicAttributes = {
    rect: Rect;
    layerId: LayerId;
    detectable: boolean;
    mirrorX: number | null;
    mirrorY: number | null;
};

export function getDefaultBasicAttributes(): ViewBasicAttributes {
    return {
        layerId: null,
        detectable: true,
        rect: Rect.zero(),
        mirrorX: null,
        mirrorY: null
    };
}

export const VIEW_FRAGMENT_PRIORITY_COUNT = Object.values(ViewFragmentPriority).length / 2;