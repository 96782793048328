export class ReadonlyMap<K, V> {
    private wrappedMap: Map<K, V> = new Map();

    [Symbol.iterator]() {
        return this.wrappedMap[Symbol.iterator]();
    }

    has(key: K) {
        return this.wrappedMap.has(key);
    }

    get(key: K): V | undefined {
        return this.wrappedMap.get(key);
    }

    entries() {
        return this.wrappedMap.entries();
    }

    values() {
        return this.wrappedMap.values();
    }

    keys() {
        return this.wrappedMap.keys();
    }

    forEach(callback: (value: V, key: K) => void) {
        this.wrappedMap.forEach(callback);
    }
}
globalThis.ALL_FUNCTIONS.push(ReadonlyMap);