export const WEBGL_TEXTURE_FILTER = {
    'linear': (gl, target) => {
        gl.texParameteri(target, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(target, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.texParameteri(target, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        gl.texParameteri(target, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    },
    'nearest': (gl, target) => {
        gl.texParameteri(target, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(target, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.texParameteri(target, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
        gl.texParameteri(target, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
    },
} satisfies { [key: string]: (gl: WebGL2RenderingContext, target: number) => void };

export type WebglTextureFilter = keyof typeof WEBGL_TEXTURE_FILTER;

export const WEBGL_TEXTURE_FILTER_ENUM = Object.keys(WEBGL_TEXTURE_FILTER) as WebglTextureFilter[];

export function applyWebglTextureFilter(gl: WebGL2RenderingContext, target: number, filter: WebglTextureFilter) {
    WEBGL_TEXTURE_FILTER[filter](gl, target);
}