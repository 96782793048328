import { Logger } from '../logging/logger.ts';

export type ImageLike = HTMLImageElement | HTMLCanvasElement;
export const IMAGE_EXTENSIONS = ['.png', '.jpg', '.jpeg', '.bmp', '.webm'];

export class ImageLoader<T = any> {
    private images: Map<string, ImageLike | null | Promise<ImageLike | null>> = new Map();
    private imagesMetadata: Map<string, T> = new Map();
    private onLoadCallbacks: ((imageUrl: string) => void)[] = [];

    get(url: string, loadIfMissing: boolean = true): ImageLike | null {
        if (!url) {
            return null;
        }

        let image = this.images.get(url);

        if (image && !(image instanceof Promise)) {
            return image;
        }

        if (image === undefined && loadIfMissing) {
            let promise = loadImage(url).then(result => {
                if (!result) {
                    Logger.warn(`could not load image "${url}"`);
                }

                this.images.set(url, result);

                for (let callback of this.onLoadCallbacks) {
                    callback(url);
                }

                return result;
            });
            this.images.set(url, promise);
        }

        return null;
    }

    has(url: string): boolean {
        return this.images.has(url);
    }

    register(url: string, image: ImageLike) {
        this.images.set(url, image);
    }

    unregister(url: string) {
        this.images.delete(url);
        this.imagesMetadata.delete(url);
    }

    async load(url: string): Promise<ImageLike | null> {
        let result = this.images.get(url);

        if (result === undefined) {
            this.get(url);
            result = this.images.get(url) as Promise<ImageLike | null>;
        }

        return result;
    }

    setMetadata(url: string, metadata: T) {
        this.imagesMetadata.set(url, metadata);
    }

    getMetadata(url: string): T | undefined {
        return this.imagesMetadata.get(url);
    }

    onLoad(callback: (imageUrl: string) => void) {
        this.onLoadCallbacks.push(callback);
    }
}

export function loadImage(url: string): Promise<HTMLImageElement | null> {
    return new Promise(resolve => {
        let image = new Image();
        image.src = url;
        image.onload = () => {
            resolve(image);
        };
        image.onerror = () => {
            resolve(null);
        }
    });
}
globalThis.ALL_FUNCTIONS.push(ImageLoader);