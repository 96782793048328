import { BoardZoneComponent } from './board-zone-component';
import { BoardPlayer } from './board-types';
import { BoardLocalData } from './board-local-data';
import { CSSProperties } from 'preact/compat';
import { BoardCardTooltipComponent } from './board-card-tooltip-component';
import { BoardInspectZoneComponent } from './board-inspect-zone-component';

export type BoardPlayerComponentProps = {
    style?: CSSProperties;
    localData: BoardLocalData;
    player: BoardPlayer;
};

export function BoardPlayerComponent(props: BoardPlayerComponentProps) {
    let { localData, player } = props;
    let style: CSSProperties = {
        position: 'relative',
        display: 'grid',
        gridTemplateColumns: '6fr 1fr 1fr 1fr',
        gridTemplateRows: '1fr 1fr',
        gap: '10px',
        flexGrow: 1,
        ...props.style,
    };

    let isSelf = player.playerId === localData.playerId;
    let firstRow = isSelf ? '1 / 2' : '2 / 3';
    let secondRow = isSelf ? '2 / 3' : '1 / 2';

    return (
        <div style={style}>
            <BoardZoneComponent
                localData={localData}
                zone={player.zones.battlefield}
                style={{
                    gridRow: firstRow,
                    gridColumn: '1 / 2',
                }}
            />
            <BoardZoneComponent
                localData={localData}
                zone={player.zones.stack}
                style={{
                    gridRow: firstRow,
                    gridColumn: '2 / 4',
                }}
            />
            <BoardZoneComponent
                localData={localData}
                zone={player.zones.hero}
                style={{
                    gridRow: firstRow,
                    gridColumn: '4 / 5',
                }}
            />
            <BoardZoneComponent
                localData={localData}
                zone={player.zones.hand}
                style={{
                    gridRow: secondRow,
                    gridColumn: '1 / 2',
                }}
            />
            <BoardZoneComponent
                localData={localData}
                zone={player.zones.deck}
                style={{
                    gridRow: secondRow,
                    gridColumn: '2 / 3',
                }}
            />
            <BoardZoneComponent
                localData={localData}
                zone={player.zones.graveyard}
                style={{
                    gridRow: secondRow,
                    gridColumn: '3 / 4',
                }}
            />
            <BoardZoneComponent
                localData={localData}
                zone={player.zones.exile}
                style={{
                    gridRow: secondRow,
                    gridColumn: '4 / 5',
                }}
            />
            <BoardInspectZoneComponent
                localData={localData}
                sideId={player.playerId}
            />
            <BoardCardTooltipComponent
                localData={localData}
                sideId={player.playerId}
            />
        </div>
    );
};