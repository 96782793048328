import { View } from '../view/view.ts';
import { ComponentRenderMethods } from './component-types.ts';
import { Component } from './component.ts';

const COMPONENT_RENDER_METHODS: ComponentRenderMethods[] = [
    'render',
    'tooltip',
    'highlightEnabled',
    'highlightDimmed',
    'highlightHovered',
    'highlightFocused',
    'highlightPressed',
    'highlightDisabled',
];

export function forwardComponentHighlightMethods(source: Component, target: Component) {
    for (let methodName of COMPONENT_RENDER_METHODS) {
        if (!target[methodName] && typeof source[methodName] === 'function') {
            (target as any)[methodName] = (view: View) => source[methodName]!(view);
        }
    }
}