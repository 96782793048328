import { ComponentModifier, mergeComponentModifiers } from '../../framework/component/component-modifier.ts';
import { Component, ComponentLike } from '../../framework/component/component.ts';
import { DummyComponent, formatComponent, formatOptionalComponent } from '../../framework/component/dummy-component.ts';
import { Room } from '../../framework/global/global-room-api.ts';
import { LayerId } from '../../framework/graphics-engine/layer-types.ts';
import { View } from '../../framework/view/view.ts';
import { DisplaySize, DisplaySizeLike } from '../../utils/geometry/display-size.ts';
import { HorizontalAlign } from '../../utils/geometry/horizontal-align.ts';
import { GridLayoutParams } from '../../utils/geometry/rect-types.ts';
import { Rect, RectLike } from '../../utils/geometry/rect.ts';
import { Vector } from '../../utils/geometry/vector.ts';
import { VerticalAlign } from '../../utils/geometry/vertical-align.ts';
import { Collection, collectionToArray } from '../../utils/language/collection.ts';
import { clamp } from '../../utils/language/math.ts';
import { getMaxGridDimension, getMinGridDimension } from '../../utils/language/range.ts';
import { or } from '../../utils/language/utils.ts';
import { LayoutDirection } from '../../utils/layout/layout-direction.ts';

export type AddGridParams = {
    rect?: RectLike | ((rect: Rect) => Rect);
    layerId?: LayerId | null;
    background?: ComponentLike;
    header?: ComponentLike;
    footer?: ComponentLike;
    items?: Collection<ComponentLike>;
    itemModifier?: ComponentModifier;
    allowScroll?: boolean;
    scrollBarSize?: DisplaySizeLike;
    headerSize?: DisplaySizeLike;
    footerSize?: DisplaySizeLike;
    scrollBar?: ComponentLike;
    modifier?: ComponentModifier;

    fillFirst?: GridFillFirst;
    innerMargin?: DisplaySizeLike;
    outerMargin?: DisplaySizeLike;
    margin?: DisplaySizeLike;
    rowSize?: number | [number, number];
    columnSize?: number | [number, number];
    horizontalAlign?: HorizontalAlign;
    verticalAlign?: VerticalAlign;
    ignoreEmptyCells?: boolean;
    itemAspectRatio?: number;
    flipRows?: boolean;
    flipColumns?: boolean;
};

export type GridFillFirst = 'row' | 'column' | 'auto';

const DEFAULT_ALLOW_SCROLL: boolean = true;
const DEFAULT_SCROLL_BAR_SIZE: DisplaySizeLike = '15';
const DEFAULT_HEADER_SIZE: DisplaySizeLike = '20%h';
const DEFAULT_FOOTER_SIZE: DisplaySizeLike = '20%h';
const DEFAULT_MARGIN: DisplaySizeLike = '1%h';

export class GridPanel implements Component {
    allowScroll: boolean = DEFAULT_ALLOW_SCROLL
    scrollBarSize: DisplaySizeLike = DEFAULT_SCROLL_BAR_SIZE;
    headerSize: DisplaySizeLike = DEFAULT_HEADER_SIZE;
    footerSize: DisplaySizeLike = DEFAULT_FOOTER_SIZE;

    layerId: LayerId | null = null;
    items: Component[] = [];
    itemModifier: ComponentModifier | null = null;
    modifier: ComponentModifier | null = null;
    background: Component | null = null;
    header: Component | null = null;
    footer: Component | null = null;
    scrollBar: Component | null = null;
    canScroll: boolean = true;

    private margin: DisplaySizeLike | null = null;
    private innerMargin: DisplaySizeLike | null = null;
    private outerMargin: DisplaySizeLike | null = null;;
    private horizontalAlign: HorizontalAlign = 'center';
    private verticalAlign: VerticalAlign = 'middle';
    private ignoreEmptyCells: boolean = false;
    private itemAspectRatio: number = 1;
    private flipRows: boolean = false;
    private flipColumns: boolean = false;

    private startScroll: number = 0;
    private scroll: number = 0;
    private minRowSize: number = 0;
    private maxRowSize: number = 0;
    private minColumnSize: number = 0;
    private maxColumnSize: number = 0;
    private isHorizontal: boolean = true;
    private scrollDirection: LayoutDirection = 'top-to-bottom';
    private stepSize: number = 0
    private displayedItemCount: number = 0;
    private scrollBarRect: Rect = Rect.ZERO;

    setup(params: AddGridParams, getDummyComponent?: () => DummyComponent) {
        this.layerId = params.layerId ?? null;
        this.items = collectionToArray(params.items).map(item => formatComponent(item));
        this.allowScroll = params.allowScroll ?? DEFAULT_ALLOW_SCROLL;
        this.scrollBarSize = params.scrollBarSize ?? DEFAULT_SCROLL_BAR_SIZE;
        this.headerSize = params.headerSize ?? DEFAULT_HEADER_SIZE;
        this.footerSize = params.footerSize ?? DEFAULT_FOOTER_SIZE;
        this.itemModifier = params.itemModifier ?? null;
        this.modifier = params.modifier ?? null;
        this.margin = or(params.margin, DEFAULT_MARGIN);
        this.innerMargin = or(params.innerMargin, null);
        this.outerMargin = or(params.outerMargin, null);
        this.background = formatOptionalComponent(params.background, getDummyComponent);
        this.header = formatOptionalComponent(params.header, getDummyComponent);
        this.footer = formatOptionalComponent(params.footer, getDummyComponent);
        this.scrollBar = formatComponent(params.scrollBar ?? { color: 'black' }, getDummyComponent);
        this.minRowSize = getMinGridDimension(params.rowSize);
        this.maxRowSize = getMaxGridDimension(params.rowSize);
        this.minColumnSize = getMinGridDimension(params.columnSize);
        this.maxColumnSize = getMaxGridDimension(params.columnSize);
        this.isHorizontal = params.fillFirst === 'row' ? false :
            params.fillFirst === 'column' ? true :
            this.maxRowSize > this.maxColumnSize;
        this.canScroll = this.allowScroll && this.minRowSize === this.maxRowSize && this.minColumnSize === this.maxColumnSize;
        this.scrollDirection = this.isHorizontal ? 'left-to-right' : 'top-to-bottom';
        this.stepSize = this.isHorizontal ? this.maxColumnSize : this.maxRowSize;
        this.displayedItemCount = this.maxRowSize * this.maxColumnSize;
        this.horizontalAlign = params.horizontalAlign ?? 'center';
        this.verticalAlign = params.verticalAlign ?? 'middle';
        this.ignoreEmptyCells = params.ignoreEmptyCells ?? false;
        this.itemAspectRatio = params.itemAspectRatio ?? 1;
        this.flipRows = params.flipRows ?? false;
        this.flipColumns = params.flipColumns ?? false;
    }

    add(item: Component, index?: number) {
        let minIndex = 0;
        let maxIndex = this.items.length;
        let insertIndex = clamp(index ?? maxIndex, minIndex, maxIndex);

        this.items.splice(insertIndex, 0, item);
    }

    remove(item: Component): number {
        let index = this.items.indexOf(item);

        if (index !== -1) {
            this.items.splice(index, 1);
        }

        return index;
    }

    setItems(items: Component[]) {
        this.items = items;
    }

    setScroll(value: number) {
        let roundedTotalItemCount = Math.ceil(this.items.length / this.stepSize) * this.stepSize;
        let maxScroll = (roundedTotalItemCount - this.displayedItemCount) / this.stepSize;
        let newScroll = clamp(value, 0, maxScroll);

        if (newScroll !== this.scroll) {
            this.scroll = newScroll;

            Room.render(this);
        }
    }

    async $dragScrollbar() {
        let input = await Room.waitForUserInput({
            selectable: this.scrollBar,
            selectionTrigger: 'down'
        });

        this.startScroll = this.scroll;

        let start = input.position;

        while (true) {
            let input = await Room.waitForUserInput({
                predicate: input => input.action === 'move' || (input.action === 'up' && input.button === 'MouseLeft')
            });
            let offset = start.getVectorTo(input.position);
            this.onDragProgress(offset);

            if (input.action === 'up') {
                break;
            }
        }
    }

    async $wheel() {
        let input = await Room.waitForScroll();
        let pointerPosition = Room.getPointerPosition(this.layerId);

        if (Room.getViewRect(this)?.containsPoint(pointerPosition)) {
            this.setScroll(this.scroll + Math.sign(input.scrollDeltaY));
        }
    }

    onDragProgress(dragOffset: Vector) {
        let stepRatio = this.stepSize / this.items.length;
        let bigSideSize = this.scrollBarRect.width;
        let { x: dx, y: dy } = dragOffset
        let movedValue = dx;

        if (this.scrollDirection === 'top-to-bottom') {
            bigSideSize = this.scrollBarRect.height;
            movedValue = dy;
        }

        let sign = Math.sign(movedValue);
        let value = Math.abs(movedValue);
        let scrollDif = sign * Math.round(value / (stepRatio * bigSideSize));

        this.setScroll(this.startScroll + scrollDif);
    }

    setHeader(header: ComponentLike | null) {
        this.header = formatOptionalComponent(header);
    }

    render(view: View): void {
        let headerSize = this.header ? this.headerSize : 0;
        let footerSize = this.footer ? this.footerSize : 0;
        let [headerRect, contentRect, footerRect] = view.rect.split('top', [headerSize, null, footerSize]);

        view.paint({ layerId: this.layerId });
        
        if (this.background) {
            view.addChild(this.background);
        }

        if (this.header) {
            view.addChild(this.header, mergeComponentModifiers(headerRect, this.modifier));
        }

        if (this.footer) {
            view.addChild(this.footer, mergeComponentModifiers(footerRect, this.modifier));
        }

        this.renderItems(view, contentRect);

        if (this.canScroll && this.items.length > this.displayedItemCount) {
            this.renderScrollBar(view, contentRect);
        }
    }

    renderItems(view: View, rect: Rect) {
        let appendHorizontalThenVertical = !this.isHorizontal;
        let gridLayout: Partial<GridLayoutParams> = {
            itemCount: this.items.length,
            horizontalAlign: this.horizontalAlign,
            verticalAlign: this.verticalAlign,
            ignoreEmptyCells: this.ignoreEmptyCells,
            itemAspectRatio: this.itemAspectRatio,
            innerMargin: DisplaySize.resolve(this.innerMargin ?? this.margin ?? 0, rect),
            outerMargin: DisplaySize.resolve(this.outerMargin ?? this.margin ?? 0, rect),
            rowSize: [this.minRowSize, this.maxRowSize],
            columnSize: [this.minColumnSize, this.maxColumnSize],
            fillFirst: appendHorizontalThenVertical ? 'row' : 'column',
            flipRows: this.flipRows,
            flipColumns: this.flipColumns,
        };

        let { items, rowSize, columnSize } = rect.asGrid(gridLayout);
        let stepSize = appendHorizontalThenVertical ? rowSize : columnSize;
        let maxScroll = Math.max(0, Math.ceil((this.items.length - items.length) / stepSize));
        let startIndex = Math.ceil(clamp(this.scroll, 0, maxScroll)) * stepSize;

        for (let i = 0; i < this.items.length; ++i) {
            let item = this.items[i];
            let itemRect = items[i - startIndex] ?? Rect.ZERO;

            view.addChild(item, mergeComponentModifiers(itemRect, this.modifier, this.itemModifier));
        }
    }

    renderScrollBar(view: View, contentRect: Rect) {
        this.scrollBarRect = this.isHorizontal
            ? contentRect.fromBottomInwards('*', this.scrollBarSize)
            : contentRect.fromRightInwards(this.scrollBarSize, '*');

        let totalStepCount = Math.ceil(Math.max(this.items.length, this.displayedItemCount) / this.stepSize);
        let hiddenStartStepCount = this.scroll;
        let displayedStepCount = this.displayedItemCount / this.stepSize;
        let hiddenEndStepCount = totalStepCount - hiddenStartStepCount - displayedStepCount;

        view.layout()
            .setRootRect(this.scrollBarRect)
            .direction(this.scrollDirection)
            .addChild(null)
            .force(hiddenStartStepCount)
            .addChild(this.scrollBar)
            .force(displayedStepCount)
            .addChild(null)
            .force(hiddenEndStepCount);
    }
}
globalThis.ALL_FUNCTIONS.push(GridPanel);