import { TypeKind, TypeSchema } from './type-schema.ts';

export function booleanSchema(): TypeSchema<boolean> {
    return {
        kind: TypeKind.Boolean,
        optional: false,
        check(ctx, value) {
            if (typeof value === 'boolean') {
                return true;
            } else {
                return ctx.expected('boolean', value);
            }
        },
        serialize(buffer, value) {
            buffer.writeBoolean(value);
        },
        deserialize(buffer) {
            return buffer.readBoolean();
        },
    };
};