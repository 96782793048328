import { Connection, connectToServer, FlexBuffer, makePromise } from 'outpost';
import { ClientMessageKind, ClientMessageParams, IceCandidateParams, OnGuestJoinRoomParams, OnHostWelcomeToRoomParams, ServerMessageKind } from '../network-types';
import { BoardInitParams, PeerMessageKind, PeerMessageParams } from './client-connection-types';
import { SERIALIZABLE_ASSETS } from '../network-constants';
import { BoardLocalData } from './board-local-data';

const RTC_CONFIG: RTCConfiguration = { iceServers: [{ urls: 'stun:stun.l.google.com:19302' }] }; // STUN server

export type ClientConnectionParams = {
    label: string;
};

export class ClientConnection {
    private label: string;
    private buffer: FlexBuffer = new FlexBuffer();
    private serverConnection!: Connection;
    private peerConnection = new RTCPeerConnection(RTC_CONFIG);
    private connectedToPeer: boolean = false;
    private dataChannel = this.peerConnection.createDataChannel('channel');
    private onConnectedToServer = makePromise<ClientConnection>();
    private onConnectedToPeer = makePromise<BoardInitParams | null>();
    private localData: BoardLocalData | null = null;

    private constructor(params: ClientConnectionParams) {
        this.label = params.label;
    }

    static async start(params: ClientConnectionParams): Promise<ClientConnection> {
        let connection = new ClientConnection(params);

        return await connection.start();
    }

    private async start(): Promise<ClientConnection> {
        this.serverConnection = connectToServer({
            onConnect: (connection) => this.onConnect(connection),
            onDisconnect: (connection) => this.onDisconnect(connection),
            onMessage: (connection, data) => this.onServerMessage(connection, data),
        });

        this.peerConnection.onicecandidate = (evt) => this.onIceCandidate(evt);
        this.peerConnection.ondatachannel = (event) => {
            event.channel.onmessage = (evt) => this.onPeerMessage(evt);
            this.onPeerConnected();
        };

        return this.onConnectedToServer.promise;
    }

    private onConnect(connection: Connection) {
        this.onConnectedToServer.resolve(this);
    }

    private onDisconnect(connection: Connection) {

    }

    private onServerMessage(connection: Connection, arrayBuffer: ArrayBuffer) {
        let buffer = new FlexBuffer(arrayBuffer);
        let kind = buffer.readString() as ServerMessageKind;
        let params = buffer.readAny();

        // console.log(`${this.label}: receive from server ${kind}`);

        if (this.connectedToPeer) {
            return;
        }

        if (kind === 'onGuestJoinRoom') {
            this.onGuestJoinRoom(params);
        } else if (kind === 'onHostWelcomeToRoom') {
            this.onHostWelcomeToRoom(params);
        } else if (kind === 'onIceCandidate') {
            this.onRemoteIceCandidate(params);
        } else if (kind === 'onInvalidRoom') {
            this.onConnectedToPeer.resolve(null);
        }
    }

    private sendMessageToServer<K extends ClientMessageKind>(kind: K, params: ClientMessageParams[K]) {
        // console.log(`${this.label}: send to server ${kind}`);
        this.buffer.reset();
        this.buffer.writeString(kind);
        this.buffer.writeAny(params);
        this.serverConnection.sendMessage(this.buffer);
    }

    async createRoom(localData: BoardLocalData): Promise<string> {
        let roomId = crypto.randomUUID();
        let rtcOffer = await this.peerConnection.createOffer();

        await this.peerConnection.setLocalDescription(rtcOffer);

        this.sendMessageToServer('hostCreateRoom', { roomId, rtcOffer });

        this.localData = localData;

        return roomId;
    }

    async joinRoom(roomId: string) {
        this.sendMessageToServer('guestJoinRoom', { roomId });
    }

    private async onHostWelcomeToRoom(params: OnHostWelcomeToRoomParams) {
        await this.peerConnection.setRemoteDescription(new RTCSessionDescription(params.rtcOffer));

        let rtcAnswer = await this.peerConnection.createAnswer();
        await this.peerConnection.setLocalDescription(rtcAnswer);

        this.sendMessageToServer('guestConfirm', { rtcAnswer });
    }

    private async onGuestJoinRoom(params: OnGuestJoinRoomParams) {
        await this.peerConnection.setRemoteDescription(new RTCSessionDescription(params.rtcAnswer));
    }

    private onIceCandidate(event: RTCPeerConnectionIceEvent) {
        let iceCandidate = event.candidate;

        if (iceCandidate) {
            this.sendMessageToServer('iceCandidate', {
                iceCandidate: iceCandidate.toJSON()
            });
        }
    }

    private async onRemoteIceCandidate(params: IceCandidateParams) {
        await this.peerConnection.addIceCandidate(new RTCIceCandidate(params.iceCandidate));
    }

    private onPeerConnected() {
        if (this.localData) {
            // Host
            this.sendPeerMessage('init', {
                projectHash: this.localData.project.getHash(),
                spreadsheetId: this.localData.project.spreadsheetId,
            });
        }
    }
    private onPeerMessage(evt: MessageEvent<any>) {
        let buffer = new FlexBuffer(evt.data);
        let kind = buffer.readString() as PeerMessageKind;
        let params = buffer.readAny(SERIALIZABLE_ASSETS);

        // console.log(`${this.label}: receive from peer ${kind}`);
        // console.log(params);

        if (kind === 'init') {
            this.onConnectedToPeer.resolve(params);
        } else if (kind === 'ready') {
            this.onConnectedToPeer.resolve(null);
            this.localData!.setConnection(this);
            this.sendBoardState();
        } else if (kind === 'board') {
            if (!this.localData!.connection) {
                this.localData!.setConnection(this);
            }

            this.localData!.board = params;
            this.localData!.forceRefresh();
        }
    }

    private sendPeerMessage<K extends PeerMessageKind>(kind: K, params: PeerMessageParams[K]) {
        // console.log(`${this.label}: send to peer ${kind}`);
        this.buffer.reset();
        this.buffer.writeString(kind);
        this.buffer.writeAny(params, SERIALIZABLE_ASSETS);
        this.dataChannel.send(this.buffer.copyBytes());
    }

    notifyGuestReady(localData: BoardLocalData) {
        this.localData = localData;
        this.sendPeerMessage('ready', {});
    }

    async whenPeerConnected(): Promise<BoardInitParams | null> {
        return this.onConnectedToPeer.promise;
    }

    sendBoardState() {
        this.sendPeerMessage('board', this.localData!.board);
    }
}
globalThis.ALL_FUNCTIONS.push(ClientConnection);