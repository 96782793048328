import { mix } from '../language/math.ts';

/**
 * Union type used by {@link Color.from} to create a new color, depending to what is specified:
 * - `Color`: creates a copy of the color
 * - `null`: creates a color with all channels set to 0
 * - `number` creates a color with RGB set to the specified value and alpha to 1.
 * - `[r: number, g: number, b: number]`: creates a color with the specified RGB, and alpha set to 1.
 * - `[r: number, g: number, b: number, a: number]`: creates a color with the specified RGBA
 * - `Float32Array`: creates a color with the specified RGBA (must be of size 3 or 4)
 * - `Uint8Array`: creates a color with the specified RGBA, but values are expected in the range [0-255] (must be of size 3 or 4)
 * - `'#RRGGBB'`: creates a color by parsing the hexadecimal characters (e.g "#4B561F")
 * - `'rgb(r, g, b)'`: creates a color by parsing the specified RGB (each value must be between 0 and 255) (e.g "rgb(110, 50, 240)")
 * - `'rgba(r, g, b, a)'`: creates a color by parsing the specified RGBA (each value must be between 0 and 255, except the alpha which must be between 0 and 1) (e.g "rgb(110, 50, 240, 0.5)")
 * - `name: string`: creates a color from the specified builtin color name according to [this chart](https://www.w3schools.com/cssref/css_colors.php) (e.g "aquamarine")
 */
export type ColorLike =
    | Color
    | null
    | number
    | [number, number, number]
    | [number, number, number, number]
    | Float32Array
    | Uint8Array
    | Uint8ClampedArray
    | `#${string}`
    | `rgb(${string})`
    | `rgba(${string})`
    | keyof typeof NAMED_COLORS;

/**
 * Represents a color with 4 channels RGBA, each being a number between 0 and 1.
 * 
 * All methods returns new instances.
 */
export class Color {
    /**
     * Red channel (between 0 and 1).
     */
    readonly r: number;
    /**
     * Green channel (between 0 and 1).
     */
    readonly g: number;
    /**
     * Blue channel (between 0 and 1).
     */
    readonly b: number;
    /**
     * Alpha channel (between 0 and 1).
     */
    readonly a: number;

    /**
     * 
     * @param r Red channel (between 0 and 1).
     * @param g Green channel (between 0 and 1).
     * @param b Blue channel (between 0 and 1).
     * @param a Alpha channel (between 0 and 1).
     */
    constructor(r: number, g: number, b: number, a: number) {
        this.r = r;
        this.g = g;
        this.b = b;
        this.a = a;
    }

    /**
     * Creates a new color with the specified channels.
     * Values are expected to be in the range [0-255].
     * @param r 
     * @param g 
     * @param b 
     * @param a 
     * @returns 
     */
    static rgb(r: number, g: number, b: number, a: number = 255) {
        return new Color(r / 255, g / 255, b / 255, a / 255);
    }

    /**
     * Creates a new color from a {@link ColorLike}.
     * @param value 
     * @returns 
     */
    static from(value: ColorLike): Color {
        if (!value) {
            return new Color(0, 0, 0, 0);
        } else if (value instanceof Color) {
            return value;
        } else if (typeof value === 'number') {
            return Color.rgb(value, value, value, 1);
        } else if (value instanceof Uint8Array || value instanceof Uint8ClampedArray || Array.isArray(value)) {
            return Color.rgb(value[0], value[1], value[2], value[3] ?? 1);
        } else if (value instanceof Float32Array) {
            return new Color(value[0], value[1], value[2], value[3] ?? 1);
        } else if (value.startsWith('#')) {
            return parseColorHex(value) ?? Color.transparent();
        } else if (value.startsWith('rgb')) {
            return parseColorRgba(value) ?? Color.transparent();
        } else if (value in NAMED_COLORS) {
            return NAMED_COLORS[value as keyof typeof NAMED_COLORS].clone();
        } else {
            return Color.transparent();
        }
    }

    static isColor(content: string): content is Extract<ColorLike, string> {
        return content.toLowerCase() in NAMED_COLORS
            || parseColorHex(content) !== undefined
            || parseColorRgba(content) !== undefined;
    }

    /**
     * Creates a new color by computing the hash of the specified string.
     * This is useful when prototyping, to quickly associate each unique name/id to a unique color.
     * @param string 
     * @returns 
     */
    static fromStringHash(string: string): Color {
        let hash = 0;

        for (let i = 0; i < string.length; ++i) {
            let c = string.charCodeAt(i);

            c = ~c + (c << 15);
            c = c ^ (c >> 12);
            c = c + (c << 2);
            c = c ^ (c >> 4);
            c = c * 2057;
            c = c ^ (c >> 16);

            hash = (hash + c) & 0x3fffffff;
        }

        let r = (hash >> 16) % 256;
        let g = (hash >> 8) % 256;
        let b = (hash >> 0) % 256;

        return Color.rgb(r, g, b);
    }

    /**
     * Indicates if the color is equal to the specified color.
     * @param color 
     * @param maxDistance Maximum distance between two channels to be considered equal.
     * @returns 
     */
    equals(color: ColorLike, maxDistance: ColorLike = 0): boolean {
        let colorFmt = Color.from(color);
        let maxDistanceFmt = Color.from(maxDistance);

        return Math.abs(this.r - colorFmt.r) <= maxDistanceFmt.r
            && Math.abs(this.g - colorFmt.g) <= maxDistanceFmt.g
            && Math.abs(this.b - colorFmt.b) <= maxDistanceFmt.b
            && Math.abs(this.a - colorFmt.a) <= maxDistanceFmt.a;
    }

    /**
     * Creates a copy of the color.
     * @returns 
     */
    clone(): Color {
        return new Color(this.r, this.g, this.b, this.a);
    }

    /**
     * Creates a copy of the color, with its alpha multiplied by the specified value.
     * @param ratio 
     * @returns 
     */
    multAlpha(ratio: number): Color {
        return new Color(this.r, this.g, this.b, this.a * ratio);
    }

    /**
     * Returns the stringified version of the color, in the format: `"rgba(r, g, b, a)"`.
     * RGB are between 0 and 1, while alpha is between 0 and 1.
     * @returns 
     */
    toString(): string {
        return `rgba(${this.r * 255 | 0},${this.g * 255 | 0},${this.b * 255 | 0},${this.a})`;
    }

    toHexString(): string {
        return `#${toHex(this.r)}${toHex(this.g)}${toHex(this.b)}`;
    }

    /**
     * Returns the [luminance](https://www.w3.org/WAI/GL/wiki/Relative_luminance) of the color.
     * @returns 
     */
    getLuminance(): number {
        return 0.2126 * this.r + 0.7152 * this.g + 0.0722 * this.b;
    }

    /**
     * Returns the distance to the specified color, by treating each color's RGB as 3 dimensional points.
     * @param color 
     * @returns 
     */
    getDistanceTo(color: Color) {
        return Math.sqrt((color.r - this.r) ** 2 + (color.g - this.g) ** 2 + (color.b - this.b) ** 2)
    }

    /**
     * Returns an 4-element array corresponding to the RGBA of the color, with each value scaled between 0 and 255.
     * @param target 
     * @param index 
     * @returns 
     */
    toUint8Array(target: Uint8Array = new Uint8Array(4), index: number = 0): Uint8Array {
        target[index] = scaleTo8Bits(this.r);
        target[index + 1] = scaleTo8Bits(this.g);
        target[index + 2] = scaleTo8Bits(this.b);
        target[index + 3] = scaleTo8Bits(this.a);

        return target;
    }

    /**
     * Creates a new color by making a linear interpolation of the two specified colors.
     * @param prev 
     * @param next 
     * @param t 
     * @returns 
     */
    static mix(prev: Color, next: Color, t: number): Color {
        let r = mix(prev.r, next.r, t);
        let g = mix(prev.g, next.g, t);
        let b = mix(prev.b, next.b, t);
        let a = mix(prev.a, next.a, t);

        return new Color(r, g, b, a);
    }

    static transparent() { return new Color(0, 0, 0, 0); }
    static aliceBlue() { return Color.rgb(240, 248, 255); }
    static antiqueWhite() { return Color.rgb(250, 235, 215); }
    static aqua() { return Color.rgb(0, 255, 255); }
    static aquamarine() { return Color.rgb(127, 255, 212); }
    static azure() { return Color.rgb(240, 255, 255); }
    static beige() { return Color.rgb(245, 245, 220); }
    static bisque() { return Color.rgb(255, 228, 196); }
    static black() { return Color.rgb(0, 0, 0); }
    static blanchedAlmond() { return Color.rgb(255, 235, 205); }
    static blue() { return Color.rgb(0, 0, 255); }
    static blueViolet() { return Color.rgb(138, 43, 226); }
    static brown() { return Color.rgb(165, 42, 42); }
    static burlyWood() { return Color.rgb(222, 184, 135); }
    static cadetBlue() { return Color.rgb(95, 158, 160); }
    static chartreuse() { return Color.rgb(127, 255, 0); }
    static chocolate() { return Color.rgb(210, 105, 30); }
    static coral() { return Color.rgb(255, 127, 80); }
    static cornflowerBlue() { return Color.rgb(100, 149, 237); }
    static cornsilk() { return Color.rgb(255, 248, 220); }
    static crimson() { return Color.rgb(220, 20, 60); }
    static cyan() { return Color.rgb(0, 255, 255); }
    static darkBlue() { return Color.rgb(0, 0, 139); }
    static darkCyan() { return Color.rgb(0, 139, 139); }
    static darkGoldenrod() { return Color.rgb(184, 134, 11); }
    static darkGray() { return Color.rgb(169, 169, 169); }
    static darkGrey() { return Color.rgb(169, 169, 169); }
    static darkGreen() { return Color.rgb(0, 100, 0); }
    static darkKhaki() { return Color.rgb(189, 183, 107); }
    static darkMagenta() { return Color.rgb(139, 0, 139); }
    static darkOlivegreen() { return Color.rgb(85, 107, 47); }
    static darkOrange() { return Color.rgb(255, 140, 0); }
    static darkOrchid() { return Color.rgb(153, 50, 204); }
    static darkRed() { return Color.rgb(139, 0, 0); }
    static darkSalmon() { return Color.rgb(233, 150, 122); }
    static darkSeagreen() { return Color.rgb(143, 188, 143); }
    static darkSlateblue() { return Color.rgb(72, 61, 139); }
    static darkSlategray() { return Color.rgb(47, 79, 79); }
    static darkSlategrey() { return Color.rgb(47, 79, 79); }
    static darkTurquoise() { return Color.rgb(0, 206, 209); }
    static darkViolet() { return Color.rgb(148, 0, 211); }
    static deepPink() { return Color.rgb(255, 20, 147); }
    static deepSkyblue() { return Color.rgb(0, 191, 255); }
    static dimGray() { return Color.rgb(105, 105, 105); }
    static dimGrey() { return Color.rgb(105, 105, 105); }
    static dodgerBlue() { return Color.rgb(30, 144, 255); }
    static fireBrick() { return Color.rgb(178, 34, 34); }
    static floralWhite() { return Color.rgb(255, 250, 240); }
    static forestGreen() { return Color.rgb(34, 139, 34); }
    static fuchsia() { return Color.rgb(255, 0, 255); }
    static gainsboro() { return Color.rgb(220, 220, 220); }
    static ghostWhite() { return Color.rgb(248, 248, 255); }
    static gold() { return Color.rgb(255, 215, 0); }
    static goldenRod() { return Color.rgb(218, 165, 32); }
    static gray() { return Color.rgb(128, 128, 128); }
    static grey() { return Color.rgb(128, 128, 128); }
    static green() { return Color.rgb(0, 128, 0); }
    static greenYellow() { return Color.rgb(173, 255, 47); }
    static honeyDew() { return Color.rgb(240, 255, 240); }
    static hotPink() { return Color.rgb(255, 105, 180); }
    static indianRed() { return Color.rgb(205, 92, 92); }
    static indigo() { return Color.rgb(75, 0, 130); }
    static ivory() { return Color.rgb(255, 255, 240); }
    static khaki() { return Color.rgb(240, 230, 140); }
    static lavender() { return Color.rgb(230, 230, 250); }
    static lavenderBlush() { return Color.rgb(255, 240, 245); }
    static lawnGreen() { return Color.rgb(124, 252, 0); }
    static lemonChiffon() { return Color.rgb(255, 250, 205); }
    static lightBlue() { return Color.rgb(173, 216, 230); }
    static lightCoral() { return Color.rgb(240, 128, 128); }
    static lightCyan() { return Color.rgb(224, 255, 255); }
    static lightGoldenrodyellow() { return Color.rgb(250, 250, 210); }
    static lightGray() { return Color.rgb(211, 211, 211); }
    static lightGrey() { return Color.rgb(211, 211, 211); }
    static lightGreen() { return Color.rgb(144, 238, 144); }
    static lightPink() { return Color.rgb(255, 182, 193); }
    static lightSalmon() { return Color.rgb(255, 160, 122); }
    static lightSeagreen() { return Color.rgb(32, 178, 170); }
    static lightSkyblue() { return Color.rgb(135, 206, 250); }
    static lightSlategray() { return Color.rgb(119, 136, 153); }
    static lightSlategrey() { return Color.rgb(119, 136, 153); }
    static lightSteelblue() { return Color.rgb(176, 196, 222); }
    static lightYellow() { return Color.rgb(255, 255, 224); }
    static lime() { return Color.rgb(0, 255, 0); }
    static limeGreen() { return Color.rgb(50, 205, 50); }
    static linen() { return Color.rgb(250, 240, 230); }
    static magenta() { return Color.rgb(255, 0, 255); }
    static maroon() { return Color.rgb(128, 0, 0); }
    static mediumAquamarine() { return Color.rgb(102, 205, 170); }
    static mediumBlue() { return Color.rgb(0, 0, 205); }
    static mediumOrchid() { return Color.rgb(186, 85, 211); }
    static mediumPurple() { return Color.rgb(147, 112, 219); }
    static mediumSeagreen() { return Color.rgb(60, 179, 113); }
    static mediumSlateblue() { return Color.rgb(123, 104, 238); }
    static mediumSpringgreen() { return Color.rgb(0, 250, 154); }
    static mediumTurquoise() { return Color.rgb(72, 209, 204); }
    static mediumVioletred() { return Color.rgb(199, 21, 133); }
    static midnightBlue() { return Color.rgb(25, 25, 112); }
    static mintCream() { return Color.rgb(245, 255, 250); }
    static mistyRose() { return Color.rgb(255, 228, 225); }
    static moccasin() { return Color.rgb(255, 228, 181); }
    static navajoWhite() { return Color.rgb(255, 222, 173); }
    static navy() { return Color.rgb(0, 0, 128); }
    static oldLace() { return Color.rgb(253, 245, 230); }
    static olive() { return Color.rgb(128, 128, 0); }
    static oliveDrab() { return Color.rgb(107, 142, 35); }
    static orange() { return Color.rgb(255, 165, 0); }
    static orangeRed() { return Color.rgb(255, 69, 0); }
    static orchid() { return Color.rgb(218, 112, 214); }
    static paleGoldenrod() { return Color.rgb(238, 232, 170); }
    static paleGreen() { return Color.rgb(152, 251, 152); }
    static paleTurquoise() { return Color.rgb(175, 238, 238); }
    static paleVioletred() { return Color.rgb(219, 112, 147); }
    static papayaWhip() { return Color.rgb(255, 239, 213); }
    static peachPuff() { return Color.rgb(255, 218, 185); }
    static peru() { return Color.rgb(205, 133, 63); }
    static pink() { return Color.rgb(255, 192, 203); }
    static plum() { return Color.rgb(221, 160, 221); }
    static powderBlue() { return Color.rgb(176, 224, 230); }
    static purple() { return Color.rgb(128, 0, 128); }
    static rebeccaPurple() { return Color.rgb(102, 51, 153); }
    static red() { return Color.rgb(255, 0, 0); }
    static rosyBrown() { return Color.rgb(188, 143, 143); }
    static royalBlue() { return Color.rgb(65, 105, 225); }
    static saddleBrown() { return Color.rgb(139, 69, 19); }
    static salmon() { return Color.rgb(250, 128, 114); }
    static sandyBrown() { return Color.rgb(244, 164, 96); }
    static seaGreen() { return Color.rgb(46, 139, 87); }
    static seaShell() { return Color.rgb(255, 245, 238); }
    static sienna() { return Color.rgb(160, 82, 45); }
    static silver() { return Color.rgb(192, 192, 192); }
    static skyBlue() { return Color.rgb(135, 206, 235); }
    static slateBlue() { return Color.rgb(106, 90, 205); }
    static slateGray() { return Color.rgb(112, 128, 144); }
    static slateGrey() { return Color.rgb(112, 128, 144); }
    static snow() { return Color.rgb(255, 250, 250); }
    static springGreen() { return Color.rgb(0, 255, 127); }
    static steelBlue() { return Color.rgb(70, 130, 180); }
    static tan() { return Color.rgb(210, 180, 140); }
    static teal() { return Color.rgb(0, 128, 128); }
    static thistle() { return Color.rgb(216, 191, 216); }
    static tomato() { return Color.rgb(255, 99, 71); }
    static turquoise() { return Color.rgb(64, 224, 208); }
    static violet() { return Color.rgb(238, 130, 238); }
    static wheat() { return Color.rgb(245, 222, 179); }
    static white() { return Color.rgb(255, 255, 255); }
    static whiteSmoke() { return Color.rgb(245, 245, 245); }
    static yellow() { return Color.rgb(255, 255, 0); }
    static yellowGreen() { return Color.rgb(154, 205, 50); }
}

function parseColorHex(string: string): Color | undefined {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(string);

    if (!result) {
        return undefined;
    }

    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);

    return Color.rgb(r, g, b);
}

function parseColorRgba(string: string): Color | undefined {
    let result = /^rgba?\((\d+),?\s*(\d+),?\s*(\d+),?\s*((\d+)?\.?\d+)*\)$/.exec(string);

    if (!result) {
        return undefined;
    }

    let r = parseInt(result[1]);
    let g = parseInt(result[2]);
    let b = parseInt(result[3]);
    let a = result[4] === undefined ? 1 : parseFloat(result[4]);

    return Color.rgb(r, g, b, a * 255);
}

function toHex(value: number): string {
    let a = (value * 255) | 0;
    let b = a / 16 | 0;
    let c = a % 16;
    
    return `${b.toString(16)}${c.toString(16)}`.toUpperCase();
}

/**
 * Index of named colors, according to [this chart](https://www.w3schools.com/cssref/css_colors.php).
 */
export const NAMED_COLORS = {
    transparent: Color.transparent(),
    aliceblue: Color.aliceBlue(),
    antiquewhite: Color.antiqueWhite(),
    aqua: Color.aqua(),
    aquamarine: Color.aquamarine(),
    azure: Color.azure(),
    beige: Color.beige(),
    bisque: Color.bisque(),
    black: Color.black(),
    blanchedalmond: Color.blanchedAlmond(),
    blue: Color.blue(),
    blueviolet: Color.blueViolet(),
    brown: Color.brown(),
    burlywood: Color.burlyWood(),
    cadetblue: Color.cadetBlue(),
    chartreuse: Color.chartreuse(),
    chocolate: Color.chocolate(),
    coral: Color.coral(),
    cornflowerblue: Color.cornflowerBlue(),
    cornsilk: Color.cornsilk(),
    crimson: Color.crimson(),
    cyan: Color.cyan(),
    darkblue: Color.darkBlue(),
    darkcyan: Color.darkCyan(),
    darkgoldenrod: Color.darkGoldenrod(),
    darkgray: Color.darkGray(),
    darkgrey: Color.darkGrey(),
    darkgreen: Color.darkGreen(),
    darkkhaki: Color.darkKhaki(),
    darkmagenta: Color.darkMagenta(),
    darkolivegreen: Color.darkOlivegreen(),
    darkorange: Color.darkOrange(),
    darkorchid: Color.darkOrchid(),
    darkred: Color.darkRed(),
    darksalmon: Color.darkSalmon(),
    darkseagreen: Color.darkSeagreen(),
    darkslateblue: Color.darkSlateblue(),
    darkslategray: Color.darkSlategray(),
    darkslategrey: Color.darkSlategrey(),
    darkturquoise: Color.darkTurquoise(),
    darkviolet: Color.darkViolet(),
    deeppink: Color.deepPink(),
    deepskyblue: Color.deepSkyblue(),
    dimgray: Color.dimGray(),
    dimgrey: Color.dimGrey(),
    dodgerblue: Color.dodgerBlue(),
    firebrick: Color.fireBrick(),
    floralwhite: Color.floralWhite(),
    forestgreen: Color.forestGreen(),
    fuchsia: Color.fuchsia(),
    gainsboro: Color.gainsboro(),
    ghostwhite: Color.ghostWhite(),
    gold: Color.gold(),
    goldenrod: Color.goldenRod(),
    gray: Color.gray(),
    grey: Color.grey(),
    green: Color.green(),
    greenyellow: Color.greenYellow(),
    honeydew: Color.honeyDew(),
    hotpink: Color.hotPink(),
    indianred: Color.indianRed(),
    indigo: Color.indigo(),
    ivory: Color.ivory(),
    khaki: Color.khaki(),
    lavender: Color.lavender(),
    lavenderblush: Color.lavenderBlush(),
    lawngreen: Color.lawnGreen(),
    lemonchiffon: Color.lemonChiffon(),
    lightblue: Color.lightBlue(),
    lightcoral: Color.lightCoral(),
    lightcyan: Color.lightCyan(),
    lightgoldenrodyellow: Color.lightGoldenrodyellow(),
    lightgray: Color.lightGray(),
    lightgrey: Color.lightGrey(),
    lightgreen: Color.lightGreen(),
    lightpink: Color.lightPink(),
    lightsalmon: Color.lightSalmon(),
    lightseagreen: Color.lightSeagreen(),
    lightskyblue: Color.lightSkyblue(),
    lightslategray: Color.lightSlategray(),
    lightslategrey: Color.lightSlategrey(),
    lightsteelblue: Color.lightSteelblue(),
    lightyellow: Color.lightYellow(),
    lime: Color.lime(),
    limegreen: Color.limeGreen(),
    linen: Color.linen(),
    magenta: Color.magenta(),
    maroon: Color.maroon(),
    mediumaquamarine: Color.mediumAquamarine(),
    mediumblue: Color.mediumBlue(),
    mediumorchid: Color.mediumOrchid(),
    mediumpurple: Color.mediumPurple(),
    mediumseagreen: Color.mediumSeagreen(),
    mediumslateblue: Color.mediumSlateblue(),
    mediumspringgreen: Color.mediumSpringgreen(),
    mediumturquoise: Color.mediumTurquoise(),
    mediumvioletred: Color.mediumVioletred(),
    midnightblue: Color.midnightBlue(),
    mintcream: Color.mintCream(),
    mistyrose: Color.mistyRose(),
    moccasin: Color.moccasin(),
    navajowhite: Color.navajoWhite(),
    navy: Color.navy(),
    oldlace: Color.oldLace(),
    olive: Color.olive(),
    olivedrab: Color.oliveDrab(),
    orange: Color.orange(),
    orangered: Color.orangeRed(),
    orchid: Color.orchid(),
    palegoldenrod: Color.paleGoldenrod(),
    palegreen: Color.paleGreen(),
    paleturquoise: Color.paleTurquoise(),
    palevioletred: Color.paleVioletred(),
    papayawhip: Color.papayaWhip(),
    peachpuff: Color.peachPuff(),
    peru: Color.peru(),
    pink: Color.pink(),
    plum: Color.plum(),
    powderblue: Color.powderBlue(),
    purple: Color.purple(),
    rebeccapurple: Color.rebeccaPurple(),
    red: Color.red(),
    rosybrown: Color.rosyBrown(),
    royalblue: Color.royalBlue(),
    saddlebrown: Color.saddleBrown(),
    salmon: Color.salmon(),
    sandybrown: Color.sandyBrown(),
    seagreen: Color.seaGreen(),
    seashell: Color.seaShell(),
    sienna: Color.sienna(),
    silver: Color.silver(),
    skyblue: Color.skyBlue(),
    slateblue: Color.slateBlue(),
    slategray: Color.slateGray(),
    slategrey: Color.slateGrey(),
    snow: Color.snow(),
    springgreen: Color.springGreen(),
    steelblue: Color.steelBlue(),
    tan: Color.tan(),
    teal: Color.teal(),
    thistle: Color.thistle(),
    tomato: Color.tomato(),
    turquoise: Color.turquoise(),
    violet: Color.violet(),
    wheat: Color.wheat(),
    white: Color.white(),
    whitesmoke: Color.whiteSmoke(),
    yellow: Color.yellow(),
    yellowgreen: Color.yellowGreen()
};

function scaleTo8Bits(n: number): number {
    return Math.round(n * 255);
}
globalThis.ALL_FUNCTIONS.push(Color);