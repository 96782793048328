export function iterate<T>(value: undefined | null | T | { values: () => IterableIterator<T> }): IterableIterator<T> {
    if (!value) {
        return emptyIterator();
    } else if (typeof value === 'object' && 'values' in value) {
        return value.values();
    } else {
        return singleValueIterator(value);
    }
}

export function *singleValueIterator<T>(value: T) {
    yield value;
}

export function *emptyIterator<T>() {

}