import { getRandomNumber } from './math.ts';

export function makeArray<T>(length: number, createItem: (index: number) => T): T[] {
    let array = new Array(length);

    for (let i = 0; i < length; ++i) {
        array[i] = createItem(i);
    }

    return array;
}

export function removeItem<T>(array: T[], item: T): boolean {
    let index = array.indexOf(item);

    if (index !== -1) {
        array.splice(index, 1);
        return true;
    } else {
        return false;
    }
}

export function intersectArrays<T>(array1: T[], array2: T[]): T[] {
    let set = new Set(array2);

    return array1.filter(item => set.has(item));
}

export function excludeItems<T>(allItems: T[] | Set<T>, excludedItems: T[] | Set<T>): T[] {
    let excludedItemsSet = excludedItems instanceof Set ? excludedItems : new Set(excludedItems);
    let result: T[] = [];

    for (let item of allItems) {
        if (!excludedItemsSet.has(item)) {
            result.push(item);
        }
    }

    return result;
}

export function partitionArray<T>(array: T[], callback: (item: T, index: number) => boolean): [T[], T[]] {
    let validList = [];
    let invalidList = [];

    for (let i = 0; i < array.length; ++i) {
        let item = array[i];
        let isValid = callback(item, i);

        if (isValid) {
            validList.push(item);
        } else {
            invalidList.push(item);
        }
    }

    return [validList, invalidList];
}

export function getRandomItem<T>(array: T[], rand: () => number = getRandomNumber): T {
    let n = rand();
    let index = (n * array.length) | 0;

    return array[index];
}

export function shuffleArray<T>(array: T[], rand: () => number = getRandomNumber): T[] {
    let length = array.length;
    let currentIndex = length;

    while (currentIndex > 0) {
        let randomIndex = rand() * (currentIndex - 1) | 0;
        currentIndex -= 1;

        let tmp = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = tmp;
    }

    return array;
}

export function getNextIndexWrapped<T>(array: T[], index: number, direction: number = 1): number {
    if (index < 0) {
        index += 1;
    }

    let len = array.length;
    let nextIndex = (((index + direction) % len) + len) % len;

    return nextIndex;
}

export function getNextItemWrapped<T>(array: T[], item: T | undefined, direction: number = 1): T {
    let index = item === undefined ? -1 : array.indexOf(item);
    let nextIndex = getNextIndexWrapped(array, index, direction);

    return array[nextIndex];
}

export function selectClosestItem<T>(array: T[], getDistance: (item: T) => number | false | null | undefined): T | null {
    let minDistance = Infinity;
    let selectedItem: T | null = null;

    for (let item of array) {
        let distance = getDistance(item);

        if (typeof distance === 'number' && distance < minDistance) {
            minDistance = distance;
            selectedItem = item;
        }
    }

    return selectedItem;
}

export function sliceAndPush<T>(array: T[], ...items: T[]): T[] {
    let clone = array.slice();

    clone.push(...items);

    return clone;
}

// Necessary when testing against readonly arrays, because `Array.isArray` assumes the value is a mutable array
export function isReadonlyArray(value: any): value is ReadonlyArray<any> {
    return Array.isArray(value);
}

export function arrayIncludesAnyOf<T>(array: T[], items: T | T[]): boolean {
    if (!Array.isArray(items)) {
        return array.includes(items);
    } else {
        return array.some(item => items.includes(item));
    }
}

export function filterMap<T, U>(array: T[], callback: (item: T) => U | undefined): U[] {
    let result: U[] = [];

    for (let item of array) {
        let mapped = callback(item);

        if (mapped !== undefined) {
            result.push(mapped);
        }
    }

    return result;
}

export function getArrayAtKey<K extends string, T>(object: { [Key in K]: T[] }, key: K): T[] {
    let array = object[key];

    if (!array) {
        array = [];
        object[key] = array;
    }

    return array;
}