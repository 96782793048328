import { Color, ColorLike } from '../color/color.ts';

export type ColorComparatorLike =
    | ColorLike
    | ((color1: Color, color2: Color) => number);

export class ColorComparator {
    private compare: (color1: Color, color2: Color) => number;

    constructor(compareFunction: (color1: Color, color2: Color) => number) {
        this.compare = compareFunction;
    }

    static from(colorComparatorLike: ColorComparatorLike = 0): ColorComparator {
        let compareFunction: (color1: Color, color2: Color) => number;

        if (typeof colorComparatorLike === 'function') {
            compareFunction = colorComparatorLike;
        } else {
            let { r, g, b, a } = Color.from(colorComparatorLike);

            compareFunction = function (color1: Color, color2: Color): number {
                if (color1.r + r < color2.r) {
                    return -1;
                } else if (color1.r > color2.r + r) {
                    return 1;
                } else if (color1.g + g < color2.g) {
                    return -1;
                } else if (color1.g > color2.g + g) {
                    return 1;
                } else if (color1.b + b < color2.b) {
                    return -1;
                } else if (color1.b > color2.b + b) {
                    return 1;
                } else if (color1.a + a < color2.a) {
                    return -1;
                } else if (color1.a > color2.a + a) {
                    return 1;
                } else {
                    return 0;
                }
            };
        }

        return new ColorComparator(compareFunction);
    }

    equal(color1: Color, color2: Color): boolean {
        return this.compare(color1, color2) === 0;
    }
}
globalThis.ALL_FUNCTIONS.push(ColorComparator);