import { isFunction } from '../../../utils/language/function.ts';
import { mixLayerProperties } from '../../graphics-engine/layer.ts';
import { AnimationApi } from '../animation-api.ts';
import { UpdateLayerParams } from './update-layer-params.ts';

export async function updateLayerAnimation(api: AnimationApi, params: UpdateLayerParams) {
    let { graphicsEngine } = api;
    let { layerId, properties, animationId, blocking, delay, duration, easing } = params;

    api.configure({ animationId, blocking, delay, duration, easing });

    await api.waitForStart();

    let startProperties = graphicsEngine.getLayerProperties(layerId);
    let endProperties = isFunction(properties) ? properties(startProperties) : properties;

    while (await api.waitForFrame()) {
        let animationRatio = api.getElapsedRatio();
        let interpolatedProps = mixLayerProperties(startProperties, endProperties, animationRatio);

        graphicsEngine.updateLayer(layerId, interpolatedProps);
    }

    graphicsEngine.updateLayer(layerId, endProperties);
}