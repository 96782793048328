import { GraphicsAttributeDetails, getDefaultGraphicsAttributeDetails } from './graphics-attribute-details.ts';
import { GraphicsAttribute, UnwrapAttribute } from './graphics-attribute-types.ts';
import { Graphics } from './graphics.ts';

export class GraphicsAliasApi {
    private targetByKey: { [key: string]: GraphicsAttributeDetails<any> | undefined } = {};
    private source!: GraphicsAttributeDetails<any>;
    private isStartStep: boolean = true;

    init(source: GraphicsAttributeDetails<any>) {
        this.source = source;
        this.isStartStep = true;

        for (let key in this.targetByKey) {
            this.targetByKey[key]!.startTime = -1;
        }
    }

    nextStep() {
        this.isStartStep = false;
    }

    set<K extends keyof Graphics>(key: K, value: UnwrapAttribute<Exclude<Graphics[K], undefined>>) {
        let source = this.source;
        let target = this.targetByKey[key];

        if (target === undefined) {
            target = getDefaultGraphicsAttributeDetails();
            this.targetByKey[key] = target;
        }

        if (this.isStartStep) {
            target.start = value;
            target.operation = source.operation;
            target.startTime = source.startTime;
            target.duration = source.duration;
            target.delay = source.delay;
            target.delayBetweenLoops = source.delayBetweenLoops;
            target.loop = source.loop;
            target.easing = source.easing;
            target.reverse = source.reverse;
        } else {
            target.end = value;
        }
    }

    pack(callback: (key: string, details: GraphicsAttributeDetails<any>) => void) {
        let targets = this.targetByKey;

        for (let key in targets) {
            let target = targets[key]!;

            if (target.startTime >= 0) {
                callback(key, target);
            }
        }
    }
}
globalThis.ALL_FUNCTIONS.push(GraphicsAliasApi);