import { useState } from 'preact/hooks';
import { ClientConnection } from './client-connection';
import { Button } from '../base-components/button';
import { BoardLocalData } from './board-local-data';
import { GAME_ID_PARAM_NAME } from '../constants';

export type BoardLinkComponentProps = {
    localData: BoardLocalData;
    isGuest: boolean;
};

export function BoardLinkComponent(props: BoardLinkComponentProps) {
    let { localData, isGuest } = props;
    let [roomId, setRoomId] = useState<string | null>(null);
    let [isConnected, setIsConnected] = useState(!!localData.connection);
    let [hostConnection, setHostConnection] = useState<ClientConnection | null>(null);

    let onCreateLink = async () => {
        hostConnection = await ClientConnection.start({ label: 'host' });

        setHostConnection(hostConnection);

        roomId = await hostConnection.createRoom(localData);

        setRoomId(roomId);
        await onCopyGameLink();

        hostConnection.whenPeerConnected().then(() => setIsConnected(true));
    };

    let onCopyGameLink = async () => {
        let url = new URL(location.origin);
        url.searchParams.set(GAME_ID_PARAM_NAME, roomId!);

        let gameLink = url.toString();

        await navigator.clipboard.writeText(gameLink);

        console.log(`Game link: ${gameLink}`);
    };

    if (roomId) {
        return <Button
            label='Copy join link'
            onClick={onCopyGameLink}
            disabled={isConnected}
        />;
    } else {
        return <Button
            label='Create join link'
            onClick={onCreateLink}
            disabled={isGuest || (!!hostConnection && !roomId)}
        />;
    }
};