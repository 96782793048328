import { makeArray } from 'outpost';
import { onClearCache, onLoadProject, onSaveProject } from '../action-cache';
import { onExportAsPdf } from '../action-export';
import { onPreviewCards } from '../action-preview';
import { Button } from '../base-components/button';
import { Column, Row } from '../base-components/container';
import { Separator } from '../base-components/separator';
import { TextInput } from '../base-components/text-input';
import { SPREADSHEET_URL_STORAGE_KEY } from '../constants';
import { createCanvas } from '../utils';
import { App } from './app';
import { CardPreview } from './card-preview';
import { SpreadsheetInstructions } from './spreadsheet-instructions';
import { useEffect, useState } from 'preact/hooks';
import { onResumePlay, onShowPlaySetup } from '../action-play';
import { Project } from '../project';
import { PlaySetupPanel } from './play-setup-panel';
import { LocalStorage } from '../local-storage';
import { AutoConnect } from './auto-connect';

export type HomePageProps = {
    spreadsheetUrl: string;
    currentAction: string | null;
    project: Project | null;
    showCards: boolean;
    justSaved: boolean;
    hasCache: boolean;
    setupPlayOpen: boolean;
    hasOngoingPlay: boolean;
};

const EMPTY_CANVASES = makeArray(6, () => createCanvas({ height: 1 }));

export function HomePage(props: HomePageProps) {
    let { spreadsheetUrl, currentAction, project, showCards, justSaved, hasCache, setupPlayOpen, hasOngoingPlay } = props;
    let [inputFocused, setInputFocused] = useState(false);
    let globalDisabled = currentAction !== null || setupPlayOpen || hasOngoingPlay;
    let hasUrl = spreadsheetUrl !== '';
    let cardsToDisplay = (showCards && project?.getCardsWithImage()) || [];

    let onSpreadsheetUrlChange = (value: string) => {
        App.instance.setState({
            spreadsheetUrl: value
        });

        LocalStorage.writeString(SPREADSHEET_URL_STORAGE_KEY, value);
    };

    useEffect(() => {
        let onKeyDown = (evt: KeyboardEvent) => {
            if (evt.key === 'Enter' && inputFocused && hasUrl && !globalDisabled) {
                onPreviewCards();
            }
        };

        document.addEventListener('keydown', onKeyDown);

        return () => document.removeEventListener('keydown', onKeyDown);
    }, [inputFocused, hasUrl, globalDisabled]);

    let setupPlayPanel = undefined;

    if (project) {
        setupPlayPanel = <PlaySetupPanel
            open={setupPlayOpen}
            project={project}
        />;
    }

    return (
        <Column alignItems='center' gap={20}>
            <h2 style={{ marginTop: '15px', marginBottom: '5px' }}>CARD MAKER</h2>
            <SpreadsheetInstructions />
            <Row alignItems='center' gap={10}>
                {/* Google spreadsheet URL: */}
                <TextInput
                    onContentChange={onSpreadsheetUrlChange}
                    onFocusChange={value => setInputFocused(value)}
                    content={spreadsheetUrl}
                    disabled={globalDisabled}
                    autoFocus
                    width={900}
                />
            </Row>
            <Row gap={10} alignItems='stretch'>
                <Button
                    label='Preview cards'
                    onClick={onPreviewCards}
                    disabled={globalDisabled || !hasUrl}
                />
                <Button
                    label='Export as PDF'
                    onClick={onExportAsPdf}
                    disabled={globalDisabled || !showCards}
                />
                <Button
                    label='Test game'
                    onClick={onShowPlaySetup}
                    disabled={globalDisabled || !showCards}
                    hidden={hasOngoingPlay}
                />
                <Button
                    label='Resume play'
                    onClick={onResumePlay}
                    hidden={!hasOngoingPlay}
                />
                <Separator
                    color={'black'}
                    width={1}
                />
                {/* <Button
                    label='Save'
                    onClick={onSaveProject}
                    disabled={globalDisabled || !showCards || justSaved}
                /> */}
                <Button
                    label='Load cache'
                    onClick={onLoadProject}
                    disabled={globalDisabled || !hasCache}
                />
                <Button
                    label='Clear cache'
                    onClick={onClearCache}
                    disabled={globalDisabled || !hasCache}
                />
            </Row>
            <AutoConnect />
            <Row hidden={currentAction === null} alignItems='center' justifyContent='center' gap={15} >
                <img src="assets/loading.gif" height="20" />
                <div style={{ fontSize: '16px', position: 'relative', top: '2px' }}>{currentAction}</div>
            </Row>
            {setupPlayPanel}
            <Row wrap='wrap' justifyContent='center' shrink gap={15} style={{ marginTop: '10px' }} >
                {cardsToDisplay.map(card => <CardPreview canvas={card.image!} quantity={1} />)}
                {EMPTY_CANVASES.map(canvas => <CardPreview canvas={canvas} quantity={1} />)}
            </Row>
        </Column>
    );
}