import { Color, ColorLike } from '../../../utils/color/color.ts';
import { UniqueIdCache } from '../../../utils/data-structures/unique-id-cache.ts';
import { ImageLoader } from '../../../utils/dom/image-loader.ts';
import { DisplaySize, DisplaySizeLike } from '../../../utils/geometry/display-size.ts';
import { HorizontalAlign } from '../../../utils/geometry/horizontal-align.ts';
import { VerticalAlign } from '../../../utils/geometry/vertical-align.ts';
import { clamp } from '../../../utils/language/math.ts';
import { GraphicsEngine } from '../graphics-engine.ts';
import { GraphicsAttributeList } from '../graphics/graphics-attribute-list.ts';
import { Font } from '../graphics/graphics-types.ts';
import { LayerId } from '../layer-types.ts';
import { TextAttributes } from './render-text-types.ts';
import { renderText } from './render-text.ts';

type CachedText = {
    hash: bigint;
    layerId: LayerId;
    imageId: string;
    image: HTMLCanvasElement;
    dependsOnAssets: Set<string>;
};

export type TextImgeData = {
    layerId: LayerId;
    imageId: string;
    image: HTMLCanvasElement;
};

export class TextManager {
    private graphicsEngine: GraphicsEngine;
    private imageLoader: ImageLoader;
    private textsByHash: Map<bigint, CachedText> = new Map();
    private textsById: Map<string, CachedText> = new Map();
    private cachedAttributes: TextAttributes = {} as any;

    private layerIds: UniqueIdCache<LayerId> = new UniqueIdCache(3);
    private widthsIds: UniqueIdCache<number> = new UniqueIdCache(8);
    private heightIds: UniqueIdCache<number> = new UniqueIdCache(8);
    private contentIds: UniqueIdCache<string | null> = new UniqueIdCache(15, 1);
    private fontIds: UniqueIdCache<string> = new UniqueIdCache(4);
    private sizeIds: UniqueIdCache<DisplaySizeLike> = new UniqueIdCache(4);
    private paddingIds: UniqueIdCache<DisplaySizeLike> = new UniqueIdCache(4);
    private colorIds: UniqueIdCache<ColorLike> = new UniqueIdCache(4);
    private horizontalAlignIds: UniqueIdCache<HorizontalAlign> = new UniqueIdCache(2);
    private verticalAlignIds: UniqueIdCache<VerticalAlign> = new UniqueIdCache(2);

    constructor(graphicsEngine: GraphicsEngine) {
        this.graphicsEngine = graphicsEngine;
        this.imageLoader = graphicsEngine.getImageLoader();
    }

    getTextImage(graphics: GraphicsAttributeList): TextImgeData {
        let attributes = this.fillTextAttributes(graphics);
        let hash = this.getTextHash(attributes);
        let cachedText = this.textsByHash.get(hash);

        if (!cachedText) {
            let layerId = attributes.layerId;
            let sizeMultiplier = this.graphicsEngine.getLayerSizeMultiplier(attributes.layerId);
            let width = attributes.width;
            let height = attributes.height;
            let borderRadius = this.resolveDisplaySize(attributes.borderRadius, width, height) * sizeMultiplier;
            let padding = this.resolveDisplaySize(attributes.textPadding, width, height) * sizeMultiplier;
            let { image, dependsOnAssets } = renderText({
                imageLoader: this.graphicsEngine.getImageLoader(),
                iconAliases: this.graphicsEngine.getIconAliases(),
                width: width * sizeMultiplier,
                height: height * sizeMultiplier,
                content: attributes.text ?? '',
                font: attributes.textFont,
                size: this.resolveDisplaySize(attributes.textSize, width, height) * sizeMultiplier,
                color: Color.from(attributes.textColor).toString(),
                bold: attributes.textBold,
                italic: attributes.textItalic,
                horizontalAlign: attributes.horizontalAlign,
                verticalAlign: attributes.verticalAlign,
                allowMultiline: attributes.textMultiline,
                padding: Math.max(padding, borderRadius),
                cursorIndex: attributes.textCursorShow ? attributes.textCursorIndex : -1,
            });
            let imageId = `text_${hash}`;

            if (attributes.text === 'AYtawo') {
                console.log(this.resolveDisplaySize(attributes.textSize, width, height) * sizeMultiplier)
            }

            cachedText = { hash, layerId, imageId, image, dependsOnAssets };
            this.textsByHash.set(hash, cachedText);
            this.textsById.set(imageId, cachedText);
            this.imageLoader.register(imageId, image);
            this.graphicsEngine.getImageTexture(layerId).addImage(imageId, image);
        }

        return cachedText;
    }

    deleteTextImage(imageId: string) {
        let data = this.textsById.get(imageId);

        if (data) {
            this.textsByHash.delete(data.hash);
            this.textsById.delete(data.imageId);
            this.imageLoader.unregister(data.imageId);
        }
    }

    getAllTextIds(): string[] {
        return [...this.textsById.keys()];
    }

    private resolveDisplaySize(displaySize: DisplaySizeLike | undefined, width: number, height: number) {
        return Math.round(DisplaySize.resolve(displaySize ?? 0, { width, height }));
    }

    notifyResourceLoaded(resourceId: string) {
        this.deleteTextImage(resourceId);

        for (let [key, value] of this.textsByHash.entries()) {
            if (value.dependsOnAssets.has(resourceId)) {
                this.textsByHash.delete(key);
                this.graphicsEngine.notifyResourceLoaded(value.imageId);
            }
        }
    }

    private getTextFont(font: Font): string {
        if (font === 'default') {
            return this.graphicsEngine.getDefaultFont();
        } else {
            return font;
        }
    }

    private getTextHash(attributes: TextAttributes): bigint {
        if (!attributes.text) {
            return 0n;
        }

        let layerId = this.layerIds.getId(attributes.layerId);
        let widthId = this.widthsIds.getId(attributes.width);
        let heightId = this.heightIds.getId(attributes.height);
        let contentId = this.contentIds.getId(attributes.text);
        let fontId = this.fontIds.getId(attributes.textFont);
        let sizeId = this.sizeIds.getId(attributes.textSize);
        let colorId = this.colorIds.getId(attributes.textColor);
        let paddingId = this.paddingIds.getId(attributes.textPadding);
        let horizontalAlignId = this.horizontalAlignIds.getId(attributes.horizontalAlign);
        let verticalAlignId = this.verticalAlignIds.getId(attributes.verticalAlign);
        let boldId = BigInt(+!!attributes.textBold);
        let italicId = BigInt(+!!attributes.textItalic);
        let multilineId = BigInt(+!!attributes.textMultiline);
        let cursorIndexId = BigInt(clamp(attributes.textCursorIndex + 1, 0, 127));

        if (!attributes.textCursorShow) {
            cursorIndexId = 0n;
        }

        return (contentId << 49n)
            + (layerId << 46n)
            + (widthId << 38n)
            + (heightId << 30n)
            + (fontId << 26n)
            + (sizeId << 22n)
            + (colorId << 18n)
            + (paddingId << 14n)
            + (horizontalAlignId << 12n)
            + (verticalAlignId << 10n)
            + (boldId << 9n)
            + (italicId << 8n)
            + (multilineId << 7n)
            + cursorIndexId;
    }

    private fillTextAttributes(attributes: GraphicsAttributeList): TextAttributes {
        let result = this.cachedAttributes;

        result.text = attributes.requireValue('text');

        if (!result.text) {
            return result;
        }

        result.layerId = attributes.requireValue('layerId');
        result.width = attributes.requireValue('width');
        result.height = attributes.requireValue('height');
        result.borderRadius = attributes.requireValue('borderRadius');
        result.horizontalAlign = attributes.requireValue('horizontalAlign');
        result.verticalAlign = attributes.requireValue('verticalAlign');
        result.textFont = attributes.requireValue('textFont');
        result.textSize = attributes.requireValue('textSize');
        result.textColor = attributes.requireValue('textColor');
        result.textPadding = attributes.requireValue('textPadding');
        result.textBold = attributes.requireValue('textBold');
        result.textItalic = attributes.requireValue('textItalic');
        result.textMultiline = attributes.requireValue('textMultiline');
        result.textCursorIndex = attributes.requireValue('textCursorIndex');
        result.textCursorShow = attributes.requireValue('textCursorShow');

        result.textFont = this.getTextFont(result.textFont);

        return this.cachedAttributes;
    }
}
globalThis.ALL_FUNCTIONS.push(TextManager);