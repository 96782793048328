import { clearCache, loadProjectFromCache, saveProjectToCache } from './cache';
import { App } from './components/app';

export async function onSaveProject() {
    App.instance.setState({
        hasCache: true,
        justSaved: true,
    });

    await App.instance.runAction('Saving to cache', () => saveProjectToCache(App.instance.state.project));
}

export async function onLoadProject() {
    App.instance.setState({
        showCards: false
    });

    let project = await App.instance.runAction('Loading cache', () => loadProjectFromCache());

    if (project) {
        App.instance.setState({
            project,
            showCards: true,
            hasCache: true,
            justSaved: true,
        });
    }
}

export async function onClearCache() {
    App.instance.setState({
        showCards: false,
        hasCache: false
    });

    await App.instance.runAction(null, () => clearCache());
}