import { getConstructorChain } from '../../utils/language/object.ts';
import { Constructor } from '../../utils/language/types.ts';
import { Component } from '../component/component.ts';
import { RoomEventListenerEntry } from './room-event.ts';

export class EntityWrapper {
    id: number;
    entity: Component;
    constructorChain: Constructor[];
    defaultEventPriority: number;
    eventListeners: RoomEventListenerEntry[] = [];
    parent: EntityWrapper | null = null;

    constructor(id: number, entity: Component, defaultEventPriority: number) {
        this.id = id;
        this.entity = entity;
        this.constructorChain = getConstructorChain(entity);
        this.defaultEventPriority = defaultEventPriority;
    }
}
globalThis.ALL_FUNCTIONS.push(EntityWrapper);