import { Constructor, DefaultValues, FunctionOrConstructor, Nullish, ObjectPredicate } from './types.ts';

export type Writeable<T> = { -readonly [P in keyof T]: T[P] };
export type ComputedProperties<T extends object> = Partial<T> | ((currentProperties: T) => Partial<T>);

export function isObjectEmpty(object: object | null): boolean {
    if (!object) {
        return true;
    }

    for (let _ in object) {
        return false;
    }

    return true;
}

export function isObject(value: unknown): value is object {
    return value?.constructor === Object;
}

let globalObjectToVirtualId: WeakMap<object, number> = new WeakMap();
let globalCounter = 1;

export function getObjectVirtualId(object: object): number {
    let id = globalObjectToVirtualId.get(object);

    if (!id) {
        id = globalCounter++;
        globalObjectToVirtualId.set(object, id);
    }

    return id;
}

export function formatObject<T, U extends (...args: any[]) => Partial<T>>(value: Partial<T> | U | undefined, defaultValue: T, ...args: Parameters<U>): T {
    let properties = typeof value === 'function' ? value(...args) : value;

    return Object.assign({}, defaultValue, properties);
}

export function collectPrototypeKeys(object: any, result: Set<string> = new Set()): Set<string> {
    if (object?.constructor) {
        for (let name of Object.getOwnPropertyNames(object.constructor.prototype)) {
            if (name !== 'constructor') {
                result.add(name);
            }
        }

        collectPrototypeKeys(Object.getPrototypeOf(object.constructor).prototype, result);
    }

    return result;
}

export function collectObjectAndPrototypeKeys(object: any): Set<string> {
    let result = collectPrototypeKeys(object);

    for (let key of Object.keys(object)) {
        result.add(key);
    }

    return result;
}

export function getConstructorChain(value: any): Constructor[] {
    let result: any[] = [];
    let item = value;

    while (item?.constructor) {
        result.push(item.constructor);

        item = Object.getPrototypeOf(item.constructor).prototype;
    }

    return result;
}

export function makeObject<T, P extends any[] = any[]>(func: FunctionOrConstructor<T, P>, ...args: P): T {
    if (func.prototype === undefined) {
        return (func as (...args: P) => T)(...args);
    } else {
        return new (func as new (...args: P) => T)(...args);
    }
}

export function mapKeys(object: object, mapFunction: (key: string) => string | string[]): object {
    let result: any = {};

    for (let [key, value] of Object.entries(object)) {
        let mapping = (mapFunction(key));

        if (Array.isArray(mapping)) {
            for (let mappedKey of mapping) {
                result[mappedKey] = value;
            }
        } else{ 
            result[mapping] = value;
        }
    }

    return result;
}

export function formatAsObject<T = any>(value: T[] | { [key: string]: T }, prefix: string = ''): { [key: string]: T } {
    if (!Array.isArray(value)) {
        return value;
    }

    let result = {} as any;

    for (let i = 0; i < value.length; ++i) {
        result[prefix + i.toString()] = value[i];
    }

    return result;
}

export function toFunction<T extends object, U>(objectOrFunction: T | ((ctx: U) => T)): (ctx: U) => T {
    if (typeof objectOrFunction === 'function') {
        return objectOrFunction;
    } else {
        return () => ({ ...objectOrFunction });
    }
}

export function updateProperties<T extends object>(object: T, properties: ComputedProperties<T>): T {
    let p = typeof properties === 'function' ? properties(object) : properties;

    for (let [key, value] of Object.entries(p)) {
        if (key in object && value !== undefined) {
            (object as any)[key] = value;
        }
    }

    return object;
}

export function mapObjectValues<T extends object>(object: T, callback: (value: any, key: string) => any): any {
    let result: any = {};

    for (let [key, value] of Object.entries(object)) {
        result[key] = callback(value, key);
    }

    return result;
}

export function mapObject<T extends object, U extends object>(object: T, callback: (key: string, value: any) => [string, any] | undefined): U {
    let result: any = {};

    for (let [key, value] of Object.entries(object)) {
        let mapped = callback(key, value);

        if (mapped) {
            result[mapped[0]] = mapped[1];
        }
    }

    return result;
}

export function getProperty<T, K extends keyof Extract<T, object>>(object: T, prop: K): Extract<T, object>[K] | undefined {
    if (object && typeof object === 'object') {
        return (object as any)[prop];
    } else {
        return undefined;
    }
}

export function objectMatchesPredicate<T extends object = object>(
    object: object | null | undefined,
    constructor: Constructor<T> | null,
    predicate: ObjectPredicate<T> | null
): object is T {
    let objectMatchesConstructor = !constructor || (object && object instanceof constructor);
    
    if (!objectMatchesConstructor) {
        return false;
    }

    if (!object && predicate) {
        return false;
    }

    let value = object as T;

    if (typeof predicate === 'function') {
        return predicate(value);
    } else if (predicate) {
        for (let key in predicate) {
            if ((value as any)[key] !== (predicate as any)[key]) {
                return false;
            }
        }
    }

    return true;
}

export function applyObjectPredicate<T extends object = object>(
    object: object | null | undefined,
    constructor: Constructor<T> | null = null,
    predicate: ObjectPredicate<T> | null = null
): T | null {
    if (objectMatchesPredicate(object, constructor, predicate)) {
        return object;
    } else {
        return null;
    }
}

export function applyObjectPredicateOrThrow<T extends object = object>(
    object: object | null | undefined,
    constructor: Constructor<T> | null = null,
    predicate: ObjectPredicate<T> | null = null,
    throwCallback: () => never = (() => { throw new Error(`the object does not match the predicate`) }),
): T {
    let result = applyObjectPredicate(object, constructor, predicate);

    if (!result) {
        throwCallback();
    }

    return result;
}

export function makeRequired<T extends object>(object: T, defaultValues: DefaultValues<T>): Required<T> {
    let result: any = { ...object };

    for (let key in defaultValues) {
        if (key in result) {
            continue;
        }

        let value = defaultValues[key];

        if (value && typeof value === 'object' && 'compute' in value && typeof value.compute === 'function') {
            result[key] = value.compute(result);
        } else {
            result[key] = value;
        }
    }

    return result;
}

export function getObjectKeyIndex(object: object | undefined, key: string): number {
    if (!object) {
        return -1;
    }

    let index = 0;

    for (let currentKey in object) {
        if (currentKey === key) {
            return index;
        }

        index += 1;
    }

    return -1;
}

export function getObjectValueByIndex(object: object | undefined, index: number): any {
    if (!object) {
        return undefined;
    }

    let i = 0;

    for (let key in object) {
        if (i === index) {
            return (object as any)[key];
        }
    }

    return undefined;
}

export function getObjectCallback<T extends Function>(object: any, path: string[], keyPredicate: (key: string) => boolean = (() => true)): [any, string] | undefined {
    let thisArg: any = object;
    let key = path[path.length - 1];

    for (let i = 0; i < path.length - 1; ++i) {
        thisArg = thisArg?.[path[i]];
    }

    if (!thisArg || !key || !keyPredicate(key) || typeof thisArg[key] !== 'function') {
        return undefined;
    } else {
        return [thisArg, key];
    }
}

export function getObjectPath(root: any, target: object, lookInPrototype: boolean = false, visitedObjects: Set<any> = new Set()): string[] | undefined {
    if (root === target) {
        return [];
    } else if (Array.isArray(root)) {
        if (visitedObjects.has(root)) {
            return undefined;
        }

        visitedObjects.add(root);

        for (let i = 0; i < root.length; ++i) {
            let subPath = getObjectPath(root[i], target, lookInPrototype, visitedObjects);

            if (subPath) {
                return [i.toString(), ...subPath];
            }
        }
    } else if (root && typeof root === 'object') {
        if (visitedObjects.has(root)) {
            return undefined;
        }

        visitedObjects.add(root);

        for (let [key, value] of Object.entries(root)) {
            let subPath = getObjectPath(value, target, lookInPrototype, visitedObjects);

            if (subPath) {
                return [key, ...subPath];
            }
        }

        if (lookInPrototype) {
            for (let key of Object.getOwnPropertyNames(root.constructor.prototype)) {
                if (root[key] === target) {
                    return [key];
                }
            }
        }
    }

    return undefined;
}