import VERTEX_SOURCE from './graphics-shader.vert';
import FRAGMENT_SOURCE from './graphics-shader.frag';
import { WebglAttributesLayout, WebglAttributesValues } from '../../../utils/webgl/webgl-attributes.ts';
import { WebglProgram } from '../../../utils/webgl/webgl-program.ts';
import { WebglUniformsLayout } from '../../../utils/webgl/webgl-uniforms.ts';
import { WebglVaryingsLayout } from '../../../utils/webgl/webgl-varyings.ts';

export const GRAPHICS_UNIFORMS = {
    'u_currentTime': 'float',
    'u_realCanvasSize': 'vec2',
    'u_virtualToRealRatio': 'float',
    'u_cameraCenter': 'vec2',
    'u_cameraZoom': 'float',
    'u_viewportCenter': 'vec2',
    'u_imageTexture': 'sampler2DArray',
    'u_imageColorReplaceDistanceThreshold': 'float',
    'u_attributesDataTexture': 'sampler2D',
    // 'u_virtualCanvasSize': 'vec2',
    // 'u_dataTextureBlockSize': 'uint',
} satisfies WebglUniformsLayout;

export const GRAPHICS_INSTANCE_ATTRIBUTES = {
    'a_attributesDataPointer': 'int',
    'a_componentId': 'uint',
} satisfies WebglAttributesLayout;

export const GRAPHICS_VERTEX_ATTRIBUTES = {
    'a_vertexCoords': 'vec2',
} satisfies WebglAttributesLayout;

export const GRAPHICS_VARYINGS = {
    'v_color': 'vec4',
    'v_strokeSize': 'float',
    'v_borderRadius': 'float',
    'v_borderSharp': 'float',
    'v_radialFillPercent': 'float',
    'v_radialFillDirection': 'float',
    'v_radialFillStartAngle': 'float',
    'v_linearFillPercent': 'float',
    'v_linearFillStep': 'float',
    'v_distanceToTopLeft': 'vec2',
    'v_rectSize': 'vec2',
    'v_imageTextureCoords': 'vec3',
    'v_imageReplaceSourceColor': 'vec4',
    'v_imageReplaceTargetColor': 'vec4',
    'v_alpha': 'float',
    'v_brightness': 'float',
    'v_grayscale': 'float',
    'v_tint': 'vec4',
    'v_gradient': 'vec4',
    'v_gradientProgress': 'float',
    'v_componentId': 'vec4',
} satisfies WebglVaryingsLayout;

export type GraphicsWebglProgram = WebglProgram<
    typeof GRAPHICS_UNIFORMS,
    typeof GRAPHICS_INSTANCE_ATTRIBUTES,
    typeof GRAPHICS_VERTEX_ATTRIBUTES
    // GraphicsInstance
>;

export function createGraphicsProgram(gl: WebGL2RenderingContext): GraphicsWebglProgram {
    return new WebglProgram(gl, {
        vertexSource: VERTEX_SOURCE,
        fragmentSource: FRAGMENT_SOURCE,
        uniforms: GRAPHICS_UNIFORMS,
        perInstanceAttributes: GRAPHICS_INSTANCE_ATTRIBUTES,
        perVertexAttributes: GRAPHICS_VERTEX_ATTRIBUTES,
        perVertexValues: VERTEX_ATTRIBUTES,
        // createInstance: () => new GraphicsInstance(),
    });
}

const VERTEX_ATTRIBUTES: WebglAttributesValues<typeof GRAPHICS_VERTEX_ATTRIBUTES>[] = [
    { a_vertexCoords: [-1, -1] },
    { a_vertexCoords: [1, 1] },
    { a_vertexCoords: [-1, 1] },
    { a_vertexCoords: [-1, -1] },
    { a_vertexCoords: [1, 1] },
    { a_vertexCoords: [1, -1] },
];