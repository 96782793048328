import { getKeyboardEventCombination, joinString, KeyCombination } from 'outpost';
import { CSSProperties } from 'preact/compat';
import { useEffect } from 'preact/hooks';

export type ButtonProps = {
    style?: CSSProperties;
    label: string;
    onClick: () => void;
    disabled?: boolean;
    shortcut?: KeyCombination | KeyCombination[];
    tooltip?: string;
    hidden?: boolean;
};

export function Button(props: ButtonProps) {
    let { label, disabled, onClick, shortcut, tooltip, hidden } = props;
    let style = {
        display: hidden ? 'none' : undefined,
        ...props.style
    };
    let shortcuts = joinString(shortcut, ',');

    useEffect(() => {
        let list = shortcuts.split(',');

        let onKeyDown = (evt: KeyboardEvent) => {
            let pressedCombination = getKeyboardEventCombination(evt);
            if (!disabled && list.some(combination => pressedCombination === combination)) {
                onClick();
            }
        };

        document.addEventListener('keydown', onKeyDown);

        return () => document.removeEventListener('keydown', onKeyDown);
    }, [shortcuts, onClick, disabled]);

    return (
        <button style={style} onClick={onClick} disabled={disabled} title={tooltip} >
            {label}
        </button>
    );
}