type CreateCanvasParameters = {
    width?: number;
    height?: number;
    append?: boolean;
    border?: boolean | string;
    id?: string;
};

export function createCanvas(params: CreateCanvasParameters = {}): HTMLCanvasElement {
    let {
        width = 800,
        height = 600,
        append = false,
        border = false,
        id = 'canvas',
    } = params;

    let canvas = document.createElement('canvas');

    canvas.width = width;
    canvas.height = height;
    canvas.id = id;

    if (border === true) {
        border = '1px solid black';
    }

    if (border) {
        canvas.style.border = border;
    }

    if (append) {
        if (document.body) {
            document.body.append(canvas);
        } else {
            window.addEventListener('load', () => {
                document.body.append(canvas);
            });
        }
    }

    return canvas;
}

export function copyCanvasTo(srcCanvas: HTMLCanvasElement, dstWidth: number, dstHeight: number): HTMLCanvasElement {
    let dstCanvas = createCanvas({ width: dstWidth, height: dstHeight });
    let dstCtx = dstCanvas.getContext('2d')!;

    dstCtx.drawImage(srcCanvas, 0, 0);

    return dstCanvas;
}