import { Counter } from '../../utils/data-structures/counter.ts';

export class ViewHelperStack<T> {
    private createItem: () => T;
    private items: T[] = [];
    private indexCounter: Counter = new Counter(0);

    constructor(createItem: () => T) {
        this.createItem = createItem;
    }

    reset() {
        this.indexCounter.reset();
    }

    next(): T {
        let index = this.indexCounter.next();
        let item: T;

        if (index < this.items.length) {
            item = this.items[index];
        } else {
            item = this.createItem();
            this.items.push(item);
        }

        return item;
    }
}
globalThis.ALL_FUNCTIONS.push(ViewHelperStack);