export class Stack<T> {
    private array: (T | undefined)[] = [];
    private index: number = -1;

    push(item: T) {
        this.index += 1;

        if (this.index === this.array.length) {
            this.array.push(item);
        } else {
            this.array[this.index] = item;
        }
    }

    pop(): T | undefined {
        let item = this.array[this.index];

        if (item) {
            this.array[this.index] = undefined;
            this.index -= 1;
        }

        return item;
    }

    isEmpty() {
        return this.index === -1;
    }
}
globalThis.ALL_FUNCTIONS.push(Stack);