import { TextContent, TextContentLike } from '../text/text-content.ts';
import { MouseButton, WheelDeltaMode, DomEvent, KeyCode, TextInputType } from './dom-events/dom-events-types.ts';

const BUTTON_TO_STRING: { [key: number]: MouseButton; } = ['left', 'middle', 'right'];
const DELTA_MODE_TO_STRING: { [key: number]: WheelDeltaMode; } = ['pixel', 'line', 'page'];
const DRAG_THRESHOLD = 5;

type EventMap<T> =
      T extends Window ? WindowEventMap
    : T extends Document ? DocumentEventMap
    : T extends HTMLElement ? HTMLElementEventMap
    : never;

export type DomWindowManagerParams = {
    aspectRatio?: number,
};

type TextEditionData = {
    targetId: number;
    priority: number;
    textInput: TextContent;
}

export type AddElementParams = {
    capturePointerEvents?: boolean;
};

export class WindowManager {
    private aspectRatio: number | null = null;
    private windowWidth: number = 0;
    private windowHeight: number = 0;
    private windowTopLeftX: number = 0;
    private windowTopLeftY: number = 0;
    private elements: HTMLElement[] = [];
    private inputOverlay: HTMLInputElement = {} as any;
    private onResizeCallbacks: (() => void)[] = [];
    private onEventCallbacks: ((evt: DomEvent) => void)[] = [];
    private onSpecializedEventCallbacks: Partial<{ [Key in DomEvent['kind']]: ((evt: Extract<DomEvent, { kind: Key }>) => void)[] }> = {};
    private initialized: boolean = false;
    private enabledTextEditions: TextEditionData[] = [];
    private delayedTextEventTimeout: NodeJS.Timeout | null = null;
    private domEventListeners: [Window | Document | HTMLElement, string, (evt: any) => void][] = [];
    private animationFrameRequestId: number = 0;

    constructor(params: DomWindowManagerParams = {}) {
        if (params.aspectRatio !== undefined) {
            this.aspectRatio = params.aspectRatio;
        }
    }

    static async create(params: DomWindowManagerParams = {}): Promise<WindowManager> {
        return new WindowManager(params).init();
    }

    async init(): Promise<WindowManager> {
        if (this.initialized) {
            return this;
        }

        let document = window.document;

        this.addEventListener(window, 'beforeunload', () => this.clearDom());
        this.addEventListener(window, 'resize', () => this.onResize());
        this.addEventListener(document, 'mousemove', evt => this.onMouseMove(evt));
        this.addEventListener(document, 'mousedown', evt => this.onMouseDown(evt));
        this.addEventListener(document, 'mouseup', evt => this.onMouseUp(evt));
        this.addEventListener(document, 'wheel', evt => this.onWheel(evt));
        this.addEventListener(document, 'keydown', evt => this.onKeyDown(evt));
        this.addEventListener(document, 'keyup', evt => this.onKeyUp(evt));
        this.addEventListener(document, 'contextmenu', evt => evt.preventDefault());

        this.initInputOverlay();

        this.initialized = true;

        this.onResize();

        if (document.body) {
            return this;
        } else {
            return new Promise(resolve => {
                this.addEventListener(window, 'load', () => resolve(this));
            });
        }
    }

    destroy() {
        this.clearDom();
    }

    private initInputOverlay() {
        this.inputOverlay = document.createElement('input');
        this.inputOverlay.style.position = 'absolute';
        this.inputOverlay.style.top = '0px';
        this.inputOverlay.style.left = '0px';
        // this.inputOverlay.style.right = '0px';
        // this.inputOverlay.style.bottom = '0px';
        this.inputOverlay.style.cursor = 'default';
        this.inputOverlay.style.outlineWidth = '0';
        this.inputOverlay.style.padding = '0';
        this.inputOverlay.style.borderWidth = '0';
        this.inputOverlay.style.backgroundColor = 'rgba(0,0,0,0)';
        this.inputOverlay.style.color = 'rgba(0,0,0,0)';
        this.inputOverlay.style.visibility = 'hidden';
        this.addEventListener(this.inputOverlay, 'input', evt => this.onTextInput(evt as InputEvent));
    }

    onWindowEvent(callback: (evt: DomEvent) => void) {
        this.onEventCallbacks.push(callback);
    }

    on<K extends DomEvent['kind']>(kind: K, callback: ((evt: Extract<DomEvent, { kind: K }>) => void)) {
        let array = this.onSpecializedEventCallbacks[kind];

        if (!array) {
            array = [];
            this.onSpecializedEventCallbacks[kind] = array;
        }

        array.push(callback);
    }

    onResizeEvent(callback: () => void) {
        this.onResizeCallbacks.push(callback);
    }

    setAspectRatio(aspectRatio: number | null) {
        this.aspectRatio = aspectRatio;
        this.onResize();
    }

    getWidth() {
        return this.windowWidth;
    }

    getHeight() {
        return this.windowHeight;
    }

    addElement(element: HTMLElement) {
        this.elements.push(element);
        this.updateElement(element);
        this.updateDom();
    }

    removeElement(element: HTMLElement) {
        this.elements.remove(element);
        this.updateDom();
    }

    setCursor(cursor: string) {
        for (let element of this.elements) {
            element.style.cursor = cursor;
        }

        this.inputOverlay.style.cursor = cursor;
    }

    requestAnimationFrame(callback: () => void) {
        this.animationFrameRequestId = requestAnimationFrame(callback);
    }

    reset() {
        this.elements = [];
        this.updateDom();
    }

    setTitle(title: string) {
        window.document.title = title;
    }

    enableTextEdition(targetId: number, priority: number, text: TextContentLike) {
        let textEdition = this.enabledTextEditions.find(item => item.targetId === targetId);
        let textInput = TextContent.from(text);

        if (!textEdition) {
            textEdition = { targetId, priority, textInput };
            this.enabledTextEditions.push(textEdition);
            this.enabledTextEditions.sort((a, b) => a.priority - b.priority);
            this.updateTextEdition();
        } else if (!textEdition.textInput.equals(textInput)) {
            textEdition.textInput = textInput;
            this.updateTextEdition();
        }
    }

    disableTextEdition(targetId: number) {
        let index = this.enabledTextEditions.findIndex(item => item.targetId === targetId);

        if (index !== -1) {
            this.enabledTextEditions.splice(index, 1);
            this.updateTextEdition();
        }
    }

    private getCurrentTextEdition(): TextEditionData | null {
        return this.enabledTextEditions.at(-1) ?? null;
    }

    private updateTextEdition() {
        let textEdition = this.getCurrentTextEdition();

        if (textEdition) {
            let textInput = textEdition.textInput;

            this.inputOverlay.style.visibility = 'visible';
            this.inputOverlay.value = textInput.content;
            this.inputOverlay.setSelectionRange(textInput.cursorIndex, textInput.cursorIndex);
            this.inputOverlay.focus();
        } else {
            this.inputOverlay.style.visibility = 'hidden';
        }
    }

    // private addEventListener<T extends Window | Document, K extends string>(element: T, type: K, callback: Parameters<T['addEventListener']>[1]) {
    //     element.addEventListener(type, callback);
    // }

    private addEventListener<T extends Window | Document | HTMLElement, K extends keyof EventMap<T> & string>(
        element: T,
        type: K,
        callback: (event: EventMap<T>[K]) => void,
    ) {
        element.addEventListener(type, callback as EventListener, { passive: false });
        this.domEventListeners.push([element, type, callback]);
    }

    private clearDom() {
        for (let [element, kind, callback] of this.domEventListeners) {
            element.removeEventListener(kind, callback);
        }

        if (this.animationFrameRequestId) {
            cancelAnimationFrame(this.animationFrameRequestId);
            this.animationFrameRequestId = 0;
        }

        // while (document.firstChild) {
        //     document.removeChild(document.firstChild);
        // }

        this.domEventListeners = [];
    }

    private updateWindowRect() {
        let width = window.innerWidth;
        let height = window.innerHeight;
        let aspectRatio = this.aspectRatio;

        if (aspectRatio !== null) {
            if (height * aspectRatio > width) {
                height = width / aspectRatio;
            } else {
                width = height * aspectRatio;
            }
        }

        let x = (window.innerWidth - width) / 2;
        let y = (window.innerHeight - height) / 2;

        this.windowTopLeftX = Math.round(x);
        this.windowTopLeftY = Math.round(y);
        this.windowWidth = Math.round(width);
        this.windowHeight = Math.round(height);
    }

    private updateElement(element: HTMLElement) {
        // TODO: handle devicePixelRatio
        element.style.position = 'absolute';
        element.style.left = `${this.windowTopLeftX}px`;
        element.style.top = `${this.windowTopLeftY}px`;
        element.style.width = `${this.windowWidth}px`;
        element.style.height = `${this.windowHeight}px`;

        if (element instanceof HTMLCanvasElement) {
            element.width = this.windowWidth;
            element.height = this.windowHeight;
        }
    }

    private updateDom() {
        if (!document.body) {
            this.addEventListener(window, 'load', () => this.updateDom());
            return;
        }

        document.body.style.margin = '0';
        document.body.style.backgroundColor = 'black';

        while (document.body.firstChild) {
            document.body.removeChild(document.body.firstChild);
        }

        for (let element of this.elements) {
            document.body.appendChild(element);
        }

        document.body.appendChild(this.inputOverlay);
    }

    private emitEvent(event: DomEvent) {
        for (let callback of this.onEventCallbacks) {
            callback(event);
        }

        let specializedCallbacks = this.onSpecializedEventCallbacks[event.kind];

        if (specializedCallbacks) {
            for (let callback of specializedCallbacks) {
                callback(event as any);
            }
        }
    }

    private onResize() {
        if (!this.initialized) {
            return;
        }

        this.updateWindowRect();

        for (let element of this.elements) {
            this.updateElement(element);
        }

        this.updateDom();

        for (let callback of this.onResizeCallbacks) {
            callback();
        }

        this.emitEvent({ kind: 'resize' });
    }

    private parseMouseEvent(evt: MouseEvent) {
        let button = BUTTON_TO_STRING[evt.button] ?? 'left';
        let x = evt.clientX - this.windowTopLeftX;
        let y = evt.clientY - this.windowTopLeftY;
        let altKey = evt.altKey;
        let ctrlKey = evt.ctrlKey;
        let shiftKey = evt.shiftKey;
        let metaKey = evt.metaKey;
        let dx = 0;
        let dy = 0;
        let startX = 0;
        let startY = 0;

        return { button, x, y, dx, dy, startX, startY, altKey, ctrlKey, shiftKey, metaKey };
    }

    private onMouseMove(evt: MouseEvent) {
        let attributes = this.parseMouseEvent(evt);

        this.emitEvent({
            kind: 'mouse',
            action: 'move',
            nativeEvent: evt,
            ...attributes
        });

        // this.updateTextEdition();
    }

    private onMouseDown(evt: MouseEvent) {
        let attributes = this.parseMouseEvent(evt);

        this.emitEvent({
            kind: 'mouse',
            action: 'down',
            nativeEvent: evt,
            ...attributes
        });

        this.updateTextEdition();
    }

    private onMouseUp(evt: MouseEvent) {
        let attributes = this.parseMouseEvent(evt);

        this.emitEvent({
            kind: 'mouse',
            action: 'up',
            nativeEvent: evt,
            ...attributes
        });

        this.updateTextEdition();
    }

    private onKeyDown(evt: KeyboardEvent) {
        this.emitEvent({
            kind: 'keyboard',
            action: 'down',
            nativeEvent: evt,
            key: evt.key,
            code: evt.code as KeyCode,
            metaKey: evt.metaKey,
            ctrlKey: evt.ctrlKey,
            shiftKey: evt.shiftKey,
            altKey: evt.altKey,
            repeat: evt.repeat
        });

        let textEdition = this.getCurrentTextEdition();

        if (textEdition) {
            let targetId = textEdition.targetId;
            let textInput = textEdition.textInput;

            this.delayedTextEventTimeout = setTimeout(() => {
                let newText = TextContent.from(this.inputOverlay);

                if (!newText.equals(textInput)) {
                    this.emitEvent({
                        kind: 'text-input',
                        targetId,
                        inputType: 'cursor-move',
                        nativeEvent: null,
                        text: newText.content,
                        selectionStart: newText.cursorIndex,
                        selectionEnd: newText.cursorIndex,
                    });
                }

                this.updateTextEdition();
                this.delayedTextEventTimeout = null;
            });
        }
    }

    private onKeyUp(evt: KeyboardEvent) {
        this.emitEvent({
            kind: 'keyboard',
            action: 'up',
            nativeEvent: evt,
            key: evt.key,
            code: evt.code as KeyCode,
            metaKey: evt.metaKey,
            ctrlKey: evt.ctrlKey,
            shiftKey: evt.shiftKey,
            altKey: evt.altKey,
            repeat: evt.repeat
        });

        this.updateTextEdition();
    }

    private onWheel(evt: WheelEvent) {
        let { x, y } = this.parseMouseEvent(evt);

        this.emitEvent({
            kind: 'wheel',
            action: 'down',
            nativeEvent: evt,
            x,
            y,
            deltaX: evt.deltaX,
            deltaY: evt.deltaY,
            deltaZ: evt.deltaZ,
            deltaMode: DELTA_MODE_TO_STRING[evt.deltaMode],
            metaKey: evt.metaKey,
            ctrlKey: evt.ctrlKey,
            shiftKey: evt.shiftKey,
            altKey: evt.altKey,
        });

        this.updateTextEdition();
    }

    private onTextInput(evt: InputEvent) {
        let input = this.inputOverlay;

        if (this.delayedTextEventTimeout) {
            clearTimeout(this.delayedTextEventTimeout);
            this.delayedTextEventTimeout = null;
        }

        let textEdition = this.getCurrentTextEdition();

        if (textEdition) {
            this.emitEvent({
                kind: 'text-input',
                targetId: textEdition.targetId,
                nativeEvent: evt,
                inputType: evt.inputType as TextInputType,
                text: input.value,
                selectionStart: input.selectionStart ?? input.value.length,
                selectionEnd: input.selectionEnd ?? input.value.length,
            });
        }
    }


}
globalThis.ALL_FUNCTIONS.push(WindowManager);