export class Counter {
    private firstValue: number;
    private nextValue: number;

    constructor(firstValue: number = 1) {
        this.firstValue = firstValue;
        this.nextValue = this.firstValue;
    }

    next() {
        return this.nextValue++;
    }

    peek() {
        return this.nextValue;
    }

    reset(firstValue?: number) {
        this.firstValue = firstValue ?? this.firstValue;
        this.nextValue = this.firstValue;
    }
}
globalThis.ALL_FUNCTIONS.push(Counter);