import { GameEvent } from './game-event.ts';
import { GameEventQueue } from './game-event-queue.ts';

export class GameEventQueueGroup extends GameEvent {
    private eventChains: GameEventQueue[] = [];

    add() {
        let eventChain = new GameEventQueue();

        this.eventChains.push(eventChain);

        return eventChain;
    }

    onProgress(elapsed: number) {
        let remaining = elapsed;

        for (let eventChain of this.eventChains) {
            remaining = Math.min(remaining, eventChain.trigger(elapsed));
        }

        return remaining;
    }
}
globalThis.ALL_FUNCTIONS.push(GameEventQueueGroup);