import { TypeKind, TypeSchema } from './type-schema.ts';

export function valueSchema<T>(refValue: T): TypeSchema<T> {
    return {
        kind: TypeKind.Other,
        optional: false,
        check(ctx, value) {
            return value == refValue;
        },
        serialize(buffer, value) {
            
        },
        deserialize(buffer) {
            return refValue;
        },
    };
}