export class LocalStorageInstance {
    readString(key: string, defaultValue: string = ''): string {
        return localStorage.getItem(key) ?? defaultValue;
    }

    readObject<T extends object>(ctor: (new (...args: any[]) => T) | null, key: string): T | null {
        let data = localStorage.getItem(key);
        let result = ctor ? Object.create(ctor.prototype) : {};

        if (data) {
            return Object.assign(result, JSON.parse(data));
        } else {
            return null;
        }
    }

    writeString(key: string, value: string) {
        localStorage.setItem(key, '' + value);
    }

    writeObject<T extends object | null>(key: string, value: T) {
        localStorage.setItem(key, JSON.stringify(value, jsonStringifyReplacer));
    }

    delete(key: string) {
        localStorage.removeItem(key);
    }
}

function jsonStringifyReplacer(key: string, value: unknown) {
    if (value && value instanceof Element) {
        return null;
    }

    return value;
}

export const LocalStorage = new LocalStorageInstance();
globalThis.ALL_FUNCTIONS.push(LocalStorageInstance);