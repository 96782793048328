import { ComputedProperties } from '../language/object.ts';
import { ClockProperties } from './clock-types.ts';

export class Clock {
    private realStartTime: number = performance.now();
    private realCurrentTime: number = this.realStartTime;
    private virtualTimeSpeed: number = 1;
    private virtualCurrentTime: number = 0;
    private virtualDurationSinceLastTick: number = 0;

    static readonly IDLE: Clock = new Clock(-1);

    constructor(currentTime: number = 0) {
        this.virtualCurrentTime = currentTime;
    }

    /**
     * Returns the number of milliseconds elapsed from the creation of the clock to the last `tick` call.
     * @returns 
     */
    getCurrentTime(): number {
        return this.virtualCurrentTime;
    }

    /**
     * Returns the number of milliseconds elapsed since the specified time.
     * @param time Timestamp returned by a previous call to `getCurrentTime()`.
     * @returns 
     */
    getElapsedSince(time: number) {
        return this.getCurrentTime() - time;
    }

    /**
     * Update the clock time with the current time.
     * @returns The number of milliseconds elapsed since the last `tick` call.
     */
    tick(): number {
        let realCurrentTime = performance.now();
        let realElapsedDuration = realCurrentTime - this.realCurrentTime;

        let virtualCurrentTime = this.virtualCurrentTime + realElapsedDuration * this.virtualTimeSpeed;
        let virtualElapsedDuration = virtualCurrentTime - this.virtualCurrentTime;

        this.realCurrentTime = realCurrentTime;
        this.virtualCurrentTime = virtualCurrentTime;
        this.virtualDurationSinceLastTick = virtualElapsedDuration;

        return virtualElapsedDuration;
    }

    setSpeed(speed: number) {
        this.virtualTimeSpeed = speed;
    }

    leap(duration: number) {
        this.virtualCurrentTime += duration;
    }

    setCurrentTime(time: number) {
        this.virtualCurrentTime = time;
    }

    getElapsedDurationSinceLastTick(): number {
        return this.virtualDurationSinceLastTick;
    }

    reset() {
        this.virtualCurrentTime = 0;
        this.virtualTimeSpeed = 1;
        this.virtualDurationSinceLastTick = 0;
    }

    setProperties(properties: ComputedProperties<ClockProperties>): Clock {
        let p = typeof properties === 'function' ? properties(this.getProperties()) : properties;

        if (p.currentTime !== undefined) {
            this.virtualCurrentTime = p.currentTime;
        }

        if (p.speed !== undefined) {
            this.virtualTimeSpeed = p.speed;
        }

        return this;
    }

    getProperties(): ClockProperties {
        return {
            currentTime: this.virtualCurrentTime,
            speed: this.virtualTimeSpeed
        };
    }
}
globalThis.ALL_FUNCTIONS.push(Clock);