import { CSSProperties } from 'preact/compat';
import { BoardLocalData } from './board-local-data';
import { BoardCardComponent } from './board-card-component';
import { toCssPixels } from 'outpost';
import { BoardCard } from './board-card';
import { BoardZone } from './board-types';

export type BoardCardSlotComponentProps = {
    localData: BoardLocalData;
    card: BoardCard;
    sourceZone: BoardZone;
    hidden: boolean;
    height?: string;
    marginTop?: number | string;
    disableHover?: boolean;
};

export function BoardCardSlotComponent(props: BoardCardSlotComponentProps) {
    let { card, localData, sourceZone, hidden, height, marginTop, disableHover } = props;
    let disableInteractions = false;
    let style: CSSProperties = {
        position: 'relative',
        aspectRatio: '2.5 / 3.5',
        height: height ?? '100%',
        pointerEvents: disableInteractions ? 'none' : 'auto',
        display: hidden ? 'none' : 'block',
        marginRight: toCssPixels(card.attachedCards.length * 15),
        marginTop: toCssPixels(marginTop),
    };

    let cardElts = [card, ...card.attachedCards].map((childCard, i) => <BoardCardComponent
        localData={localData}
        card={childCard}
        sourceZone={sourceZone}
        stackIndex={i}
        disableHover={disableHover}
    />).reverse();

    return <div style={style}>{cardElts}</div>;
};