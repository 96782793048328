export function requireById<T>(map: Map<string, T>, itemId: string, itemKind: string = "item"): T {
    let item = map.get(itemId);

    if (!item) {
        throw new Error(`no ${itemKind} with id "${itemId}" exists`);
    }

    return item;
}

export function assertAvailableId<T>(map: Map<string, T>, itemId: string, itemKind: string = "item") {
    let item = map.get(itemId);

    if (item) {
        throw new Error(`${itemKind} with id "${itemId}" already exists`);
    }
}

export function getOrInsertWith<K, V>(map: Map<K, V>, key: K, insertCallback: () => V): V {
    let value = map.get(key);

    if (value === undefined) {
        value = insertCallback();
        map.set(key, value);
    }

    return value;
}