import { LobbyPlayer } from './lobby-player.ts';
import { LOBBY_PLAYER_COLORS } from './lobby-constants.ts';
import { GameHubParams, PlayerParams } from '../hub.ts';
import { BaseHubRoom } from '../base-hub-room.ts';
import { View } from '../../framework/view/view.ts';
import { Button } from '../../helpers/widgets/button.ts';
import { Label } from '../../helpers/widgets/label.ts';
import { Component } from '../../framework/component/component.ts';
import { Room } from '../../framework/global/global-room-api.ts';
import { getRangeEnd, getRangeStart } from '../../utils/language/range.ts';

export type LobbyRoomParams = {
    name: string;
};

export class LobbyRoom extends BaseHubRoom implements Component {
    nameLabel = new Label();
    players: LobbyPlayer[] = [];
    startButton = new Button('Start');
    leaveButton = new Button('Leave');
    host: LobbyPlayer | null = null;
    minPlayerCount: number;
    maxPlayerCount: number;

    constructor(baseParams: GameHubParams, params: LobbyRoomParams) {
        super(baseParams);
        this.nameLabel.setText(params.name);
        this.startButton.setStyle(this.params.buttonStyle);
        this.leaveButton.setStyle(this.params.buttonStyle);
        this.minPlayerCount = getRangeStart(baseParams.playerCount, 1);
        this.maxPlayerCount = getRangeEnd(baseParams.playerCount, Infinity);
    }

    onRegister() {
        this.startButton.setEnabledCondition(() => this.players.length >= this.minPlayerCount && this.players.length <= this.maxPlayerCount);
    }

    private refresh() {
        for (let i = 0; i < this.players.length; ++i) {
            let player = this.players[i];

            player.isHost = i === 0;
            player.color = LOBBY_PLAYER_COLORS[i % LOBBY_PLAYER_COLORS.length];
        }

        this.host = this.players[0] ?? null;

        Room.render();
    }

    onPlayerAdded(): void {
        this.players.push(Room.getSourcePlayer(LobbyPlayer));
        this.refresh();
    }

    onPlayerRemoved(): void {
        this.players.remove(Room.getSourcePlayer(LobbyPlayer));
        this.refresh();
    }

    render(view: View) {
        if (this.params.renderLobbyRoom) {
            this.params.renderLobbyRoom(view, this);
        } else {
            this.defaultRender(view);
        }
    }

    defaultRender(view: View): void {
        let nameRect = view.rect.fromTopInwards('*', '20%');

        view.addChild(this.nameLabel, nameRect);

        if (this.maxPlayerCount !== Infinity) {
            view.addChild({
                text: `${this.players.length} / ${this.maxPlayerCount} players`,
                textSize: '50%',
                rect: nameRect.fromRightInwards(200, '50%').translate(-150, 0),
            });
        }

        view.addGrid({
            items: this.players,
            rect: rect => rect.fromCenter('100%', '70%'),
            columnSize: 4,
            rowSize: [1, Infinity],
            fillFirst: 'column',
            itemAspectRatio: 6,
            horizontalAlign: 'left',
        });

        let buttons = [this.leaveButton];

        if (this.host?.id === view.getLocalPlayerId()) {
            buttons.push(this.startButton);
        }

        view.layout()
            .bottomLeftToRight()
            .horizontalAlign('center')
            .childAspectRatio(4)
            .childHeight('10%')
            .margin('5%')
            .addChild(buttons);
    }

    async $leaveLobbyInteraction() {
        let player = Room.getSourcePlayer(LobbyPlayer);

        await Room.waitForItemSelection(this.leaveButton);
        await Room.waitForServerResponse();

        Room.removePlayerFromRoom(Room.getId(), player.id);
    }

    async $startGameInteraction() {
        let player = Room.getSourcePlayer(LobbyPlayer);

        await Room.waitForItemSelection(this.startButton);
        await Room.waitForServerResponse();

        Room.assert(player.isHost);

        Room.createRoom(this.params.gameConstructor, {
            constructorArgs: [{ dataSpreadsheet: [] }],
            players: this.players.map(p => ([p.id, this.createPlayer(p)]))
        });

        Room.deleteRoom(Room.getId());
    }

    private createPlayer(lobbyPlayer: LobbyPlayer) {
        return new this.params.playerConstructor({
            id: lobbyPlayer.id,
            color: lobbyPlayer.color
        });
    }
}
globalThis.ALL_FUNCTIONS.push(LobbyRoom);