import { ItemBuffer } from '../memory/item-buffer.ts';
import { WebglAttributesLayout, WebglAttributesLayoutDetails } from './webgl-attributes.ts';
import { WebglBufferWriter, WebglBufferWriterImplementation } from './webgl-buffer-writer.ts';
import { WEBGL_BUFFER_USAGES, WebglBufferUsage } from './webgl-types.ts';

export type WebglBufferParams<T extends WebglAttributesLayout> = {
    gl: WebGL2RenderingContext;
    usage: WebglBufferUsage;
    itemByteSize: number;
    initialItemCapacity: number;
    attributes: WebglAttributesLayoutDetails<T>;
};

export class WebglBuffer<T extends WebglAttributesLayout> {
    private gl: WebGL2RenderingContext;
    private usage: WebglBufferUsage;
    private itemByteSize: number;
    private itemBuffer: ItemBuffer;
    private attributes: WebglAttributesLayoutDetails<T>;
    private writer: WebglBufferWriterImplementation<T>;
    private glBuffer: WebGLBuffer;
    private glBufferByteCapacity: number = 0;
    private mustSyncWithGpu: boolean = false;

    constructor(params: WebglBufferParams<T>) {
        this.gl = params.gl;
        this.usage = params.usage;
        this.itemByteSize = params.itemByteSize;
        this.itemBuffer = new ItemBuffer({
            itemByteSize: params.itemByteSize,
            initialItemCapacity: params.initialItemCapacity
        });
        this.attributes = params.attributes;
        this.writer = new WebglBufferWriterImplementation({
            dataView: this.itemBuffer.dataView,
            attributes: params.attributes,
        });
        this.glBuffer = this.createGlBuffer();
    }

    private createGlBuffer(): WebGLBuffer {
        let gl = this.gl;
        let glBuffer = gl.createBuffer()!;
        let usage = WEBGL_BUFFER_USAGES[this.usage];

        gl.bindBuffer(gl.ARRAY_BUFFER, glBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, this.itemBuffer.byteCapacity, usage);

        return glBuffer;
    }

    getGlBuffer(): WebGLBuffer {
        return this.glBuffer;
    }

    getDataView(): DataView {
        return this.itemBuffer.dataView;
    }

    getItemCount(): number {
        return this.itemBuffer.getItemCount();
    }

    allocate(): WebglBufferWriter<T> {
        let byteOffset = this.itemBuffer.allocate();

        this.mustSyncWithGpu = true;

        return this.writer.setWriteIndex(this.itemBuffer.dataView, byteOffset);
    }

    update(elementIndex: number): WebglBufferWriter<T> {
        let byteOffset = elementIndex * this.itemByteSize;

        this.mustSyncWithGpu = true;

        return this.writer.setWriteIndex(this.itemBuffer.dataView, byteOffset);
    }

    deallocate(elementIndex: number): null {
        let byteOffset = elementIndex * this.itemByteSize;

        this.mustSyncWithGpu = true;
        this.itemBuffer.dataView.setFloat32(byteOffset, 0);

        return this.itemBuffer.deallocate(byteOffset);
    }

    clear() {
        this.itemBuffer.clear();
        this.mustSyncWithGpu = true;
    }

    syncWithGpu() {
        if (!this.mustSyncWithGpu) {
            return
        }

        let gl = this.gl;

        gl.bindBuffer(gl.ARRAY_BUFFER, this.glBuffer);

        if (this.glBufferByteCapacity < this.itemBuffer.byteCapacity) {
            let usage = WEBGL_BUFFER_USAGES[this.usage];

            gl.bufferData(gl.ARRAY_BUFFER, this.itemBuffer.uint8Array, usage);
        } else {
            gl.bufferSubData(gl.ARRAY_BUFFER, 0, this.itemBuffer.uint8Array);
        }

        this.mustSyncWithGpu = false;
    }
}
globalThis.ALL_FUNCTIONS.push(WebglBuffer);