import { TypeKind, TypeSchema } from './type-schema.ts';

export type StringTypeParams = {
    minLength?: number;
    maxLength?: number;
    predicate?: (value: string) => boolean;
};

export function stringSchema(params: StringTypeParams | RegExp | ((value: string) => boolean) = {}): TypeSchema<string> {
    let fmt =
        typeof params === 'function' ? { predicate: params } :
        params instanceof RegExp ? { predicate: (value: string) => params.test(value)}:
        params;
    let {
        minLength = 0,
        maxLength = Infinity,
        predicate = () => true,
    } = fmt;

    return {
        kind: TypeKind.String,
        optional: false,
        check(ctx, value) {
            let ok = typeof value === 'string'
                && value.length >= minLength
                && value.length <= maxLength
                && predicate(value)

            if (ok) {
                return true;
            }

            let expected = 'string';

            if (minLength === 0 && isFinite(maxLength)) {
                expected += ` with length >= ${minLength}`;
            } else if (minLength > 0 && !isFinite(maxLength)) {
                expected += ` with length <= ${maxLength}`;
            } else if (minLength !== 0 && isFinite(maxLength)) {
                expected += ` with length >= ${minLength} and <= ${maxLength}`;
            }

            return ctx.expected(expected, value);
        },
        serialize(buffer, value) {
            buffer.writeString(value);
        },
        deserialize(buffer) {
            return buffer.readString();
        },
    };
}