export function kebabToCamelCase(string: string): string {
    return string.replace(/-\w/g, (str) => str[1].toUpperCase());
}

export function camelToKebabCase(string: string): string {
    return string
        .replace(/[a-z][A-Z]/g, (str) => str[0] + '-' + str[1].toLowerCase())
        .replace(/^[A-Z]/, str => str.toLowerCase());
}

export function capitalize(string: string): string {
    return string.substring(0, 1).toUpperCase() + string.substring(1);
}

export function uncapitalize(string: string): string {
    return string.substring(0, 1).toLowerCase() + string.substring(1);
}

export function compareStrings(a: string, b: string): number {
    if (a < b) {
        return -1;
    } else if (a > b) {
        return 1;
    } else {
        return 0;
    }
}

export function extractBetweenMarkers(content: string, start: string, end: string, searchLast: boolean = false): string | null {
    let startIndex = searchLast ? content.lastIndexOf(start) : content.indexOf(start);

    if (startIndex === -1) {
        return null;
    }

    let endIndex = content.indexOf(end, startIndex + start.length);

    if (endIndex === -1) {
        return null;
    }

    return content.substring(startIndex + start.length, endIndex);
}

export function joinString(string: undefined | string | string[], separator: string = '\n'): string {
    if (typeof string === 'string') {
        return string;
    } else if (string) {
        return string.join(separator);
    } else {
        return '';
    }
}