import { OUTPOST_EMAIL } from '../constants';

export function SpreadsheetInstructions() {
    let link = <a href={`mailto:${OUTPOST_EMAIL}`}>{OUTPOST_EMAIL}</a>;

    return (
        <div style={{ textAlign: 'center' }}>
            <div style={{ fontSize: 'large', marginBottom: '10px' }}>
                Google spreadsheet URL:
            </div>
            <div style={{ fontSize: 'small' }}>
                If the spreadsheet is private, share it with {link}
            </div>
        </div>
    );
}