import { TypeSchemaLike, formatTypeSchema } from './type-schema-like.ts';
import { TypeSchema } from './type-schema.ts';

export function nullableSchema<T>(itemTypeLike: TypeSchemaLike<T>): TypeSchema<T | null> {
    let itemType = formatTypeSchema(itemTypeLike);

    return {
        kind: itemType.kind,
        optional: false,
        check(ctx, value) {
            if (value === null) {
                return true;
            } else {
                return itemType.check(ctx, value);
            }
        },
        serialize(buffer, value, key, options) {
            buffer.writeBoolean(value !== null);

            if (value !== null) {
                itemType.serialize(buffer, value, key, options);
            }
        },
        deserialize(buffer, options) {
            if (!buffer.readBoolean()) {
                return null;
            } else {
                return itemType.deserialize(buffer, options);
            }
        },
    }
}