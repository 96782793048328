import { GLOBAL_FUNCTION_ARRAY_NAME } from '../cli/esbuild/plugins/code-injection.ts';
import { SerializableAsset, SerializableAssetIndex } from '../utils/serialization/serializable-asset-index.ts';

const SERIALIZABLE_ASSETS: SerializableAsset[] = [];

let globalSerializableAssetIndex: SerializableAssetIndex | null = null;

export function registerSerializableAsset<T extends SerializableAsset>(classFunction: T): T {
    SERIALIZABLE_ASSETS.push(classFunction);

    return classFunction;
}

export function getSerializableAssets(): SerializableAssetIndex {
    if (!globalSerializableAssetIndex) {
        globalSerializableAssetIndex = new SerializableAssetIndex([
            ...(globalThis as any)[GLOBAL_FUNCTION_ARRAY_NAME],
            ...SERIALIZABLE_ASSETS,
        ]);
    }

    return globalSerializableAssetIndex;
}