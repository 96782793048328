import { ColorLike, toCssPixels } from 'outpost';
import { CSSProperties } from 'preact/compat';

export type SeparatorProps = {
    style?: CSSProperties;
    width?: number | `${number}%`;
    height?: number | `${number}%`;
    color?: ColorLike;
    grow?: boolean;
};

export function Separator(props: SeparatorProps) {
    let style: CSSProperties = {
        backgroundColor: props.color as string,
        width: toCssPixels(props.width),
        height: toCssPixels(props.height),
        alignSelf: 'stretch',
        grow: props.grow ? 1 : 0,
        ...props.style
    };

    return <div style={style} />;
}