import { TypeKind, TypeSchema } from './type-schema.ts';

const PRIMITIVE_NAMES = <const>['undefined', 'null', 'boolean', 'number', 'string'];

export type PrimitiveName = typeof PRIMITIVE_NAMES[number];

type MapPrimitiveName<T extends PrimitiveName> =
    T extends 'undefined' ? undefined :
    T extends 'null' ? null :
    T extends 'boolean' ? boolean :
    T extends 'number' ? number :
    T extends 'string' ? string :
    never;

export function primitiveSchema<T extends readonly PrimitiveName[]>(types: T): TypeSchema<MapPrimitiveName<T[number]>> {
    return {
        kind: TypeKind.Other,
        optional: false,
        check(ctx, value) {
            let primitiveName = getPrimitiveName(value);

            if (primitiveName) {
                return true;
            } else {
                return ctx.expected(types.join(', '), value);
            }
        },
        serialize(buffer, value) {
            if (value === undefined) {
                buffer.writeUint8(0);
            } else if (value === null) {
                buffer.writeUint8(1);
            } else if (typeof value === 'boolean') {
                buffer.writeUint8(2);
                buffer.writeBoolean(value);
            } else if (typeof value === 'number') {
                buffer.writeUint8(3);
                buffer.writeNumber(value);
            } else if (typeof value === 'string') {
                buffer.writeUint8(4);
                buffer.writeString(value);
            } else {
                throw new Error(`invalid value type`);
            }
        },
        deserialize(buffer) {
            let typeId = buffer.readUint8();
            let result: any;

            if (typeId === 0) {
                result = undefined;
            } else if (typeId === 1) {
                result = null;
            } else if (typeId === 2) {
                result = buffer.readBoolean();
            } else if (typeId === 3) {
                result = buffer.readNumber();
            } else if (typeId === 4) {
                result = buffer.readString();
            } else {
                throw new Error(`invalid type id`);
            }

            return result as MapPrimitiveName<T[number]>;
        },
    };
};

function getPrimitiveName(value: unknown): PrimitiveName | null {
    if (value === undefined) {
        return 'undefined';
    } else if (value === null) {
        return 'null';
    } else if (typeof value === 'boolean') {
        return 'boolean';
    } else if (typeof value === 'number') {
        return 'number';
    } else if (typeof value === 'string') {
        return 'string';
    } else {
        return null;
    }
}