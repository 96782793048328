import { Collection, iterateCollection, collectionToSet } from '../../utils/language/collection.ts';
import { Client } from '../client/client.ts';
import { Component } from '../component/component.ts';
import { ViewModifierComponentState } from './view-modifier-types.ts';
import { ComponentModifier, ComponentModifierCallback, componentModifierToCallback } from '../component/component-modifier.ts';
import { ComponentHighlightMethodName } from '../component/component-types.ts';
import { ViewFragmentPriority } from './view-types.ts';

export type ViewModifierParams = {
    modifier: ComponentModifier | ComponentHighlightMethodName;
    components?: Collection<Component>;
    priority?: number;
    refreshEveryMs?: number;
};

export class ViewModifier {
    private client: Client;
    private components: Map<Component, ViewModifierComponentState> = new Map()
    private modifier: ComponentModifier | ComponentHighlightMethodName;
    private priority: number;
    private refreshEveryMs: number;
    private lastUpdateTime: number = 0;
    private count: number = 0;

    constructor(client: Client, params: ViewModifierParams) {
        this.client = client;
        this.modifier = params.modifier;
        this.priority = params.priority ?? ViewFragmentPriority.Interaction;
        this.refreshEveryMs = Infinity;

        if (params.components) {
            for (let component of iterateCollection(params.components)) {
                this.add(component);
            }
        }
    }

    get size() {
        return this.count;
    }

    has(component: Component): boolean {
        let state = this.components.get(component);

        return !!state && !state.deleted;
    }

    add(component: Component): boolean {
        let state = this.components.get(component);

        if (!state || state.deleted) {
            this.components.set(component, {
                component,
                assigned: false,
                deleted: false,
                mustRefresh: false
            });

            this.count += 1;

            return true;
        }

        return false;
    }
    
    delete(component: Component): boolean {
        let state = this.components.get(component);

        if (state && !state.deleted) {
            state.deleted = true;
            this.count -= 1;

            return true;
        }

        return false;
    }

    clear() {
        for (let state of this.components.values()) {
            if (!state.deleted) {
                state.deleted = true;
            }
        }

        this.count = 0;
    }

    isEmpty(): boolean {
        return this.count === 0;
    }

    *values() {
        for (let { component, deleted } of this.components.values()) {
            if (!deleted) {
                yield component;
            }
        }
    }

    set(components: Collection<Component>) {
        let componentsToAdd = collectionToSet(components);

        for (let state of this.components.values()) {
            if (!componentsToAdd.has(state.component) && !state.deleted) {
                state.deleted = true;
                this.count -= 1;
            }
        }

        for (let component of componentsToAdd) {
            this.add(component);
        }
    }

    update() {
        for (let state of this.components.values()) {
            let { component, assigned, deleted } = state;

            if (deleted) {
                this.unassignModifier(component);
                this.components.delete(component);
            } else if (!assigned || state.mustRefresh) {
                state.mustRefresh = this.assignModifier(component);
                state.assigned = true;
            }
        }

        let currentTime = this.client.getGraphicsEngine().getCurrentTime();

        if (this.lastUpdateTime + this.refreshEveryMs < currentTime) {
            this.lastUpdateTime = currentTime;
            // TODO: refresh if necessary
        }
    }

    destroy() {
        this.clear();
        this.update();
    }

    private assignModifier(component: Component): boolean {
        let view = this.client.getView(component);
        let modifier: ComponentModifier | null = null;

        if (typeof this.modifier === 'string') {
            let key = this.modifier as ComponentHighlightMethodName;

            if (component[key]) {
                modifier = (view, component) => component[key]!(view);
            }
        } else {
            modifier = this.modifier;
        }

        if (view && modifier) {
            let modifierCallback = componentModifierToCallback(modifier);
            let fragment = view.addFragment(this.priority, modifierCallback);

            return fragment.autoRefresh;
        }

        return false;
    }

    private unassignModifier(component: Component) {
        let view = this.client.getView(component);

        if (view) {
            view.deleteFragment(this.priority, true);
        }
    }
}
globalThis.ALL_FUNCTIONS.push(ViewModifier);