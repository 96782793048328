import { Counter } from './counter.ts';

export class UniqueIdCache<T> {
    private bitMask: bigint = 0xffffffffffffffffn;
    private cache: Map<T, bigint> = new Map();
    private idCounter: Counter = new Counter();

    constructor(maxBitSize: number = 64, startId: number = 0) {
        this.bitMask = (1n << BigInt(maxBitSize)) - 1n;
        this.idCounter = new Counter(startId);
    }

    getId(item: T): bigint {
        let id = this.cache.get(item);

        if (id === undefined) {
            id = BigInt(this.idCounter.next()) & this.bitMask;
            this.cache.set(item, id);
        }

        return id;
    }
}
globalThis.ALL_FUNCTIONS.push(UniqueIdCache);