import { Deserializer } from '../serialization/deserializer.ts';
import { SerializableAssetIndexLike } from '../serialization/serializable-asset-index.ts';
import { Serializer } from '../serialization/serializer.ts';
import { TypeKind, TypeSchema } from './type-schema.ts';

export function anySchema<T>(serializableAssets?: SerializableAssetIndexLike): TypeSchema<T> {
    return {
        kind: TypeKind.Other,
        optional: false,
        check(ctx, value) {
            return true;
        },
        serialize(buffer, value, options) {
            new Serializer(options?.serializableAssets ?? serializableAssets).serialize(value, buffer);
        },
        deserialize(buffer, options) {
            return new Deserializer(options?.serializableAssets ?? serializableAssets).deserialize(buffer);
        },
    };
}