import { createCanvas, HorizontalAlign, VerticalAlign } from 'outpost';
import { IconMapping, renderText } from './text-rendering.ts';
import { AssetLoader } from './asset-loader.ts';
import { isImageUrl, normalizeString } from './utils.ts';
import { PREVIEW_CARD_HEIGHT_PIXELS, PREVIEW_CARD_WIDTH_PIXELS, PDF_CARD_HEIGHT_PIXELS, PDF_CARD_WIDTH_PIXELS } from './constants.ts';
import { FontSize } from './types.ts';
import { Project } from './project.ts';
import { CardData, TextReplacements } from './project-types.ts';

export type CardImageData = {
    canvas: HTMLCanvasElement;
    quantity: number;
};

export type GenerateCardImageParams = {
    imageLoader: AssetLoader;
    pdfQuality: boolean;
}

const HORIZONTAL_ALIGN_MULTIPLIER: { [Key in HorizontalAlign]: number } = { left: 0, center: 0.5, right: 1 };
const VERTICAL_ALIGN_MULTIPLIER: { [Key in VerticalAlign]: number } = { top: 0, middle: 0.5, bottom: 1 };

export async function generateCardImages(project: Project, params: GenerateCardImageParams): Promise<CardImageData[]> {
    let { imageLoader, pdfQuality } = params;
    let result: Promise<CardImageData>[] = [];
    let preloadPromises: Promise<any>[] = [];

    for (let { url } of Object.values(project.icons)) {
        preloadPromises.push(imageLoader.loadImage(url));
    }

    await Promise.all(preloadPromises);

    for (let card of project.cards) {
        let quantity = card.quantity;

        if (quantity <= 0) {
            continue;
        }

        let promise = drawCard(card, project, imageLoader, pdfQuality).then(canvas => ({ canvas, quantity }));

        result.push(promise);
    }

    return await Promise.all(result);
}

async function drawCard(card: CardData, project: Project, assetLoader: AssetLoader, pdfQuality: boolean): Promise<HTMLCanvasElement> {
    let canvasWidth = pdfQuality ? PDF_CARD_WIDTH_PIXELS : PREVIEW_CARD_WIDTH_PIXELS;
    let canvasHeight = pdfQuality ? PDF_CARD_HEIGHT_PIXELS : PREVIEW_CARD_HEIGHT_PIXELS;
    let canvas = createCanvas({ width: canvasWidth, height: canvasHeight });
    let ctx = canvas.getContext('2d')!;
    let template = project.templates[card.templateId];
    let { width: templateWidth, height: templateHeight } = template.size;
    let scaleX = canvasWidth / templateWidth;
    let scaleY = canvasHeight / templateHeight;
    let defaultAttributes = template.defaultAttributes ?? {};

    for (let [name, url] of Object.entries(template.fonts ?? {})) {
        if (!assetLoader.isFontLoaded(name)) {
            await assetLoader.loadFont(name, url);
        }
    }

    for (let [columnName, properties] of Object.entries(template.boxAttributes)) {
        let {
            rect,
            backgroundColor = defaultAttributes.backgroundColor ?? null,
            textFont = defaultAttributes.textFont ?? 'Arial',
            textColor = defaultAttributes.textColor ?? 'black',
            textSize = defaultAttributes.textSize ?? '80%',
            textHorizontalAlign = defaultAttributes.textHorizontalAlign ?? 'center',
            textVerticalAlign = defaultAttributes.textVerticalAlign ?? 'middle',
            textBold = defaultAttributes.textBold ?? false,
            textItalic = defaultAttributes.textItalic ?? false,
            imageUrl = defaultAttributes.imageUrl ?? null,
            textContent = defaultAttributes.textContent ?? '$'
        } = properties;
        let x = rect.x * scaleX | 0;
        let y = rect.y * scaleY | 0;
        let width = rect.width * scaleX | 0;
        let height = rect.height * scaleY | 0;
        let image: HTMLImageElement | null = null;

        let content = card.columns[columnName] || '';
        content = textContent.replaceAll('$', content);
        content = formatContent(content, project.replacements, card);

        if (content === '-') {
            textHorizontalAlign = 'center';
        }
        
        if (imageUrl) {
            image = await assetLoader.loadImage(imageUrl);
        }

        drawRectangle(ctx, { x, y, width, height, backgroundColor, image });

        if (isImageUrl(content)) {
            let image = await assetLoader.loadImage(content);

            drawRectangle(ctx, { x, y, width, height, image });
        } else {
            drawText(ctx, {
                icons: project.icons,
                imageLoader: assetLoader,
                x,
                y,
                width,
                height,
                text: content,
                textFont,
                textColor,
                textSize: Math.round(computeTextSize(textSize, height / scaleY) * scaleY),
                textHorizontalAlign,
                textVerticalAlign,
                textBold,
                textItalic,
            });
        }
    }

    if (!pdfQuality) {
        card.image = canvas;
        card.imageId = crypto.randomUUID();
    }

    return canvas;
}

type DrawTextParams = {
    icons: IconMapping;
    imageLoader: AssetLoader;
    x: number;
    y: number;
    width: number;
    height: number;
    text: string;
    textFont: string;
    textSize: number;
    textColor: string
    textHorizontalAlign: HorizontalAlign;
    textVerticalAlign: VerticalAlign;
    textBold: boolean;
    textItalic: boolean;
}

function drawText(ctx: CanvasRenderingContext2D, params: DrawTextParams) {
    if (!params.text) {
        return;
    }

    let textImage = renderText({
        imageLoader: params.imageLoader,
        icons: params.icons,
        content: params.text,
        width: params.width,
        height: params.height,
        font: params.textFont,
        size: params.textSize,
        color: params.textColor,
        bold: params.textBold,
        italic: params.textItalic,
        horizontalAlign: params.textHorizontalAlign,
        verticalAlign: params.textVerticalAlign,
        padding: 0,
        allowMultiline: true,
    }).image;

    let mx = HORIZONTAL_ALIGN_MULTIPLIER[params.textHorizontalAlign] ?? 0.5;
    let my = VERTICAL_ALIGN_MULTIPLIER[params.textVerticalAlign] ?? 0.5;
    let offsetX = (params.width - textImage.width) * mx;
    let offsetY = (params.height - textImage.height) * my;

    ctx.drawImage(textImage, params.x + offsetX, params.y + offsetY);
}

function computeTextSize(textSize: FontSize, boxHeight: number): number {
    let value = parseInt(textSize);

    if (textSize.endsWith('%')) {
        return Math.round(value / 100 * boxHeight);
    } else {
        return value;
    }
}

function formatColor(color: string | null): string | null {
    if (color === 'none') {
        return null;
    } else {
        return color;
    }
}

type DrawRectangleParams = {
    x: number;
    y: number;
    width: number;
    height: number;
    backgroundColor?: string | null;
    borderColor?: string | null;
    borderRadius?: number;
    borderWidth?: number;
    image?: HTMLImageElement | null;
    imageScale?: number;
    imageFit?: boolean;
    imageCrop?: boolean;
}

function drawRectangle(ctx: CanvasRenderingContext2D, params: DrawRectangleParams) {
    let {
        x,
        y,
        width,
        height,
        backgroundColor = null,
        borderRadius = 0,
        borderColor = null,
        borderWidth = 0,
        image = null,
        imageScale = 1,
        imageFit = false,
        imageCrop = true,
    } = params;

    backgroundColor = formatColor(backgroundColor);
    borderColor = formatColor(borderColor);

    if (!borderColor && !backgroundColor && !image) {
        return;
    }

    let r = borderRadius;
    let x1 = x;
    let y1 = y;
    let x2 = x + width;
    let y2 = y + height;
    let drawBorder = borderWidth && borderColor;
    let drawImage = image && image.width > 0 && image.height > 0;
    let doClip = drawBorder || drawImage;

    doClip = false;

    if (borderRadius === 0) {
        ctx.beginPath();
        ctx.rect(x, y, width, height);
    } else {
        ctx.beginPath();
        ctx.moveTo(x1 + r, y1);
        ctx.lineTo(x2 - r, y1);
        ctx.quadraticCurveTo(x2, y1, x2, y1 + r);
        ctx.lineTo(x2, y2 - r);
        ctx.quadraticCurveTo(x2, y2, x2 - r, y2);
        ctx.lineTo(x1 + r, y2);
        ctx.quadraticCurveTo(x1, y2, x1, y2 - r);
        ctx.lineTo(x1, y1 + r);
        ctx.quadraticCurveTo(x1, y1, x1 + r, y1);
        ctx.closePath();
    }

    if (backgroundColor) {
        ctx.fillStyle = backgroundColor;
        ctx.fill();
    }

    if (doClip) {
        ctx.save();
        ctx.clip();
    }

    if (image && drawImage) {
        let imageAspectRatio = image.width / image.height;
        let boxAspectRatio = width / height;
        let sWidth = image.width;
        let sHeight = image.height;
        let dWidth = width;
        let dHeight = height;

        if (!imageFit) {
            if (imageCrop) {
                if (imageAspectRatio > boxAspectRatio) {
                    sWidth = image.height * boxAspectRatio;
                } else {
                    sHeight = image.width / boxAspectRatio;
                }
            } else {
                if (imageAspectRatio > boxAspectRatio) {
                    dHeight = width / imageAspectRatio;
                } else {
                    dWidth = height * imageAspectRatio;
                }
            }
        }

        dWidth = Math.round(dWidth * imageScale);
        dHeight = Math.round(dHeight * imageScale);
        
        let sx = (image.width - sWidth) / 2;
        let sy = (image.height - sHeight) / 2;
        let dx = x + (width - dWidth) / 2;
        let dy = y + (height - dHeight) / 2;

        ctx.drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
    }

    if (drawBorder && borderColor) {
        ctx.lineWidth = borderWidth * 2;
        ctx.strokeStyle = borderColor;
        ctx.stroke();
    }

    if (doClip) {
        ctx.restore();
    }
}

function formatContent(content: string, replacements: TextReplacements, card: CardData): string {
    for (let source in replacements) {
        content = content.replaceAll(source, replacements[source]);
    }

    content = content.replace(/{[\w -]+(\/[^}]+)}/g, str => {
        let items = str.substring(1, str.length - 1).split('/');
        let columnName = normalizeString(items[0]);
        let replacement = card.columns[columnName];

        if (replacement) {
            for (let option of items.slice(1)) {
                if (option === 'lower') {
                    replacement = replacement.toLowerCase();
                } else if (option === 'upper') {
                    replacement = replacement.toUpperCase();
                } else if (option.startsWith('>')) {
                    replacement = option.substring(1) + replacement;
                }
            }
        }

        return replacement ?? str;
    });

    content = content.replaceAll('\\n', '\n');

    content = content.replace(/\n(?!-)/g, '\n\n');

    return content;
}