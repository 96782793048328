import { FirstItem, FirstParameter, GetReturnType } from './types.ts';

export type MaybeAsync<T> = T | Promise<T>;

export type MapPromise<T, U> = T extends Promise<infer A> ? Promise<U> : U;

export async function sleep(durationMs: number): Promise<void> {
    if (durationMs === 0) {
        return Promise.resolve();
    } else {
        return new Promise(resolve => setTimeout(resolve, durationMs));
    }
}

const FUNCS = <const>[
    (a: number) => a.toString(),
    (a: string) => a.length > 2,
    (a: boolean) => ({ value: a }),
];

type A = typeof FUNCS;

type GetCallbackChainArgType<T extends readonly ((arg: any) => any)[]> = T extends readonly [infer A, ...any[]] ? FirstParameter<A> : never;
type GetCallbackChainReturnType<T extends readonly ((arg: any) => any)[]> = T extends readonly [...any[], infer A] ? GetReturnType<A> : never;

type B = GetCallbackChainArgType<A>;
type C = GetCallbackChainReturnType<A>;

let a = runCallbackChain(FUNCS, 4);

function runCallbackChain<T extends readonly ((arg: any) => any)[]>(chain: T, arg: GetCallbackChainArgType<T>, index = 0): GetCallbackChainReturnType<T> {
    let callback = chain[index];

    if (!callback) {
        return arg as any;
    } else {
        let result = callback(arg);

        if (result instanceof Promise) {
            return result.then(value => runCallbackChain(chain, value, index + 1)) as any;
        } else {
            return runCallbackChain(chain, result, index + 1);
        }
    }
}