import { WebglShaderKind } from './webgl-types.ts';

export async function compileWebglProgram(gl: WebGL2RenderingContext, shaders: { [Key in WebglShaderKind]: string }): Promise<WebGLProgram> {
    let ext = gl.getExtension('KHR_parallel_shader_compile');
    let program = gl.createProgram() ?? abort('failed to create webgl program');
    let vertexShader = gl.createShader(gl.VERTEX_SHADER) ?? abort('failed to create vertex shader');
    let fragmentShader = gl.createShader(gl.FRAGMENT_SHADER) ?? abort('failed to create fragment shader');

    gl.shaderSource(vertexShader, shaders.vertex);
    gl.shaderSource(fragmentShader, shaders.fragment);
    gl.compileShader(vertexShader);
    gl.compileShader(fragmentShader);
    gl.attachShader(program, vertexShader);
    gl.attachShader(program, fragmentShader);
    gl.linkProgram(program);
    gl.flush();

    // console.time('compile');
    if (ext) {
        // await waitForNextFrame();
        while (!gl.getProgramParameter(program, ext.COMPLETION_STATUS_KHR)) {
            await waitForNextFrame();
        }
    }
    // console.timeEnd('compile');

    let success = gl.getProgramParameter(program, gl.LINK_STATUS);

    if (!success) {
        displayErrorMessage(gl.getProgramInfoLog(program));
        displayErrorMessage(gl.getShaderInfoLog(vertexShader));
        displayErrorMessage(gl.getShaderInfoLog(fragmentShader));

        gl.deleteShader(vertexShader);
        gl.deleteShader(fragmentShader);
        gl.deleteProgram(program);

        throw new Error(`failed to compile webgl program`);
    }

    return program;
}

function isCompilationFinished(gl: WebGL2RenderingContext, ext: KHR_parallel_shader_compile, program: WebGLProgram): boolean {
    console.time('check');
    let result = gl.getProgramParameter(program, ext.COMPLETION_STATUS_KHR);
    console.timeEnd('check');

    return result;
}

async function waitForNextFrame() {
    return new Promise<void>(resolve => requestAnimationFrame(() => resolve()));
    // return new Promise<void>(resolve => setTimeout(resolve, 1000));
}

function displayErrorMessage(message: string | null) {
    if (message) {
        console.error(message);
    }
}

function abort(message: string): never {
    throw new Error(message);
}