import WebSocket from 'ws';
import { OUTPOST_ENV } from '../../environment.ts';

export function setupMockWindow(global: any) {
    if (typeof window !== 'undefined') {
        return;
    }

    let windowMock = {
        OUTPOST_APP_PORT: OUTPOST_ENV.APP_PORT,
        innerWidth: 1600,
        innerHeight: 900,
        location: {
            protocol: 'http',
            hostname: 'localhost',
        },
        localStorage: new DummyLocalStorage(),
        document: new DummyHtmlElement(),
        WebSocket: WebSocket,
        HTMLInputElement: class extends DummyHtmlElement {},
        addEventListener: () => {},
        removeEventListener: () => {},
        requestAnimationFrame: (callback: Function) => setTimeout(callback, 15),
        cancelAnimationFrame: (timer: NodeJS.Timeout) => clearTimeout(timer),
        MOCK: true,
    };

    global.window = windowMock;
    global.window.document.body = new DummyHtmlElement();

    for (let key in windowMock) {
        global[key] = (windowMock as any)[key];
    }
}

class DummyLocalStorage {
    getItem() { return  null; }
    setItem() {}
    clear() {}
}

class DummyHtmlElement {
    style = {};

    width = 1600;
    height = 900;

    addEventListener() {}
    removeEventListener() {}
    getContext() {
        return new DummyCanvasContext(this);
    }
    createElement() {
        return new DummyHtmlElement();
    }
    appendChild() {}
    removeChild() {}
    setSelectionRange() {}
    focus() {}
}

class DummyCanvasContext {
    canvas: DummyHtmlElement;

    constructor(canvas: DummyHtmlElement) {
        this.canvas = canvas;
    }

    measureText() { return { width: 1 }; }
    fillText() {}
    clearRect() {}
    drawImage() {}

    createRenderbuffer() { return 1; }
    createFramebuffer() { return 1; }
    createProgram() { return 1; }
    createShader() { return 1; }
    createBuffer() { return 1; }
    createVertexArray() { return 1; }
    createTexture() { return 1; }
    bindBuffer() {}
    bindRenderbuffer() {}
    bindFramebuffer() {}
    bindVertexArray() {}
    bindTexture() {}
    enableVertexAttribArray() {}
    renderbufferStorage() {}
    framebufferRenderbuffer() {}
    viewport() {}
    enable() {}
    blendFunc() { return { width: 1 } };
    getProgramParameter() { return true; }
    getExtension() { return {}; }
    shaderSource() {}
    compileShader() {}
    attachShader() {}
    linkProgram() {}
    flush() {}
    getProgramInfoLog() { return '<mock>' }
    getShaderInfoLog() { return '<mock>' }
    deleteShader() {}
    deleteProgram() {}
    getAttribLocation() { return 1; }
    getUniformLocation() { return 1; }
    drawBuffers() { }
    clearColor() { }
    bufferData() { }
    clear() { }
    vertexAttribPointer() {}
    vertexAttribIPointer() {}
    vertexAttribDivisor() {}
    texParameteri() {}
    texImage2D() {}
    texImage3D() {}
    generateMipmap() {}
    disable() {}
    activeTexture() {}
    blitFramebuffer() {}
    useProgram() {}
    uniform1f() {}
    uniform2fv() {}
    uniform1i() {}
    drawArraysInstanced() {}
    readPixels() {}
    fenceSync() {}
    clientWaitSync() {}
    texSubImage3D() {}
}
globalThis.ALL_FUNCTIONS.push(DummyLocalStorage);
globalThis.ALL_FUNCTIONS.push(DummyHtmlElement);
globalThis.ALL_FUNCTIONS.push(DummyCanvasContext);