import { LobbyRoom } from '../lobby-room/lobby-room.ts';
import { LobbyPlayer } from '../lobby-room/lobby-player.ts';
import { LobbyPreview } from '../lobby-room/lobby-preview.ts';
import { HubPlayer } from './hub-player.ts';
import { BaseHubRoom } from '../base-hub-room.ts';
import { View } from '../../framework/view/view.ts';
import { Button } from '../../helpers/widgets/button.ts';
import { Label } from '../../helpers/widgets/label.ts';
import { Component } from '../../framework/component/component.ts';
import { Room } from '../../framework/global/global-room-api.ts';
import { MatchmakingManager } from '../../helpers/matchmaking/matchmaking.ts';
import { getRangeStart } from '../../utils/language/range.ts';
import { LOBBY_PLAYER_COLORS } from '../lobby-room/lobby-constants.ts';
import { GearSelectionPanel } from '../gear/gear-selection-panel.ts';
import { GameHubParams } from '../hub.ts';

export class HubRoom extends BaseHubRoom implements Component {
    titleLabel = new Label();
    createGameButton = new Button('Create game');
    joinGameButton = new Button('Join game');
    backButton = new Button('Back');
    logoutButton = new Button('Logout');
    matchmakingButton = new Button('Find game');
    usernameLabel = new Label();
    lobbies: Map<string, LobbyPreview> = new Map();
    showLobbies: boolean = false;
    autoJoin: boolean = false;
    matchmaking: MatchmakingManager<HubPlayer> | null = null;
    selectGearButton = new Button('Pick gear');

    constructor(params: GameHubParams) {
        super(params);

        if (params.gearName) {
            this.selectGearButton.setText(params.gearName);
        }
    }

    onSpawn(): void {
        this.autoJoin = this.params.autoJoin ?? false;

        if (this.params.matchmaking) {
            this.matchmaking = new MatchmakingManager({
                teamCount: 1,
                teamSize: getRangeStart(this.params.playerCount, 1)
            });
        }
    }

    onMount(): void {
        Room.configureRenderer(this.params.renderer);
        this.usernameLabel.setText(Room.getLocalPlayerId()!);
        this.titleLabel.setText(this.gameName);
    }

    onPlayerAddedToOtherRoom(roomId: string): void {
        let player = Room.getSourcePlayer(HubPlayer);
        let roomInfo = Room.getRoomInfo(roomId)!;

        if (roomInfo.roomConstructor === this.params.gameConstructor) {
            player.gameId = roomId;
        }
    }

    onPlayerRemovedFromOtherRoom(roomId: string): void {
        let player = Room.getSourcePlayer(HubPlayer);

        if (player.gameId === roomId) {
            player.gameId = null;
        } else if (player.lobby?.id === roomId) {
            player.lobby.playerCount -= 1;

            if (player.lobby.playerCount === 0) {
                this.deleteLobby(player.lobby.id);
            }

            player.lobby = null;
        }
    }

    onPlayerAdded() {
        let player = Room.getSourcePlayer(HubPlayer);

        player.hasValidGear = new GearSelectionPanel(this.params).isGearValid(player.gear);

        if (this.autoJoin) {
            let playerToAdd = this.createPlayer(player, 0);

            Room.createRoom(this.params.gameConstructor, {
                constructorArgs: [{ dataSpreadsheet: [] }],
                players: [[player.id, playerToAdd]]
            });
        }
    }

    onPlayerDisconnected() {
        let player = Room.getSourcePlayer(HubPlayer);

        if (this.matchmaking) {
            this.disableMatchmaking(player);
        }
    }

    async $toggleMatchmaking() {
        let player = Room.getSourcePlayer(HubPlayer);

        if (!this.matchmaking) {
            return;
        }

        await Room.waitForUserInput({
            selectable: this.matchmakingButton,
            isEnabled: () => player.isIdle() && player.hasValidGear
        });
        await Room.waitForServerResponse();

        if (!player.isInMatchmaking) {
            this.enableMatchmaking(player);
        } else {
            this.disableMatchmaking(player);
        }

        let entries = this.matchmaking.extractMatches();

        for (let teams of entries) {
            let players = teams[0];
            let playersToAdd: [string, Component][] = [];

            for (let i = 0; i < players.length; ++i) {
                let player = players[i];

                playersToAdd.push([player.id, this.createPlayer(player, i)]);
            }

            let gameId = Room.createRoom(this.params.gameConstructor, {
                constructorArgs: [{ dataSpreadsheet: [] }],
                players: playersToAdd
            });

            for (let player of players) {
                player.gameId = gameId;

                this.disableMatchmaking(player);
            }
        }
    }

    private enableMatchmaking(player: HubPlayer) {
        this.matchmaking?.addPlayer(player);
        player.isInMatchmaking = true;

        if (Room.isLocalPlayer(player)) {
            this.matchmakingButton.setText('Cancel search');
            Room.refresh(this);
        }
    }

    private disableMatchmaking(player: HubPlayer) {
        this.matchmaking?.removePlayer(player);
        player.isInMatchmaking = false;

        if (Room.isLocalPlayer(player)) {
            this.matchmakingButton.setText('Find game');
            Room.refresh(this);
        }
    }

    render(view: View) {
        if (this.params.renderHubRoom) {
            this.params.renderHubRoom(view, this);
        } else {
            this.defaultRender(view);
        }
    }

    defaultRender(view: View): void {
        view.addChild(this.titleLabel, view.rect.fromTopInwards('*', '20%'));

        if (this.showLobbies) {
            this.renderLobbies(view);
        } else {
            this.renderHome(view);
        }

        view.addChild(this.usernameLabel, view.rect.fromTopRightInwards(100, 70, 10));
    }

    private renderHome(view: View) {
        let layout = view.layout()
            .centerToBottom()
            .width('30%')
            .innerMargin('5%')
            .childAspectRatio(5)
            .addChild(this.createGameButton)
            .addChild(this.joinGameButton)
            .scale(0.9);

        if (this.params.matchmaking) {
            layout = layout
                .addChild(this.matchmakingButton)
                .scale(0.9);
        }

        if (this.params.gearCategories?.length) {
            view.addChild(this.selectGearButton, view.rect.fromBottomInwards('30%', '*', '5%').stripToMatchAspectRatio(5, 'bottom'));
        }

        view.addChild(this.logoutButton, view.rect.fromTopLeftInwards(100, 50, 10));
    }

    private renderLobbies(view: View) {
        view.addGrid({
            rect: rect => rect.fromBottomInwards('*', '80%'),
            items: this.lobbies.values(),
            rowSize: 2,
            columnSize: [4, Infinity],
            itemAspectRatio: 5,
            margin: 5,
            verticalAlign: 'top',
        });

        view.addChild(this.backButton, view.rect.fromTopLeftInwards(100, 50, 10));
    }

    async $createLobbyInteraction() {
        let player = Room.getSourcePlayer(HubPlayer);

        await Room.waitForUserInput({
            selectable: this.createGameButton,
            isEnabled: () => player.lobby === null && !player.isInMatchmaking && player.hasValidGear
        });

        await Room.waitForServerResponse();

        let lobby = this.createLobby(`Game of ${player.id}`);

        this.joinLobby(player, lobby);

        Room.render();
    }

    private createLobby(lobbyName: string): LobbyPreview {
        let lobbyId = Room.createRoom(LobbyRoom, {
            constructorArgs: [this.params, {
                name: lobbyName
            }]
        });

        let lobbyPreview = new LobbyPreview(this.params, {
            id: lobbyId,
            name: lobbyName
        });

        this.lobbies.set(lobbyId, lobbyPreview);

        return lobbyPreview;
    }

    async $displayLobbiesInteraction() {
        await Room.waitForUserInput({
            selectable: this.joinGameButton,
            isEnabled: () => this.lobbies.size > 0
        });

        this.showLobbies = true;
        Room.render();
    }

    async $hideLobbiesInteraction() {
        await Room.waitForItemSelection(this.backButton);

        this.showLobbies = false;
        Room.render();
    }

    async $joinLobbyInteraction() {
        let player = Room.getSourcePlayer(HubPlayer);
        let lobbies = [...this.lobbies.values()];
        let input = await Room.waitForUserInput({
            selectable: () => lobbies.filter(lobby => lobby.canAcceptPlayers()).map(lobby => lobby.joinButton),
            isEnabled: () => !player.isInMatchmaking && player.hasValidGear
        });
        let lobby = lobbies.find(lobby => lobby.joinButton === input.selection)!;

        await Room.waitForServerResponse();

        Room.withSourcePlayer(() => this.showLobbies = false);

        this.joinLobby(player, lobby);
    }

    private joinLobby(player: HubPlayer, lobby: LobbyPreview) {
        player.lobby = lobby;
        lobby.playerCount += 1;

        Room.addPlayerToRoom(lobby.id, player.id, new LobbyPlayer(player.id));

        if (Room.getLocalPlayerId() !== player.id) {
            Room.render();
        }
    }

    async $logoutInteraction() {
        await Room.waitForItemSelection(this.logoutButton);

        Room.withSourcePlayer(() => window.localStorage.removeItem('username'));

        await Room.waitForServerResponse();

        Room.deauthenticatePlayer(Room.getSourcePlayerId());
    }

    private deleteLobby(lobbyId: string) {
        this.lobbies.delete(lobbyId);
        Room.deleteRoom(lobbyId);
    }

    private createPlayer(player: HubPlayer, index: number) {
        return new this.params.playerConstructor({
            id: player.id,
            color: LOBBY_PLAYER_COLORS[index % LOBBY_PLAYER_COLORS.length],
        });
    }

    async $selectGear() {
        let player = Room.getSourcePlayer(HubPlayer);

        await Room.waitForUserInput({
            selectable: this.selectGearButton,
            isEnabled: () => !player.isInMatchmaking && player.isIdle()
        });

        let gearSelectionPanel = new GearSelectionPanel(this.params);
        let result = await Room.prompt(gearSelectionPanel);

        await Room.waitForServerResponse();

        player.gear = result;
        player.hasValidGear = gearSelectionPanel.isGearValid(result);
    }
}
globalThis.ALL_FUNCTIONS.push(HubRoom);