import { GracefulAbort } from '../../utils/language/error.ts';
import { FlexBuffer } from '../../utils/serialization/flex-buffer.ts';
import { deserializeValueSafe, serializeValue } from '../../utils/type-schema/type-schema.ts';
import { ClientApiParams, RetrieveClientDataParams } from './client-api-types.ts';
import { ClientData } from './client-data.ts';
import { Client } from './client.ts';

export class ClientApi {
    readonly client: Client | null;
    protected preLoadedData: ClientData[] | null = null;
    protected savedData: Promise<ClientData | null>[] | null = null;
    protected destroyed: boolean = true;

    constructor(client: Client | null) {
        this.client = client;
    }

    reset(params: ClientApiParams = {}) {
        this.destroy();
        this.destroyed = false;
        this.preLoadedData = params.preLoadedData?.slice() ?? null;
        this.savedData = params.saveData ? [] : null;
    }

    destroy() {
        this.destroyed = true;
    }

    protected retrieveClientData<T>(params: RetrieveClientDataParams<T>): T {
        if (this.preLoadedData) {
            let item = this.preLoadedData?.shift();

            if (item === undefined) {
                throw new GracefulAbort(`client data: no more data available for "${params.kind}"`);
            } else if (item.kind !== params.kind) {
                throw new GracefulAbort(`client data: expected "${item.kind}", got "${params.kind}"`);
            } else {
                let result = deserializeValueSafe(params.schema, new FlexBuffer(item.data));

                if (!result.isOk()) {
                    throw new GracefulAbort(`client data: deserialization error ("${params.kind}")`)
                }

                return result.data!;
            }
        } else if (this.client) {
            let data = params.callback(this.client);

            if (this.savedData) {
                let promise = Promise.resolve(data).then(value => ({
                    kind: params.kind,
                    data: serializeValue(params.schema, new FlexBuffer(), value).copyBytes()
                })).catch(() => null);

                this.savedData.push(promise);
            }

            return data;
        }

        throw new Error('unreachable');
    }

    isDestroyed(): boolean {
        return this.destroyed;
    }
}
globalThis.ALL_FUNCTIONS.push(ClientApi);