import { BoardZoneName, BoardZoneMetadata } from './board-types';

export const BOARD_ZONES: { [Ket in BoardZoneName]: BoardZoneMetadata } = {
    hand: {
        label: 'Hand',
        kind: 'spread',
        visibility: 'self',
        autoFlip: 'front',
    },
    battlefield: {
        label: 'Battlefield',
        kind: 'spread',
        visibility: 'all',
        autoFlip: null,
    },
    deck: {
        label: 'Deck',
        kind: 'stack',
        visibility: 'all',
        autoFlip: 'back'
    },
    graveyard: {
        label: 'Graveyard',
        kind: 'stack',
        visibility: 'all',
        autoFlip: 'front',
    },
    exile: {
        label: 'Exile',
        kind: 'stack',
        visibility: 'all',
        autoFlip: 'front',
    },
    stack: {
        label: 'Stack',
        kind: 'spread',
        visibility: 'all',
        autoFlip: null,
    },
    hero: {
        label: 'Hero',
        kind: 'spread',
        visibility: 'all',
        autoFlip: null,
    }
};

export const TOOLTIP_SHOW_DELAY = 500;
export const TOOLTIP_HIDE_DELAY = 100;
export const TOOLTIP_QUICK_SHOW_DELAY = 500;