export type CallbackQueueItem<T> = {
    callback: () => T | Promise<T>;
    resolve: (result: T) => void;
}

export class CallbackQueue {
    private items: CallbackQueueItem<any>[] = [];
    private ongoing: boolean = false;

    get size() {
        return this.items.length;
    }

    queue<T>(callback: () => T | Promise<T>, immediate: boolean = false): Promise<T> {
        return new Promise(resolve => {
            if (immediate) {
                this.items.unshift({ callback, resolve });
            } else {
                this.items.push({ callback, resolve });
            }
            this.process();
        });
    }

    private async process(): Promise<void> {
        if (this.ongoing) {
            return;
        }

        let item = this.items.shift();

        if (item) {
            let { callback, resolve } = item;

            this.ongoing = true;
            let result = await callback();
            resolve(result);
            this.ongoing = false;
            return this.process();
        }
    }
}
globalThis.ALL_FUNCTIONS.push(CallbackQueue);