import { KeyCode } from '../../utils/dom/dom-events/dom-events-types.ts';
import { Point } from '../../utils/geometry/point.ts';
import { Collection, CollectionItem } from '../../utils/language/collection.ts';
import { ComponentModifier } from '../component/component-modifier.ts';
import { ComponentHighlightMethodName } from '../component/component-types.ts';
import { Component } from '../component/component.ts';
import { LayerId } from '../graphics-engine/layer-types.ts';
import { ViewFragmentPriority } from '../view/view-types.ts';
import { UserInput } from './user-input.ts';

export type WaitForUserInputParams<
    A extends Collection<Component> = never,
    B = never,
    C extends Collection<Component> = never,
    D extends Collection<Component> = never
> = {
    selectable?: A | (() => A);
    dimmed?: C | (() => C);
    shortcuts?: Partial<{ [Key in GenericButtonCombination]: B }>;
    isEnabled?: (component: CollectionItem<A>) => boolean,
    linked?: (component: CollectionItem<A>) => D,
    checkIsEnabledOn?: UserInputIsEnabledTarget;
    predicate?: (input: UserInput<CollectionItem<A> | B>) => boolean;
    selectionTrigger?: Collection<UserInputTrigger>;
    selectionButton?: Collection<GenericButtonName>;
    shortcutTrigger?: Collection<UserInputTrigger>;
    abortOnInvalidSelection?: boolean;
    capture?: boolean;
    layerId?: LayerId;
    highlightEnabled?: ComponentModifier<CollectionItem<A>>;
    highlightDimmed?: ComponentModifier<CollectionItem<C>>;
    highlightHovered?: ComponentModifier<CollectionItem<A>>;
    highlightFocused?: ComponentModifier<CollectionItem<A>>;
    highlightPressed?: ComponentModifier<CollectionItem<A>>;
    highlightDisabled?: ComponentModifier<CollectionItem<A>>;
    highlightLinked?: ComponentModifier<CollectionItem<D>>;
};

export type UserInputTrigger = typeof USER_INPUT_TRIGGERS[number];
export const USER_INPUT_TRIGGERS = <const>['click', 'down', 'up', 'drag', 'move', 'hover', 'unhover', 'focus', 'unfocus', 'text', 'unknown'];

export type UserInputSource = typeof USER_INPUT_SOURCES[number];
export const USER_INPUT_SOURCES = <const>[null, 'mouse', 'keyboard', 'wheel'];

export type UserInputIsEnabledTarget = 'client' | 'both';

/**
 * Represents either a key code, or a common mouse button.
 */
export type GenericButtonName = 'None' | KeyCode | typeof MOUSE_LEFT_NAME | typeof MOUSE_MIDDLE_NAME | typeof MOUSE_RIGHT_NAME | typeof SCROLL_NAME;
/**
 * A {@link GenericButtonName}, possibly prefixed by one or more modifier.
 * @example
 * let searchShortcut: GenericButtonCombination = 'Ctrl_KeyF';
 * let submitShortcut: GenericButtonCombination = 'Enter';
 * let newLineShortcut: GenericButtonCombination = 'Shift_Enter';
 * let zoomShortcut: GenericButtonCombination = 'Ctrl_Scroll';
 * let cheatCodeShortcut: GenericButtonCombination = 'Ctrl_Shift_KeyS';
 */
export type GenericButtonCombination = `${'Meta_' | ''}${'Ctrl_' | ''}${'Shift_' | ''}${'Alt_' | ''}${GenericButtonName}`;
export type GenericButtonAction = 'down' | 'up';
export type GenericButtonInput = {
    button: GenericButtonCombination;
    action: GenericButtonAction;
};

export const USER_INPUT_HIGHLIGHTS = {
    'enabled': [ViewFragmentPriority.Highlighted, 'highlightEnabled'],
    'dimmed': [ViewFragmentPriority.Dimmed, 'highlightDimmed'],
    'disabled': [ViewFragmentPriority.Disabled, 'highlightDisabled'],
    'hovered': [ViewFragmentPriority.Hovered, 'highlightHovered'],
    'linked': [ViewFragmentPriority.Linked, 'highlightLinked'],
    'pressed': [ViewFragmentPriority.Pressed, 'highlightPressed'],
    'focused': [ViewFragmentPriority.Focused, 'highlightFocused'],
} satisfies { [key: string]: [ViewFragmentPriority, ComponentHighlightMethodName]; };

export type UserInputHighlight = keyof typeof USER_INPUT_HIGHLIGHTS;

export const MODIFIER_KEYS = ['Meta', 'Control', 'Shift', 'Alt'];

export const MOUSE_LEFT_NAME = 'MouseLeft';
export const MOUSE_MIDDLE_NAME = 'MouseMiddle';
export const MOUSE_RIGHT_NAME = 'MouseRight';
export const SCROLL_NAME = 'Scroll';

export const META_PREFIX = 'Meta_';
export const CTRL_PREFIX = 'Ctrl_';
export const SHIFT_PREFIX = 'Shift_';
export const ALT_PREFIX = 'Alt_';

export type PressData = {
    value: any;
    position: Point;
    timestamp: number;
};

export function getIsEnabledCallback(params: WaitForUserInputParams<any, any, any, any>): (component: Component) => boolean {
    return params.isEnabled ?? ((component: Component) => component.isEnabled?.() ?? true);
}

export type UserInputTargetData = {
    value: any;
    isShortcut?: boolean;
};