import crypto from 'crypto';
import { base64ToArrayBuffer, arrayBufferToBase64 } from './base64.ts';
import { HASH_ALGORITHM_NAME, RSA_ALGORITHM_NAME, RSA_MODULUS_LENGTH } from './rsa-constants.ts';

export type RsaKeyPair = {
    publicKey: string;
    privateKey: string;
};

export type RsaKeyKind = "public" | "private";
export type RsaKeyFormat = "spki" | "pkcs8";
export type RsaKeyUsage = "encrypt" | "decrypt";

function getRsaKeyFormat(kind: RsaKeyKind): RsaKeyFormat {
    if (kind === "public") {
        return "spki";
    } else {
        return "pkcs8";
    }
}

function getRsaKeyKind(pem: string): RsaKeyKind {
    if (pem.includes("PUBLIC")) {
        return "public";
    } else if (pem.includes("PRIVATE")) {
        return "private";
    } else {
        throw new Error(`cannot find key kind from PEM content`);
    }
}

export async function generateRsaKeyPair(): Promise<RsaKeyPair> {
    let keyPair = await crypto.subtle.generateKey(
        {
            name: RSA_ALGORITHM_NAME,
            modulusLength: RSA_MODULUS_LENGTH,
            publicExponent: new Uint8Array([1, 0, 1]),
            hash: { name: HASH_ALGORITHM_NAME },
        },
        true,
        ["encrypt", "decrypt"]
    );

    let publicKey = await exportRsaKey(keyPair.publicKey, "public");
    let privateKey = await exportRsaKey(keyPair.privateKey, "private");

    return { publicKey, privateKey };
}

export async function importRsaKey(keyPem: string, usage: RsaKeyUsage): Promise<CryptoKey> {
    let kind = getRsaKeyKind(keyPem);
    let format = getRsaKeyFormat(kind);
    let base64Data: string = keyPem.split("\n")[1];
    let keyData: ArrayBuffer = base64ToArrayBuffer(base64Data)!;
    let algorithm = {
        name: RSA_ALGORITHM_NAME,
        hash: HASH_ALGORITHM_NAME,
    };

    return await crypto.subtle.importKey(format, keyData, algorithm, true, [usage]);
}

export async function exportRsaKey(key: CryptoKey, kind: RsaKeyKind): Promise<string> {
    let format = getRsaKeyFormat(kind);
    let qualifier = kind.toUpperCase();
    let exported = (await crypto.subtle.exportKey(format as any, key)) as ArrayBuffer;
    let base64 = arrayBufferToBase64(exported);
    let keyPem = `-----BEGIN ${qualifier} KEY-----\n${base64}\n-----END ${qualifier} KEY-----`;

    return keyPem;
}
