import { isReadonlyArray } from '../language/array.ts';
import { booleanSchema } from './boolean-schema.ts';
import { TypeSchema } from './type-schema.ts';
import { enumSchema, getCachedEnumSchema } from './enum-schema.ts';
import { numberSchema } from './number-schema.ts';
import { stringSchema } from './string-schema.ts';
import { valueSchema } from './value-schema.ts';
import { bufferSchema } from './buffer-schema.ts';
import { anySchema } from './any-schema.ts';
import { IsAny } from '../language/types.ts';
import { bigintSchema } from './bigint-schema.ts';

/** */
export type TypeSchemaLike<T> = TypeSchemaAlias<T> | TypeSchema<T> | 'any';

/** */
export type TypeSchemaAlias<T> =
    IsAny<T> extends true ? 'null' | 'boolean' | 'number' | 'integer' | 'bigint' | 'string' | 'buffer' | readonly string[] | TypeSchema<any> :
    [T] extends [null] ? 'null' :
    [T] extends [boolean] ? 'boolean' :
    [T] extends [number] ? 'number' | 'integer' :
    [T] extends [bigint] ? 'bigint' :
    [T] extends [string] ? 'string' | readonly string[] :
    [T] extends [Uint8Array] ? 'buffer' :
    never;

/** */
export type MapTypeSchemaLike<T extends TypeSchemaLike<any>> =
    T extends 'any' ? any :
    T extends 'null' ? null :
    T extends 'boolean' ? boolean :
    T extends 'number' | 'integer' ? number :
    T extends 'bigint' ? bigint :
    T extends 'string' ? string :
    T extends 'buffer' ? Uint8Array :
    T extends TypeSchemaLike<infer U> ? U :
    never;

export function formatTypeSchema<T>(dataTypeLike: TypeSchemaLike<T>): TypeSchema<T> {
    if (typeof dataTypeLike === 'object' && 'serialize' in dataTypeLike) {
        return dataTypeLike;
    }

    let result: any = dataTypeLike;

    if (isReadonlyArray(dataTypeLike)) {
        result = getCachedEnumSchema(dataTypeLike);
    } else if (dataTypeLike === 'null') {
        result = DEFAULT_NULL_TYPE;
    } else if (dataTypeLike === 'boolean') {
        result = DEFAULT_BOOLEAN_TYPE;
    } else if (dataTypeLike === 'number') {
        result = DEFAULT_NUMBER_TYPE;
    } else if (dataTypeLike === 'integer') {
        result = DEFAULT_INTEGER_TYPE;
    } else if (dataTypeLike === 'bigint') {
        result = DEFAULT_BIGINT_TYPE;
    } else if (dataTypeLike === 'string') {
        result = DEFAULT_STRING_TYPE;
    } else if (dataTypeLike === 'buffer') {
        result = DEFAULT_BUFFER_TYPE;
    } else if (dataTypeLike === 'any') {
        result = DEFAULT_ANY_TYPE;
    } else if (typeof dataTypeLike === 'string') {
        throw new Error(`unkown type schema "${dataTypeLike}"`);
    }

    return result;
}

const DEFAULT_ANY_TYPE = anySchema();
const DEFAULT_NULL_TYPE = valueSchema(null);
const DEFAULT_BOOLEAN_TYPE = booleanSchema();
const DEFAULT_NUMBER_TYPE = numberSchema();
const DEFAULT_INTEGER_TYPE = numberSchema({ integer: true });
const DEFAULT_BIGINT_TYPE = bigintSchema();
const DEFAULT_STRING_TYPE = stringSchema();
const DEFAULT_BUFFER_TYPE = bufferSchema();