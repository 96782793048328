/**
 * Used by many methods of {@link AnimationQueue}.
 * Tweaks how fast the time goes at different points in an anmation.
 * 
 * - `"linear"`: linear time
 * - `"quadratic-in"`: starts slow, then accelerates as time passes
 * - `"quadratic-out"`: starts fast, then decelerates as time passes
 * - `"quadratic-in-out"`: starts slow, then accelerates until half of the animation, then decelerates
 * - `"cubic-in"`: same as `"quadratic-in"`, but more stressed
 * - `"cubic-out"`: same as `"quadratic-out"`, but more stressed
 * - `"cubic-in-out'"`: same as `"quadratic-in-out"`, but more stressed
 */
// export type Easing =
//     | 'linear'
//     | 'quadratic-in'
//     | 'quadratic-out'
//     | 'quadratic-in-out'
//     | 'cubic-in'
//     | 'cubic-out'
//     | 'cubic-in-out'

const EASING_FUNCTIONS = {
    'linear': (t: number) => t,
    'quadratic-in': (t: number) => t * t,
    'quadratic-out': (t: number, u: number = 1 - t) => 1 - u * u,
    'quadratic-in-out': (t: number, u: number = 1 - t) => t < 0.5 ? t * t : 1 - u * u,
    'cubic-in': (t: number) => t * t * t,
    'cubic-out': (t: number, u: number = 1 - t) => 1 - u * u * u,
    'cubic-in-out': (t: number, u: number = 1 - t) => t < 0.5 ? t * t * t : 1 - u * u * u,
} satisfies { [key: string]: (t: number) => number }

export type Easing = keyof typeof EASING_FUNCTIONS | ((t: number) => number);

export function applyEasing(easing: Easing, t: number) {
    if (typeof easing === 'function') {
        return easing(t);
    }

    let func = EASING_FUNCTIONS[easing];

    return func(t);
}

export function getEasingFunction(easing: Easing): (t: number) => number {
    if (typeof easing === 'function') {
        return easing;
    }

    return EASING_FUNCTIONS[easing];
}