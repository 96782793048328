import { HASH_STOARGE_KEY, PROJECT_STOARGE_KEY, SPREADSHEET_URL_STORAGE_KEY } from './constants';
import { clearDb, loadImageFromDb, storeImagesToDb } from './indexed-db';
import { LocalStorage } from './local-storage';
import { Project } from './project';

export async function loadProjectFromCache(requiredHash?: string): Promise<Project | null> {
    let storedHash = LocalStorage.readString(HASH_STOARGE_KEY);

    if (!storedHash || (requiredHash && storedHash !== requiredHash)) {
        return null;
    }

    let project = LocalStorage.readObject(Project, PROJECT_STOARGE_KEY);
    let cardsWithImages = project?.getCardsWithImage() ?? [];

    for (let card of cardsWithImages) {
        let image = await loadImageFromDb(card.imageId);

        if (!image) {
            console.error(`Failed to load image ${card.imageId}`);
            continue;
        }

        card.image = image;
    }

    return project;
}

export async function saveProjectToCache(project: Project | null) {
    if (!project) {
        await clearCache();
        return;
    }

    let projectHash = project.getHash();

    await clearDb();

    let imagesToStore = project?.getCardsWithImage().map(card => ({
        id: card.imageId,
        image: card.image!
    }));

    await storeImagesToDb(imagesToStore ?? []);

    LocalStorage.writeString(HASH_STOARGE_KEY, projectHash);
    LocalStorage.writeObject(PROJECT_STOARGE_KEY, project);
}

export async function clearCache() {
    localStorage.removeItem(HASH_STOARGE_KEY);
    localStorage.removeItem(PROJECT_STOARGE_KEY);
    await clearDb();
}

