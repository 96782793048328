import { getNextPowerOfTwo } from '../language/math.ts';
import { PointerStack } from './pointer-stack.ts';

export type SimpleMemoryAllocatorParams = {
    totalMemorySize: number;
};

export class SimpleMemoryAllocator {
    private totalMemorySize: number;
    private releasedBlocks: PointerStack[] = [];
    private availableBlockOffset: number = 1;
    private availableBlockSize: number = 0;
    private blockSizes: Uint8Array = new Uint8Array(32);
    private totalAllocationCount: number = 0;
    private totalDeallocationCount: number = 0;

    constructor(params: SimpleMemoryAllocatorParams) {
        this.totalMemorySize = params.totalMemorySize;
        this.availableBlockSize = params.totalMemorySize;
    }

    allocate(blockSize: number): number | null {
        let releasedBlocks = this.releasedBlocks[blockSize];
        let address: number | null = null;

        if (releasedBlocks && !releasedBlocks.isEmpty()) {
            address = releasedBlocks.pop()!;
        } else {
            if (this.availableBlockSize < blockSize) {
                return null;
            }

            address = this.availableBlockOffset;

            this.availableBlockSize -= blockSize;
            this.availableBlockOffset += blockSize;
        }

        this.markBlockSize(address, blockSize);
        this.totalAllocationCount += 1;

        return address;
    }

    dealllocate(address: number) {
        let blockSize = this.blockSizes[address];

        if (!blockSize) {
            console.warn(`deallocate invalid address ${address}, that should not happen`);
            return;
        }

        let releasedBlocks = this.releasedBlocks[blockSize];

        if (!releasedBlocks) {
            releasedBlocks = new PointerStack();
            this.releasedBlocks[blockSize] = releasedBlocks;
        }

        releasedBlocks.push(address);
        this.markBlockSize(address, 0);
        this.totalDeallocationCount += 1;
    }

    private markBlockSize(address: number, blockSize: number) {
        if (address >= this.blockSizes.length) {
            let newLength = getNextPowerOfTwo(this.blockSizes.length);
            let newBlockSizes = new Uint8Array(newLength);

            newBlockSizes.set(this.blockSizes);
            this.blockSizes = newBlockSizes;
        }

        this.blockSizes[address] = blockSize;
    }
}
globalThis.ALL_FUNCTIONS.push(SimpleMemoryAllocator);