import { Column, Row } from '../base-components/container';
import { Project } from '../project';
import { DropDownList, DropDownListProps, DropDownOption } from '../base-components/drop-down-list';
import { Button } from '../base-components/button';
import { useState } from 'preact/hooks';
import { CSSProperties } from 'preact/compat';
import { onHidePlaySetup } from '../action-play';
import { App } from './app';
import { LocalStorage } from '../local-storage';
import { DECK_1_STORAGE_KEY, DECK_2_STORAGE_KEY } from '../constants';

export type PlaySetupPanelProps = {
    open: boolean;
    project: Project;
};

export function PlaySetupPanel(props: PlaySetupPanelProps) {
    let { open, project } = props;
    let [deckId1, setDeckId1] = useState<string | null>(readStoredDeck(DECK_1_STORAGE_KEY, project));
    let [deckId2, setDeckId2] = useState<string | null>(readStoredDeck(DECK_2_STORAGE_KEY, project));

    let selectStyle: CSSProperties = {
        padding: '2px',
    };
    let deckSelect1: DropDownListProps = {
        onChange: value => {
            setDeckId1(value);
            writeStoredDeck(DECK_1_STORAGE_KEY, value);
        },
        options: [
            { label: 'Choose deck 1', value: null },
            ...getDeckOptions(project, deckId1)
        ],
        style: selectStyle,
    };
    let deckSelect2: DropDownListProps = {
        onChange: value => {
            setDeckId2(value);
            writeStoredDeck(DECK_2_STORAGE_KEY, value);
        },
        options: [
            { label: 'Choose deck 2', value: null },
            ...getDeckOptions(project, deckId2)
        ],
        style: selectStyle,
    };

    let onClickPlay = () => {
        console.log(`Start play: ${deckId1} vs ${deckId2}`);
        App.instance.setState({
            initBoardCallback: localData => localData.board.initDecks(project, [deckId1!, deckId2!]),
            playPanelOpen: true,
            setupPlayOpen: false,
            isGuest: false,
        });
    };

    return (
        <Column hidden={!open} gap={15} justifyContent='center'>
            <Row gap={15}>
                <DropDownList {...deckSelect1} />
                <span style={{ position: 'reoative', top: '-1px' }}>VS</span>
                <DropDownList {...deckSelect2} />
            </Row>
            <Row justifyContent='center' gap={15}>
                <Button
                    label='Play'
                    onClick={onClickPlay}
                    disabled={!deckId1 || !deckId2}
                />
                <Button
                    label='Cancel'
                    onClick={onHidePlaySetup}
                />
            </Row>
        </Column>
    );
};

function readStoredDeck(key: string, project: Project): string | null {
    let value = LocalStorage.readString(key);

    if (!project || !project.getDeckById(value)) {
        return null;
    } else {
        return value;
    }
}

function writeStoredDeck(key: string, value: string | null) {
    if (!value) {
        LocalStorage.delete(key);
    } else {
        LocalStorage.writeString(key, value);
    }
}

function getDeckOptions(project: Project, selected: string | null): DropDownOption[] {
    return project.decks.map(deck => ({
        label: deck.name,
        value: deck.id,
        selected: deck.id === selected
    }));
}