import { GridResizeParams } from './grid-types.ts';

export type GridParams<T> = {
    makeItem: (x: number, y: number) => T;
    width: number;
    height: number;
};

type GridCellData<T> = {
    x: number;
    y: number;
    value: T;
};

export class Grid<T> {
    width: number;
    height: number;
    items: T[];

    // private makeItem: (x: number, y: number) => T;
    private cellData: GridCellData<T> = { x: 0, y: 0, value: undefined as any };

    constructor(params: GridParams<T>) {
        this.width = params.width;
        this.height = params.height;
        // this.makeItem = params.makeItem;
        this.items = new Array(this.width * this.height);

        for (let i = 0; i < this.items.length; ++i) {
            let x = i % this.width;
            let y = i / this.width | 0;

            this.items[i] = params.makeItem(x, y);
        }
    }

    private getIndex(x: number, y: number): number {
        return y * this.width + x;
    }

    get(x: number, y: number): T | undefined {
        if (x < 0 || x >= this.width || y < 0 || y >= this.height) {
            return undefined;
        }

        return this.items[this.getIndex(x, y)];
    }

    set(x: number, y: number, value: T) {
        if (x < 0 || x >= this.width || y < 0 || y >= this.height) {
            return;
        }

        this.items[this.getIndex(x, y)] = value;
    }

    resize(params: GridResizeParams<T>): this {
        let width = params.width ?? this.width;
        let height = params.height ?? this.height;
        let landmark = params.from ?? 'top-left';
        let fromLeft = landmark === 'top-left' || landmark === 'bottom-left';
        let fromTop = landmark === 'top-left' || landmark === 'top-right';

        if (width === this.width && height === this.height) {
            return this;
        }

        let newItems = new Array(width * height);

        for (let x = 0; x < width; ++x) {
            for (let y = 0; y < height; ++y) {
                let index = y * width + x;
                let srcX = fromLeft ? x : (this.width - width) + x;
                let srcY = fromTop ? y : (this.height - height) + y;

                newItems[index] = this.get(srcX, srcY) ?? params.makeItem(x, y);
            }
        }

        this.width = width;
        this.height = height;
        this.items = newItems;

        return this;
    }

    *cells() {
        for (let x = 0; x < this.width; ++x) {
            for (let y = 0; y < this.height; ++y) {
                this.cellData.x = x;
                this.cellData.y = y;
                this.cellData.value = this.items[this.getIndex(x, y)];

                yield this.cellData;
            }
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Grid);