import { Graphics } from '../../framework/graphics-engine/graphics/graphics.ts';

export type WidgetGraphics = {
    all?: Graphics;
    background?: Graphics;
    body?: Graphics;
    overlay?: Graphics;
    border?: Graphics;
};

export type WidgetStyle = {
    base?: WidgetGraphics;
    hovered?: WidgetGraphics;
    focused?: WidgetGraphics;
    disabled?: WidgetGraphics;
};

export function mergeWidgetStyles(defaultValues: WidgetStyle | undefined, userValues: WidgetStyle | undefined): WidgetStyle | undefined {
    if (!defaultValues && !userValues) {
        return undefined;
    } else if (!userValues) {
        return defaultValues;
    } else if (!defaultValues) {
        return userValues;
    }

    return {
        base: mergeWidgetGraphics(defaultValues.base, userValues.base),
        hovered: mergeWidgetGraphics(defaultValues.hovered, userValues.hovered),
        focused: mergeWidgetGraphics(defaultValues.focused, userValues.focused),
        disabled: mergeWidgetGraphics(defaultValues.disabled, userValues.disabled),
    };
}

export function mergeWidgetGraphics(defaultValues: WidgetGraphics | undefined, userValues: WidgetGraphics | undefined): WidgetGraphics | undefined {
    if (!defaultValues && !userValues) {
        return undefined;
    } else if (!userValues) {
        return defaultValues;
    } else if (!defaultValues) {
        return userValues;
    }

    return {
        all: mergeGraphics(defaultValues?.all, userValues?.all),
        background: mergeGraphics(defaultValues?.background, userValues?.background),
        body: mergeGraphics(defaultValues?.body, userValues?.body),
        overlay: mergeGraphics(defaultValues?.overlay, userValues?.overlay),
        border: mergeGraphics(defaultValues?.border, userValues?.border),
    };
}

export function mergeGraphics(defaultValues: Graphics | undefined, userValues: Graphics | undefined): Graphics | undefined {
    if (!defaultValues && !userValues) {
        return undefined;
    } else if (!userValues) {
        return defaultValues;
    } else if (!defaultValues) {
        return userValues;
    }

    return { ...defaultValues, ...userValues };
}