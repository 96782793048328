import { DisplaySizeProperties } from '../geometry/display-size.ts';
import { Counter } from '../data-structures/counter.ts';

const STRING_HASHES: Map<string, number> = new Map();
const REFERENCE_HASHES: WeakMap<object, number> = new WeakMap();

let gobalReferenceCounter = new Counter();

export class Hash {
    private value: number = 0;

    integer(n: number): this {
        let hash = this.value;

        hash = (hash << 5) - hash + n;
        hash &= hash

        this.value = hash;

        return this;
    }

    float(n: number): this {
        return this.integer(n * 1000000 | 0);
    }

    boolean(b: boolean): this {
        return this.integer(b ? 543254 : 642873);
    }

    color(color: { r: number, g: number, b: number, a: number; }): this {
        return this
            .integer(color.r * 255 | 0)
            .integer(color.g * 255 | 0)
            .integer(color.b * 255 | 0)
            .integer(color.a * 255 | 0);
    }

    displaySize(displaySize: DisplaySizeProperties): this {
        return this
            .float(displaySize.fixedReal)
            .float(displaySize.fixedVirtual)
            .float(displaySize.scaledFromParentWidth)
            .float(displaySize.scaledFromParentHeight)
            .float(displaySize.scaledFromParentMin);
    }

    string(string: string): this {
        for (let i = 0; i < string.length; ++i) {
            let code = string.charCodeAt(i);

            this.integer(code);
        }

        return this;
    }

    stringCached(string: string): this {
        let hash = STRING_HASHES.get(string);

        if (hash === undefined) {
            hash = new Hash().string(string).finish();
            STRING_HASHES.set(string, hash);
        }

        this.integer(hash);

        return this;
    }

    reference(object: object): this {
        let hash = REFERENCE_HASHES.get(object);

        if (!hash) {
            hash = gobalReferenceCounter.next();
            REFERENCE_HASHES.set(object, hash);
        }

        this.integer(hash);

        return this;
    }

    finish(): number {
        return this.value;
    }
}
globalThis.ALL_FUNCTIONS.push(Hash);